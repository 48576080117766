import { Divider, Stack, Textarea, rem } from '@mantine/core';
import { Formik, useFormikContext } from 'formik';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import parse from 'date-fns/parse';
import * as Yup from 'yup';

import BaseModal from '../BaseModal';
import ModalFooter from './ModalFooter';

import { MODALS } from 'constants/component';

import useModalStore from 'hooks/store/useModalStore';
import useAvailabilityRecords from 'hooks/users/useAvailabilityRecords';
import { SelectOption } from 'components/common/Select/types';
import { AvailabilityRecord } from 'types/api';
import AvailabilityRecordStatusSelect from 'components/common/Select/variants/AvailabilityRecordStatusSelect';
import InstanceUserSelect from 'components/common/Select/variants/InstanceUserSelect';
import DateRangePicker from 'components/common/DateRangePicker';
import { useMemo } from 'react';
import { DATE_FORMATS } from 'constants/date';
import { DateValue } from '@mantine/dates';

const validationSchema = Yup.object().shape({
  status: Yup.string()
    .oneOf(['approved', 'denied'], 'Invalid status provided')
    .required('Status is required'),
  status_reason: Yup.string(),
});

interface FormValues {
  status: AvailabilityRecord['status'];
  status_reason: AvailabilityRecord['status_reason'];
}

const initialValues: FormValues = {
  status: 'denied',
  status_reason: '',
};

function ReviewAvailabilityRecordForm({
  availabilityRecord,
}: {
  availabilityRecord?: AvailabilityRecord;
}) {
  const { values, handleChange, setFieldValue, errors } =
    useFormikContext<FormValues>();

  const handleStatusChange = (selected: SelectOption) => {
    setFieldValue('status', selected.value);
  };

  const dateRange = useMemo(() => {
    return [
      availabilityRecord?.date_start
        ? parse(
            availabilityRecord?.date_start,
            DATE_FORMATS.DATE_KEY,
            new Date()
          )
        : undefined,
      availabilityRecord?.date_end
        ? parse(availabilityRecord?.date_end, DATE_FORMATS.DATE_KEY, new Date())
        : undefined,
    ];
  }, [availabilityRecord?.date_end, availabilityRecord?.date_start]);

  return (
    <Stack sx={() => ({ padding: 16, paddingBottom: 32 })} spacing={8}>
      <InstanceUserSelect
        readonly
        label="Employee"
        defaultValue={availabilityRecord?.instance_user_id}
      />
      <DateRangePicker
        label="Requested Days Off"
        value={dateRange as [DateValue, DateValue]}
        readOnly
      />
      <Textarea
        label="Reason for Time Off"
        value={availabilityRecord?.description || 'None provided'}
        readOnly
      />
      <Divider color="gray" labelPosition="center" label="DECISION" />
      <AvailabilityRecordStatusSelect
        required
        label="Status"
        defaultValue={values.status}
        onChange={handleStatusChange}
        readonly={
          availabilityRecord?.status === 'approved' ||
          availabilityRecord?.status === 'denied'
        }
      />
      <Textarea
        name="status_reason"
        label={`Reason for ${
          values.status === 'approved' ? 'Approval' : 'Denial'
        }`}
        value={values.status_reason}
        onChange={handleChange}
        error={errors.status_reason}
      />
    </Stack>
  );
}

interface ReviewAvailabilityRecordModalProps {
  availabilityRecord?: AvailabilityRecord;
  status?: AvailabilityRecord['status'];
}

function ReviewAvailabilityRecordModal({
  availabilityRecord,
  status,
}: ReviewAvailabilityRecordModalProps) {
  const { popModal } = useModalStore();
  const modal = useModal();
  const { updateAvailabilityRecord } = useAvailabilityRecords();

  const handleClose = () => {
    popModal(MODALS.REVIEW_AVAILABILITY_RECORd_MODAL);
  };

  const handleSubmit = async (values: FormValues) => {
    if (
      await updateAvailabilityRecord(availabilityRecord?.id as number, values)
    ) {
      handleClose();
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        status: status,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
    >
      <BaseModal
        id={MODALS.REVIEW_AVAILABILITY_RECORd_MODAL}
        isOpen={modal.visible}
        onClose={handleClose}
        title="Review Time Off Request"
        height={rem(500)}
        size="40vw"
        FooterComponent={ModalFooter}
      >
        <ReviewAvailabilityRecordForm availabilityRecord={availabilityRecord} />
      </BaseModal>
    </Formik>
  );
}

export default NiceModal.create((props) => (
  <ReviewAvailabilityRecordModal {...props} />
));
