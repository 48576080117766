import startOfWeek from 'date-fns/startOfWeek';
import setWeek from 'date-fns/setWeek';
import addDays from 'date-fns/addDays';

/**
 * get array of week days, from sunday to saturday
 * @param week - week # to get days from
 * @param initialDate - date to base on
 * @return week days
 */
export default function getDaysOfWeek(
  week: number,
  initialDate: Date = new Date()
): Date[] {
  const weekDays: Date[] = [];
  const baseDate = setWeek(initialDate, week);
  const start = startOfWeek(baseDate);

  Array.from({ length: 7 }).forEach((_, i) => {
    weekDays.push(addDays(start, i));
  });

  return weekDays;
}
