import format from 'date-fns/format';
import parse from 'date-fns/parse';
import add from 'date-fns/add';

import constructQueryPath from 'helpers/api/constructQueryPath';
import axios from './axios';
import { ENDPOINTS } from 'constants/api';
import {
  BillingRate,
  BillingRecord,
  Invoice,
  InvoiceLineItem,
  UserLaborCost,
} from 'types/api';
import { DATE_FORMATS } from 'constants/date';
import { downloadCSV } from './file';
import { BillingType } from 'types/app';

export type AddBillingRecordBody = Pick<
  BillingRecord,
  | 'amount'
  | 'description'
  | 'billable'
  | 'date'
  | 'job_type'
  | 'billing_type'
  | 'employee_id'
  | 'ticket_id'
>;

export interface AddInvoiceRecordBody
  extends Pick<
    Invoice,
    | 'pub_id'
    | 'due_date'
    | 'emails'
    | 'issue_date'
    | 'client_id'
    | 'id'
    | 'discount'
    | 'instance_info'
  > {
  invoiceLineItems: Partial<InvoiceLineItem & { deleted?: boolean }>[];
  projectIds: string[];
  stepIndex: number;
  billingTypes: BillingType[];
  groupBy: 'by-billing-record' | 'by-task' | 'by-project' | 'by-jobtype';
  includeNonBillable: string;
}

export async function addBillingRecord({
  body,
  ticketId,
  projectId,
}: {
  body: Partial<AddBillingRecordBody>;
  ticketId?: string | number;
  projectId?: string | number;
}) {
  if (projectId && !ticketId) {
    return axios.put(
      constructQueryPath(ENDPOINTS.PROJECT_BILLING_RECORDS, {
        projectId: `${projectId}`,
      }),
      body
    );
  } else if (ticketId) {
    return axios.put(
      constructQueryPath(ENDPOINTS.TICKET_BILLING_RECORDS, {
        ticketId: `${ticketId}`,
      }),
      body
    );
  }

  throw new Error('Invalid ticket or project id');
}

export async function editBillingRecord({
  body,
  ticketId,
  billingRecordId,
}: {
  body: Partial<AddBillingRecordBody>;
  ticketId: string | number;
  billingRecordId: string | number;
}) {
  return axios.patch(
    constructQueryPath(ENDPOINTS.TICKET_BILLING_RECORD, {
      ticketId: `${ticketId}`,
      billingRecordId: `${billingRecordId}`,
    }),
    body
  );
}

export async function deleteBillingRecord({
  ticketId,
  billingRecordId,
}: {
  ticketId: string | number;
  billingRecordId: string | number;
}) {
  return axios.delete(
    constructQueryPath(ENDPOINTS.TICKET_BILLING_RECORD, {
      ticketId: `${ticketId}`,
      billingRecordId: `${billingRecordId}`,
    })
  );
}

export function groupBillingRecordsByDate(billingRecords: BillingRecord[]) {
  const groups: { [key: string]: BillingRecord[] } = {};

  billingRecords.forEach((billingRecord) => {
    const dateKey = format(
      parse(billingRecord?.date || '', DATE_FORMATS.DATE_KEY, new Date()),
      DATE_FORMATS.DATE_KEY
    );

    if (!groups[dateKey]) {
      groups[dateKey] = [];
    }

    groups[dateKey].push(billingRecord);
  });

  return groups;
}

export async function downloadBillingRecordsCSV(params: any) {
  return downloadCSV(
    ENDPOINTS.BILLING_RECORDS,
    `BillingRecords.${Date.now()}.csv`,
    params
  );
}

export async function updateBillingRates(billingRates: Partial<BillingRate>[]) {
  return axios.post(ENDPOINTS.BILLING_RATES, {
    billing_rates: billingRates.map((billingRate) => {
      return {
        ...billingRate,
        is_active: billingRate.is_active === false ? '0' : '1',
      };
    }),
  });
}

export async function getBillingRecords(params?: any) {
  return axios.get(ENDPOINTS.BILLING_RECORDS, { params });
}

export async function getInvoiceLineItems(params?: any) {
  return axios.get(ENDPOINTS.INVOICE_LINE_ITEMS, { params });
}

export function getInvoiceDate(date: string, issueDate: Date): Date {
  if (date === 'issue-date') {
    return issueDate;
  }

  if (date === 'ten-days') {
    return add(issueDate, { days: 10 });
  }

  if (date === 'fifteen-days') {
    return add(issueDate, { days: 15 });
  }

  if (date === 'thirty-days') {
    return add(issueDate, { days: 30 });
  }

  if (date === 'sixty-days') {
    return add(issueDate, { days: 60 });
  }

  if (date === 'one-month') {
    return add(issueDate, { months: 1 });
  }

  if (date === 'first-day-of-issue-month') {
    const nextMonth = add(issueDate, { months: 1 });
    nextMonth.setDate(1);
    return nextMonth;
  }

  if (date === 'last-day-of-issue-month') {
    const nextMonth = add(issueDate, { months: 1 });
    nextMonth.setDate(0);
    return nextMonth;
  }

  if (date === 'custom') {
    return issueDate;
  }

  return parse(date, DATE_FORMATS.DATE_KEY, new Date());
}

export async function getInvoice(invoiceId: number, params: any) {
  return axios.get(
    constructQueryPath(ENDPOINTS.INVOICE, { invoiceId: `${invoiceId}` }),
    params
  );
}

export async function createInvoice(invoiceBody: AddInvoiceRecordBody) {
  return axios.put(ENDPOINTS.INVOICES, {
    issue_date: invoiceBody.issue_date,
    due_date: format(
      getInvoiceDate(
        invoiceBody.due_date,
        parse(invoiceBody.issue_date, DATE_FORMATS.DATE_KEY, new Date())
      ),
      DATE_FORMATS.DATE_KEY
    ),
    client_id: invoiceBody.client_id,
    line_items: invoiceBody.invoiceLineItems,
    project_ids: invoiceBody.projectIds,
    group_by: invoiceBody.groupBy,
    discount: invoiceBody.discount,
    instance_info: invoiceBody.instance_info,
  });
}

export async function updateInvoice(
  invoiceId: string,
  invoiceBody: AddInvoiceRecordBody
) {
  return axios.patch(constructQueryPath(ENDPOINTS.INVOICE, { invoiceId }), {
    line_items: invoiceBody.invoiceLineItems,
    discount: invoiceBody.discount,
    instance_info: invoiceBody.instance_info,
    due_date: format(
      getInvoiceDate(
        invoiceBody.due_date,
        parse(invoiceBody.issue_date, DATE_FORMATS.DATE_KEY, new Date())
      ),
      DATE_FORMATS.DATE_KEY
    ),
  });
}

export async function updateInvoiceStatus(
  invoiceId: string,
  status: 'approved' | 'voided'
) {
  return axios.post(constructQueryPath(ENDPOINTS.INVOICE, { invoiceId }), {
    status,
    action: 'update_status',
  });
}

export async function voidInvoice(invoiceId: number) {
  return updateInvoiceStatus(`${invoiceId}`, 'voided');
}

export interface SendInvoiceBody {
  emails: string[];
  invoice_email_body: string;
  invoice_email_topic: string;
}

export async function sendInvoice(invoiceId: string, values: SendInvoiceBody) {
  return axios.post(constructQueryPath(ENDPOINTS.INVOICE, { invoiceId }), {
    action: 'send',
    ...values,
  });
}

export async function addInvoicePayment(invoiceId: string, amount: number) {
  return axios.put(
    constructQueryPath(ENDPOINTS.INVOICE_PAYMENTS, { invoiceId }),
    { amount }
  );
}

export async function addUserLaborCosts(userLaborCosts: UserLaborCost[]) {
  return axios.put(ENDPOINTS.USER_LABOR_COSTS, {
    user_labor_costs: userLaborCosts,
  });
}
