import { Box, Text } from '@mantine/core';

import { ProjectTicket } from 'types/api';
import { AUTH_USER_ID } from 'constants/api';

import getUserFullName from 'helpers/display/getUserFullName';

import useUser from 'hooks/users/useUser';
import useInstanceConfig from 'hooks/instance/useInstanceConfig';

interface MainContentMetadataProps {
  ticket?: Partial<ProjectTicket>;
  isEditing: boolean;
}

export default function MainContentMetadata({
  ticket = {},
  isEditing,
}: MainContentMetadataProps) {
  const { formatUserTimezone } = useUser({ userId: AUTH_USER_ID });
  const { dateDisplay } = useInstanceConfig();

  const getDataDisplay = (
    ticket: Partial<ProjectTicket>,
    key: keyof ProjectTicket
  ) => {
    if (!ticket?.[key]) return '';

    if (key === 'created_by_user') {
      return getUserFullName(ticket?.created_by_user);
    }

    if (key === 'datetime_created') {
      return formatUserTimezone(
        new Date(ticket.datetime_created as string),
        `${dateDisplay} h:mm a zzz`
      );
    }

    return ticket?.[key] || '';
  };

  if (isEditing) return <></>;

  return (
    <Box>
      <Text size="xs" color="gray" align="center">
        Created by {getDataDisplay(ticket, 'created_by_user')} on{' '}
        {getDataDisplay(ticket, 'datetime_created')}
      </Text>
    </Box>
  );
}
