import EditableRow from './EditableRow';

import { EditableRowProps } from '../types';

import useBillingRecord from 'hooks/billing/useBillingRecord';
import { BillingRecord } from 'types/api';

interface ContainerRowProps extends EditableRowProps {
  isInfinite?: boolean;
}

export default function ContainedRow({
  billingRecord,
  isInfinite,
  ...props
}: ContainerRowProps) {
  const { data, isLoading, editBillingRecord } = useBillingRecord({
    billingRecordId: billingRecord.id as number,
    isInfinite: isInfinite ?? true,
  });

  return (
    <EditableRow
      {...props}
      billingRecord={data?.billing_record as BillingRecord}
      loading={isLoading}
      toggle
      editBillingRecord={editBillingRecord}
    />
  );
}
