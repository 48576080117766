import { Stack, Text, rem, createStyles } from '@mantine/core';
import getTicketDisplay from 'helpers/display/getTicketDisplay';
import { Draggable } from 'react-beautiful-dnd';

import { ProjectTicket } from 'types/api';

interface UnassignedListItemProps {
  ticket?: ProjectTicket;
  index: number;
}

const useStyles = createStyles((theme) => ({
  item: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.radius.md,
    border: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: `${theme.spacing.sm} ${theme.spacing.sm}`,
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
    marginBottom: theme.spacing.xs,
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },
}));

export default function UnassignedListItem({
  ticket,
  index,
}: UnassignedListItemProps) {
  const { classes, cx } = useStyles();

  return (
    <Draggable key={ticket?.id} index={index} draggableId={`${ticket?.id}`}>
      {(provided, snapshot) => (
        <div
          className={cx(classes.item, {
            [classes.itemDragging]: snapshot.isDragging,
          })}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Stack>
            <Text>{getTicketDisplay(ticket as ProjectTicket)}</Text>
          </Stack>
        </div>
      )}
    </Draggable>
  );
}
