import { Group, Button } from '@mantine/core';
import { useFormikContext } from 'formik';

import BaseModalFooter from '../BaseModal/ModalFooter';
import { MODALS } from 'constants/component';

import useModalStore from 'hooks/store/useModalStore';

export default function ModalFooter() {
  const { submitForm, resetForm, isSubmitting, status } = useFormikContext();
  const { popModal } = useModalStore();

  const handleCancel = () => {
    if (!isSubmitting) {
      popModal(MODALS.ADD_INVOICE_PAYMENT_MODAL);
      resetForm();
    }
  };

  return (
    <BaseModalFooter>
      <Group sx={{ justifyContent: 'flex-end' }}>
        <Button
          size="xs"
          variant="outline"
          onClick={handleCancel}
          disabled={isSubmitting}
        >
          {status?.isPaid ? 'Close' : 'Cancel'}
        </Button>
        {!status?.isPaid && (
          <Button size="xs" onClick={submitForm} loading={isSubmitting}>
            Save
          </Button>
        )}
      </Group>
    </BaseModalFooter>
  );
}
