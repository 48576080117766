import { useMemo, useState } from 'react';
import { ScrollArea, Text, TextInput, Group } from '@mantine/core';
import { Droppable } from 'react-beautiful-dnd';

import useTickets from 'hooks/tickets/useTickets';

import UnassignedListItem from './ListItem';

export default function UnassignedList() {
  const [search, setSearch] = useState('');
  const { data } = useTickets({ params: { user_id: 'null' } });

  const filteredTickets = useMemo(() => {
    return (
      data?.tickets?.filter((ticket) =>
        ticket.title.toLowerCase().includes(search.toLowerCase())
      ) || []
    );
  }, [data, search]);

  return (
    <ScrollArea>
      <Droppable
        isCombineEnabled
        droppableId={`dnd-list-unassigned`}
        direction="horizontal"
      >
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ minHeight: '48px' }}
          >
            <Text>Unassigned Tickets</Text>
            <Group>
              <TextInput
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {filteredTickets?.map((ticket, ticketIndex) => (
                <UnassignedListItem
                  key={ticket.id}
                  ticket={ticket}
                  index={ticketIndex}
                />
              ))}
            </Group>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </ScrollArea>
  );
}
