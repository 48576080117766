import constructQueryPath from 'helpers/api/constructQueryPath';
import axios from './axios';
import { ENDPOINTS } from 'constants/api';
import { ClientProject } from 'types/api';

export type AddProjectBody = Pick<
  ClientProject,
  'title' | 'key' | 'client_id' | 'billing_type' | 'ticket_statuses' | 'status'
>;

export async function addProject(projectBody: Partial<AddProjectBody>) {
  return axios.put(constructQueryPath(ENDPOINTS.PROJECTS), projectBody);
}

export async function updateProject({
  projectId,
  projectBody,
}: {
  projectId: string;
  projectBody: Partial<AddProjectBody>;
}) {
  return axios.patch(
    constructQueryPath(ENDPOINTS.PROJECT, { projectId }),
    projectBody
  );
}

interface BookmarkBody {
  action: 'bookmark';
  active: '0' | '1';
}

export async function bookmarkProject({
  projectId,
  bookmarkBody,
}: {
  projectId: string;
  bookmarkBody: BookmarkBody;
}) {
  return axios.post(
    constructQueryPath(ENDPOINTS.PROJECT, { projectId }),
    bookmarkBody
  );
}

export async function rearrangeTickets({
  projectId,
  ticketIds,
}: {
  projectId: string;
  ticketIds: number[];
}) {
  return axios.post(constructQueryPath(ENDPOINTS.PROJECT, { projectId }), {
    ticket_ids: ticketIds,
    action: 'rearrange_tickets',
  });
}
