import { useCallback, useEffect, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Box, Group, Text } from '@mantine/core';
import {
  IconCheck,
  IconDownload,
  IconEdit,
  IconSend,
  IconX,
} from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';

import BaseModal from '../BaseModal';
import ModalFooter from '../BaseModal/ModalFooter';
import InvoiceStatus from 'pages/Invoices/components/InvoiceStatus';

import { MODALS, MODAL_HEADER_HEIGHT } from 'constants/component';

import * as billingUtils from 'utils/billing';

import useModalStore from 'hooks/store/useModalStore';
import useInvoice from 'hooks/billing/useInvoice';
import ConfirmationPopOver from 'components/common/PopOver/variants/ConfirmationPopOver';
import { ConfirmationPopOverDropdownProps } from 'components/common/PopOver/variants/ConfirmationPopOver/types';

interface PreviewInvoiceModalProps {
  invoiceId?: number;
}

function PreviewInvoiceModal({ invoiceId }: PreviewInvoiceModalProps) {
  const modal = useModal();
  const { data, isLoading, updateInvoiceStatus, downloadInvoice, voidInvoice } =
    useInvoice({
      invoiceId,
    });
  const { popModal, pushModal } = useModalStore();
  const [html, setHtml] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const invoice = data?.invoice;

  const handleClose = useCallback(() => {
    if (!isLoading && !loading && !statusLoading && !downloading) {
      popModal(MODALS.PREVIEW_INVOICE_MODAL);
    }
  }, [downloading, isLoading, loading, popModal, statusLoading]);

  const PreviewInvoiceModalFooter = useCallback(() => {
    return (
      <ModalFooter>
        <Group sx={{ justifyContent: 'flex-end' }}>
          <Button
            size="xs"
            onClick={handleClose}
            disabled={isLoading || loading || statusLoading}
          >
            Close
          </Button>
        </Group>
      </ModalFooter>
    );
  }, [handleClose, isLoading, loading, statusLoading]);

  const handleApprove = useCallback(async () => {
    if (!invoice) return;

    return pushModal(MODALS.CONFIRM, {
      title: 'Confirm Approving Invoice Draft',
      body: 'Approving invoice will update all associated billing records.',
      onConfirm: async () => {
        setStatusLoading(true);
        await updateInvoiceStatus?.('approved');
        popModal(MODALS.CONFIRM);
        setStatusLoading(false);
      },
    });
  }, [invoice, popModal, pushModal, updateInvoiceStatus]);

  const handleDownload = useCallback(async () => {
    setDownloading(true);

    try {
      await downloadInvoice();
    } finally {
      setDownloading(false);
    }
  }, [downloadInvoice]);

  const handleEdit = useCallback(() => {
    popModal(MODALS.PREVIEW_INVOICE_MODAL);
    pushModal(MODALS.ADD_INVOICE_MODAL, { invoiceId });
  }, [invoiceId, popModal, pushModal]);

  const handleSend = useCallback(() => {
    pushModal(MODALS.SEND_INVOICE_MODAL, { invoiceId });
  }, [invoiceId, pushModal]);

  const handleDelete = useCallback(() => {
    if (!invoice) return;

    return pushModal(MODALS.CONFIRM, {
      title:
        invoice.status === 'draft'
          ? 'Confirm Deleting Invoice Draft'
          : 'Confirm Voiding Invoice',
      body:
        invoice.status === 'draft'
          ? 'Permanately delete draft?'
          : 'Voiding invoice will move all associated billing records to the uninvoiced status.',
      onConfirm: async () => {
        if (await voidInvoice?.()) {
          popModal(MODALS.CONFIRM);

          if (invoice.status === 'draft') {
            popModal(MODALS.PREVIEW_INVOICE_MODAL);
            notifications.show({
              message: 'Invoice Draft Successfully Deleted!',
              color: 'green',
            });
          } else {
            notifications.show({
              message: 'Invoice Successfully Voided!',
              color: 'green',
            });
          }
        }
      },
    });
  }, [invoice, popModal, pushModal, voidInvoice]);

  useEffect(() => {
    if (invoiceId) {
      setLoading(true);
      billingUtils
        .getInvoice(invoiceId, { headers: { Accept: 'text/html' } })
        .then((res) => {
          setHtml(res.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [invoice, invoiceId]);

  return (
    <BaseModal
      id={MODALS.PREVIEW_INVOICE_MODAL}
      loading={isLoading || loading}
      isOpen={modal.visible}
      onClose={handleClose}
      title="Preview Invoice"
      height="80vh"
      size="800px"
      FooterComponent={PreviewInvoiceModalFooter}
    >
      <Box
        sx={(theme) => ({
          padding: theme.spacing.xl,
          height: `calc(80vh - ${MODAL_HEADER_HEIGHT})`,
          display: 'flex',
          flexDirection: 'column',
        })}
      >
        <Box>
          <Group>
            <InvoiceStatus
              status={invoice?.status}
              badgeProps={{ size: 'xl' }}
            />
            {invoice?.status === 'draft' && (
              <>
                <Button
                  size="xs"
                  color="blue"
                  leftIcon={<IconEdit />}
                  radius="xl"
                  onClick={handleEdit}
                >
                  Edit
                </Button>
                <Button
                  size="xs"
                  color="green"
                  leftIcon={<IconCheck />}
                  radius="xl"
                  onClick={handleApprove}
                  loading={statusLoading}
                >
                  Approve
                </Button>
              </>
            )}
            {invoice?.status === 'draft' && (
              <Button
                size="xs"
                color="red"
                leftIcon={<IconX />}
                radius="xl"
                onClick={handleDelete}
                loading={statusLoading}
              >
                Delete
              </Button>
            )}
            {invoice?.status !== 'voided' && invoice?.status !== 'draft' && (
              <ConfirmationPopOver
                onConfirm={handleDelete}
                dropdownProps={{
                  message: 'Confirm',
                }}
                DropdownComponent={({
                  onClose,
                  onConfirm,
                }: ConfirmationPopOverDropdownProps) => {
                  return (
                    <Group sx={{ justifyContent: 'space-evenly' }}>
                      {invoice?.status === 'partially_paid' && (
                        <Text size="sm" sx={{ lineHeight: '100%' }}>
                          This invoice is partially paid. Are you certain it
                          should be voided?
                        </Text>
                      )}
                      {invoice?.status === 'paid' && (
                        <Text size="sm" sx={{ lineHeight: '100%' }}>
                          This invoice is paid off. Are you certain it should be
                          voided?
                        </Text>
                      )}
                      <Button size="xs" variant="outline" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button
                        size="xs"
                        onClick={() => {
                          onConfirm?.();
                          onClose?.();
                        }}
                      >
                        Confirm
                      </Button>
                    </Group>
                  );
                }}
              >
                <Button
                  size="xs"
                  color="red"
                  leftIcon={<IconX />}
                  radius="xl"
                  loading={statusLoading}
                >
                  Void
                </Button>
              </ConfirmationPopOver>
            )}
            <Button
              size="xs"
              color="blue"
              leftIcon={<IconDownload />}
              radius="xl"
              onClick={handleDownload}
              loading={downloading}
            >
              Download PDF
            </Button>
            {!['draft', 'paid', 'voided'].includes(
              invoice?.status as string
            ) && (
              <Button
                size="xs"
                color="blue"
                leftIcon={<IconSend />}
                radius="xl"
                onClick={handleSend}
              >
                {invoice?.status === 'sent' ? 'Re-Send' : 'Send'}
              </Button>
            )}
          </Group>
        </Box>
        <Box
          component="iframe"
          srcDoc={invoice?.download_link ? undefined : html}
          src={invoice?.download_link}
          height="100%"
          width="100%"
          sx={{
            width: '100%',
            minHeight: `calc(80vh - (${MODAL_HEADER_HEIGHT} * 2) - 32px)`,
            border: 'none',
            overflow: 'hidden',
          }}
        />
      </Box>
    </BaseModal>
  );
}

export default NiceModal.create((props) => <PreviewInvoiceModal {...props} />);
