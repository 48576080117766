import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { notifications } from '@mantine/notifications';

import useApiQuery from 'hooks/api/useApiQuery';
import useApiStore from 'hooks/store/useApiStore';
import * as billing from 'utils/billing';
import showApiErrNotif from 'helpers/api/showApiErrNotif';

import type { BillingRate } from 'types/api';
import { ENDPOINTS } from 'constants/api';
import { BillingRatesHookParams } from './types';

export default function useBillingRates(hookParams?: BillingRatesHookParams) {
  const { params } = hookParams || {};
  const { invalidate, invalidatePath } = useApiStore();

  const queryResult = useApiQuery<{ billing_rates?: BillingRate[] }>({
    path: ENDPOINTS.BILLING_RATES,
    axiosConfig: {
      params,
    },
  });

  const updateBillingRates = useCallback(
    async (billingRates: Partial<BillingRate>[]) => {
      try {
        const res = (await billing.updateBillingRates(
          billingRates
        )) as AxiosResponse;

        if (res.status !== 200) {
          throw new Error(
            res.data?.message ||
              'Unable to update billing rates at this time. Try again later.'
          );
        }

        notifications.show({ message: 'Billing rates successfully updated!' });
        queryResult.refetch();
        invalidate('billing-rate-preview');
        invalidatePath(['activity']);
      } catch (err) {
        showApiErrNotif(
          'Unable to update billing rates at this time. Try again later.',
          err
        );
      }
    },
    [invalidate, invalidatePath, queryResult]
  );

  return {
    ...queryResult,
    updateBillingRates,
  };
}
