// @ts-nocheck
import { Card, Box, createStyles } from '@mantine/core';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { MentionOptions } from '@tiptap/extension-mention';
import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';
import getUserFullName from 'helpers/display/getUserFullName';
import useUsers from 'hooks/users/useUsers';

const useStyles = createStyles((theme) => ({
  option: {
    padding: theme.spacing.xs,
  },
  selected: {
    backgroundColor: theme.colors.gray[1],
  },
}));

const MentionList = forwardRef((props, ref) => {
  const { classes, cx } = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index) => {
    const item = props.items[index];

    if (item) {
      props.command({ id: item });
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }
      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <Card
      sx={(theme) => ({
        padding: '0 !important',
        overflow: 'hidden',
        borderRadius: theme.radius.sm,
      })}
    >
      {props.items.length ? (
        <>
          {props.items.map((item, index) => (
            <Box
              className={cx(classes.option, {
                [classes.selected]: selectedIndex === index,
              })}
              key={index}
              onClick={() => selectItem(index)}
            >
              {item}
            </Box>
          ))}
        </>
      ) : (
        <Box className={classes.option}>No result</Box>
      )}
    </Card>
  );
});

MentionList.displayName = 'MentionList';

export default function useSuggestion(): MentionOptions['suggestion'] {
  const { data } = useUsers();

  return useMemo(
    () => ({
      items: async ({ query }) => {
        return (data?.users || [])
          .map((user) => getUserFullName(user))
          .filter((item) => item.toLowerCase().startsWith(query.toLowerCase()))
          .slice(0, 5);
      },

      render: () => {
        let component;
        let popup;

        return {
          onStart: (props) => {
            component = new ReactRenderer(MentionList, {
              props,
              editor: props.editor,
            });

            if (!props.clientRect) {
              return;
            }

            popup = tippy('body', {
              getReferenceClientRect: props.clientRect,
              appendTo: () => document.body,
              content: component.element,
              showOnCreate: true,
              interactive: true,
              trigger: 'manual',
              placement: 'bottom-start',
            });
          },

          onUpdate(props) {
            component.updateProps(props);

            if (!props.clientRect) {
              return;
            }

            popup[0].setProps({
              getReferenceClientRect: props.clientRect,
            });
          },

          onKeyDown(props) {
            try {
              if (props.event.key === 'Escape') {
                popup[0].hide();

                return true;
              }

              return component.ref?.onKeyDown(props);
            } catch (err) {
              return;
            }
          },

          onExit() {
            try {
              popup[0].destroy();
              component.destroy();
            } catch (err) {
              return;
            }
          },
        };
      },
    }),
    [data?.users]
  );
}
