import { useMantineTheme } from '@mantine/core';
import { Link } from '@mantine/tiptap';
import {
  Editor,
  EditorOptions,
  KeyboardShortcutCommand,
  useEditor,
} from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import Image from '@tiptap/extension-image';
import Mention from '@tiptap/extension-mention';
import BulletList from '@tiptap/extension-bullet-list';

import useSuggestion from 'components/common/RichTextEditor/utils/useSuggestion';

interface WYSIWYGHookParams extends Partial<EditorOptions> {
  content: string;
  onChange: (html: string) => void;
  getKeyboardShortcuts?: (editor: Editor) => {
    [key: string]: KeyboardShortcutCommand;
  };
}

export default function useWYSIWYG({
  content,
  onChange,
  getKeyboardShortcuts,
  ...props
}: WYSIWYGHookParams) {
  const theme = useMantineTheme();
  const suggestion = useSuggestion();

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link.configure({
        autolink: false,
      }),
      Superscript,
      SubScript,
      Highlight,
      Image.configure({
        inline: true,
        allowBase64: true,
      }),
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Mention.configure({
        HTMLAttributes: {
          style: `color: ${theme.colors.blue[9]};`,
        },
        renderLabel({ options, node }) {
          return `${options.suggestion.char}${
            node.attrs.label ?? node.attrs.id
          }`;
        },
        suggestion,
      }),
      BulletList.extend({
        addKeyboardShortcuts() {
          return getKeyboardShortcuts?.(this.editor as Editor) || {};
        },
      }),
    ],
    content,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
    autofocus: true,
    ...props,
  });

  return {
    editor,
  };
}
