import useInstanceConfig from './useInstanceConfig';

export default function useGlobalConfig() {
  const { data, ...rest } = useInstanceConfig({
    storeKey: 'global-config',
    params: {
      is_global: '1',
    },
  });

  return {
    config: data?.config || {},
    ...rest,
  };
}
