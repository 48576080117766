import { SelectOption, SelectProps } from '../../types';
import BaseSelect from '../BaseSelect';

import { BILLING_TYPE } from 'constants/billing';

interface BillingTypeSelectProps extends SelectProps {}

export default function BillingTypeSelect({
  onChange,
  defaultValue,
  label,
}: BillingTypeSelectProps) {
  const options: SelectOption[] = [
    {
      label: 'Time',
      value: BILLING_TYPE.TIME,
    },
    {
      label: 'Expense',
      value: BILLING_TYPE.EXPENSE,
    },
    {
      label: 'Time & Expense',
      value: [BILLING_TYPE.TIME, BILLING_TYPE.EXPENSE].join(','),
    },
  ];

  return (
    <BaseSelect
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      label={label}
    />
  );
}
