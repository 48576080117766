import { Button, Group, rem } from '@mantine/core';
import { useFormikContext } from 'formik';
import { notifications } from '@mantine/notifications';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

import UserInput from 'components/common/UserInput';

import { InputParam } from 'types/app';
import { DATE_FORMATS } from 'constants/date';
import { downloadCSV } from 'utils/file';

import useDownloadStore from 'hooks/store/useDownloadStore';
import constructQueryPath from 'helpers/api/constructQueryPath';
import { ENDPOINTS } from 'constants/api';

interface FiltersProps {
  reportId: string | number;
  filename: string;
  reportParams: InputParam[];
}

export default function Filters({
  reportParams,
  reportId,
  filename,
}: FiltersProps) {
  const { values, setFieldValue, initialValues } = useFormikContext<any>();
  const { pushDownload } = useDownloadStore();
  const showExport = Boolean(reportParams?.length);

  const getDefaultValue = (inputParam: InputParam) => {
    if (inputParam.type === 'date' && inputParam.default_value) {
      return parse(inputParam.default_value, DATE_FORMATS.DATE_KEY, new Date());
    }

    return values[inputParam.name];
  };

  return (
    <Group>
      {reportParams.map((inputParam) => (
        <UserInput
          key={inputParam.name}
          variant={inputParam.type as any}
          inputProps={{
            name: inputParam.name,
            label: inputParam.label || inputParam.name,
            defaultValue: getDefaultValue({
              ...inputParam,
              default_value: initialValues[inputParam.name],
            }),
            sx: {
              width: rem(250),
            },
          }}
          onChange={(value: any) => {
            if (value instanceof Date) {
              setFieldValue(
                inputParam.name,
                format(value, DATE_FORMATS.DATE_KEY)
              );
              return;
            }

            setFieldValue(inputParam.name, value);
          }}
        />
      ))}
      {showExport && (
        <Button
          size="xs"
          mt="auto"
          sx={{ marginBottom: rem(4) }}
          onClick={() => {
            pushDownload({
              filename: filename,
              download: async () => {
                await downloadCSV(
                  constructQueryPath(ENDPOINTS.REPORT_ROWS, {
                    reportId: `${reportId}`,
                  }),
                  filename,
                  values
                );
                notifications.show({
                  message:
                    'CSV has been successfully downloaded to your computer!',
                  variant: 'info',
                });
              },
            });
          }}
        >
          Export to CSV
        </Button>
      )}
    </Group>
  );
}
