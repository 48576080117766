import { BaseTagsInputProps } from './types';
import BaseTagsInput from './variants/Base';

interface TagsInputProps extends BaseTagsInputProps {
  variant?: 'base';
}

export default function TagsInput({ variant, ...props }: TagsInputProps) {
  if (variant === 'base') {
    return <BaseTagsInput {...props} />;
  }

  return <BaseTagsInput {...props} />;
}
