import { ReactNode, useState, useEffect, useMemo } from 'react';
import {
  Tabs,
  createStyles,
  Box,
  ScrollArea,
  ActionIcon,
  Menu,
  rem,
} from '@mantine/core';
import { useSearchParams } from 'react-router-dom';
import { IconCirclePlus } from '@tabler/icons-react';

import { HEADER_HEIGHT, MODALS, TABS_HEIGHT } from 'constants/component';

import ConsoleLayout from 'layouts/ConsoleLayout';
import TicketsTab from './components/TicketsTab';
import AssignmentsTab from './components/TimesheetTab';
import ActivityTab from './components/ActivityTab';
import AvailabilityTab from './components/AvailabilityTab';
import useModalStore from 'hooks/store/useModalStore';
import { AUTH_USER_ID } from 'constants/api';
import useUser from 'hooks/users/useUser';
import useConfigStore from 'hooks/store/useConfigStore';
import getUserFullName from 'helpers/display/getUserFullName';

type AssignmentTab = 'tickets' | 'timesheet' | 'activity' | 'availability';

const tabs: AssignmentTab[] = [
  'tickets',
  'timesheet',
  'activity',
  'availability',
];

const useStyles = createStyles((theme) => ({
  tabsList: {
    paddingLeft: theme.spacing.lg,
    backgroundColor: theme.white,
  },
  tab: {
    fontSize: theme.fontSizes.sm,
    height: TABS_HEIGHT,
    fontWeight: 'bold',
  },
}));

function TabPanel({
  children,
  height,
}: {
  children: ReactNode;
  height?: string;
}) {
  return (
    <Box>
      <ScrollArea>
        <Box
          sx={(theme) => ({
            height: height || `calc(100vh - ${TABS_HEIGHT} - ${HEADER_HEIGHT})`,
            padding: theme.spacing.md,
          })}
        >
          {children}
        </Box>
      </ScrollArea>
    </Box>
  );
}

export default function AssignmentsPage() {
  const { classes } = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pushModal } = useModalStore();
  const { setBreadcrumbsVar } = useConfigStore();
  const [activeTab, setActiveTab] = useState<AssignmentTab>('tickets');
  const [userId] = useState(
    parseInt(searchParams.get('userId') || '0') || AUTH_USER_ID
  );
  const { data, isLoading: userLoading } = useUser({ userId });

  const apiUserId = useMemo(() => {
    return data?.user?.id;
  }, [data]);

  const handleTabChange = (value: string) => {
    setActiveTab(value as AssignmentTab);
    setSearchParams({
      tab: value,
      userId: `${apiUserId}`,
    });
  };

  useEffect(() => {
    const tab = searchParams.get('tab') as AssignmentTab;
    if (tabs.includes(tab)) {
      setActiveTab(tab);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setBreadcrumbsVar('user', getUserFullName(data?.user));
  }, [data, setBreadcrumbsVar]);

  return (
    <ConsoleLayout disablePadding disableScroll loading={userLoading}>
      <Tabs value={activeTab} onTabChange={handleTabChange}>
        <Tabs.List className={classes.tabsList}>
          {tabs.map((tab) => (
            <Tabs.Tab
              key={tab}
              value={tab}
              className={classes.tab}
              sx={(theme) => ({
                color:
                  activeTab === tab
                    ? `${theme.colors.blue[8]} !important`
                    : theme.colors.gray[6],
                textTransform: 'capitalize',
              })}
            >
              {tab.replace(/-/gm, ' ')}
            </Tabs.Tab>
          ))}
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Box
                sx={(theme) => ({
                  marginLeft: 'auto',
                  marginRight: theme.spacing.md,
                  display: 'flex',
                })}
              >
                <ActionIcon sx={{ margin: 'auto 0' }}>
                  <IconCirclePlus />
                </ActionIcon>
              </Box>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                onClick={() =>
                  pushModal(MODALS.ADD_AVAILABILITY_RECORD_MODAL, {
                    userId: apiUserId,
                  })
                }
                icon={<IconCirclePlus size={rem(16)} />}
              >
                Add Availability Record
              </Menu.Item>
              <Menu.Item
                onClick={() =>
                  pushModal(MODALS.ADD_BILLING_RECORD_MODAL, {
                    userId: apiUserId,
                  })
                }
                icon={<IconCirclePlus size={rem(16)} />}
              >
                Add Billing Record
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Tabs.List>

        <Tabs.Panel value="tickets">
          <TabPanel>
            <TicketsTab userId={`${apiUserId}`} />
          </TabPanel>
        </Tabs.Panel>

        <Tabs.Panel value="timesheet">
          <TabPanel>
            <AssignmentsTab userId={`${apiUserId}`} />
          </TabPanel>
        </Tabs.Panel>

        <Tabs.Panel value="activity">
          <ActivityTab userId={`${apiUserId}`} />
        </Tabs.Panel>

        <Tabs.Panel value="availability">
          <TabPanel>
            <AvailabilityTab userId={`${apiUserId}`} />
          </TabPanel>
        </Tabs.Panel>
      </Tabs>
    </ConsoleLayout>
  );
}
