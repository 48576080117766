import { ENDPOINTS } from 'constants/api';
import constructQueryPath from 'helpers/api/constructQueryPath';

import useApiQuery from 'hooks/api/useApiQuery';

import type { InvoicePayment } from 'types/api';
import { ApiQueryKey } from 'types/api/query';

interface InvoicePaymentsHookParams {
  invoiceId?: number;
  storeKey?: ApiQueryKey;
}

export default function useInvoicePayments(
  hookParams: InvoicePaymentsHookParams
) {
  const { invoiceId, storeKey } = hookParams || {};

  const queryResult = useApiQuery<{ invoice_payments?: InvoicePayment[] }>({
    path: constructQueryPath(ENDPOINTS.INVOICE_PAYMENTS, {
      invoiceId: `${invoiceId}`,
    }),
    queryOptions: {
      enabled: Boolean(invoiceId),
    },
    storeKey: storeKey,
  });

  return {
    ...queryResult,
  };
}
