import format from 'date-fns/format';
import { ActionIcon, Badge, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { IconCircleX, IconCircleCheck, IconEdit } from '@tabler/icons-react';

import BaseTable from 'components/common/Table/variants/BaseTable';

import getUserFullName from 'helpers/display/getUserFullName';
import * as userUtils from 'utils/user';
import useModalStore from 'hooks/store/useModalStore';

import { TableColumn } from 'components/common/Table/types';
import { InstanceUser } from 'types/api';
import { BaseTableProps } from 'components/common/Table/types';
import { MODULES } from 'constants/app';
import { MODALS } from 'constants/component';
import AvatarGroup from 'components/common/AvatarGroup';
import getInitials from 'helpers/display/getInitials';
import useAuthStore from 'hooks/store/useAuthStore';
import useAuthUser from 'hooks/users/useAuthUser';
import useInstanceConfig from 'hooks/instance/useInstanceConfig';

interface TableInstanceUser extends InstanceUser {
  name?: string;
}

export interface PeopleTableProps {
  users: TableInstanceUser[];
  updateUser: typeof userUtils.updateUser;
}

export default function PeopleTable({ users, updateUser }: PeopleTableProps) {
  const navigate = useNavigate();
  const { pushModal } = useModalStore();
  const { isAdmin } = useAuthStore();
  const { user } = useAuthUser();
  const { dateDisplay } = useInstanceConfig();

  const handleActiveChange = useCallback(
    async (user: TableInstanceUser) => {
      const active = !user.active;

      pushModal(MODALS.CONFIRM, {
        title: !active ? 'Deactivate User' : 'Activate User',
        body: "Are you sure you want to update this user's status?",
        onConfirm: async () => {
          await updateUser({
            userId: `${user.id}`,
            body: { active: active },
          });
        },
      });
    },
    [pushModal, updateUser]
  );

  const getDataDisplay: BaseTableProps<TableInstanceUser>['getDataDisplay'] =
    useCallback(
      (key: string, row: TableInstanceUser) => {
        const rowKey = key;

        if (rowKey === 'name') {
          return getUserFullName(row);
        }

        if (rowKey === 'datetime_created') {
          return format(new Date(row[rowKey] as string), dateDisplay);
        }

        if (rowKey === 'admin') {
          return (
            <Badge
              variant="filled"
              radius="xl"
              color={row.is_admin ? 'blue' : 'gray'}
            >
              {row.is_admin ? 'Admin' : 'Employee'}
            </Badge>
          );
        }

        if (rowKey === 'manager') {
          if (!row.manager) {
            return <>No one</>;
          }

          return (
            <Group sx={{ justifyContent: 'center' }}>
              <AvatarGroup
                avatars={
                  [row.manager]
                    ?.filter((m) => m)
                    ?.map((reporter) => ({
                      title: getUserFullName(reporter),
                      children: getInitials({
                        fullName: getUserFullName(reporter),
                      }),
                      radius: 'xl',
                      variant: 'filled',
                      color: 'blue',
                    })) || []
                }
                max={4}
              />
            </Group>
          );
        }

        if (rowKey === 'actions') {
          const showEdit = row.id === user?.id || isAdmin;

          return (
            <Group sx={{ justifyContent: 'center' }}>
              <ActionIcon
                variant="light"
                color={row.active ? 'red' : 'green'}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  handleActiveChange(row);
                }}
                title={row.active ? 'Disable' : 'Re-enable'}
              >
                {row.active ? <IconCircleX /> : <IconCircleCheck />}
              </ActionIcon>
              {showEdit && (
                <ActionIcon
                  variant="light"
                  color="blue"
                  title="Edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    pushModal(MODALS.EDIT_USER_MODAL, { user: row });
                  }}
                >
                  <IconEdit />
                </ActionIcon>
              )}
            </Group>
          );
        }

        return `${row[rowKey as keyof TableInstanceUser]}`;
      },
      [dateDisplay, handleActiveChange, isAdmin, pushModal, user?.id]
    );

  const columns: TableColumn[] = useMemo(() => {
    const baseColumns: TableColumn[] = [];

    if (isAdmin) {
      baseColumns.push({
        id: 'id',
        display: 'ID',
      });
    }

    baseColumns.push(
      {
        id: 'name',
        display: 'Name',
      },
      {
        id: 'email',
        display: 'Email',
      },
      {
        id: 'manager',
        display: 'Manager',
      },
      {
        id: 'admin',
        display: 'Role',
      },
      {
        id: 'datetime_created',
        display: 'Join Date',
      }
    );

    if (isAdmin) {
      baseColumns.push({
        id: 'actions',
        display: 'Actions',
      });
    }

    return baseColumns;
  }, [isAdmin]);

  return (
    <BaseTable<TableInstanceUser>
      columns={columns}
      rows={users}
      getDataDisplay={getDataDisplay}
      tableProps={{
        sx: {
          tableLayout: 'fixed',
        },
      }}
      onRowClick={(row) => {
        navigate(`/${MODULES.ASSIGNMENTS}?userId=${row.id}`);
      }}
    />
  );
}
