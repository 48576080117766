import { Menu } from '@mantine/core';

import { SelectOption } from '../../types';

interface SelectItemProps {
  option: SelectOption;
  setSelected?: (option: SelectOption) => void;
}

export default function SelectItem({ option, setSelected }: SelectItemProps) {
  return (
    <Menu.Item
      onClick={(e) => {
        setSelected?.(option);
        e.stopPropagation();
        e.preventDefault();
      }}
      key={option.value}
    >
      {option.label}
    </Menu.Item>
  );
}
