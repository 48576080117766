import { Box, Group, Text, Divider, rem } from '@mantine/core';
import { Formik } from 'formik';
import { notifications } from '@mantine/notifications';

import MainContentTitle from './Title';
import MainContentDescription from './Description';
import { ProjectTicket } from 'types/api';
import * as ticketUtils from 'utils/ticket';
import TabbedContent from '../TabbedContent';
import AttachmentPreview from 'components/common/Attachment/Preview';
import useDownloadStore from 'hooks/store/useDownloadStore';
import getTicketDisplay from 'helpers/display/getTicketDisplay';
import useApiStore from 'hooks/store/useApiStore';

interface EditTicketModalMainContentProps {
  ticket?: ProjectTicket;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  updateTicket?: typeof ticketUtils.updateTicket;
}

export default function EditTicketModalMainContent({
  ticket,
  isEditing,
  setIsEditing,
  updateTicket,
}: EditTicketModalMainContentProps) {
  const { pushDownload } = useDownloadStore();
  const { invalidate } = useApiStore();

  const initialValues = {
    title: ticket?.title,
    description: ticket?.description,
    attachments: ticket?.attachments
      ?.filter((attachment) => attachment)
      .map((filename) => new File([], filename.split('/').at(-1))) as File[],
    is_done: ticket?.is_done,
  };

  const handleSubmit = async (values: typeof initialValues) => {
    const ticketBody = {
      title: values.title,
      description: values.description,
      is_done: values.is_done,
    };

    if (ticket) {
      if (ticketBody.title === ticket.title) {
        delete ticketBody.title;
      }

      if (
        ticketBody.description === ticket.description ||
        (!ticketBody.description && !ticket.description)
      ) {
        delete ticketBody.description;
      }

      if (ticketBody.is_done === ticket.is_done) {
        delete ticketBody.is_done;
      }
    }

    if (values.attachments.length) {
      await ticketUtils.updateAttachments(
        ticket?.id as number,
        values.attachments?.filter((attachment) => attachment.size)
      );
    }

    await updateTicket?.(ticket?.id as number, ticketBody);

    if (ticketBody?.is_done !== undefined) {
      invalidate('list-project-tickets');
    }

    setIsEditing(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Box
          sx={() => ({
            flex: 1,
            height: '100%',
          })}
        >
          <Box
            sx={() => ({
              height: '100%',
              width: '100%',
              overflowX: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            })}
          >
            <Box
              sx={(theme) => ({
                padding: theme.spacing.md,
              })}
            >
              <MainContentTitle
                title={ticket ? getTicketDisplay(ticket) : ''}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                ticket={ticket}
              />
            </Box>
            <Box
              sx={(theme) => ({
                width: '100%',
                flex: 1,
                overflowY: 'auto',
                overflowX: 'hidden',
                '&::-webkit-scrollbar': {
                  width: rem(9),
                },
                '&::-webkit-scrollbar-track': {
                  background: theme.colors.gray[4],
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.colors.gray[5],
                  borderRadius: theme.radius.md,
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: theme.colors.gray[6],
                },
              })}
            >
              <MainContentDescription
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
              {!isEditing && Boolean(values?.attachments?.length) && (
                <>
                  <Text
                    sx={(theme) => ({
                      fontWeight: 'bold',
                      fontSize: theme.fontSizes.md,
                    })}
                  >
                    Attachments
                  </Text>
                  <Divider />
                  <Group
                    sx={(theme) => ({
                      paddingTop: theme.spacing.xs,
                      paddingBottom: theme.spacing.xs,
                      borderBottomLeftRadius: theme.radius.sm,
                      borderBottomRightRadius: theme.radius.sm,
                    })}
                    spacing={4}
                  >
                    {values?.attachments?.map((attachment) => (
                      <AttachmentPreview
                        key={attachment.name}
                        file={attachment}
                        onDownload={() => {
                          pushDownload({
                            filename: attachment.name,
                            download: async () => {
                              const foundFilename = ticket?.attachments?.find(
                                (a) => a.includes(attachment.name)
                              );

                              if (foundFilename) {
                                await ticketUtils.downloadTicketAttachment(
                                  ticket?.id as number,
                                  foundFilename
                                );
                                notifications.show({
                                  message:
                                    'File has been successfully downloaded.',
                                  variant: 'info',
                                });
                              }
                            },
                          });
                        }}
                      />
                    ))}
                  </Group>
                </>
              )}
              <TabbedContent ticket={ticket} />
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  );
}
