import { Group } from '@mantine/core';

import ContentSection from '../ContentSection';

import { TicketEstimate, ProjectTicket } from 'types/api';
import EstimatePopOver from 'components/common/PopOver/variants/EstimatePopOver';
import {
  AddTicketEstimateCallback,
  DeleteTicketEstimateCallback,
  EditTicketEstimateCallback,
} from '../../types';

import useProjectPageStore from 'hooks/store/useProjectPageStore';

interface EstimatesSectionProps {
  ticket?: ProjectTicket;
  estimates: TicketEstimate[];
  addTicketEstimate?: AddTicketEstimateCallback;
  editTicketEstimate?: EditTicketEstimateCallback;
  deleteTicketEstimate?: DeleteTicketEstimateCallback;
}

export default function EstimatesSection({
  addTicketEstimate,
  editTicketEstimate,
  deleteTicketEstimate,
  estimates,
}: EstimatesSectionProps) {
  const { ruleErrors } = useProjectPageStore();

  const handleFilter = (value: string) => {
    return !estimates.map((estimate) => estimate.job_type).includes(value);
  };

  return (
    <ContentSection
      title="Estimates"
      titleSx={(theme) => ({
        color: ruleErrors.includes('estimate_count')
          ? theme.colors.yellow[1]
          : undefined,
      })}
    >
      <Group spacing={4}>
        {estimates.map((estimate) => (
          <EstimatePopOver
            key={estimate.id}
            variant="edit"
            estimate={estimate}
            deleteTicketEstimate={deleteTicketEstimate}
            filter={(value) =>
              value === estimate.job_type || handleFilter(value)
            }
            updateTicketEstimate={async (body) => {
              return (
                (await editTicketEstimate?.(estimate.id as number, body)) ||
                false
              );
            }}
          />
        ))}
        <EstimatePopOver
          updateTicketEstimate={addTicketEstimate}
          filter={handleFilter}
        />
      </Group>
    </ContentSection>
  );
}
