import { useCallback, useMemo } from 'react';
import {
  ActionIcon,
  Box,
  Button,
  Group,
  JsonInput,
  TextInput,
  Textarea,
} from '@mantine/core';
import { Formik, useFormikContext } from 'formik';
import { IconInfoCircle } from '@tabler/icons-react';

import useInstanceConfig from 'hooks/instance/useInstanceConfig';

import Section from '../../section/Section';
import TagsInput from 'components/common/TagsInput';
import SubSection from '../../section/SubSection';
import MinExpectedWorkInput from 'components/common/UserInput/variants/MinExpectedWorkInput';
import RuleList from 'components/common/RuleList';

import { InstanceConfig } from 'types/api';
import JsonEditor from 'components/common/JsonEditor';

type ConfigTypesFormState = Pick<
  InstanceConfig,
  | 'job_types'
  | 'expense_types'
  | 'ticket_statuses'
  | 'invoice_instance_info'
  | 'invoice_email_topic'
  | 'invoice_email_body'
  | 'min_expected_work'
  | 'site_meta'
  | 'display_meta'
  | 'project_status_rules'
>;

function ConfigTypesSection({ loading }: { loading?: boolean }) {
  const {
    values,
    initialValues,
    resetForm,
    submitForm,
    setFieldValue,
    isSubmitting,
    handleChange,
  } = useFormikContext<ConfigTypesFormState>();

  const handleValueRemove = useCallback(
    (key: keyof ConfigTypesFormState, value: string) => {
      const valueTypes = values[key] as string[];
      const filteredValueTypes = valueTypes.filter(
        (val) => val.toUpperCase() !== value.toUpperCase()
      );
      setFieldValue(key, filteredValueTypes);
    },
    [setFieldValue, values]
  );

  const handleValueAdd = useCallback(
    (key: keyof ConfigTypesFormState, value: string) => {
      const valueTypes = values[key] as string[];
      const updatedValueTypes = [
        ...new Set(valueTypes.concat([value.toUpperCase()])),
      ];
      setFieldValue(key, updatedValueTypes);
    },
    [setFieldValue, values]
  );

  return (
    <Section title="Config Types" loading={loading}>
      <TagsInput
        tags={values.job_types || []}
        inputProps={{ label: 'Job Types' }}
        onRemove={(tag) => handleValueRemove('job_types', tag)}
        onAdd={(tag) => handleValueAdd('job_types', tag)}
      />
      <TagsInput
        tags={values.expense_types || []}
        inputProps={{ label: 'Expense Types' }}
        onRemove={(tag) => handleValueRemove('expense_types', tag)}
        onAdd={(tag) => handleValueAdd('expense_types', tag)}
      />
      <TagsInput
        tags={values.ticket_statuses || []}
        inputProps={{ label: 'Ticket Statuses' }}
        onRemove={(tag) => handleValueRemove('ticket_statuses', tag)}
        onAdd={(tag) => handleValueAdd('ticket_statuses', tag)}
      />
      <SubSection title="Billing">
        <MinExpectedWorkInput
          defaultValue={initialValues.min_expected_work ?? undefined}
          onValueChange={(value) => setFieldValue('min_expected_work', value)}
          label="Min. Daily Employee Hours"
        />
      </SubSection>
      <SubSection title="Invoicing">
        <Textarea
          autosize
          minRows={4}
          name="invoice_instance_info"
          label="Company Return Address Info"
          value={values.invoice_instance_info}
          onChange={handleChange}
        />
        <TextInput
          name="invoice_email_topic"
          label="Email Topic"
          value={values.invoice_email_topic}
          onChange={handleChange}
        />
        <Textarea
          autosize
          minRows={4}
          name="invoice_email_body"
          label="Email Body"
          value={values.invoice_email_body}
          onChange={handleChange}
        />
      </SubSection>
      <SubSection title="Display Options">
        <JsonEditor
          variant="config"
          value={values.display_meta || '{}'}
          setValue={(newValue) => setFieldValue('display_meta', newValue)}
        />
      </SubSection>
      <SubSection title="Rules">
        <RuleList
          rules={values.project_status_rules}
          setRules={(rules) => setFieldValue('project_status_rules', rules)}
        />
      </SubSection>
      <SubSection title="Site Settings">
        <JsonInput
          formatOnBlur
          autosize
          minRows={4}
          name="site_meta"
          label={
            <Group spacing={2}>
              Site Metadata{' '}
              <a
                href="https://developer.mozilla.org/en-US/docs/Learn/HTML/Introduction_to_HTML/The_head_metadata_in_HTML"
                target="_blank"
                rel="noreferrer"
              >
                <ActionIcon size="xs" color="blue" radius="xs">
                  <IconInfoCircle />
                </ActionIcon>
              </a>
            </Group>
          }
          value={values.site_meta}
          onChange={(newValue) => {
            setFieldValue('site_meta', newValue);
          }}
        />
      </SubSection>
      <Box
        sx={(theme) => ({
          padding: theme.spacing.xs,
          display: 'flex',
          justifyContent: 'flex-start',
          columnGap: '12px',
        })}
      >
        <Button
          variant="outline"
          radius="md"
          onClick={() => resetForm()}
          disabled={isSubmitting}
        >
          Reset
        </Button>
        <Button
          variant="filled"
          radius="md"
          onClick={() => submitForm()}
          loading={isSubmitting}
        >
          Save
        </Button>
      </Box>
    </Section>
  );
}

export default function ConfigSection() {
  const { data, updateInstanceConfig, isLoading } = useInstanceConfig();

  const handleSubmit = useCallback(
    async (values: ConfigTypesFormState) => {
      await updateInstanceConfig(values);
    },
    [updateInstanceConfig]
  );

  const initialValues = useMemo(() => {
    return {
      job_types: data?.config?.job_types || [],
      expense_types: data?.config?.expense_types || [],
      ticket_statuses: data?.config?.ticket_statuses || [],
      project_status_rules: data?.config?.project_status_rules || {},
      invoice_email_body: data?.config?.invoice_email_body || '',
      invoice_email_topic: data?.config?.invoice_email_topic || '',
      invoice_instance_info: data?.config?.invoice_instance_info || '',
      min_expected_work: data?.config?.min_expected_work || undefined,
      site_meta: data?.config?.site_meta || '{}',
      display_meta: data?.config?.display_meta || '{}',
    };
  }, [data]);

  return (
    <Formik<ConfigTypesFormState>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <ConfigTypesSection loading={isLoading} />
    </Formik>
  );
}
