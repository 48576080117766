import { IconLockPause } from '@tabler/icons-react';
import { Indicator, useMantineTheme } from '@mantine/core';
import { capitalize } from 'lodash';

import { BaseToggleButtonProps } from '../types';
import BaseToggleButton from './Base';

export default function BlockedTicketToggleButton({
  value,
  ...props
}: BaseToggleButtonProps) {
  const theme = useMantineTheme();

  const displayMap = {
    all: <IconLockPause fontSize="12px" color={theme.colors.gray[6]} />,
    none: <IconLockPause fontSize="12px" color={theme.colors.blue[6]} />,
    only: <IconLockPause fontSize="12px" color={theme.colors.blue[6]} />,
  };

  return (
    <Indicator disabled={!value || value === 'all'} label={capitalize(value)}>
      <BaseToggleButton
        {...props}
        value={value || 'all'}
        displayMap={displayMap}
      />
    </Indicator>
  );
}
