import { ReactNode, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import useApiStore from 'hooks/store/useApiStore';
import useUsers from 'hooks/users/useUsers';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
    },
  },
});

interface QueryClientProviderProps {
  children: ReactNode;
}

// Populates common hooks in the background on load
function HooksLoader() {
  useUsers();

  return <></>;
}

export default function QueryProvider({ children }: QueryClientProviderProps) {
  const { setQueryClient } = useApiStore();

  useEffect(() => {
    setQueryClient(queryClient);
  }, [setQueryClient]);

  return (
    <QueryClientProvider client={queryClient}>
      <HooksLoader />
      {children}
    </QueryClientProvider>
  );
}
