import { TextInputProps, TextareaProps } from '@mantine/core';

import ClipboardInputTextField from './variants/TextField';
import ClipboardInputTextArea from './variants/TextArea';

interface ClipboardInputProps {
  variant?: 'base' | 'textfield' | 'textarea';
  inputProps?: TextInputProps | TextareaProps;
}

export default function ClipboardInput({
  variant,
  inputProps,
}: ClipboardInputProps) {
  if (variant === 'textfield') {
    return <ClipboardInputTextField {...(inputProps as TextInputProps)} />;
  }

  if (variant === 'textarea') {
    return <ClipboardInputTextArea {...(inputProps as TextareaProps)} />;
  }

  return <ClipboardInputTextField {...(inputProps as TextInputProps)} />;
}
