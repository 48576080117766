import { SearchBaseProps } from './types';

import IconSearch from './variants/Icon';

interface SearchProps extends SearchBaseProps {
  variant?: 'base' | 'icon';
}

export default function Search({ variant, ...props }: SearchProps) {
  if (variant === 'icon') {
    return <IconSearch {...props} />;
  }

  return <IconSearch {...props} />;
}
