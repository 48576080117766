import getDate from 'date-fns/getDate';
import differenceInMinutes from 'date-fns/differenceInMinutes';

import { Activity } from 'types/api';

function shouldMerge(a: Activity, b: Activity) {
  // merge activity on the same ticket
  if (a.ticket_id !== b.ticket_id) return false;

  // merge activity on the same project
  if (a.project?.id !== b.project?.id) return false;

  // merge activity on the same user
  if (a.instance_user?.id !== b.instance_user?.id) return false;

  // merge activity made by the same user
  if (a.user.id !== b.user.id) return false;

  // merge activity with the same app-model targets
  try {
    const changeMessageA = JSON.parse(a.change_message || '{}');
    const changeMessageB = JSON.parse(b.change_message || '{}');

    if (
      JSON.stringify(changeMessageA.target) !==
      JSON.stringify(changeMessageB.target)
    ) {
      return false;
    }
  } catch (err) {
    // pass
  }

  // merge activity on same date
  if (
    getDate(new Date(a.datetime_created as string)) !==
    getDate(new Date(b.datetime_created as string))
  )
    return false;

  // merge activity of the same action
  if (a.action_flag !== b.action_flag) return false;

  // merge activity within a time frame
  if (
    Math.abs(
      differenceInMinutes(
        new Date(a.datetime_created as string),
        new Date(b.datetime_created as string)
      )
    ) > 5
  )
    return false;

  return true;
}

export function mergeActivity(a: Activity, b: Activity) {
  const mergeKeys = [
    'values',
    'old_values',
    'values_display',
    'old_values_display',
  ];

  try {
    const changeMessageA = JSON.parse((a.change_message || '{}') as string);
    const changeMessageB = JSON.parse((b.change_message || '{}') as string);

    mergeKeys.forEach((key) => {
      const valA = changeMessageA[key];
      const valB = changeMessageB[key];

      Object.entries(valB).forEach(([bKey, bVal]) => {
        // only merge old value if already exists
        if (!valA[bKey] || ['old_values', 'old_values_display'].includes(key)) {
          valA[bKey] = bVal;
        }
      });
    });

    a.change_message = JSON.stringify(changeMessageA);
  } catch (err) {
    // pass
  }

  return a;
}

/**
 * Merges similar activity into a single activity item
 * @param activityItems
 */
export function mergeSimilarActivity(activityItems: Activity[]): Activity[] {
  const mergedActivityItems: Activity[] = [];

  if (!activityItems) return mergedActivityItems;

  let lastActivityItem = activityItems[0];

  activityItems.slice(1).forEach((activityItem) => {
    if (shouldMerge(lastActivityItem, activityItem)) {
      lastActivityItem = mergeActivity(lastActivityItem, activityItem);
      return;
    }

    mergedActivityItems.push(lastActivityItem);
    lastActivityItem = activityItem;
  });

  if (lastActivityItem) {
    mergedActivityItems.push(lastActivityItem);
  }

  return mergedActivityItems;
}
