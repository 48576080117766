import { useCallback, useMemo } from 'react';
import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Stack,
  Text,
  rem,
} from '@mantine/core';
import { IconCirclePlus, IconTrash } from '@tabler/icons-react';

import TicketStatusSelect from '../Select/variants/TicketStatusSelect';
import useInstanceConfig from 'hooks/instance/useInstanceConfig';

import TicketRulesMultiSelect from '../MultiSelect/variants/TicketRulesMultiSelect';

interface RuleListProps {
  rules: { [status: string]: string[] };
  setRules: (rules: { [status: string]: string[] }) => void;
  label?: string;
}

export default function RuleList({ rules, setRules, label }: RuleListProps) {
  const { data } = useInstanceConfig();

  const excludeStatuses = useMemo(() => {
    const firstStatus = data?.config?.ticket_statuses?.[0]?.toLowerCase() || '';
    return [firstStatus].concat(
      Object.keys(rules).map((rule) => rule.toLowerCase())
    );
  }, [data?.config?.ticket_statuses, rules]);

  const updateStatus = useCallback(
    (currentStatus: string, updatedStatus: string) => {
      const updatedRules = { ...rules };
      updatedRules[updatedStatus] = rules[currentStatus];
      delete updatedRules[currentStatus];
      setRules(updatedRules);
    },
    [rules, setRules]
  );

  const updateRuleset = useCallback(
    (status: string, value: string[]) => {
      const updatedRules = { ...rules };
      updatedRules[status] = value;
      setRules(updatedRules);
    },
    [rules, setRules]
  );

  const deleteStatus = useCallback(
    (status: string) => {
      const updatedRules = { ...rules };
      delete updatedRules[status];
      setRules(updatedRules);
    },
    [rules, setRules]
  );

  return (
    <Stack>
      {label && (
        <Box>
          <Text size="sm">{label}</Text>
          <Divider />
        </Box>
      )}
      {Object.entries(rules).map(([status, ruleList]) => (
        <Box
          key={status}
          sx={(theme) => ({ display: 'flex', gap: theme.spacing.xs })}
        >
          <Text
            sx={{
              marginTop: 'auto',
              marginBottom: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            Ticket cannot be moved to
          </Text>
          <Box
            sx={{ minWidth: rem(200), marginTop: 'auto', marginBottom: 'auto' }}
          >
            <TicketStatusSelect
              defaultValue={status}
              filter={(s) =>
                !excludeStatuses.includes(s.toLowerCase()) ||
                s.toLowerCase() === status.toLowerCase()
              }
              onChange={(option) =>
                updateStatus(status, option.value as string)
              }
            />
          </Box>
          <Text sx={{ marginTop: 'auto', marginBottom: 'auto' }}>without</Text>
          <Box sx={{ minWidth: rem(230) }}>
            <TicketRulesMultiSelect
              value={ruleList}
              onChange={(value) => updateRuleset(status, value)}
            />
          </Box>
          <ActionIcon
            color="red"
            sx={{ marginTop: 'auto', marginBottom: 'auto' }}
            onClick={() => {
              deleteStatus(status);
            }}
          >
            <IconTrash />
          </ActionIcon>
        </Box>
      ))}
      <Box sx={{ width: '50%' }}>
        <Button
          size="xs"
          variant="light"
          leftIcon={<IconCirclePlus />}
          onClick={() => {
            setRules({ ...rules, '': [] });
          }}
          disabled={Boolean(rules[''])}
        >
          Add Ticket Rule
        </Button>
      </Box>
    </Stack>
  );
}
