import { ReactNode } from 'react';
import { Box, ScrollArea, Loader } from '@mantine/core';

import ConsoleLayoutHeader from './components/Header';
import ConsoleLayoutSidebar from './components/Sidebar';
import ConsoleLayoutActions from './components/Actions';

import { HEADER_HEIGHT } from 'constants/component';
import { ConsoleLayoutActionsProps } from './types';

export interface ConsoleLayoutProps {
  children: ReactNode;
  disablePadding?: boolean;
  disableScroll?: boolean;
  actionProps?: ConsoleLayoutActionsProps;
  loading?: boolean;
}

export default function ConsoleLayout({
  children,
  disablePadding = false,
  disableScroll = false,
  actionProps,
  loading,
}: ConsoleLayoutProps) {
  const ContentWrapper = disableScroll ? Box : ScrollArea;

  return (
    <Box
      display="flex"
      sx={{ gap: 0, height: '100vh', width: '100vw', overflow: 'hidden' }}
    >
      <ConsoleLayoutSidebar />
      <Box
        display="flex"
        sx={(theme) => ({
          backgroundColor: theme.colors.gray[0],
          flexDirection: 'column',
          flex: 1,
          height: '100%',
          gap: 0,
          overflow: 'hidden',
        })}
      >
        <ConsoleLayoutHeader />
        <Box
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            height: `calc(100vh - ${HEADER_HEIGHT})`,
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {actionProps && <ConsoleLayoutActions {...actionProps} />}
          {loading ? (
            <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
              <Loader sx={{ margin: 'auto' }} />
            </Box>
          ) : (
            <ContentWrapper
              p={disablePadding ? 0 : 16}
              sx={{ flex: 1, height: '100%' }}
            >
              {children}
            </ContentWrapper>
          )}
        </Box>
      </Box>
    </Box>
  );
}
