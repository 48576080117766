import { useState, useEffect, useRef, MutableRefObject } from 'react';
import { Group, Button, Text, Stack, Input, Box } from '@mantine/core';
import debounce from 'lodash/debounce';

import { TicketGroup } from './types';
import useProjectTickets from 'hooks/tickets/useProjectTickets';
import InstanceUserSelect from 'components/common/Select/variants/InstanceUserSelect';
import PrioritySelector from 'components/common/PrioritySelector';
import { TicketBody } from 'utils/ticket';

interface AddTicketItemProps {
  ticketBody: Partial<TicketBody>;
  setTicketBody: (ticketBody: Partial<TicketBody>) => void;
  setIsAdding?: (value: boolean) => void;
  onSubmit?: () => void;
  loading?: boolean;
}

function AddTicketItem({
  ticketBody,
  setTicketBody,
  setIsAdding,
  onSubmit,
  loading,
}: AddTicketItemProps) {
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <Input
        ref={inputRef}
        value={ticketBody.title || ''}
        onChange={(e) =>
          setTicketBody({ ...ticketBody, title: e.target.value })
        }
        onBlur={debounce(() => !ticketBody.title && setIsAdding?.(false), 100)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSubmit?.();
          }
        }}
        sx={(theme) => ({
          '& > input': {
            border: 'none',
            boxShadow: theme.shadows.sm,
            height: 48,
          },
          flex: 1,
        })}
        disabled={loading}
      />
      <Box
        sx={(theme) => ({
          padding: theme.spacing.xs,
          paddingTop: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        })}
      >
        <InstanceUserSelect
          variant="avatar"
          defaultValue={ticketBody.assignee_id}
          onChange={(option) => {
            setTicketBody({
              ...ticketBody,
              assignee_id: option.value as number,
            });
          }}
        />
        <Box sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <PrioritySelector
            amount={ticketBody.priority ?? 0}
            setAmount={(amount) => {
              setTicketBody({
                ...ticketBody,
                priority: amount,
              });
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export interface TicketGroupFooterProps {
  projectId: string;
  ticketGroup: TicketGroup;
}

export default function TicketGroupFooter({
  ticketGroup,
  projectId,
}: TicketGroupFooterProps) {
  const [ticketBody, setTicketBody] = useState<Partial<TicketBody>>({});
  const [isAdding, setIsAdding] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addProjectTicket } = useProjectTickets({ projectId, disabled: true });

  const handleSubmit = async () => {
    setLoading(true);

    if (
      await addProjectTicket({
        projectId,
        ticketBody: {
          ...ticketBody,
          priority: (ticketBody.priority ?? 0) + 1,
          status: ticketGroup.id,
          position: ticketGroup.items.length,
        },
      })
    ) {
      setIsAdding(false);
    }

    setLoading(false);
  };

  const toggleVisibility = (show = false) => {
    setIsAdding(show);
    setTicketBody({});
  };

  return (
    <Stack spacing={8}>
      {isAdding && (
        <AddTicketItem
          ticketBody={ticketBody}
          setTicketBody={setTicketBody}
          setIsAdding={setIsAdding}
          onSubmit={handleSubmit}
          loading={loading}
        />
      )}
      {!ticketGroup.isDone && (
        <Group
          grow
          display="flex"
          sx={(theme) => ({
            textAlign: 'center',
            justifyContent: 'center',
            borderTop: '1px solid',
            borderColor: theme.colors.gray[4],
          })}
        >
          {isAdding ? (
            <>
              <Button
                sx={{ flex: 1 }}
                variant="subtle"
                onClick={() => toggleVisibility(false)}
                disabled={loading}
              >
                <Text sx={{ lineHeight: 1, marginLeft: 4 }}>Cancel</Text>
              </Button>
              <Button
                sx={{ flex: 1 }}
                variant="subtle"
                onClick={() => handleSubmit()}
                loading={loading}
              >
                <Text sx={{ lineHeight: 1, marginLeft: 4 }}>Save</Text>
              </Button>
            </>
          ) : (
            <Button variant="subtle" onClick={() => toggleVisibility(true)}>
              <Text sx={{ lineHeight: 1, marginLeft: 4 }}>Add Ticket</Text>
            </Button>
          )}
        </Group>
      )}
    </Stack>
  );
}
