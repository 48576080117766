import { useCallback } from 'react';
import { notifications } from '@mantine/notifications';

import constructQueryPath from 'helpers/api/constructQueryPath';
import showApiErrNotif from 'helpers/api/showApiErrNotif';

import * as billing from 'utils/billing';
import type { AddBillingRecordBody } from 'utils/billing';

import useApiQuery from 'hooks/api/useApiQuery';
import useApiStore from 'hooks/store/useApiStore';

import { BILLING_TYPE } from 'constants/billing';
import { ApiQueryKey } from 'types/api/query';
import { BillingRecord } from 'types/api';
import { ENDPOINTS, INVALIDATE_GROUP } from 'constants/api';

interface TicketBillingRecordsHookParams {
  ticketId?: number | string;
  projectId?: number | string;
  params?: any;
  storeKey?: ApiQueryKey;
}

export default function useTicketBillingRecords({
  ticketId,
  projectId,
  params,
  storeKey,
}: TicketBillingRecordsHookParams) {
  const { invalidateGroup, invalidatePath } = useApiStore();

  const queryResult = useApiQuery<{ billing_records?: BillingRecord[] }>({
    path: constructQueryPath(ENDPOINTS.BILLING_RECORDS),
    axiosConfig: {
      params: {
        ...params,
        ticket_id: ticketId,
        project_id: projectId,
      },
    },
    storeKey,
  });

  const addBillingRecord = useCallback(
    async (billingRecordBody: Partial<AddBillingRecordBody>) => {
      try {
        await billing.addBillingRecord({
          ticketId,
          body: billingRecordBody,
        });
        invalidateGroup(INVALIDATE_GROUP.BILLING_RECORD);
        invalidatePath(['activity']);
        notifications.show({
          message: 'Billing Record successfully added!',
          color: 'green',
        });
        return true;
      } catch (err) {
        showApiErrNotif('Unable to add billing record. Try again later.', err);
      }

      return false;
    },
    [invalidateGroup, invalidatePath, ticketId]
  );

  const addExpense = useCallback(
    async (expenseBody: Partial<AddBillingRecordBody>) => {
      try {
        await billing.addBillingRecord({
          ticketId,
          body: {
            ...expenseBody,
            billing_type: BILLING_TYPE.EXPENSE,
            date: expenseBody.date,
          },
        });
        invalidateGroup(INVALIDATE_GROUP.EXPENSE);
        invalidatePath(['activity']);
        notifications.show({
          message: 'Expense successfully added!',
          color: 'green',
        });
        return true;
      } catch (err) {
        showApiErrNotif('Unable to add expense. Try again later.', err);
      }

      return false;
    },
    [invalidateGroup, invalidatePath, ticketId]
  );

  const editBillingRecord: typeof billing.editBillingRecord = useCallback(
    async (editBillingRecordParams) => {
      try {
        await billing.editBillingRecord(editBillingRecordParams);
        invalidateGroup(INVALIDATE_GROUP.BILLING_RECORD);
        invalidatePath(['activity']);
        queryResult.refetch();
        notifications.show({
          message: 'Billing Record has been successfully updated!',
          variant: 'success',
        });
      } catch (err) {
        showApiErrNotif(
          'Unable to update billing record. Try again later.',
          err
        );
      }
    },
    [invalidateGroup, invalidatePath, queryResult]
  );

  const deleteBillingRecord = useCallback(
    async (deleteBillingRecordParams) => {
      try {
        const res = await billing.deleteBillingRecord(
          deleteBillingRecordParams
        );
        invalidateGroup(INVALIDATE_GROUP.BILLING_RECORD);
        invalidatePath(['activity']);
        queryResult.refetch();
        notifications.show({
          message: 'Billing Record has been successfully deleted!',
          variant: 'success',
        });
        return res;
      } catch (err) {
        showApiErrNotif(
          'Unable to delete billing record. Try again later.',
          err
        );
      }
    },
    [invalidateGroup, invalidatePath, queryResult]
  ) as typeof billing.deleteBillingRecord;

  return {
    ...queryResult,
    addBillingRecord,
    editBillingRecord,
    addExpense,
    deleteBillingRecord,
  };
}
