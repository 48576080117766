import { create } from 'zustand';

import { ping } from 'utils/auth';

interface AuthStoreState {
  isAuthenticated: boolean;
  isAdmin: boolean;
  isSuperUser: boolean;
  hasPinged: boolean;
  setIsAuthenticated: (isAuth: boolean) => void;
  ping: () => Promise<void>;
}

const useAuthStore = create<AuthStoreState>((set) => ({
  isAuthenticated: false,
  isAdmin: false,
  isSuperUser: false,
  hasPinged: false,
  setIsAuthenticated: (isAuthenticated: boolean) =>
    set((state) => ({
      ...state,
      isAuthenticated,
      hasPinged: !isAuthenticated ? false : state.hasPinged,
    })),
  ping: async () => {
    let isAuthenticated = false;
    let isAdmin = false;
    let isSuperUser = false;

    try {
      const res = await ping();
      isAuthenticated = Boolean(res.is_authenticated);
      isAdmin = Boolean(res.is_admin);
      isSuperUser = Boolean(res.is_superuser);
    } catch (err) {
      // pass
    }

    set((state) => ({
      ...state,
      isAuthenticated,
      isAdmin,
      isSuperUser,
      hasPinged: true,
    }));
  },
}));

export default useAuthStore;
