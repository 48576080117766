import {
  Box,
  Card,
  createStyles,
  rem,
  Button,
  ActionIcon,
  Table,
  ScrollArea,
  Text,
  Loader,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { IconX } from '@tabler/icons-react';

import useInstanceConfig from 'hooks/instance/useInstanceConfig';
import useBillingRates from 'hooks/billing/useBillingRates';
import useBillingRatesAggregate from 'hooks/billing/useBillingRatesAggregate';
import useProjects from 'hooks/projects/useProjects';
import useClients from 'hooks/clients/useClients';
import useUsers from 'hooks/users/useUsers';

import CurrencyInput from 'components/common/UserInput/variants/CurrencyInput';

import getUserFullName from 'helpers/display/getUserFullName';

import { DOLLAR } from 'constants/billing';
import { BillingRate } from 'types/api';

const colWidth = 150;

interface BillingRatesMap {
  [id: string]: Partial<BillingRate>;
}

interface BillingRatesOverrideMap {
  [id: string]: boolean;
}

const useStyles = createStyles((theme) => ({
  groupLabel: {
    backgroundColor: theme.colors.gray[1],
    paddingLeft: theme.spacing.md,
    fontWeight: 'bold',
    width: '100%',
    height: rem(24),
  },
  fixedCol: {
    position: 'absolute',
    marginLeft: rem(-colWidth),
    width: rem(colWidth),

    lineHeight: '100%',
    fontWeight: 'normal',
    fontSize: '14px',
    wordBreak: 'break-word',
    overflow: 'hidden',
    height: rem(52),
    display: 'flex',
  },
  groupLabelCol: {
    position: 'absolute',
    marginLeft: rem(-colWidth),
    width: rem(colWidth),
    lineHeight: '100%',
    fontWeight: 'bold',
    backgroundColor: theme.colors.gray[1],
    height: rem(24),
    paddingTop: rem(4),
  },
  rowData: {
    padding: theme.spacing.sm,
  },
  rowCol: {
    width: rem(colWidth),
  },
}));

interface BillingRatesGridCellProps {
  billingRate?: Partial<BillingRate>;
  onChange?: (value: number) => void;
  onActiveChange?: (active: boolean) => void;
  id: string;
  overridden: boolean;
  onFocus?: (blur?: boolean) => void;
  jobType?: string;
}

const BillingRateGridCell = ({
  billingRate,
  onChange,
  onActiveChange,
  id,
  overridden,
  onFocus,
  jobType,
}: BillingRatesGridCellProps) => {
  const [rate, setRate] = useState<number>(billingRate?.rate || 0);
  const [active, setActive] = useState<boolean>(
    Boolean(billingRate?.is_active)
  );

  const allowRateDisable = !(jobType === '' && id === 'instance');

  const handleChange = (value: number) => {
    setRate(value * DOLLAR);
    onChange?.(value);
  };

  const handleEnable = (enabled: boolean) => {
    setActive(enabled);
    onActiveChange?.(enabled);
  };

  useEffect(() => {
    setRate(billingRate?.rate || 0);
    setActive(Boolean(billingRate?.is_active));
  }, [billingRate]);

  let content = <></>;

  if (!active) {
    content = (
      <Button
        color="gray"
        variant="subtle"
        sx={{ width: '100%', margin: 'auto 0' }}
        onClick={() => handleEnable(true)}
      >
        Enable Override
      </Button>
    );
  } else {
    content = (
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          width: rem(colWidth),
        }}
      >
        <Box sx={{ margin: 'auto 0' }}>
          <CurrencyInput
            sx={{
              textDecoration: overridden ? 'line-through' : undefined,
            }}
            value={rate / DOLLAR}
            onChange={handleChange}
            min={0}
            onFocus={() => onFocus?.()}
            onBlur={() => onFocus?.(true)}
          />
        </Box>
        {allowRateDisable && (
          <ActionIcon
            sx={{ margin: 'auto 0' }}
            title="Disable this rate?"
            onClick={() => handleEnable(false)}
          >
            <IconX />
          </ActionIcon>
        )}
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>{content}</Box>
  );
};

interface BillingRatesGridProps {
  jobTypes: string[];
  jobTypeRateMap: { [jt: string]: Partial<BillingRate> };
  overrideMap: { [id: string]: boolean };
  onChange: (
    id: string,
    billingRate: Partial<BillingRate>,
    value: number
  ) => void;
  onActiveChange: (
    id: string,
    billingRate: Partial<BillingRate>,
    active: boolean
  ) => void;
  onFocus: (id: string, blur?: boolean) => void;
  id: string;
  name: string;
}

const BillingRatesGridRow = ({
  jobTypes,
  jobTypeRateMap,
  onChange,
  id,
  onActiveChange,
  onFocus,
  overrideMap,
  name,
}: BillingRatesGridProps) => {
  const { classes } = useStyles();
  const columns = [''].concat(jobTypes);

  const getId = (jobType: string) => {
    return `${id}.${jobType}`;
  };

  return (
    <tr>
      <th className={classes.fixedCol}>
        <Text
          sx={{
            margin: 'auto 0',
            textAlign: 'center',
            width: '100%',
            lineHeight: '100%',
          }}
        >
          {name}
        </Text>
      </th>
      {columns.map((jt) => {
        const billingRate = jobTypeRateMap?.[jt];
        return (
          <td key={jt} className={classes.rowData}>
            <BillingRateGridCell
              billingRate={billingRate}
              onChange={(value) => {
                onChange?.(getId(jt), billingRate, value);
              }}
              id={id}
              onActiveChange={(active) => {
                onActiveChange?.(getId(jt), billingRate, active);
              }}
              onFocus={(blur) => {
                onFocus?.(getId(jt), blur);
              }}
              overridden={overrideMap[getId(jt)]}
              jobType={jt}
            />
          </td>
        );
      })}
    </tr>
  );
};

export default function BillingRatesGrid() {
  const [loading, setLoading] = useState(false);
  const [updatedBillingRates, setUpdatedBillingRates] =
    useState<BillingRatesMap>({});
  const [overriddenBillingRates, setOverriddenBillingRates] =
    useState<BillingRatesOverrideMap>({});
  const { classes } = useStyles();
  const { data: configData, isLoading: configLoading } = useInstanceConfig();
  const { data: projectData, isLoading: projectLoading } = useProjects();
  const { data: clientData, isLoading: clientLoading } = useClients();
  const { data: userData, isLoading: userLoading } = useUsers();
  const { data, updateBillingRates, isLoading } = useBillingRates();
  const {
    getClientBillingRates,
    getEmployeeBillingRates,
    getInstanceBillingRates,
    getProjectBillingRates,
  } = useBillingRatesAggregate({
    billingRates: data?.billing_rates,
  });

  const jobTypes = configData?.config?.job_types || [];
  const fetchLoading =
    configLoading ||
    projectLoading ||
    clientLoading ||
    userLoading ||
    isLoading;

  const handleRateChange = (
    id: string,
    billingRate: Partial<BillingRate>,
    value: number
  ) => {
    const [target, targetId, jobType] = id.split('.');

    const updatedBillingRate: Partial<BillingRate> = {
      ...updatedBillingRates[id],
      ...billingRate,
      rate: value * DOLLAR,
    };

    if (target === 'project' && targetId) {
      updatedBillingRate.project_id = parseInt(targetId);
    }

    if (target === 'employee' && targetId) {
      updatedBillingRate.employee_id = parseInt(targetId);
    }

    if (target === 'client' && targetId) {
      updatedBillingRate.client_id = parseInt(targetId);
    }

    if (jobType) {
      updatedBillingRate.job_type = jobType;
    }

    setUpdatedBillingRates({
      ...updatedBillingRates,
      [id]: updatedBillingRate,
    });
  };

  const handleActiveChange = (
    id: string,
    billingRate: Partial<BillingRate>,
    active: boolean
  ) => {
    setUpdatedBillingRates({
      ...updatedBillingRates,
      [id]: {
        ...updatedBillingRates[id],
        ...billingRate,
        is_active: active,
      },
    });
  };

  const handleFocus = (id: string, blur: boolean = false) => {
    if (blur) {
      setOverriddenBillingRates({});
      return;
    }

    const [target, targetId, jobType] = id.split('.');

    if (id === 'instance.') {
      return;
    }

    const override = { ...overriddenBillingRates };

    if (jobType) {
      override[`${target}.${targetId}.`] = true;
      override[`instance.${jobType || ''}`] = true;
    } else if (target !== 'instance') {
      jobTypes.forEach((jt) => {
        override[`instance.${jt}`] = true;
      });
    }

    // client and project rates override employee rates
    if (target === 'client' || target === 'project') {
      userData?.users?.forEach((user) => {
        override[`employee.${user.id}.${jobType || ''}`] = true;
        override[`employee.${user.id}.`] = true;

        if (!jobType) {
          jobTypes.forEach((jt) => {
            override[`employee.${user.id}.${jt}`] = true;
          });
        }
      });
    }

    // project rates override it's client's rate
    if (target === 'project') {
      projectData?.projects?.forEach((project) => {
        if (project.id?.toString() === targetId) {
          override[`client.${project.client_id}.${jobType || ''}`] = true;
          override[`client.${project.client_id}.`] = true;

          if (!jobType) {
            jobTypes.forEach((jt) => {
              override[`client.${project.client_id}.${jt}`] = true;
            });
          }
        }
      });
    }

    // everything overrides instance default
    override[`instance.`] = true;

    setOverriddenBillingRates(override);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await updateBillingRates(Object.values(updatedBillingRates));
      setUpdatedBillingRates({});
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      padding={0}
      withBorder
      shadow="md"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {fetchLoading ? (
        <Box sx={(theme) => ({ padding: theme.spacing.xl, display: 'flex' })}>
          <Loader sx={{ margin: 'auto' }} />
        </Box>
      ) : (
        <>
          <Box sx={{ position: 'relative' }}>
            <ScrollArea
              sx={() => ({
                marginLeft: rem(colWidth),
                overflow: 'visible',
              })}
            >
              <Table>
                <tr>
                  <th className={classes.fixedCol}></th>
                  <th>BASE</th>
                  {jobTypes.map((jobType) => (
                    <th key={jobType}>{jobType}</th>
                  ))}
                </tr>
                <BillingRatesGridRow
                  jobTypes={jobTypes}
                  jobTypeRateMap={getInstanceBillingRates()}
                  onChange={handleRateChange}
                  onActiveChange={handleActiveChange}
                  id="instance"
                  overrideMap={overriddenBillingRates}
                  onFocus={handleFocus}
                  name="BASE"
                />
                <tr>
                  <th className={classes.groupLabelCol}>Employees</th>
                  <td colSpan={100} className={classes.groupLabel}></td>
                </tr>
                {userData?.users?.map((user) => (
                  <BillingRatesGridRow
                    key={user.id}
                    jobTypeRateMap={getEmployeeBillingRates(user.id as number)}
                    jobTypes={jobTypes}
                    onChange={handleRateChange}
                    id={`employee.${user.id}`}
                    onActiveChange={handleActiveChange}
                    overrideMap={overriddenBillingRates}
                    onFocus={handleFocus}
                    name={getUserFullName(user)}
                  />
                ))}
                <tr>
                  <th className={classes.groupLabelCol}>Clients</th>
                  <td colSpan={100} className={classes.groupLabel}></td>
                </tr>
                {clientData?.clients?.map((client) => (
                  <BillingRatesGridRow
                    key={client.id}
                    jobTypeRateMap={getClientBillingRates(client.id as number)}
                    jobTypes={jobTypes}
                    onChange={handleRateChange}
                    id={`client.${client.id}`}
                    onActiveChange={handleActiveChange}
                    overrideMap={overriddenBillingRates}
                    onFocus={handleFocus}
                    name={client.name}
                  />
                ))}
                <tr>
                  <th className={classes.groupLabelCol}>Projects</th>
                  <td colSpan={100} className={classes.groupLabel}></td>
                </tr>
                {projectData?.projects?.map((project) => (
                  <BillingRatesGridRow
                    key={project.id}
                    jobTypeRateMap={getProjectBillingRates(
                      project.id as number
                    )}
                    jobTypes={jobTypes}
                    onChange={handleRateChange}
                    id={`project.${project.id}`}
                    onActiveChange={handleActiveChange}
                    overrideMap={overriddenBillingRates}
                    onFocus={handleFocus}
                    name={project.title}
                  />
                ))}
              </Table>
            </ScrollArea>
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'center',
              columnGap: '12px',
              padding: theme.spacing.lg,
              width: '100%',
            })}
          >
            <Button
              variant="filled"
              radius="md"
              sx={{ width: rem(100) }}
              onClick={handleSubmit}
              loading={loading}
            >
              Save
            </Button>
          </Box>
        </>
      )}
    </Card>
  );
}
