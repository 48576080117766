import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import CONFIG from 'config';
import useConfigStore from 'hooks/store/useConfigStore';

export default function useBreadcrumbs() {
  const location = useLocation();
  const { breadcrumbsMap } = useConfigStore();

  const breadcrumbs = useMemo(() => {
    let initialDisplay = (window.INSTANCE_NAME ||
      process.env.REACT_APP_INSTANCE_NAME) as string;

    if (process.env.REACT_APP_API_BASE_URL?.includes('dev')) {
      initialDisplay = `${initialDisplay} (DEV)`;
    }

    const links: { display: string; href: string }[] = [
      {
        display: initialDisplay,
        href: '',
      },
    ];

    const breadcrumbMatches = Object.values(CONFIG.breadcrumbs);

    const foundMatch = breadcrumbMatches.find((bm) =>
      bm.re.test(location.pathname)
    );

    let display = '';
    if (foundMatch) {
      display = foundMatch.display;
      const tokens = foundMatch.display.match(/:\w+/) || [];

      tokens.forEach((token) => {
        const tokenKey = token.slice(1).trim();
        const replacement = breadcrumbsMap?.[tokenKey] || '';
        display = display.replace(token, replacement);
      });
    }

    const pathSplit = location.pathname.split('/');

    let path = '';
    pathSplit.forEach((pathname, index) => {
      if (!pathname) return;

      path += `${pathname}/`;

      let pathDisplay = pathname;

      if (display && index === pathSplit.length - 1) {
        pathDisplay = display;
      }

      links.push({
        display: pathDisplay.replace('-', ' '),
        href: path,
      });
    });

    return links;
  }, [breadcrumbsMap, location.pathname]);

  return {
    breadcrumbs,
  };
}
