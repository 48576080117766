interface GetInitialsParams {
  fullName?: string;
  firstName?: string;
  lastName?: string;
  count?: number;
}

export default function getInitials({
  fullName,
  firstName,
  lastName,
  count = 2,
}: GetInitialsParams) {
  let tokens = [];

  if (fullName) {
    tokens = fullName.split(' ');
  } else {
    if (firstName) {
      tokens.push(firstName);
    }

    if (lastName) {
      tokens.push(lastName);
    }
  }

  return tokens.slice(0, count).map((token) => token[0].toUpperCase());
}
