import getHashOfString from './getHashOfString';
import normalizeHash from './normalizeHash';

const hRange = [0, 360];
const sRange = [50, 60];
const lRange = [30, 50];

export default function generateHSL(name: string) {
  const hash = getHashOfString(name);
  const h = normalizeHash(hash, hRange[0], hRange[1]);
  const s = normalizeHash(hash, sRange[0], sRange[1]);
  const l = normalizeHash(hash, lRange[0], lRange[1]);
  return [h, s, l];
}
