import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import parse from 'date-fns/parse';
import format from 'date-fns/format';

import useAvailabilityRecords from 'hooks/users/useAvailabilityRecords';

import { DATE_FORMATS } from 'constants/date';
import { AvailabilityRecord } from 'types/api';

interface AvailabilityRecordsMap {
  users: {
    [userId: string]: {
      dates: {
        [dateKey: string]: AvailabilityRecord;
      };
    };
  };
}

export default function useAvailabilityRecordsAggregate() {
  const { data, ...rest } = useAvailabilityRecords({
    params: { status: 'approved' },
  });

  const availabilityRecordsMap: AvailabilityRecordsMap = { users: {} };

  data?.availability_records?.forEach((availabilityRecord) => {
    const userKey = availabilityRecord.instance_user_id.toString();

    if (!availabilityRecordsMap.users[userKey]) {
      availabilityRecordsMap.users[userKey] = { dates: {} };
    }

    // an availability record can cover a range of dates
    // store each date of availability record in map for quick reference
    const startDay = parse(
      availabilityRecord.date_start,
      DATE_FORMATS.DATE_KEY,
      new Date()
    );
    const endDay = availabilityRecord.date_end
      ? parse(availabilityRecord.date_end, DATE_FORMATS.DATE_KEY, new Date())
      : null;
    const dateRange = eachDayOfInterval({
      start: startDay,
      end: endDay || startDay,
    });

    dateRange.forEach((date) => {
      const dateKey = format(date, DATE_FORMATS.DATE_KEY);
      availabilityRecordsMap.users[userKey].dates[dateKey] = availabilityRecord;
    });
  });

  return {
    ...rest,
    aggregateData: availabilityRecordsMap,
  };
}
