import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Alert, List, Text } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import parse from 'date-fns/parse';
import format from 'date-fns/format';

import { ModalProps } from '../../types';
import { BillingRecord, ProjectTicket } from 'types/api';
import { MODALS } from 'constants/component';
import { DATE_FORMATS } from 'constants/date';
import { BILLING_TYPE } from 'constants/billing';

import BaseModal from '../BaseModal';
import BillingRecordsList from 'components/shared/BillingRecordsList';

import useModalStore from 'hooks/store/useModalStore';
import useBillingRecords from 'hooks/billing/useBillingRecords';
import useInstanceConfig from 'hooks/instance/useInstanceConfig';

interface ListBillingRecordsModalProps extends ModalProps {
  billingRecords?: BillingRecord[];
  params?: any;
  title?: string;
  ticket?: ProjectTicket;
  paramsMap?: { [key: string]: string };
}

interface ParamListItemProps {
  keyName: string;
  value: string;
}

function ParamListItem({ keyName, value }: ParamListItemProps) {
  const { dateDisplay } = useInstanceConfig();

  let displayValue = value as string;

  if (keyName.includes('date')) {
    const date = parse(value, DATE_FORMATS.DATE_KEY, new Date());
    displayValue = format(date, dateDisplay);
  }

  return (
    <List.Item key={keyName}>
      <Text
        component="span"
        sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
      >
        {keyName.replace('_id', '').replace('_', ' ')}
      </Text>
      : {displayValue}
    </List.Item>
  );
}

function ListBillingRecordsModal({
  params,
  title,
  paramsMap,
  ...props
}: ListBillingRecordsModalProps) {
  const modal = useModal();
  const { popModal } = useModalStore();
  const { data, editBillingRecord, deleteBillingRecord, isLoading } =
    useBillingRecords({
      params: {
        billing_type: BILLING_TYPE.TIME,
        ...params,
      },
      storeKey: 'list-billing-records-modal',
    });

  const handleClose = () => {
    popModal(MODALS.LIST_BILLING_RECORDS_MODAL);
  };

  return (
    <>
      <BaseModal
        isOpen={modal.visible}
        onClose={handleClose}
        size="800px"
        title={title || 'Billing Records'}
        includesBackdrop={false}
        loading={isLoading}
        {...props}
      >
        <Alert icon={<IconAlertCircle size="1rem" />} color="blue">
          Filtered By:
          <List>
            {Boolean(Object.keys(params || {}).length) &&
              Object.entries(params || {})
                .filter(([k, v]) => k && v)
                .map(([key, value]) => (
                  <ParamListItem
                    key={key}
                    keyName={key}
                    value={(paramsMap?.[key] || value) as string}
                  />
                ))}
          </List>
        </Alert>
        <BillingRecordsList
          billingRecords={data?.billing_records}
          editBillingRecord={editBillingRecord}
          deleteBillingRecord={deleteBillingRecord}
          variant="time"
        />
      </BaseModal>
    </>
  );
}

export default NiceModal.create((props) => (
  <ListBillingRecordsModal {...props} id={MODALS.LIST_BILLING_RECORDS_MODAL} />
));
