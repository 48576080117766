import { ENDPOINTS } from 'constants/api';

import useApiQueryInfinite from 'hooks/api/useApiQueryInfinite';

import type { Activity } from 'types/api';
import { ApiQueryKey } from 'types/api/query';

interface UserActivityInfiniteHookParams {
  params?: any;
  userId: number | string;
  disabled?: boolean;
  storeKey?: ApiQueryKey;
}

export default function useUserActivityInfinite(
  hookParams: UserActivityInfiniteHookParams
) {
  const { params, disabled, userId, storeKey } = hookParams || {};

  const queryResult = useApiQueryInfinite<{
    activity_items?: Activity[];
  }>({
    path: ENDPOINTS.ACTIVITY,
    axiosConfig: {
      params: {
        ...params,
        user_id: userId,
      },
    },
    queryOptions: {
      enabled: !disabled,
      refetchOnWindowFocus: false,
    },
    storeKey,
  });

  return {
    ...queryResult,
  };
}
