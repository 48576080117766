import { LAYERED_PERMISSIONS } from 'constants/role';

import useAuthUser from 'hooks/users/useAuthUser';

export default function usePermissions() {
  const { user, ...rest } = useAuthUser();

  const hasPermission = (
    content: string,
    permission: string,
    target?: number
  ) => {
    const foundPermission = user?.permissions?.find(
      (p) => p.codename === permission && p.content_type.includes(content)
    );

    if (!foundPermission && !user?.is_admin) return false;

    // handle permissions that only appy to certain users
    if (LAYERED_PERMISSIONS[permission] && target) {
      const reportsTo = user?.manager;
      const reportsFor = user?.manages || [];

      if (target === user?.id) {
        return !reportsTo;
      }

      if (reportsFor.length === 0) {
        return false;
      }

      const foundEmployee = reportsFor.find(
        (employee) => employee.id === target
      );

      return Boolean(foundEmployee);
    }

    return Boolean(foundPermission);
  };

  return {
    user,
    hasPermission,
    ...rest,
  };
}
