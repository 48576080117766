import { useCallback, useMemo } from 'react';
import { Text } from '@mantine/core';
import format from 'date-fns/format';
import sum from 'lodash/sum';

import BaseTable from 'components/common/Table/variants/BaseTable';

import { TableColumn } from 'components/common/Table/types';
import { InstanceUserStat } from 'types/api';
import { BaseTableProps } from 'components/common/Table/types';
import { DATE_FORMATS } from 'constants/date';

import useAuthStore from 'hooks/store/useAuthStore';
import useAuthUser from 'hooks/users/useAuthUser';
import useUserStats from 'hooks/users/useUserStats';
import roundToNearestMultiple from 'helpers/data/roundToNearestMultiple';
import getCurrencyDisplay from 'helpers/display/getCurrencyDisplay';

export interface BillingTableProps {
  dateStart: Date;
  dateEnd?: Date;
  params?: any;
}

export default function BillingTable({
  params,
  dateStart,
  dateEnd,
}: BillingTableProps) {
  const { data, isLoading } = useUserStats({
    params: {
      ...params,
      date_start: format(dateStart, DATE_FORMATS.DATE_KEY),
      date_end: dateEnd ? format(dateEnd, DATE_FORMATS.DATE_KEY) : undefined,
    },
  });
  const { isAdmin } = useAuthStore();
  const { user } = useAuthUser();

  const rows = useMemo(() => {
    const userStats = data?.user_stats?.slice() || [];

    const totalsRow: InstanceUserStat = {
      id: 0,
      billable_amount: sum(userStats.map((stat) => stat.billable_amount)),
      billing_quota: sum(userStats.map((stat) => stat.billing_quota)),
      unbillable_amount: sum(userStats.map((stat) => stat.unbillable_amount)),
      user_first_name: 'Total',
      user_last_name: '',
      quota: sum(
        userStats.map((stat) => roundToNearestMultiple(stat.quota, 15, true))
      ),
      revenue: sum(userStats.map((stat) => stat.revenue)),
      min_expected_work: 1,
      pto_days: sum(userStats.map((stat) => stat.pto_days)),
      cost: sum(userStats.map((stat) => stat.cost)),
    };

    if (!isLoading) {
      userStats.push(totalsRow);
    }

    return userStats;
  }, [data?.user_stats, isLoading]);

  const getDataDisplay: BaseTableProps<InstanceUserStat>['getDataDisplay'] =
    useCallback(
      (key: string, row: InstanceUserStat) => {
        const rowKey = key;
        const isSelf = user?.id === row.id;

        if (key === 'user_name') {
          if (row.user_first_name === 'Total') {
            return <Text weight="bold">{row.user_first_name}</Text>;
          }

          return (
            <Text color={isSelf ? 'blue' : ''} weight={isSelf ? 'bold' : ''}>
              {row.user_first_name} {row.user_last_name}
            </Text>
          );
        }

        if (key === 'billable_amount' || key === 'unbillable_amount') {
          const display = row[key] / 60;
          return display.toFixed(2);
        }

        if (key === 'total_amount') {
          const total = row.billable_amount + row.unbillable_amount;
          const display = total / 60;
          return display.toFixed(2);
        }

        if (key === 'billing_percentage') {
          const total = row.billable_amount + row.unbillable_amount;
          const percentage = (row.billable_amount / total) * 100;
          return (percentage || 0).toFixed(2);
        }

        if (['quota', 'quota_diff'].includes(key)) {
          const quota = row.quota ?? 0;
          const billingQuota = roundToNearestMultiple(quota, 15, true) / 60;
          const billingHours = row.billable_amount / 60;

          if (key === 'quota') {
            return `${billingQuota}`;
          }

          if (key === 'quota_diff') {
            const diff = billingHours - billingQuota;
            const isNegative = diff < 0;
            const getColor = () => {
              if (diff === 0) return undefined;
              if (diff < 0) return 'red';
              return 'green';
            };

            return (
              <Text color={getColor()}>
                {isNegative ? '' : '+'}
                {diff.toFixed(2)}
              </Text>
            );
          }
        }

        const revenue = row.revenue / 60;
        if (key === 'revenue') {
          return getCurrencyDisplay(revenue / 100);
        }

        if (key === 'cost') {
          return getCurrencyDisplay(row.cost / 100);
        }

        const profit = revenue - row.cost;
        if (key === 'profit_loss') {
          const getColor = () => {
            if (profit === 0) return undefined;
            if (profit < 0) return 'red';
            return 'green';
          };

          return (
            <Text color={getColor()}>{getCurrencyDisplay(profit / 100)}</Text>
          );
        }

        if (key === 'gross_margin') {
          const margin = profit / revenue || 0;
          const getColor = () => {
            if (margin === 0) return undefined;
            if (margin < 0) return 'red';
            return 'green';
          };

          return <Text color={getColor()}>{Math.round(margin * 100)}%</Text>;
        }

        return `${row[rowKey as keyof InstanceUserStat]}`;
      },
      [user?.id]
    );

  const columns: TableColumn[] = useMemo(() => {
    const baseColumns = [
      {
        id: 'user_name',
        display: 'Employee Name',
      },
      {
        id: 'pto_days',
        display: 'PTO Days',
      },
      {
        id: 'unbillable_amount',
        display: 'Non-Billable Hours',
      },
      {
        id: 'billable_amount',
        display: 'Billable Hours',
      },
      {
        id: 'total_amount',
        display: 'Total Hours',
      },
      {
        id: 'quota',
        display: 'Quota',
      },
      {
        id: 'quota_diff',
        display: 'Quota Shortfall',
      },
      {
        id: 'billing_percentage',
        display: 'Billing %',
      },
    ];

    if (isAdmin) {
      baseColumns.push(
        {
          id: 'revenue',
          display: 'Revenue Billed',
        },
        {
          id: 'cost',
          display: 'Cost',
        },
        {
          id: 'profit_loss',
          display: 'Profit / Loss',
        },
        {
          id: 'gross_margin',
          display: 'Gross Margin',
        }
      );
    }

    return baseColumns;
  }, [isAdmin]);

  return (
    <BaseTable<InstanceUserStat>
      columns={columns}
      loading={isLoading}
      rows={rows}
      getDataDisplay={getDataDisplay}
    />
  );
}
