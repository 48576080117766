import { Box, ScrollArea, Stack, Text, TextInput } from '@mantine/core';
import { MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { Formik, FormikProps, useFormikContext } from 'formik';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import * as Yup from 'yup';
import { useIMask } from 'react-imask';

import { AddClientBody } from 'utils/client';
import { MASKS } from 'constants/component';
import { MODALS } from 'constants/component';

import BaseModal from '../BaseModal';
import ModalFooter from './ModalFooter';
import CurrencyInput from 'components/common/UserInput/variants/CurrencyInput';
import RichTextEditor from 'components/common/RichTextEditor';
import StateSelect from 'components/common/Select/variants/StateSelect';
import CountrySelect from 'components/common/Select/variants/CountrySelect';
import BudgetTypeSelect from 'components/common/Select/variants/BudgetTypeSelect';

import useClient from 'hooks/clients/useClient';
import useModalStore from 'hooks/store/useModalStore';
import useClients from 'hooks/clients/useClients';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Email must be a valid email'),
  invoice_email: Yup.string().email('Invoice To must be a valid email'),
  phone: Yup.string(),
  address_line1: Yup.string().required('Address is required'),
  address_line2: Yup.string(),
  address_city: Yup.string().required('City is required'),
  address_state: Yup.string().required('State is required'),
  address_zip: Yup.string().required('Zip is required'),
  address_country: Yup.string().required('Country is required'),
  note: Yup.string(),
  budget_amount: Yup.number(),
  budget_type: Yup.string(),
});

function AddClientModalForm({ clientId }: { clientId?: number }) {
  const {
    values,
    initialValues,
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    touched,
  } = useFormikContext<AddClientBody>();
  const {
    ref: phoneRef,
    value: phoneValue,
    setValue: setPhoneValue,
  } = useIMask({
    mask: MASKS.PHONE,
  });
  const {
    ref: zipRef,
    value: zipValue,
    setValue: setZipValue,
  } = useIMask({
    mask: MASKS.ZIP,
  });

  const handleBudgetTypeChange = (budgetType: AddClientBody['budget_type']) => {
    setFieldValue('budget_type', budgetType);
  };

  useEffect(() => {
    setPhoneValue(initialValues.phone || '');
    setZipValue(initialValues.address_zip || '');
  }, [initialValues, setPhoneValue, setZipValue]);

  return (
    <Stack sx={() => ({ padding: 16 })} spacing={8}>
      <TextInput
        name="name"
        label="Name"
        value={values.name}
        onChange={handleChange}
        error={touched.name && errors.name}
        onBlur={handleBlur}
        required
      />
      <TextInput
        name="email"
        label="Email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.email && errors.email}
        placeholder="client@example.com"
      />
      <TextInput
        name="invoice_email"
        label="Invoice To"
        value={values.invoice_email}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.invoice_email && errors.invoice_email}
        placeholder="client@example.com"
      />
      <TextInput
        name="phone"
        label="Phone"
        value={phoneValue}
        onChange={(e) => {
          setPhoneValue(e.target.value);
          setFieldValue('phone', phoneValue);
        }}
        error={touched.phone && errors.phone}
        placeholder={MASKS.PHONE}
        ref={phoneRef as MutableRefObject<HTMLInputElement>}
        onBlur={handleBlur}
      />
      <TextInput
        name="address_line1"
        label="Address line1"
        value={values.address_line1}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.address_line1 && errors.address_line1}
        required
      />
      <TextInput
        name="address_line2"
        label="Address line2"
        value={values.address_line2}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.address_line2 && errors.address_line2}
      />
      <TextInput
        name="address_city"
        label="City"
        value={values.address_city}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.address_city && errors.address_city}
        required
      />
      <CountrySelect
        label="Country"
        defaultValue={values.address_country}
        onChange={(option) => setFieldValue('address_country', option.value)}
        error={touched.address_country ? errors.address_country : undefined}
        required
        onBlur={handleBlur}
      />
      {['CA', 'US'].includes(values.address_country || '') ? (
        <StateSelect
          label="State"
          defaultValue={values.address_state}
          onChange={(option) => setFieldValue('address_state', option.value)}
          error={errors.address_state}
          country={values.address_country}
          required
          onBlur={handleBlur}
        />
      ) : (
        <TextInput
          name="address_state"
          label="State"
          value={values.address_state}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.address_state && errors.address_state}
          required
        />
      )}
      <TextInput
        name="address_zip"
        label="Zip"
        value={zipValue}
        error={touched.address_zip && errors.address_zip}
        placeholder={MASKS.ZIP}
        ref={zipRef as MutableRefObject<HTMLInputElement>}
        onChange={(e) => {
          setZipValue(e.target.value);
          setFieldValue('address_zip', zipValue);
        }}
        onBlur={handleBlur}
        required
      />
      <Box>
        <Text sx={(theme) => ({ fontSize: theme.fontSizes.sm })}>Note</Text>
        {!clientId || (clientId && values.note !== undefined) ? (
          <RichTextEditor
            variant="base"
            content={values.note as string}
            onChange={(content) => {
              setFieldValue('note', content);
            }}
          />
        ) : (
          <></>
        )}
      </Box>
      <Box>
        <Box sx={(theme) => ({ display: 'flex', columnGap: theme.spacing.sm })}>
          <CurrencyInput
            name="budget_amount"
            label="Budget"
            min={0}
            value={(values.budget_amount || 0) / 100}
            onChange={(value) =>
              setFieldValue('budget_amount', (value || 0) * 100)
            }
            error={touched.budget_amount && errors.budget_amount}
            onBlur={handleBlur}
          />
          <BudgetTypeSelect
            label="Budget Type"
            defaultValue={values.budget_type}
            onChange={(option) =>
              handleBudgetTypeChange(
                option.value as AddClientBody['budget_type']
              )
            }
            error={touched.budget_type ? errors.budget_type : undefined}
            onBlur={handleBlur}
          />
        </Box>
      </Box>
    </Stack>
  );
}

function AddClientModal({ clientId }: { clientId?: number }) {
  const modal = useModal();
  const formikRef = useRef() as MutableRefObject<
    FormikProps<Partial<AddClientBody>>
  >;
  const { popModal } = useModalStore();
  const { data, editClient, isLoading } = useClient({ clientId });
  const { addClient } = useClients();

  const handleClose = () => {
    if (!formikRef.current.isSubmitting) {
      popModal(MODALS.ADD_CLIENT_MODAL);

      if (formikRef.current) {
        formikRef.current.resetForm();
      }
    }
  };

  const handleSubmit = async (values: Partial<AddClientBody>) => {
    if (data?.client) {
      if (await editClient(values)) {
        handleClose();
      }
    } else {
      if (await addClient(values)) {
        handleClose();
      }
    }
  };

  const initialValues: Partial<AddClientBody> = useMemo(() => {
    return (
      {
        ...data?.client,
        address_country: data?.client?.address_country || 'US',
        budget_amount: data?.client?.budget_amount || 0,
        budget_type: data?.client?.budget_type || 'total',
        email: data?.client?.email || '',
        phone: data?.client?.phone || '',
        address_zip: data?.client?.address_zip || '',
      } || {
        name: '',
        email: '',
        phone: '',
        address_line1: '',
        address_line2: '',
        address_city: '',
        address_state: '',
        address_zip: '',
        address_country: 'US',
        budget_amount: 0,
        budget_type: 'total',
      }
    );
  }, [data?.client]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <BaseModal
        id={MODALS.ADD_CLIENT_MODAL}
        isOpen={modal.visible}
        onClose={handleClose}
        title={clientId ? 'Edit Client' : 'Add Client'}
        height="80vh"
        size="40vw"
        FooterComponent={ModalFooter}
        loading={isLoading}
      >
        <ScrollArea sx={{ height: '75vh' }}>
          <AddClientModalForm clientId={clientId} />
        </ScrollArea>
      </BaseModal>
    </Formik>
  );
}

export default NiceModal.create((props) => <AddClientModal {...props} />);
