import { useMemo } from 'react';

import useAuthUser from 'hooks/users/useAuthUser';

import getUserFullName from 'helpers/display/getUserFullName';

import ReporteeUserSelectUI from './UI';
import { ReporteeUserSelectProps } from './types';

function ReporteeUserSelectComponent({
  onChange,
  onBlur,
  defaultValue,
  label,
  variant = 'default',
  width,
  readonly,
  ...props
}: ReporteeUserSelectProps) {
  const { user } = useAuthUser();

  const options = useMemo(() => {
    return user?.manages?.concat([user])?.map((user) => {
      return {
        value: user.id as number,
        label: getUserFullName(user),
      };
    });
  }, [user]);

  return (
    <ReporteeUserSelectUI
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      defaultValue={defaultValue}
      label={label}
      variant={variant}
      width={width}
      readonly={readonly}
      {...props}
    />
  );
}

export default function ReporteeUserSelect(props: ReporteeUserSelectProps) {
  return <ReporteeUserSelectComponent {...props} />;
}
