import { Loader, Timeline, Box, Text, ActionIcon } from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import format from 'date-fns/format';
import { DateValue } from '@mantine/dates';
import { IconPlus, IconMinus } from '@tabler/icons-react';
import isEmpty from 'lodash/isEmpty';

import { BaseActivityListProps } from '../types';
import { ProjectTicket, Activity } from 'types/api';
import { TABBED_CONTENT_HEIGHT, MODALS } from 'constants/component';

import TicketActivityListItem from '../components/TicketActivityListItem';
import InfiniteLoader from 'components/common/InfiniteLoader';

import useActivity from 'hooks/common/useActivity';
import useProjectActivityInfinite from 'hooks/projects/useProjectActivityInfinite';
import useModalStore from 'hooks/store/useModalStore';

import getTicketDisplay from 'helpers/display/getTicketDisplay';
import { DATE_FORMATS } from 'constants/date';
import { mergeSimilarActivity } from 'utils/activity';

interface ProjectActivityGroupedListProps extends BaseActivityListProps {
  projectId?: string | number;
  height?: string;
  dateRange?: [DateValue, DateValue];
}

function Highlighted({ message }: { message: string }) {
  return (
    <Text component="span" color="blue">
      {message}
    </Text>
  );
}

export default function ProjectActivityGroupedList({
  projectId,
  height = TABBED_CONTENT_HEIGHT,
  dateRange,
}: ProjectActivityGroupedListProps) {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useProjectActivityInfinite({
    params: {
      page: 1,
      page_size: 20,
      datetime_created_start: dateRange?.[0]
        ? format(dateRange[0], DATE_FORMATS.DATETIME_KEY)
        : undefined,
      datetime_created_end: dateRange?.[1]
        ? format(dateRange[1], DATE_FORMATS.DATETIME_KEY)
        : undefined,
    },
    disabled: !projectId,
    projectId: projectId || 0,
    storeKey: 'list-project-activity-tab',
  });
  const { getActionDisplay, getDateDisplay, getTargetDisplay, getUserDisplay } =
    useActivity({});
  const { pushModal } = useModalStore();
  const empty = !data?.pages?.length;

  const [expanded, setExpanded] = useState<{ [id: number]: boolean }>({});

  const getTimelineItemProps = useCallback(
    (activityItem: Activity) => {
      const title = (
        <Box sx={{ display: 'flex' }}>
          <Text
            size="sm"
            weight="bold"
            sx={{ marginTop: 'auto', marginBottom: 'auto' }}
          >
            <Text
              color="blue"
              component="span"
              sx={(theme) => ({
                '&:hover': {
                  color: theme.colors.blue[8],
                  cursor: 'pointer',
                },
                '&:active': {
                  color: theme.colors.blue[9],
                },
              })}
              onClick={() => {
                pushModal(MODALS.EDIT_TICKET_MODAL, {
                  ticket: activityItem.ticket,
                });
              }}
            >
              {getTicketDisplay(
                activityItem.ticket as Partial<ProjectTicket>,
                activityItem.ticket?.project_key
              )}
            </Text>
            {': '}
            <Text component="span">
              {getTargetDisplay(activityItem)} {getActionDisplay(activityItem)}{' '}
              by
            </Text>{' '}
            <Highlighted message={getUserDisplay(activityItem)} />{' '}
            <Text component="span">{getDateDisplay(activityItem)}</Text>
          </Text>
        </Box>
      );

      return {
        title,
      };
    },
    [
      getActionDisplay,
      getDateDisplay,
      getTargetDisplay,
      getUserDisplay,
      pushModal,
    ]
  );

  // refetch on tab change
  useEffect(() => {
    if (projectId) {
      refetch();
    }
  }, [projectId, refetch]);

  if (isLoading)
    return (
      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          marginTop: theme.spacing.lg,
        })}
      >
        <Loader sx={{ marginLeft: 'auto', marginRight: 'auto' }} />
      </Box>
    );

  return (
    <InfiniteLoader
      height={height}
      onScrollEnd={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={Boolean(hasNextPage)}
    >
      <Box sx={{ paddingTop: 6 }}>
        {empty ? (
          <Text sx={{ textAlign: 'center' }}>No activity available.</Text>
        ) : (
          <Timeline>
            {data?.pages?.map((page) =>
              mergeSimilarActivity(page.activity_items as Activity[])?.map(
                (activityItem) => {
                  let hasChangeMessage = false;

                  try {
                    hasChangeMessage = !isEmpty(
                      JSON.parse(activityItem.change_message as string).values
                    );
                  } catch (err) {
                    hasChangeMessage = false;
                  }

                  const TimelineBullet = () => {
                    if (!hasChangeMessage) {
                      return <Box />;
                    }

                    let Icon = IconPlus;

                    if (expanded[activityItem.id as number] === true) {
                      Icon = IconMinus;
                    }

                    return (
                      <ActionIcon
                        variant="default"
                        sx={(theme) => ({
                          color: theme.colors.blue[5],
                        })}
                        onClick={() =>
                          setExpanded({
                            ...expanded,
                            [activityItem.id as number]:
                              expanded[activityItem.id as number] === undefined
                                ? true
                                : !expanded[activityItem.id as number],
                          })
                        }
                      >
                        <Icon />
                      </ActionIcon>
                    );
                  };

                  return (
                    <Timeline.Item
                      key={activityItem.id}
                      bullet={<TimelineBullet />}
                      bulletSize={18}
                      {...getTimelineItemProps(activityItem)}
                    >
                      <TicketActivityListItem
                        activityItem={activityItem}
                        expanded={expanded[activityItem.id as number] ?? false}
                        setExpanded={(expand: boolean) => {
                          setExpanded({
                            ...expanded,
                            [activityItem.id as number]: expand,
                          });
                        }}
                      />
                    </Timeline.Item>
                  );
                }
              )
            )}
          </Timeline>
        )}
      </Box>
    </InfiniteLoader>
  );
}
