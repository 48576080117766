import { BillingType } from 'types/app';

export const BILLING_TYPE = {
  EXPENSE: 'expense' as BillingType,
  TIME: 'time' as BillingType,
};

export const LINE_ITEM_PAYMENT_TYPE = {
  FLAT: 'flat',
  PERCENTAGE: 'percentage',
};

export const DOLLAR = 100; // 100 cents
export const HOUR = 60; // 60 minutes

export const BILLING_RECORD_INVOICE_STATUS = {
  PAID: 'paid',
  INVOICED: 'invoiced',
  UNINVOICED: 'uninvoiced',
};
