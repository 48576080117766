import { useState, useEffect } from 'react';

import { SelectOption } from 'components/common/Select/types';

interface SelectHookProps {
  defaultValue?: number | string;
  options?: SelectOption[];
}

export default function useSelect({ defaultValue, options }: SelectHookProps) {
  const [selected, setSelected] = useState<SelectOption>();

  useEffect(() => {
    if (defaultValue !== undefined && selected?.value !== defaultValue) {
      const foundOption = options?.find(
        (option) => `${option.value}` === `${defaultValue}`
      );

      if (foundOption) {
        setSelected(foundOption);
      }
    }
  }, [defaultValue, options, selected]);

  return {
    selected,
    setSelected,
  };
}
