import { CalendarProps } from './types';
import AvailabilityCalendar from './variants/AvailabilityCalendar';
import BaseCalendar from './variants/BaseCalendar';

export default function Calendar({ variant, ...props }: CalendarProps) {
  if (variant === 'availability') {
    return <AvailabilityCalendar {...props} />;
  }

  return <BaseCalendar {...props} />;
}
