import { Droppable } from 'react-beautiful-dnd';

import TicketGroupListItem from './ListItem';
import { TicketDateMap } from 'hooks/tickets/types';

export interface TicketGroupBodyProps {
  ticketGroup: TicketDateMap;
}

export default function TicketGroupBody({ ticketGroup }: TicketGroupBodyProps) {
  return (
    <Droppable
      isCombineEnabled
      droppableId={`dnd-list-${ticketGroup.id}`}
      direction="vertical"
    >
      {(provided) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={{ minHeight: '48px' }}
        >
          {ticketGroup.assignments.map((assignment, index) => (
            <TicketGroupListItem
              key={assignment.id}
              index={index}
              assignment={assignment}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}
