import { Group, Text } from '@mantine/core';

import { TicketDateMap } from 'hooks/tickets/types';

export interface TicketGroupTitleProps {
  ticketGroup: TicketDateMap;
}

export default function TicketGroupTitle({
  ticketGroup,
}: TicketGroupTitleProps) {
  return (
    <Group
      display="flex"
      sx={(theme) => ({
        textAlign: 'center',
        justifyContent: 'center',
        borderBottom: '1px solid',
        borderColor: theme.colors.gray[4],
        gap: 4,
      })}
    >
      <Text transform="uppercase" weight="bold">
        {ticketGroup.id}
      </Text>
    </Group>
  );
}
