import { ReactNode, useCallback } from 'react';
import { ScrollArea, Box, Button } from '@mantine/core';

import useDebounce from 'hooks/common/useDebounce';
import { useElementSize } from '@mantine/hooks';

interface InfiniteLoaderProps {
  onScrollEnd: () => void;
  children?: ReactNode;
  height: number | string;
  threshold?: number;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  disablePadding?: boolean;
}

interface ScrollPosition {
  x: number;
  y: number;
}

/**
 * Provides a container for infinite pagination that executes a callback on scroll end.
 */
export default function InfiniteLoader({
  onScrollEnd,
  children,
  height,
  threshold = 0.8,
  hasNextPage,
  isFetchingNextPage,
  disablePadding = false,
}: InfiniteLoaderProps) {
  const handleScrollEnd = useDebounce(() => onScrollEnd?.());
  const { ref: scrolllRef, height: scrollHeight } = useElementSize();
  const { ref, height: elementHeight } = useElementSize();
  const onScrollPositionChange = useCallback(
    (sp: ScrollPosition) => {
      const { y: scrollY } = sp;
      const scrollThreshold = (scrollHeight + scrollY) / elementHeight;

      if (scrollThreshold >= threshold && hasNextPage && !isFetchingNextPage) {
        handleScrollEnd();
      }
    },
    [
      scrollHeight,
      elementHeight,
      threshold,
      hasNextPage,
      isFetchingNextPage,
      handleScrollEnd,
    ]
  );

  return (
    <ScrollArea
      ref={scrolllRef}
      onScrollPositionChange={onScrollPositionChange}
      sx={(theme) => ({
        height,
        paddingTop: disablePadding ? 0 : theme.spacing.md,
        paddingBottom: disablePadding ? 0 : theme.spacing.md,
      })}
    >
      <Box
        ref={ref}
        sx={(theme) => ({
          marginLeft: disablePadding ? 0 : theme.spacing.md,
          marginRight: disablePadding ? 0 : theme.spacing.md,
        })}
      >
        {children}
        {hasNextPage && (
          <Box
            sx={(theme) => ({
              textAlign: 'center',
              paddingTop: theme.spacing.md,
            })}
          >
            <Button
              size="xs"
              variant="outline"
              onClick={handleScrollEnd}
              loading={isFetchingNextPage}
            >
              Load More
            </Button>
          </Box>
        )}
      </Box>
    </ScrollArea>
  );
}
