import { Tooltip, useMantineTheme } from '@mantine/core';
import {
  IconArrowBigDownFilled,
  IconArrowBigUpFilled,
  IconArrowBigUpLineFilled,
  IconArrowBigUpLinesFilled,
} from '@tabler/icons-react';

import { ProjectTicket } from 'types/api';
import { TICKET_PRIORITY_LABELS } from 'constants/ticket';
import { useMemo } from 'react';

interface TicketPriorityDisplayProps {
  ticket: ProjectTicket;
  includeLowPriority?: boolean;
}

export default function TicketPriorityDisplay({
  ticket,
  includeLowPriority,
}: TicketPriorityDisplayProps) {
  const theme = useMantineTheme();
  const icons = useMemo(() => {
    const _icons = [
      IconArrowBigUpLinesFilled,
      IconArrowBigUpLineFilled,
      IconArrowBigUpFilled,
    ];

    if (includeLowPriority) {
      _icons.push(IconArrowBigUpFilled, IconArrowBigDownFilled);
    }

    return _icons;
  }, [includeLowPriority]);

  const priority = (ticket.priority || 0) - 1;
  const label = TICKET_PRIORITY_LABELS[priority];
  const Icon = icons[4 - priority];

  const getColor = () => {
    if (includeLowPriority && priority < 2) {
      return theme.colors.blue[2 + priority];
    }

    return theme.colors.red[4 + priority];
  };

  if (!label || !Icon) return <></>;

  return (
    <Tooltip label={`P${5 - priority}`}>
      <Icon style={{ color: getColor() }} />
    </Tooltip>
  );
}
