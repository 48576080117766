import { useMemo, useRef, useState } from 'react';
import {
  Stack,
  Textarea,
  Checkbox,
  Tooltip,
  Box,
  ScrollArea,
  Group,
  Button,
  Collapse,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { Formik, useFormikContext } from 'formik';
import format from 'date-fns/format';
import * as Yup from 'yup';

import { SelectOption } from 'components/common/Select/types';
import { AddBillingRecordModalProps } from './types';
import { AddBillingRecordBody } from 'utils/billing';
import { DATE_FORMATS } from 'constants/date';
import { ClientProject, ProjectTicket } from 'types/api';

import ExpenseTypeSelect from 'components/common/Select/variants/ExpenseTypeSelect';
import CurrencyInput from 'components/common/UserInput/variants/CurrencyInput';
import ReporteeUserSelect from 'components/common/Select/variants/ReporteeUserSelect';
import useAuthUser from 'hooks/users/useAuthUser';
import useBillingRecords from 'hooks/billing/useBillingRecords';
import { BILLING_TYPE } from 'constants/billing';

const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .required('Amount is required')
    .moreThan(0, 'Amount must be greater than 0'),
  description: Yup.string().required('Description is required'),
});

interface AddExpenseFormProps {
  ticket?: ProjectTicket;
  project?: ClientProject;
  billable?: boolean;
}

function AddExpenseFormContent({
  ticket,
  project,
  billable,
}: AddExpenseFormProps) {
  const [date, setDate] = useState<Date>(new Date());
  const {
    values,
    handleChange,
    setFieldValue,
    touched,
    errors,
    submitForm,
    isSubmitting,
  } = useFormikContext<AddBillingRecordBody>();

  const handleExpenseTypeSelect = (newJobType: SelectOption) => {
    setFieldValue('job_type', newJobType.value);
  };

  const handleAmountChange = (newAmount: number) => {
    setFieldValue('amount', newAmount * 100);
  };

  const handleAssignedUserSelect = (assigned: SelectOption) => {
    setFieldValue('employee_id', assigned.value);
  };

  const checkboxTooltip = useMemo(() => {
    const _project = ticket?.project || project;

    if (!_project?.billable) {
      return 'This project is unbillable.';
    }

    if (ticket) {
      if (!ticket?.billable) {
        return 'This ticket is unbillable.';
      }
    }

    return 'Was this work billable?';
  }, [project, ticket]);

  return (
    <Stack sx={(theme) => ({ padding: theme.spacing.lg })} spacing={16}>
      <ReporteeUserSelect
        label="Person"
        defaultValue={values?.employee_id}
        onChange={handleAssignedUserSelect}
        readonly={isSubmitting}
      />
      <CurrencyInput
        required
        name="amount"
        label="Amount"
        type="number"
        value={values.amount / 100}
        onChange={handleAmountChange}
        error={touched.amount && errors.amount}
        disabled={isSubmitting}
      />
      <Tooltip label={checkboxTooltip} position="top-start">
        <Box>
          <Checkbox
            name="billable"
            label="Billable"
            checked={values.billable}
            onChange={handleChange}
            color="green"
            error={touched.billable && errors.billable}
            disabled={!billable || isSubmitting}
          />
        </Box>
      </Tooltip>
      <DatePickerInput
        label="Date"
        name="date"
        value={date}
        onChange={(newDate) => {
          setDate(newDate as Date);

          if (newDate instanceof Date) {
            setFieldValue('date', format(newDate, DATE_FORMATS.DATE_KEY));
          } else {
            setFieldValue('date', date);
          }
        }}
        error={touched.date && errors.date}
        disabled={isSubmitting}
      />
      <ExpenseTypeSelect
        defaultFirst
        defaultValue={values.job_type}
        label="Expense Type"
        onChange={handleExpenseTypeSelect}
        readonly={isSubmitting}
      />
      <Textarea
        required
        name="description"
        label="Description"
        value={values.description}
        onChange={handleChange}
        error={touched.description && errors.description}
        disabled={isSubmitting}
      />
      <Group sx={{ justifyContent: 'flex-end' }}>
        <Button size="xs" onClick={submitForm} loading={isSubmitting}>
          Add Expense
        </Button>
      </Group>
    </Stack>
  );
}

export default function AddExpenseForm({
  ticket,
  project,
  showAdd,
}: AddBillingRecordModalProps) {
  const { user } = useAuthUser();
  const formikRef = useRef<any>();
  const { addExpense } = useBillingRecords({}, false);

  const [expanded, setExpanded] = useState(Boolean(showAdd));

  const isBillable = () => {
    if (ticket) {
      return Boolean(!ticket?.project?.billable || !ticket.billable);
    }

    return Boolean(project?.billable);
  };

  const initialValues: Partial<AddBillingRecordBody> = {
    amount: 0,
    description: '',
    date: format(new Date(), DATE_FORMATS.DATE_KEY),
    billable: Boolean(
      (ticket?.project?.billable && ticket?.billable) ?? project?.billable
    ),
    job_type: undefined,
    employee_id: user?.id,
    billing_type: BILLING_TYPE.EXPENSE,
  };

  const handleSubmit = async (values: Partial<AddBillingRecordBody>) => {
    if (
      await addExpense({
        body: values,
        ticketId: ticket?.id,
        projectId: project?.id,
      })
    ) {
      formikRef?.current?.resetForm();
    }
  };

  return (
    <Box sx={(theme) => ({ paddingTop: theme.spacing.lg })}>
      <Group sx={(theme) => ({ paddingLeft: theme.spacing.lg })}>
        <Button
          variant="white"
          color="dark"
          onClick={() => setExpanded(!expanded)}
          sx={(theme) => ({
            border: '1px solid',
            borderColor: theme.colors.dark,
            borderBottom: expanded ? '0px' : undefined,
            borderBottomLeftRadius: expanded ? '0px' : undefined,
            borderBottomRightRadius: expanded ? '0px' : undefined,
            marginBottom: '-1px',
            backgroundColor: 'white',
          })}
        >
          Add Expense
        </Button>
      </Group>
      <Collapse in={expanded}>
        <Box
          sx={(theme) => ({
            border: '1px solid',
            borderColor: theme.colors.dark,
            marginLeft: theme.spacing.lg,
            marginRight: theme.spacing.lg,
            borderBottomLeftRadius: theme.spacing.sm,
            borderBottomRightRadius: theme.spacing.sm,
            borderTopRightRadius: theme.spacing.sm,
          })}
        >
          <Formik
            enableReinitialize
            innerRef={formikRef}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <ScrollArea sx={{ height: '100%' }}>
              <AddExpenseFormContent
                ticket={ticket}
                project={project}
                billable={isBillable()}
              />
            </ScrollArea>
          </Formik>
        </Box>
      </Collapse>
    </Box>
  );
}
