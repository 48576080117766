import { NumberInput, NumberInputProps } from '@mantine/core';

const parser = (value: string) => {
  return value.replace(/\$\s?|(,*)/g, '');
};

const formatter = (value: string) => {
  return !Number.isNaN(value)
    ? `$ ${value}`.replace(/\B(?<!\.\d)(?=(\d{3})+(?!\d))/g, ',')
    : '$ ';
};

export default function CurrencyInput({ ...props }: NumberInputProps) {
  return (
    <NumberInput
      {...props}
      precision={2}
      type="text"
      parser={parser}
      formatter={formatter}
    />
  );
}
