import { DECIMAL_RE, FRACTION_RE, TIME_RE } from 'constants/re';
import fractionToDecimal from './fractionToDecimal';
import timeDisplayToDecimal from './timeDisplayToDecimal';
import roundToNearestMultiple from './roundToNearestMultiple';

/**
 * determines a time value in decimal from user string
 * @param timeInput - user string, can take the form 1:30, 1.5, :30, or 1/5
 */
export default function getTimeValueFromInput(timeInput: string): number {
  const breakpoint = 15;
  const timeString = timeInput.trim();
  let timeValue = 0;

  if (DECIMAL_RE.test(timeString)) {
    timeValue = parseFloat(timeString) * 60;
  }

  if (FRACTION_RE.test(timeString)) {
    timeValue = fractionToDecimal(timeString) * 60;
  }

  if (TIME_RE.test(timeString)) {
    timeValue = timeDisplayToDecimal(timeString);
  }

  return roundToNearestMultiple(timeValue, breakpoint);
}
