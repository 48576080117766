import { useMemo } from 'react';
import {
  AvatarProps,
  Box,
  rem,
  Text,
  Avatar,
  Indicator,
  IndicatorProps,
  Tooltip,
  Loader,
} from '@mantine/core';
import parse from 'date-fns/parse';
import isWithinInterval from 'date-fns/isWithinInterval';
import { IconReload, IconCheck } from '@tabler/icons-react';

import Calendar from 'components/common/Calendar';
import AvatarGroup from 'components/common/AvatarGroup';

import getUserFullName from 'helpers/display/getUserFullName';
import { DATE_FORMATS } from 'constants/date';
import getInitials from 'helpers/display/getInitials';

import useAvailabilityRecords from 'hooks/users/useAvailabilityRecords';
import useInstance from 'hooks/instance/useInstance';
import { AvailabilityRecord } from 'types/api';

function AvatarComponent({ status, title, ...props }: AvatarProps & any) {
  const indicatorProps: Partial<IndicatorProps> = {
    radius: 'xl',
    size: 12,
  };

  if (status === 'pending') {
    indicatorProps.color = 'gray';
    indicatorProps.label = (
      <Tooltip label="Pending Review">
        <IconReload size="8px" />
      </Tooltip>
    );
  }

  if (status === 'approved') {
    indicatorProps.color = 'green';
    indicatorProps.label = (
      <Tooltip label="Approved">
        <IconCheck size="8px" />
      </Tooltip>
    );
  }

  return (
    <Indicator {...indicatorProps}>
      <Tooltip label={title}>
        <Avatar {...props} />
      </Tooltip>
    </Indicator>
  );
}

function DateCell({
  date,
  availabilityRecords,
}: {
  date: Date;
  availabilityRecords: AvailabilityRecord[];
}) {
  const { data: instanceData } = useInstance();

  const avatars = useMemo(() => {
    const recordsToday = availabilityRecords?.filter((record) => {
      const dateStart = parse(
        record.date_start,
        DATE_FORMATS.DATE_KEY,
        new Date()
      );
      const dateEnd = record.date_end
        ? parse(record.date_end, DATE_FORMATS.DATE_KEY, new Date())
        : dateStart;

      return (
        isWithinInterval(date, { start: dateStart, end: dateEnd }) &&
        instanceData?.instance?.business_days?.includes(
          date.getDay().toString()
        )
      );
    });

    return (
      recordsToday?.map((record) => {
        const fullName = getUserFullName(record.instance_user);

        return {
          title: fullName,
          children: getInitials({ fullName }),
          radius: 'xl',
          size: 'sm',
          color: 'blue',
          status: record.status,
          variant: 'filled',
        };
      }) || []
    );
  }, [availabilityRecords, date, instanceData?.instance?.business_days]);

  const hasTimeOff = Boolean(avatars.length);

  return (
    <Box
      sx={(theme) => ({
        height: '100%',
        width: '100%',
        display: 'flex',
        position: 'relative',
        border: hasTimeOff ? `1px solid ${theme.colors.red[1]}` : undefined,
        borderRadius: theme.radius.sm,
      })}
    >
      <Box
        sx={{
          margin: 'auto',
          height: rem(64),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Text sx={{ margin: 'auto' }}>{date.getDate()}</Text>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: '0px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box>
          <AvatarGroup
            max={4}
            avatars={avatars}
            AvatarComponent={AvatarComponent}
            sharedProps={{
              radius: 'xl',
              size: 'sm',
              color: 'blue',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default function AvailabilityCalendar() {
  const { data, isLoading } = useAvailabilityRecords({
    params: { status: 'approved,pending' },
  });

  if (isLoading) {
    return (
      <Box
        sx={(theme) => ({
          padding: theme.spacing.xl,
          display: 'flex',
          justifyContent: 'center',
        })}
      >
        <Loader />
      </Box>
    );
  }

  return (
    <Calendar
      static
      numberOfColumns={2}
      variant="availability"
      sx={{ margin: 'auto' }}
      styles={{
        monthCell: {
          height: rem(104),
          width: rem(104),
        },
        calendarHeader: {
          width: '100%',
          maxWidth: 'none',
        },
        day: {
          height: '100%',
          width: '100%',
        },
      }}
      renderDay={(date) => {
        return (
          <DateCell
            date={date}
            availabilityRecords={data?.availability_records || []}
          />
        );
      }}
    />
  );
}
