import {
  Avatar as MantineAvatar,
  AvatarProps as MantineAvatarProps,
} from '@mantine/core';

interface AvatarProps extends MantineAvatarProps {
  title?: string;
}

export default function Avatar({ ...props }: AvatarProps) {
  return <MantineAvatar {...props} />;
}
