import { useCallback } from 'react';
import { Group, createStyles, Box } from '@mantine/core';
import { DatePickerInputProps, DatePickerInput } from '@mantine/dates';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import getWeek from 'date-fns/getWeek';
import add from 'date-fns/add';

import getDaysOfWeek from 'helpers/datetime/getDaysOfWeek';

const useStyles = createStyles((theme) => ({
  iconButton: {
    '&:hover': {
      color: theme.colors.blue,
      cursor: 'pointer',
      userSelect: 'none',
    },
    '&:active': {
      color: theme.colors.blue[9],
    },
  },
}));

interface ArrowDateRangePickerProps extends DatePickerInputProps<'range'> {}

export default function ArrowDateRangePicker({
  onChange,
  value,
}: ArrowDateRangePickerProps) {
  const { classes } = useStyles();

  const handleWeekChange = useCallback(
    (offset: number) => {
      if (value && value.length === 2 && value[0] && value[1]) {
        onChange?.([
          add(value[0] as Date, { weeks: offset }),
          add(value[1] as Date, { weeks: offset }),
        ]);
      } else {
        const [start, , , , , , end] = getDaysOfWeek(getWeek(new Date()));
        onChange?.([start, end]);
      }
    },
    [onChange, value]
  );

  return (
    <>
      <Group sx={{ justifyContent: 'center' }}>
        <IconChevronLeft
          className={classes.iconButton}
          onClick={() => handleWeekChange(-1)}
        />
        <Box sx={{ width: '300px' }}>
          <DatePickerInput
            type="range"
            value={value}
            styles={(theme) => ({
              input: {
                border: 'none',
                textAlign: 'center',
                backgroundColor: 'transparent',
                '&:hover': {
                  color: theme.colors.blue,
                },
              },
            })}
            onChange={onChange}
          />
        </Box>
        <IconChevronRight
          className={classes.iconButton}
          onClick={() => handleWeekChange(1)}
        />
      </Group>
    </>
  );
}
