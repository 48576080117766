import { useEffect, useMemo } from 'react';

import TextSelectTarget from './TextSelectTarget';

import { JobTypeSelectProps } from './types';
import JobTypeSelectUI from './JobTypeSelectUI';

import useInstanceConfig from 'hooks/instance/useInstanceConfig';
import useAppStore from 'hooks/store/useAppStore';

export default function JobTypeSelect({
  onChange,
  defaultValue,
  label,
  variant,
  width,
  filter = () => true,
  defaultFirst,
  readonly,
  ...props
}: JobTypeSelectProps) {
  const { data } = useInstanceConfig();
  const { lastTimeType, setLastTimeType } = useAppStore();

  const options = useMemo(() => {
    return (
      data?.config?.job_types?.filter(filter)?.map((jobType: string) => {
        return {
          value: jobType,
          label: jobType,
        };
      }) || []
    );
  }, [data, filter]);

  let TargetComponent: JobTypeSelectProps['TargetComponent'] = undefined;
  if (variant === 'text') {
    TargetComponent = TextSelectTarget;
  }

  useEffect(() => {
    if (defaultValue === undefined && lastTimeType) {
      onChange?.({ value: lastTimeType, label: '' });
    }
  }, [defaultValue, lastTimeType, onChange]);

  return (
    <JobTypeSelectUI
      defaultFirst={defaultFirst}
      options={options}
      onChange={(selected) => {
        setLastTimeType(selected.value as string);
        onChange?.(selected);
      }}
      defaultValue={defaultValue || lastTimeType || undefined}
      label={label}
      TargetComponent={TargetComponent}
      width={width}
      readonly={readonly}
      {...props}
    />
  );
}
