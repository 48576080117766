import { IconClock } from '@tabler/icons-react';

import BasePageAlert from './Base';

import { InstanceAlert } from 'types/api';
import { BasePageAlertProps } from '../types';

interface MissingTimeAlertProps extends BasePageAlertProps {
  alert: InstanceAlert;
}

export default function MissingTimeAlert({
  alert,
  ...props
}: MissingTimeAlertProps) {
  return (
    <BasePageAlert
      alert={alert}
      color="yellow"
      icon={<IconClock />}
      {...props}
    />
  );
}
