/**
 * converts a time display to decimal
 * @param timeDisplay - the time display to convert; takes the form 1:30
 * @returns decimal representation of timeDisplay
 */
export default function timeDisplayToDecimal(timeDisplay: string) {
  const [hour, min] = timeDisplay.split(':');

  // handle hour: case
  if (!min) {
    const hourValue = parseInt(hour);
    return hourValue * 60;
  }

  if (!hour) {
    const minuteValue = parseInt(min);
    return minuteValue;
  }

  const hourValue = parseInt(hour);
  const minuteValue = parseInt(min);

  return hourValue * 60 + minuteValue;
}
