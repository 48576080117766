import { useMemo } from 'react';

import { SelectProps } from '../../types';
import BaseSelect from '../BaseSelect';
import { PROJECT_STATUSES } from 'constants/project';
import capitalize from 'lodash/capitalize';

export default function ProjectStatusSelect({
  onChange,
  defaultValue,
  label,
}: SelectProps) {
  const options = useMemo(() => {
    return PROJECT_STATUSES.map((projectStatus) => {
      return {
        value: projectStatus,
        label: capitalize(projectStatus),
      };
    });
  }, []);

  return (
    <BaseSelect
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      label={label}
    />
  );
}
