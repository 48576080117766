import { SelectProps } from '../../types';

import TicketStatusSelectBase from './variants/Base';
import ProjectTicketStatusSelect from './variants/Project';

interface TicketStatusSelectProps extends SelectProps {
  projectId?: number | string;
}

export default function TicketStatusSelect({
  projectId,
  ...props
}: TicketStatusSelectProps) {
  if (projectId) {
    return <ProjectTicketStatusSelect projectId={projectId} {...props} />;
  }

  return <TicketStatusSelectBase {...props} />;
}
