import { useCallback } from 'react';
import { notifications } from '@mantine/notifications';

import { AUTH_USER_ID, INVALIDATE_GROUP } from 'constants/api';

import * as projectUtils from 'utils/project';
import * as userUtils from 'utils/user';

import useApiStore from 'hooks/store/useApiStore';

import showApiErrNotif from 'helpers/api/showApiErrNotif';

export default function useUserProjects() {
  const { invalidateGroup } = useApiStore();

  const bookmarkProject = useCallback(
    async (projectId: number, active: '0' | '1') => {
      try {
        await projectUtils.bookmarkProject({
          projectId: projectId.toString(),
          bookmarkBody: { action: 'bookmark', active },
        });
        invalidateGroup(INVALIDATE_GROUP.SELF);
        notifications.show({
          message: 'Bookmark successfully added!',
          variant: 'success',
        });
        return true;
      } catch (err) {
        showApiErrNotif('Unable to bookmark the project at this time.', err);
      }
    },
    [invalidateGroup]
  );

  const rearrangeProjects = useCallback(
    async (userId: number = AUTH_USER_ID, projectIds: number[] = []) => {
      try {
        await userUtils.rearrangeUserProjects({
          userId: `${userId}`,
          projectIds,
        });
        return true;
      } catch (err) {
        // pass
      }

      return false;
    },
    []
  );

  return {
    bookmarkProject,
    rearrangeProjects,
  };
}
