import CommentsContainer from './variants/CommentsContainer';

interface CommentsProps {
  variant?: 'item' | 'list';
  ticketId?: string;
  highlight?: boolean;
  scrollable?: boolean;
}

export default function Comments({
  ticketId,
  highlight,
  scrollable,
}: CommentsProps) {
  return (
    <CommentsContainer
      ticketId={ticketId as string}
      highlight={highlight}
      scrollable={scrollable}
    />
  );
}
