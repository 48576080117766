import BaseAvatarGroup from './variants/BaseAvatarGroup';

import { BaseAvatarGroupProps } from './types';

interface AvatarGroupProps extends BaseAvatarGroupProps {
  parentVariant?: 'base';
}

export default function AvatarGroup({ ...props }: AvatarGroupProps) {
  return <BaseAvatarGroup {...props} />;
}
