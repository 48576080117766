import { rem } from '@mantine/core';

export const MODALS = {
  EDIT_TICKET_MODAL: 'edit-ticket-modal',
  ADD_PROJECT_MODAL: 'add-project-modal',
  EDIT_PROJECT_MODAL: 'edit-project-modal',
  EDIT_USER_MODAL: 'edit-user-modal',
  ADD_CLIENT_MODAL: 'add-client-modal',
  ADD_USER_MODAL: 'add-user-modal',
  ADD_BILLING_RECORD_MODAL: 'add-billing-record-modal',
  ADD_AVAILABILITY_RECORD_MODAL: 'add-availability-record-modal',
  EDIT_AVAILABILITY_RECORD_MODAL: 'edit-availability-record-modal',
  REVIEW_AVAILABILITY_RECORd_MODAL: 'review-availability-record-modal',
  LIST_BILLING_RECORDS_MODAL: 'list-billing-records-modal',
  LIST_EXPENSES_MODAL: 'list-expenses-modal',
  LIST_TICKETS_MODAL: 'list-tickets-modal',
  ADD_INVOICE_MODAL: 'add-invoice-modal',
  PREVIEW_INVOICE_MODAL: 'preview-invoice-modal',
  SEND_INVOICE_MODAL: 'send-invoice-modal',
  ADD_INVOICE_PAYMENT_MODAL: 'add-invoice-payment-modal',
  UPLOAD_USER_LABOR_COSTS_MODAL: 'upload-user-labor-costs-modal',
  DEBUG: 'debug',
  CONFIRM: 'confirm',
  INFO: 'info',
};

export const HEADER_HEIGHT = rem(60);
export const TABS_HEIGHT = rem(52);
export const MODAL_HEADER_HEIGHT = rem(46);
export const TABBED_CONTENT_HEIGHT = `calc(100vh - ${HEADER_HEIGHT} - ${TABS_HEIGHT})`;

export const MASKS = {
  PHONE: '(000)000-0000',
  ZIP: '00000-0000',
};
