import { Grid, Text } from '@mantine/core';
import { ReactNode } from 'react';

interface JsonItemProps {
  name: string;
  value: ReactNode | string;
}

export default function JsonItem({ name, value }: JsonItemProps) {
  return (
    <>
      <Grid.Col xs={4}>
        <Text transform="capitalize">{name}</Text>
      </Grid.Col>
      <Grid.Col xs={8}>{value}</Grid.Col>
    </>
  );
}
