import { Stack, TextInput } from '@mantine/core';
import { Formik, useFormikContext } from 'formik';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import BaseModal from '../BaseModal';
import ModalFooter from './ModalFooter';

import { AddClientBody } from 'utils/client';
import useUsers from 'hooks/users/useUsers';
import { MODALS } from 'constants/component';
import useModalStore from 'hooks/store/useModalStore';

const initialValues: Partial<AddClientBody> = {
  name: '',
  email: '',
  phone: '',
  address_line1: '',
  address_line2: '',
  address_city: '',
  address_state: '',
  address_zip: '',
  address_country: '',
};

function AddUserModalForm() {
  const { values, handleChange } = useFormikContext<AddClientBody>();

  return (
    <Stack sx={() => ({ padding: 16, paddingBottom: 32 })} spacing={8}>
      <TextInput
        name="email"
        label="Email"
        value={values.email}
        onChange={handleChange}
      />
    </Stack>
  );
}

function AddUserModal() {
  const modal = useModal();
  const { popModal } = useModalStore();
  const { inviteUserByEmail } = useUsers();

  const handleClose = () => {
    popModal(MODALS.ADD_USER_MODAL);
  };

  const handleSubmit = async (values: Partial<AddClientBody>) => {
    if (await inviteUserByEmail({ email: values.email as string })) {
      handleClose();
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <BaseModal
        id={MODALS.ADD_USER_MODAL}
        isOpen={modal.visible}
        onClose={handleClose}
        title="Invite User"
        height="auto"
        size="40vw"
        FooterComponent={ModalFooter}
      >
        <AddUserModalForm />
      </BaseModal>
    </Formik>
  );
}

export default NiceModal.create((props) => <AddUserModal {...props} />);
