import { Menu, Button } from '@mantine/core';

import { BaseDropdownButtonProps } from '../../types';

export default function BaseDropdownButton({
  children,
  buttonProps,
  menuProps,
}: BaseDropdownButtonProps) {
  return (
    <Menu shadow="md" width={200} {...menuProps}>
      <Menu.Target>
        <Button {...buttonProps} />
      </Menu.Target>

      <Menu.Dropdown>{children}</Menu.Dropdown>
    </Menu>
  );
}
