import { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';

import BaseSlider from '../BaseSlider';
import hex2hsl from 'helpers/color/hex2hsl';

import { BaseSliderProps } from '../../types';

interface ColorSliderProps extends BaseSliderProps {
  /** hex-code of central color of range */
  refColor?: string;
  count?: number;
}

const generateMarks = (refColor: string, count: number) => {
  let markCount = count;
  if (markCount % 2 === 0) markCount += 1;

  const [h, s] = hex2hsl(refColor);
  const spacer = 30 / (count - 1);

  return Array.from({ length: count }).map((_, i) => {
    return {
      value: i,
      label: '',
      color: `hsl(${h}, ${s}%, ${70 - spacer * i}%)`,
    };
  });
};

export default function ColorSlider({
  refColor = '#FF0000',
  count = 5,
  value = 0,
  onChange,
  ...props
}: ColorSliderProps) {
  const [sliderValue, setSliderValue] = useState(value);

  const debouncedOnChange = useMemo(
    () => debounce((priority: number) => onChange?.(priority), 250),
    [onChange]
  );

  const handleChange = useCallback(
    (priority: number) => {
      setSliderValue(priority);

      debouncedOnChange(priority);
    },
    [debouncedOnChange]
  );

  const marks = useMemo(() => {
    return generateMarks(refColor, count);
  }, [refColor, count]);

  const trackColor = marks[sliderValue - 1 || 0]?.color;

  return (
    <BaseSlider
      {...props}
      marks={marks}
      min={1}
      max={count}
      value={sliderValue}
      onChange={handleChange}
      styles={() => ({
        bar: {
          backgroundColor: trackColor,
        },
        markFilled: {
          borderColor: trackColor,
        },
        thumb: {
          borderColor: trackColor,
        },
      })}
    />
  );
}
