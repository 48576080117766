import { AxiosRequestConfig } from 'axios';
import axios from 'utils/axios';

export default async function fetcher(
  path: string,
  config?: AxiosRequestConfig
) {
  const res = await axios.get(path, config);
  return res?.data;
}
