import {
  ChangeEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Box, Group, ActionIcon, Tooltip, rem } from '@mantine/core';
import { RichTextEditor as Editor } from '@mantine/tiptap';
import { IconPhotoPlus } from '@tabler/icons-react';

import { BaseRichTextEditorProps } from '../../types';

import useWYSIWYG from 'hooks/app/useWYSIWYG';
import AttachmentPreview from 'components/common/Attachment/Preview';

export default function BaseRichTextEditor({
  content,
  onChange,
  hasAttachments,
  attachments,
  setAttachments,
}: BaseRichTextEditorProps) {
  const { editor } = useWYSIWYG({ content, onChange });
  const reader = useRef(new FileReader());
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

  const handleImageUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const targetFile = e.target.files?.[0];

      if (!targetFile) return;

      if (targetFile?.type?.includes('image')) {
        reader.current.readAsDataURL(targetFile);
      } else {
        setAttachments?.([...(attachments || []), targetFile]);
      }
    },
    [attachments, setAttachments]
  );

  useEffect(() => {
    const currentReader = reader.current;

    const loadImage = () => {
      const result = currentReader.result;
      editor?.commands?.insertContent(`<img src="${result}" />`);

      if (inputRef.current) {
        inputRef.current.value = '';
      }
    };

    currentReader.addEventListener('load', loadImage);

    return () => {
      currentReader.removeEventListener('load', loadImage);
    };
  }, [content, editor, onChange]);

  return (
    <Box>
      <Editor
        editor={editor}
        sx={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
      >
        <Editor.Toolbar sticky>
          <Editor.ControlsGroup>
            <Editor.Bold />
            <Editor.Italic />
            <Editor.Underline />
            <Editor.Strikethrough />
          </Editor.ControlsGroup>

          <Editor.ControlsGroup>
            <Editor.BulletList />
            <Editor.OrderedList />
          </Editor.ControlsGroup>

          <Editor.ControlsGroup>
            <Editor.Link />
          </Editor.ControlsGroup>

          <Editor.ControlsGroup>
            <Editor.Code />
            <Editor.Blockquote />
          </Editor.ControlsGroup>

          {hasAttachments && (
            <Group>
              <Tooltip label="Add attachment">
                <ActionIcon
                  size="xs"
                  component="label"
                  sx={(theme) => ({
                    border: `1px solid ${theme.colors.gray[4]}`,
                    color: theme.colors.gray[8],
                    height: rem(26),
                    width: rem(26),
                  })}
                >
                  <input
                    ref={inputRef}
                    type="file"
                    hidden
                    onChange={handleImageUpload}
                  />
                  <IconPhotoPlus size={rem(16)} />
                </ActionIcon>
              </Tooltip>
            </Group>
          )}
        </Editor.Toolbar>

        <Editor.Content
          sx={(theme) => ({
            maxHeight: '65vh',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: rem(9),
            },
            '&::-webkit-scrollbar-track': {
              background: theme.colors.gray[4],
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.colors.gray[5],
              borderRadius: theme.radius.md,
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: theme.colors.gray[6],
            },
          })}
        />

        {Boolean(attachments?.length) && (
          <Group
            sx={(theme) => ({
              padding: theme.spacing.xs,
              borderBottomLeftRadius: theme.radius.sm,
              borderBottomRightRadius: theme.radius.sm,
            })}
          >
            {attachments?.map((attachment) => (
              <AttachmentPreview
                key={attachment.name}
                file={attachment}
                onRemove={
                  attachment?.size
                    ? () => {
                        setAttachments?.(
                          attachments.filter((a) => a.name !== attachment.name)
                        );
                      }
                    : undefined
                }
              />
            ))}
          </Group>
        )}
      </Editor>
    </Box>
  );
}
