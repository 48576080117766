import { useCallback } from 'react';
import { ActionIcon, Group, Tooltip } from '@mantine/core';
import { IconCoin, IconEdit, IconEye } from '@tabler/icons-react';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

import BaseTable from 'components/common/Table/variants/BaseTable';
import InvoiceStatus from './components/InvoiceStatus';

import { TableColumn } from 'components/common/Table/types';
import { Invoice } from 'types/api';
import { BaseTableProps } from 'components/common/Table/types';
import { AUTH_USER_ID } from 'constants/api';
import { MODALS } from 'constants/component';

import useUser from 'hooks/users/useUser';
import useModalStore from 'hooks/store/useModalStore';

import getCurrencyDisplay from 'helpers/display/getCurrencyDisplay';
import useInstanceConfig from 'hooks/instance/useInstanceConfig';
import { DATE_FORMATS } from 'constants/date';

export interface InvoiceTableProps {
  invoices: Invoice[];
  voidInvoice?: (invoiceId: number) => Promise<boolean>;
}

const columns: TableColumn[] = [
  {
    id: 'pub_id',
    display: 'ID',
  },
  {
    id: 'client',
    display: 'Client',
  },
  {
    id: 'due_date',
    display: 'Due Date',
  },
  {
    id: 'total',
    display: 'Total',
  },
  {
    id: 'amount_due',
    display: 'Amount to Pay',
  },
  {
    id: 'status',
    display: 'Status',
  },
  {
    id: 'actions',
    display: 'Actions',
  },
];

export default function InvoicesTable({ invoices }: InvoiceTableProps) {
  const { pushModal } = useModalStore();
  const { formatUserTimezone } = useUser({ userId: AUTH_USER_ID });
  const { dateDisplay } = useInstanceConfig();

  const getDataDisplay: BaseTableProps<Invoice>['getDataDisplay'] = useCallback(
    (key: string, row: Invoice) => {
      const rowKey = key;

      if (key === 'client') {
        return row.client?.name || 'N/A';
      }

      if (key === 'status') {
        return <InvoiceStatus status={row.status} />;
      }

      if (key === 'total') {
        return getCurrencyDisplay((row?.total || 0) / 100);
      }

      if (key === 'amount_due') {
        return getCurrencyDisplay(
          ((row?.total || 0) - (row?.amount_paid || 0)) / 100
        );
      }

      if (key === 'due_date') {
        return format(
          parse(row.due_date, DATE_FORMATS.DATE_KEY, new Date()),
          dateDisplay
        );
      }

      if (key === 'datetime_created') {
        return formatUserTimezone(
          new Date(row.datetime_created || ''),
          `${dateDisplay} h:mm a zzz`
        );
      }

      if (key === 'actions') {
        return (
          <Group spacing={4} sx={{ justifyContent: 'center' }}>
            {row.status === 'draft' && (
              <Tooltip label="Edit">
                <ActionIcon
                  color="blue"
                  onClick={() => {
                    pushModal(MODALS.ADD_INVOICE_MODAL, { invoiceId: row.id });
                  }}
                >
                  <IconEdit />
                </ActionIcon>
              </Tooltip>
            )}
            <Tooltip label="Preview">
              <ActionIcon
                color="blue"
                onClick={() => {
                  pushModal(MODALS.PREVIEW_INVOICE_MODAL, {
                    invoiceId: row.id,
                  });
                }}
              >
                <IconEye />
              </ActionIcon>
            </Tooltip>
            {row.status === 'sent' ||
            row.status === 'partially_paid' ||
            row.status === 'approved' ? (
              <Tooltip label="Add Payment">
                <ActionIcon
                  color="green"
                  onClick={() => {
                    pushModal(MODALS.ADD_INVOICE_PAYMENT_MODAL, {
                      invoiceId: row.id,
                    });
                  }}
                >
                  <IconCoin />
                </ActionIcon>
              </Tooltip>
            ) : (
              <></>
            )}
            {row.status === 'paid' ? (
              <Tooltip label="View Payments">
                <ActionIcon
                  color="blue"
                  onClick={() => {
                    pushModal(MODALS.ADD_INVOICE_PAYMENT_MODAL, {
                      invoiceId: row.id,
                    });
                  }}
                >
                  <IconCoin />
                </ActionIcon>
              </Tooltip>
            ) : (
              <></>
            )}
          </Group>
        );
      }

      return `${row[rowKey as keyof Invoice]}`;
    },
    [dateDisplay, formatUserTimezone, pushModal]
  );

  return (
    <BaseTable<Invoice>
      columns={columns}
      rows={invoices}
      getDataDisplay={getDataDisplay}
      tableProps={{
        sx: {
          tableLayout: 'fixed',
        },
      }}
    />
  );
}
