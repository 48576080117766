import { create } from 'zustand';

interface ConfigStoreState {
  breadcrumbsMap: { [name: string]: string };
  setBreadcrumbsVar: (key: string, value: string) => void;
}

const useConfigStore = create<ConfigStoreState>((set) => ({
  breadcrumbsMap: {},
  setBreadcrumbsVar(key, value) {
    set((state) => ({
      ...state,
      breadcrumbsMap: {
        ...state.breadcrumbsMap,
        [key]: value,
      },
    }));
  },
}));

export default useConfigStore;
