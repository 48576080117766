import { Badge, BadgeProps } from '@mantine/core';

import { Invoice } from 'types/api';

interface InvoiceStatusProps {
  status?: Invoice['status'];
  badgeProps?: BadgeProps;
}

export default function InvoiceStatus({
  status,
  badgeProps,
}: InvoiceStatusProps) {
  const getColor = () => {
    if (status === 'partially_paid' || status === 'sent') {
      return 'blue';
    }

    if (status === 'voided') {
      return 'red';
    }

    if (status === 'approved' || status === 'paid') {
      return 'green';
    }

    return 'gray';
  };

  return (
    <Badge
      color={getColor()}
      sx={{ textTransform: 'capitalize' }}
      {...badgeProps}
    >
      {status?.replace('_', ' ') || 'Draft'}
    </Badge>
  );
}
