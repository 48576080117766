import ConsoleLayout from 'layouts/ConsoleLayout';

import BillingRecordsTimesheet from 'components/shared/BillingRecordsTimesheet';

export default function TimesheetPage() {
  return (
    <ConsoleLayout>
      <BillingRecordsTimesheet />
    </ConsoleLayout>
  );
}
