import { Button } from '@mantine/core';

import { MODALS } from 'constants/component';

import ConsoleLayout from 'layouts/ConsoleLayout';

import useModalStore from 'hooks/store/useModalStore';
import useInvoices from 'hooks/billing/useInvoices';
import InvoicesTable from './InvoicesTable';

export default function InvoicesPage() {
  const { pushModal } = useModalStore();
  const { data, isLoading } = useInvoices({
    storeKey: 'list-invoices',
  });

  return (
    <ConsoleLayout
      loading={isLoading}
      actionProps={{
        title: 'Invoices',
        children: (
          <Button onClick={() => pushModal(MODALS.ADD_INVOICE_MODAL)}>
            Add Invoice
          </Button>
        ),
      }}
    >
      <InvoicesTable invoices={data?.invoices || []} />
    </ConsoleLayout>
  );
}
