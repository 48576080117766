import { ENDPOINTS } from 'constants/api';
import axios from './axios';
import { AxiosResponse } from 'axios';
import { InstanceConfig } from 'types/api';

export type UpdateInstanceConfigCallback = (
  data: Partial<InstanceConfig>
) => Promise<boolean>;

export const updateInstanceConfig: UpdateInstanceConfigCallback = async (
  data
) => {
  const res = (await axios.patch(
    ENDPOINTS.INSTANCE_CONFIG,
    data
  )) as AxiosResponse;
  return res.status === 200;
};

export const updateInstance = async (data: FormData) => {
  const res = (await axios.patch(ENDPOINTS.INSTANCE, data)) as AxiosResponse;
  return res.data;
};
