import { Box, Collapse, Sx, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { ReactNode, useState } from 'react';

interface ContentSectionProps {
  children: ReactNode;
  title: string;
  titleSx?: Sx;
}

export default function ContentSection({
  children,
  title,
  titleSx,
}: ContentSectionProps) {
  const [expanded, setExpanded] = useState(true);

  return (
    <Box>
      <Box
        sx={(theme) => ({
          borderBottom: '1px solid',
          borderColor: theme.white,
          paddingBottom: theme.spacing.xs,
          marginBottom: theme.spacing.xs,
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          userSelect: 'none',
        })}
        onClick={() => setExpanded(!expanded)}
      >
        <Text weight="bold" align="center" size="xl" sx={titleSx}>
          {title}
        </Text>
        {!expanded && (
          <IconChevronDown
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
          />
        )}
      </Box>
      <Collapse in={expanded}>
        <Box>{children}</Box>
      </Collapse>
    </Box>
  );
}
