import { AutocompleteProps } from './types';

import BaseAutocomplete from './variants/BaseAutocomplete';
import TicketAutocomplete from './variants/TicketAutocomplete';

export default function Autocomplete({ variant, ...props }: AutocompleteProps) {
  if (variant === 'ticket') {
    return <TicketAutocomplete {...props} />;
  }

  return <BaseAutocomplete {...props} />;
}
