import {
  useRef,
  ReactNode,
  MutableRefObject,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { UseMovePosition } from '@mantine/hooks';
import { Box, ScrollArea } from '@mantine/core';

interface DraggableContainerProps {
  children: ReactNode;
  height: string;
}

export default function DraggableContainer({
  children,
  height,
}: DraggableContainerProps) {
  const viewport = useRef<HTMLDivElement>(null);
  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  const mousePosStart = useRef({ x: 0, y: 0 }) as MutableRefObject<{
    x: number;
    y: number;
  }>;
  const scrollStart = useRef({ x: 0, y: 0 }) as MutableRefObject<{
    x: number;
    y: number;
  }>;
  const [isMouseDown, setIsMouseDown] = useState(false);

  const handleMouseDown = useCallback(
    (e: any) => {
      if (!isMouseDown && !window.DISABLE_DRAG_CONTAINER) {
        setIsMouseDown(true);
        mousePosStart.current.x = e.clientX;
        mousePosStart.current.y = e.clientY;
        scrollStart.current.x = viewport.current?.scrollLeft || 0;
        scrollStart.current.y = viewport.current?.scrollTop || 0;
      }
    },
    [isMouseDown]
  );

  const handleMouseUp = useCallback(() => {
    if (isMouseDown) {
      setIsMouseDown(false);
    }
  }, [isMouseDown]);

  const handleScrollPosition = ({ x, y }: UseMovePosition) => {
    viewport.current!.scrollTo({
      top: y,
      left: x,
    });
  };

  const handleMouseMove = useCallback(
    (e: any) => {
      if (isMouseDown && !window.DISABLE_DRAG_CONTAINER) {
        const diffX = mousePosStart.current.x - e.clientX;
        const diffY = mousePosStart.current.y - e.clientY;
        const top = scrollStart.current.y;
        const left = scrollStart.current.x;

        handleScrollPosition({ x: left + diffX, y: top + diffY });

        if (e.stopPropagation) e.stopPropagation();
        if (e.preventDefault) e.preventDefault();
        e.cancelBubble = true;
        e.returnValue = false;

        return false;
      }
    },
    [isMouseDown]
  );

  useEffect(() => {
    const element = ref.current;

    element.addEventListener('mousedown', handleMouseDown);
    element.addEventListener('mouseup', handleMouseUp);
    element.addEventListener('mouseleave', handleMouseUp);
    element.addEventListener('mousemove', handleMouseMove);

    return () => {
      element.removeEventListener('mousedown', handleMouseDown);
      element.removeEventListener('mouseup', handleMouseUp);
      element.removeEventListener('mouseleave', handleMouseUp);
      element.removeEventListener('mousemove', handleMouseMove);
    };
  }, [handleMouseDown, handleMouseMove, handleMouseUp]);

  return (
    <Box>
      <ScrollArea
        sx={() => ({
          height: height,
          cursor: 'pointer',
        })}
        viewportRef={viewport}
        ref={ref}
      >
        {children}
      </ScrollArea>
    </Box>
  );
}
