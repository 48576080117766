import { Avatar, Box, Tooltip } from '@mantine/core';

import { BaseAvatarGroupProps } from '../../types';

export default function BaseAvatarGroup({
  avatars,
  max = 0,
  sharedProps,
  AvatarComponent = Avatar,
  ...props
}: BaseAvatarGroupProps) {
  const overMax = Boolean(max && avatars.length > max);
  const end = overMax ? max - 1 : max;

  return (
    <Avatar.Group {...props}>
      {avatars.slice(0, end || undefined).map((avatarProps, index) => (
        <AvatarComponent
          key={`avatar-${index}`}
          {...sharedProps}
          {...avatarProps}
        />
      ))}
      {overMax && (
        <Tooltip
          label={avatars.slice(end).map((avatar, index) => (
            <Box key={`${avatar.title}-${index}`}>{avatar.title}</Box>
          ))}
        >
          <Avatar {...sharedProps} color="gray">
            +
          </Avatar>
        </Tooltip>
      )}
    </Avatar.Group>
  );
}
