import { Loader, Timeline, Box, Text, ActionIcon } from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import format from 'date-fns/format';
import { DateValue } from '@mantine/dates';
import { IconPlus, IconMinus } from '@tabler/icons-react';
import isEmpty from 'lodash/isEmpty';

import { BaseActivityListProps } from '../types';
import { Activity } from 'types/api';
import { TABBED_CONTENT_HEIGHT } from 'constants/component';

import TicketActivityListItem from '../components/TicketActivityListItem';
import InfiniteLoader from 'components/common/InfiniteLoader';

import useUserActivityInfinite from 'hooks/users/useUserActivityInfinite';

import { DATE_FORMATS } from 'constants/date';
import { mergeSimilarActivity } from 'utils/activity';
import useAuthUser from 'hooks/users/useAuthUser';
import ActivityDisplay from '../components/ActivityDisplay';

interface UserActivityGroupedListProps extends BaseActivityListProps {
  userId?: string | number;
  height?: string;
  dateRange?: [DateValue, DateValue];
  setDateRange?: (dateRange: [DateValue, DateValue]) => void;
}

export default function UserActivityGroupedList({
  userId,
  height = TABBED_CONTENT_HEIGHT,
  dateRange,
}: UserActivityGroupedListProps) {
  const { user } = useAuthUser();
  const apiUserId = userId || (user?.id as number);
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useUserActivityInfinite({
    params: {
      page: 1,
      page_size: 20,
      datetime_created_start: dateRange?.[0]
        ? format(dateRange[0], DATE_FORMATS.DATETIME_KEY)
        : undefined,
      datetime_created_end: dateRange?.[1]
        ? format(dateRange[1], DATE_FORMATS.DATETIME_KEY)
        : undefined,
      user_id: user?.id,
    },
    disabled: !apiUserId,
    userId: apiUserId,
    storeKey: 'list-user-activity',
  });
  const empty = !data?.pages?.length;

  const [expanded, setExpanded] = useState<{ [id: number]: boolean }>({});

  const getTimelineItemProps = useCallback((activityItem: Activity) => {
    const title = <ActivityDisplay activityItem={activityItem} />;

    return {
      title,
    };
  }, []);

  // refetch on tab change
  useEffect(() => {
    if (userId) {
      refetch();
    }
  }, [userId, refetch]);

  if (isLoading)
    return (
      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          marginTop: theme.spacing.lg,
        })}
      >
        <Loader sx={{ marginLeft: 'auto', marginRight: 'auto' }} />
      </Box>
    );

  return (
    <InfiniteLoader
      height={height}
      onScrollEnd={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={Boolean(hasNextPage)}
    >
      <Box sx={{ paddingTop: 6 }}>
        {empty ? (
          <Text sx={{ textAlign: 'center' }}>No activity available.</Text>
        ) : (
          <Timeline>
            {data?.pages?.map((page) =>
              mergeSimilarActivity(page.activity_items as Activity[])?.map(
                (activityItem) => {
                  let hasChangeMessage = false;

                  try {
                    hasChangeMessage = !isEmpty(
                      JSON.parse(activityItem.change_message as string).values
                    );
                  } catch (err) {
                    hasChangeMessage = false;
                  }

                  const TimelineBullet = () => {
                    if (!hasChangeMessage) {
                      return <Box />;
                    }

                    let Icon = IconPlus;

                    if (expanded[activityItem.id as number] === true) {
                      Icon = IconMinus;
                    }

                    return (
                      <ActionIcon
                        variant="default"
                        sx={(theme) => ({
                          color: theme.colors.blue[5],
                        })}
                        onClick={() =>
                          setExpanded({
                            ...expanded,
                            [activityItem.id as number]:
                              expanded[activityItem.id as number] === undefined
                                ? true
                                : !expanded[activityItem.id as number],
                          })
                        }
                      >
                        <Icon />
                      </ActionIcon>
                    );
                  };

                  return (
                    <Timeline.Item
                      key={activityItem.id}
                      bullet={<TimelineBullet />}
                      bulletSize={18}
                      {...getTimelineItemProps(activityItem)}
                    >
                      <TicketActivityListItem
                        activityItem={activityItem}
                        expanded={expanded[activityItem.id as number] ?? false}
                        setExpanded={(expand: boolean) => {
                          setExpanded({
                            ...expanded,
                            [activityItem.id as number]: expand,
                          });
                        }}
                      />
                    </Timeline.Item>
                  );
                }
              )
            )}
          </Timeline>
        )}
      </Box>
    </InfiniteLoader>
  );
}
