import NiceModal from '@ebay/nice-modal-react';

import { MODALS } from 'constants/component';
import { isDebug } from './app';

import EditTicketModal from 'components/common/Modal/variants/EditTicketModal';
import AddAvailabilityRecordModal from 'components/common/Modal/variants/AddAvailabilityRecordModal';
import AddBillingRecordModal from 'components/common/Modal/variants/AddBillingRecordModal';
import AddClientModal from 'components/common/Modal/variants/AddClientModal';
import AddProjectModal from 'components/common/Modal/variants/AddProjectModal';
import AddUserModal from 'components/common/Modal/variants/AddUserModal';
import EditProjectModal from 'components/common/Modal/variants/EditProjectModal';
import ListBillingRecordsModal from 'components/common/Modal/variants/ListBillingRecordsModal';
import ListExpensesModal from 'components/common/Modal/variants/ListExpensesModal';
import DebugModal from 'components/common/Modal/variants/DebugModal';
import ConfirmationModal from 'components/common/Modal/variants/ConfirmationModal';
import EditAvailabilityRecordModal from 'components/common/Modal/variants/EditAvailabilityRecordModal';
import ReviewAvailabilityRecordModal from 'components/common/Modal/variants/ReviewAvailabilityRecordModal';
import ListTicketsModal from 'components/common/Modal/variants/ListTicketsModal';
import AddInvoiceModal from 'components/common/Modal/variants/AddInvoiceModal';
import EditUserModal from 'components/common/Modal/variants/EditUserModal';
import PreviewInvoiceModal from 'components/common/Modal/variants/PreviewInvoiceModal';
import SendInvoiceModal from 'components/common/Modal/variants/SendInvoiceModal';
import AddInvoicePaymentModal from 'components/common/Modal/variants/AddInvoicePaymentModal';
import UploadUserLaborCostsModal from 'components/common/Modal/variants/UploadUserLaborCostsModal';
import InfoModal from 'components/common/Modal/variants/InfoModal';

NiceModal.register(MODALS.EDIT_TICKET_MODAL, EditTicketModal);
NiceModal.register(
  MODALS.ADD_AVAILABILITY_RECORD_MODAL,
  AddAvailabilityRecordModal
);
NiceModal.register(MODALS.ADD_BILLING_RECORD_MODAL, AddBillingRecordModal);
NiceModal.register(MODALS.ADD_CLIENT_MODAL, AddClientModal);
NiceModal.register(MODALS.ADD_PROJECT_MODAL, AddProjectModal);
NiceModal.register(MODALS.ADD_USER_MODAL, AddUserModal);
NiceModal.register(MODALS.EDIT_PROJECT_MODAL, EditProjectModal);
NiceModal.register(MODALS.LIST_BILLING_RECORDS_MODAL, ListBillingRecordsModal);
NiceModal.register(MODALS.LIST_EXPENSES_MODAL, ListExpensesModal);
NiceModal.register(MODALS.CONFIRM, ConfirmationModal);
NiceModal.register(
  MODALS.REVIEW_AVAILABILITY_RECORd_MODAL,
  ReviewAvailabilityRecordModal
);
NiceModal.register(
  MODALS.EDIT_AVAILABILITY_RECORD_MODAL,
  EditAvailabilityRecordModal
);
NiceModal.register(MODALS.EDIT_USER_MODAL, EditUserModal);
NiceModal.register(MODALS.LIST_TICKETS_MODAL, ListTicketsModal);
NiceModal.register(MODALS.ADD_INVOICE_MODAL, AddInvoiceModal);
NiceModal.register(MODALS.PREVIEW_INVOICE_MODAL, PreviewInvoiceModal);
NiceModal.register(MODALS.SEND_INVOICE_MODAL, SendInvoiceModal);
NiceModal.register(MODALS.ADD_INVOICE_PAYMENT_MODAL, AddInvoicePaymentModal);
NiceModal.register(
  MODALS.UPLOAD_USER_LABOR_COSTS_MODAL,
  UploadUserLaborCostsModal
);
NiceModal.register(MODALS.INFO, InfoModal);

if (isDebug()) {
  NiceModal.register(MODALS.DEBUG, DebugModal);
}
