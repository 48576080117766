import { InstanceAlert } from 'types/api';
import BasePageAlert from './variants/Base';
import MissingTimeAlert from './variants/MissingTime';

interface PageAlertProps {
  alert?: InstanceAlert;
  onClose?: () => void;
}

export default function PageAlert({ alert, onClose }: PageAlertProps) {
  if (alert?.notif_type === 'missing-time') {
    return <MissingTimeAlert alert={alert} onClose={onClose} />;
  }

  return <BasePageAlert alert={alert} onClose={onClose} />;
}
