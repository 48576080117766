import { Skeleton, Box, Sx, rem } from '@mantine/core';

import useInstance from 'hooks/instance/useInstance';

interface LogoProps {
  sx?: Sx;
}

export default function Logo({ sx }: LogoProps) {
  const { data, isLoading } = useInstance();

  return (
    <Box sx={{ height: rem(28), ...sx }}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Box
          component="img"
          src={data?.instance?.logo}
          sx={{ height: '100%', width: 'auto' }}
        />
      )}
    </Box>
  );
}
