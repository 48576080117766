import constructQueryPath from 'helpers/api/constructQueryPath';
import useApiQueryInfinite from 'hooks/api/useApiQueryInfinite';

import type { Activity } from 'types/api';
import { ApiQueryKey } from 'types/api/query';
import { ENDPOINTS } from 'constants/api';

interface InstanceActivityInfiniteHookParams {
  params?: any;
  disabled?: boolean;
  storeKey?: ApiQueryKey;
}

export default function useInstanceActivityInfinite(
  hookParams: InstanceActivityInfiniteHookParams
) {
  const { params, disabled, storeKey } = hookParams || {};

  const queryResult = useApiQueryInfinite<{
    activity_items?: Activity[];
  }>({
    path: constructQueryPath(ENDPOINTS.ACTIVITY),
    axiosConfig: {
      params,
    },
    queryOptions: {
      enabled: !disabled,
      refetchOnWindowFocus: false,
    },
    storeKey,
  });

  return {
    ...queryResult,
  };
}
