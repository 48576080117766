import { MultiSelect } from '@mantine/core';

import { MultiSelectProps } from '../../types';

export default function BaseMultiSelect({
  data = [],
  label,
  ...props
}: MultiSelectProps) {
  return <MultiSelect data={data} label={label} {...props} />;
}
