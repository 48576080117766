import { useState, useEffect } from 'react';
import { Box, Divider, MultiSelect, Stack, Text, rem } from '@mantine/core';
import { useFormikContext } from 'formik';

import { SendInvoiceFormValues } from '../types';

interface EmailListProps {
  options: string[];
  primaryEmail?: string;
  secondaryEmail?: string;
}

export default function EmailList({
  options,
  primaryEmail,
  secondaryEmail,
}: EmailListProps) {
  const [emailOptions, setEmailOptions] = useState<string[]>(options);
  const { values, setFieldValue } = useFormikContext<SendInvoiceFormValues>();

  useEffect(() => {
    setEmailOptions(options);
  }, [options]);

  return (
    <Box>
      <Stack spacing={4}>
        {primaryEmail && (
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: rem(150) }}>
              <Text size="xs" weight="bold">
                Client Invoice Email:{' '}
              </Text>
            </Box>
            <Box>
              <Text size="xs">{primaryEmail}</Text>
            </Box>
          </Box>
        )}
        {secondaryEmail && (
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: rem(150) }}>
              <Text size="xs" weight="bold">
                Client Primary Email:{' '}
              </Text>
            </Box>
            <Box>
              <Text size="xs">{secondaryEmail}</Text>
            </Box>
          </Box>
        )}
      </Stack>
      <Divider my={4} />
      <MultiSelect
        required
        searchable
        creatable
        label="Emails:"
        placeholder="Add email..."
        data={emailOptions || []}
        value={values.emails || []}
        defaultValue={values.emails || []}
        onChange={(value) => {
          setFieldValue('emails', value);
        }}
        getCreateLabel={(query) => `+ Add ${query}`}
        onCreate={(query) => {
          setEmailOptions(emailOptions.concat([query]));
          setFieldValue('emails', values.emails.concat(query));
          return query;
        }}
      />
    </Box>
  );
}
