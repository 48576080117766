import { useMemo } from 'react';
import { Badge, Tooltip, ActionIcon, rem } from '@mantine/core';
import { IconDownload, IconX } from '@tabler/icons-react';

interface AttachmentPreviewProps {
  file: File;
  onRemove?: () => void;
  onDownload?: () => void;
}

export default function AttachmentPreview({
  file,
  onRemove,
  onDownload,
}: AttachmentPreviewProps) {
  const rightSection = useMemo(() => {
    const removeButton = (
      <ActionIcon
        size="xs"
        radius="xl"
        variant="transparent"
        onClick={() => onRemove?.()}
      >
        <IconX size={rem(10)} />
      </ActionIcon>
    );

    if (onRemove) {
      return removeButton;
    }

    if (onDownload) {
      return <IconDownload size={rem(10)} />;
    }

    return undefined;
  }, [onDownload, onRemove]);

  return (
    <Tooltip label={onDownload ? `Download ${file.name}` : file.name}>
      <Badge
        sx={{
          cursor: 'pointer',
        }}
        rightSection={rightSection}
        onClick={() => onDownload?.()}
        color="blue"
        variant="outline"
      >
        {file.name}
      </Badge>
    </Tooltip>
  );
}
