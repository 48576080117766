import { MutableRefObject, useRef, useEffect } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Stack, Text, Divider } from '@mantine/core';
import { Formik, FormikProps } from 'formik';

import BaseModal from '../BaseModal';
import ModalFooter from './ModalFooter';
import CurrencyInput from 'components/common/UserInput/variants/CurrencyInput';

import { MODALS } from 'constants/component';
import useModalStore from 'hooks/store/useModalStore';
import useInvoice from 'hooks/billing/useInvoice';
import getCurrencyDisplay from 'helpers/display/getCurrencyDisplay';
import useApiStore from 'hooks/store/useApiStore';
import InvoicePaymentsTable from 'pages/Invoices/InvoicePaymentsTable';
import useInvoicePayments from 'hooks/billing/useInvoicePayments';

interface FormValues {
  amount?: number;
}

const initialValues: FormValues = {
  amount: 0,
};

interface AddInvoicePaymentModalProps {
  invoiceId?: number;
}

function AddInvoicePaymentModal({ invoiceId }: AddInvoicePaymentModalProps) {
  const modal = useModal();
  const { popModal } = useModalStore();
  const { invalidate } = useApiStore();
  const { data, isLoading, addInvoicePayment, refetch } = useInvoice({
    invoiceId,
  });
  const invoice = data?.invoice;
  const isPaid = invoice?.status === 'paid';
  const {
    data: paymentsData,
    refetch: invoicePaymentRefetch,
    isLoading: invoicePaymentLoading,
  } = useInvoicePayments({ invoiceId });
  const formikRef = useRef() as MutableRefObject<FormikProps<
    Partial<FormValues>
  > | null>;

  const handleClose = () => {
    if (!formikRef.current?.isSubmitting) {
      refetch();
      popModal(MODALS.ADD_INVOICE_PAYMENT_MODAL);
    }
  };

  const handleSubmit = async (values: FormValues) => {
    if (await addInvoicePayment(values.amount as number)) {
      invalidate('list-invoices');
      invoicePaymentRefetch();
      refetch();
      popModal(MODALS.ADD_INVOICE_PAYMENT_MODAL);
    }
  };

  const amountDue =
    (data?.invoice?.total || 0) - (data?.invoice?.amount_paid || 0);

  useEffect(() => {
    if (!modal.visible) {
      formikRef.current?.resetForm();
    }
  }, [modal]);

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={initialValues}
      initialStatus={{
        isPaid: isPaid,
      }}
      onSubmit={handleSubmit}
      validate={(values) => {
        if ((values.amount || 0) <= 0) {
          return {
            amount: 'Payment Amount needs to be greater than 0',
          };
        }

        if ((values.amount || 0) > amountDue) {
          return {
            amount: 'Amount exceeds amount due',
          };
        }
      }}
    >
      {({ values, setFieldValue, errors }) => (
        <BaseModal
          id={MODALS.ADD_INVOICE_PAYMENT_MODAL}
          isOpen={modal.visible}
          onClose={handleClose}
          loading={isLoading}
          title={
            isPaid
              ? `Payments made to Invoice #${invoice?.pub_id}`
              : `Add Payment to Invoice #${invoice?.pub_id || ''}`
          }
          height="auto"
          size="60vw"
          FooterComponent={ModalFooter}
        >
          <Stack sx={(theme) => ({ padding: theme.spacing.md })} spacing={4}>
            {!isPaid && (
              <>
                <Text>Amount Due: {getCurrencyDisplay(amountDue / 100)}</Text>
                <CurrencyInput
                  required
                  name="amount"
                  label="Full/Partial Payment"
                  value={(values.amount || 0) / 100}
                  onChange={(value) => {
                    setFieldValue('amount', (value as number) * 100);
                  }}
                  error={errors.amount}
                  min={0}
                />
              </>
            )}
            <Divider
              label="Payments"
              labelPosition="left"
              variant="dashed"
              style={{ color: 'gray' }}
            />
            <InvoicePaymentsTable
              payments={paymentsData?.invoice_payments || []}
              loading={invoicePaymentLoading}
            />
          </Stack>
        </BaseModal>
      )}
    </Formik>
  );
}

export default NiceModal.create((props) => (
  <AddInvoicePaymentModal {...props} />
));
