import {
  RouterProvider as BaseRouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import 'utils/modals';
import useRoutes from 'hooks/app/useRoutes';

import { LoadingOverlay } from '@mantine/core';

export default function RouterProvider() {
  const { routes, loading } = useRoutes();
  const router = createBrowserRouter(routes);

  if (loading) {
    return <LoadingOverlay visible />;
  }

  return <BaseRouterProvider router={router} />;
}
