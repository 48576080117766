import { notifications } from '@mantine/notifications';
import { create } from 'zustand';

import { DownloadItem } from './types';

interface DownloadStoreState {
  downloadQueue: DownloadItem[];
  pushDownload: (downloadItem: DownloadItem) => void;
  executeDownload: (downloadItem: DownloadItem) => void;
  preventPageLeaving: (e: BeforeUnloadEvent) => void;
  getActiveDownloadCount: () => number;
}

const useDownloadStore = create<DownloadStoreState>((set, get) => ({
  downloadQueue: [],
  getActiveDownloadCount: () => {
    const state = get();
    return state.downloadQueue.filter((downloadItem) => !downloadItem.completed)
      .length;
  },
  preventPageLeaving: (e) => {
    const confirmationMessage =
      'There are downloads pending that will be cancelled upon leaving.';
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  },
  pushDownload: (downloadItem: DownloadItem) => {
    const state = get();

    set((state) => ({ downloadQueue: [...state.downloadQueue, downloadItem] }));
    state.executeDownload(downloadItem);
  },
  executeDownload: async (downloadItem: DownloadItem) => {
    notifications.show({
      message: 'Your download has started processing. Do not close the tab.',
      variant: 'info',
    });

    const currentState = get();

    const foundItem = currentState.downloadQueue.find(
      (d) => d.filename === downloadItem.filename
    );

    function _preventPageLeaving(e: BeforeUnloadEvent) {
      currentState.preventPageLeaving(e);
    }

    if (foundItem) {
      try {
        window.addEventListener('beforeunload', _preventPageLeaving);

        foundItem.completed = false;
        set(() => ({
          ...currentState,
          downloadQueue: [...currentState.downloadQueue],
        }));

        await foundItem.download();
      } finally {
        foundItem.completed = true;
        foundItem.datetimeCompleted = new Date();
        set(() => ({
          ...currentState,
          downloadQueue: [...currentState.downloadQueue],
        }));

        window.removeEventListener('beforeunload', _preventPageLeaving);
      }
    }
  },
}));

export default useDownloadStore;
