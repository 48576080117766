import { useState } from 'react';
import { Stack, Box, SegmentedControl, Container, Space } from '@mantine/core';
import { IconReload, IconCircleCheck, IconCircleX } from '@tabler/icons-react';
import capitalize from 'lodash/capitalize';

import AvailabilityGroup from 'components/common/AvailabilityGroup';
import AvailabilityCalendar from './AvailabilityCalendar';

export default function AvailabilityTab() {
  const [activeValue, setActiveValue] = useState('Table');
  const statuses = ['pending', 'denied', 'approved'];

  return (
    <Container fluid>
      <Box
        sx={(theme) => ({
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          paddingBottom: theme.spacing.md,
        })}
      >
        <SegmentedControl
          data={['Table', 'Calendar']}
          value={activeValue}
          onChange={(av) => setActiveValue(av)}
        />
      </Box>
      {activeValue === 'Calendar' && <AvailabilityCalendar />}
      {activeValue === 'Table' && (
        <Stack spacing={12}>
          {statuses.map((status) => (
            <AvailabilityGroup
              key={status}
              title={
                <>
                  {status === 'pending' && <IconReload color="gray" />}
                  {status === 'approved' && <IconCircleCheck color="green" />}
                  {status === 'denied' && <IconCircleX color="red" />}
                  {capitalize(status)}
                </>
              }
              params={{ active: '1', status: status, sort: '-date_start' }}
            />
          ))}
        </Stack>
      )}
      <Space h={12} />
    </Container>
  );
}
