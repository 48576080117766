import { TicketEstimate } from 'types/api';
import BaseEstimatePopOver from './variants/Base';
import EstimatePopOverEdit from './variants/Edit';

import {
  AddTicketEstimateCallback,
  DeleteTicketEstimateCallback,
} from 'components/common/Modal/variants/EditTicketModal/types';

interface EstimatePopOverProps {
  updateTicketEstimate?: AddTicketEstimateCallback;
  filter?: (value: string) => boolean;
  variant?: 'base' | 'edit';
  estimate?: TicketEstimate;
  deleteTicketEstimate?: DeleteTicketEstimateCallback;
}

export default function EstimatePopOver({
  variant,
  ...props
}: EstimatePopOverProps) {
  if (variant === 'edit') {
    return <EstimatePopOverEdit {...props} />;
  }

  return <BaseEstimatePopOver {...props} />;
}
