import { IconDownload } from '@tabler/icons-react';
import {
  ActionIcon,
  Popover,
  Stack,
  Box,
  ColorSwatch,
  Text,
} from '@mantine/core';

import useDownloadStore from 'hooks/store/useDownloadStore';
import { DownloadItem as DownloadItemType } from 'hooks/store/types';

function DownloadItem({
  downloadItem,
  onDownload,
}: {
  downloadItem: DownloadItemType;
  onDownload: (downloadItem: DownloadItemType) => void;
}) {
  return (
    <Box
      sx={() => ({
        display: 'flex',
        justifyContent: 'space-between',
      })}
    >
      <Text
        sx={{ marginTop: 'auto', marginBottom: 'auto', lineHeight: '100%' }}
      >
        {downloadItem.filename}
      </Text>
      <ActionIcon
        color="blue"
        sx={(theme) => ({
          backgroundColor: theme.colors.blue,
          color: 'white',
          borderRadius: theme.radius.xl,
          padding: theme.spacing.xs,
        })}
        disabled={!downloadItem.completed}
        onClick={() => onDownload(downloadItem)}
      >
        <IconDownload />
      </ActionIcon>
    </Box>
  );
}

export default function DownloadNotification() {
  const { downloadQueue, executeDownload, getActiveDownloadCount } =
    useDownloadStore();

  return (
    <Popover width={400} position="bottom" withArrow shadow="md">
      <Popover.Target>
        <Box sx={{ position: 'relative' }}>
          {Boolean(getActiveDownloadCount()) && (
            <ColorSwatch
              color="lightgray"
              sx={{
                height: '11px',
                width: '11px',
                top: 0,
                right: 0,
                position: 'absolute',
                color: 'black',
                fontSize: '9px',
                fontWeight: 'bold',
              }}
            >
              {getActiveDownloadCount()}
            </ColorSwatch>
          )}
          <ActionIcon>
            <IconDownload />
          </ActionIcon>
        </Box>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack>
          {downloadQueue?.map((downloadItem) => (
            <DownloadItem
              key={downloadItem.filename}
              downloadItem={downloadItem}
              onDownload={executeDownload}
            />
          ))}
          {downloadQueue?.length === 0 && (
            <Box
              sx={(theme) => ({
                textAlign: 'center',
                padding: theme.spacing.md,
              })}
            >
              <Box>No downloads at this time</Box>
            </Box>
          )}
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
}
