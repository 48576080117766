import { Box, Text } from '@mantine/core';
import parse from 'date-fns/parse';
import format from 'date-fns/format';

import { DATE_FORMATS } from 'constants/date';
import useInstanceConfig from 'hooks/instance/useInstanceConfig';

interface DateHeaderProps {
  date: string;
}

export default function DateHeader({ date }: DateHeaderProps) {
  const { dateDisplay } = useInstanceConfig();

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.colors.blue,
        color: theme.white,
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
      })}
    >
      <Text size="sm" weight="bold">
        {date &&
          format(parse(date, DATE_FORMATS.DATE_KEY, new Date()), dateDisplay)}
      </Text>
    </Box>
  );
}
