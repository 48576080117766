import { AxiosResponse } from 'axios';
import axios from './axios';
import { ENDPOINTS } from 'constants/api';
import constructQueryPath from 'helpers/api/constructQueryPath';

import { ProjectTicket, TicketComment } from 'types/api';

export type TicketBody = Pick<
  ProjectTicket,
  | 'status'
  | 'assignee_id'
  | 'billable'
  | 'date_due'
  | 'date_start'
  | 'title'
  | 'description'
  | 'hide_from_client'
  | 'priority'
  | 'position'
  | 'is_done'
  | 'blocked'
>;

export async function addTicket(ticketBody: Partial<TicketBody>) {
  return axios.put(constructQueryPath(ENDPOINTS.TICKETS), ticketBody);
}

export async function addProjectTicket({
  projectId,
  ticketBody,
}: {
  projectId: string;
  ticketBody: Partial<TicketBody>;
}) {
  return axios.put<Partial<TicketBody>, { data: { ticket: ProjectTicket } }>(
    constructQueryPath(ENDPOINTS.PROJECT_TICKETS, { projectId }),
    ticketBody
  );
}

export async function updateTicket(
  ticketId: number,
  ticketBody: Partial<TicketBody>
) {
  return axios.patch(
    constructQueryPath(ENDPOINTS.TICKET, { ticketId: `${ticketId}` }),
    ticketBody
  );
}

export async function downloadTicketAttachment(
  ticketId: number,
  attachment: string
) {
  const res = (await axios.post(
    constructQueryPath(ENDPOINTS.TICKET, {
      ticketId: `${ticketId}`,
    }),
    {
      action: 'download_attachment',
      attachment,
    }
  )) as AxiosResponse;

  window.open(res.data.download_url, '_blank');
}

export async function updateAttachments(ticketId: number, attachments: File[]) {
  const formData = new FormData();

  formData.append('action', 'upload_attachments');

  attachments.forEach((attachment, index) => {
    formData.append(`attachment_${index}`, attachment);
  });

  return axios.post(
    constructQueryPath(ENDPOINTS.TICKET, { ticketId: `${ticketId}` }),
    formData
  );
}

export async function updateCommentAttachments(
  ticketId: number,
  commentId: number,
  attachments: File[]
) {
  const formData = new FormData();

  formData.append('action', 'upload_attachments');

  attachments.forEach((attachment, index) => {
    formData.append(`attachment_${index}`, attachment);
  });

  return axios.post(
    constructQueryPath(ENDPOINTS.TICKET_COMMENT, {
      ticketId: `${ticketId}`,
      commentId: `${commentId}`,
    }),
    formData
  );
}

export interface TicketEstimateBody {
  job_type: string;
  time: number;
}

export async function addTicketEstimate(
  ticketId: number,
  ticketEstimateBody: Partial<TicketEstimateBody>
) {
  await axios.put(
    constructQueryPath(ENDPOINTS.TICKET_ESTIMATES, { ticketId: `${ticketId}` }),
    ticketEstimateBody
  );
}

export interface TicketCommentBody {
  content: string;
}

export async function addTicketComment(
  ticketId: number | string,
  ticketCommentBody: Partial<TicketCommentBody>
) {
  return axios.put(
    constructQueryPath(ENDPOINTS.TICKET_COMMENTS, { ticketId: `${ticketId}` }),
    ticketCommentBody
  ) as Promise<AxiosResponse<{ comment: TicketComment }>>;
}

export async function editTicketEstimate(
  ticketId: number,
  estimateId: number,
  ticketEstimateBody: Partial<TicketEstimateBody>
) {
  await axios.patch(
    constructQueryPath(ENDPOINTS.TICKET_ESTIMATE, {
      ticketId: `${ticketId}`,
      estimateId: `${estimateId}`,
    }),
    ticketEstimateBody
  );
}

export async function deleteTicketEstimate(
  ticketId: number,
  estimateId: number
) {
  await axios.delete(
    constructQueryPath(ENDPOINTS.TICKET_ESTIMATE, {
      ticketId: `${ticketId}`,
      estimateId: `${estimateId}`,
    })
  );
}

export interface AssignmentBody {}

export async function updateAssignment({
  body,
  assignmentId,
}: {
  assignmentId: string;
  body: Partial<AssignmentBody>;
}) {
  await axios.patch(
    constructQueryPath(ENDPOINTS.TICKET_ASSIGNMENT, { assignmentId }),
    body
  );
}

export async function updateTicketSubscription({
  ticketId,
  userId,
  subscribe = true,
}: {
  ticketId: string;
  userId: string;
  subscribe: boolean;
}) {
  return axios.post(
    constructQueryPath(ENDPOINTS.TICKET, {
      ticketId,
    }),
    {
      action: subscribe ? 'subscribe' : 'unsubscribe',
      user_id: userId,
    }
  );
}
