import { createStyles, Group, rem, Box } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';

import { SelectOption } from '../../types';

interface SelectTargetProps {
  selected: SelectOption;
  opened: boolean;
  readonly?: boolean;
}

const useStyles = createStyles(
  (theme, { opened, readonly }: { opened: boolean; readonly: boolean }) => ({
    control: {
      width: '100%',
      display: 'flex',
      height: '36px',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: `${theme.spacing.sm} ${theme.spacing.md}`,
      borderRadius: theme.radius.sm,
      border: `${rem(1)} solid ${
        theme.colorScheme === 'dark'
          ? theme.colors.dark[6]
          : theme.colors.gray[4]
      }`,
      transition: 'background-color 150ms ease',
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[opened ? 5 : 6]
          : opened
          ? theme.colors.gray[0]
          : theme.white,

      '&:hover': {
        backgroundColor:
          theme.colorScheme === 'dark'
            ? theme.colors.dark[5]
            : theme.colors.gray[0],
      },
    },

    label: {
      fontWeight: 500,
      fontSize: theme.fontSizes.sm,
    },

    icon: {
      transition: 'transform 150ms ease',
      transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
      visibility: readonly ? 'hidden' : 'visible',
    },
  })
);

export default function SelectTarget({
  selected,
  opened,
  readonly,
}: SelectTargetProps) {
  const { classes } = useStyles({ opened, readonly: Boolean(readonly) });

  return (
    <Box className={classes.control}>
      <Group spacing="xs">
        <span className={classes.label}>{selected?.label}</span>
      </Group>
      <IconChevronDown size="1rem" className={classes.icon} stroke={1.5} />
    </Box>
  );
}
