import { useMemo, useState } from 'react';
import { SelectItem } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';

import { BaseAutocompleteProps } from '../../types';

import BaseAutocomplete from '../BaseAutocomplete';

import useTickets from 'hooks/tickets/useTickets';
import getTicketDisplay from 'helpers/display/getTicketDisplay';

interface TicketAutocompleteProps extends BaseAutocompleteProps {}

export default function TicketAutocomplete({
  ...props
}: TicketAutocompleteProps) {
  const [searchValue, setSearchValue] = useState('');
  const [debounced] = useDebouncedValue(searchValue, 200);
  const { data, isLoading } = useTickets({
    params: { search: `title|${debounced}` },
  });

  const options: SelectItem[] = useMemo(() => {
    return (
      data?.tickets?.map((ticket) => ({
        value: `${ticket.id}`,
        label: getTicketDisplay(ticket),
        group: `${ticket.project?.title}`,
      })) || []
    );
  }, [data]);

  return (
    <BaseAutocomplete
      {...props}
      data={options}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      loading={isLoading}
    />
  );
}
