import { useMemo } from 'react';

import { SelectOption, SelectProps } from '../../types';
import BaseSelect from '../BaseSelect';

import useClients from 'hooks/clients/useClients';

interface ClientsSelectProps extends SelectProps {
  defaultFirst?: boolean;
}

export default function ClientsSelect({
  defaultFirst,
  onChange,
  defaultValue,
  label,
  readonly,
  ...props
}: ClientsSelectProps) {
  const { data } = useClients();

  const options: SelectOption[] = useMemo(() => {
    return (
      data?.clients?.map((client) => {
        return {
          value: client.id as number,
          label: client.name,
        };
      }) || []
    );
  }, [data]);

  return (
    <BaseSelect
      defaultFirst={defaultFirst}
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      label={label}
      readonly={readonly}
      {...props}
    />
  );
}
