import {
  IconTriangleSquareCircle,
  IconUser,
  IconMan,
  IconSpeakerphone,
  IconUsers,
  IconFileReport,
  IconClock,
  IconSettings,
  IconUserCog,
  IconHistory,
  IconFileInvoice,
  IconReportMoney,
} from '@tabler/icons-react';

import { MODULES } from 'constants/app';

const CONFIG = {
  modules: {
    [MODULES.PROJECTS]: {
      href: '/projects',
      Icon: IconTriangleSquareCircle,
    },
    [MODULES.ASSIGNMENTS]: {
      href: '/assignments',
      Icon: IconUser,
    },
    [MODULES.CLIENTS]: {
      href: '/clients',
      Icon: IconMan,
    },
    [MODULES.MANAGE]: {
      href: '/manage',
      Icon: IconSpeakerphone,
    },
    [MODULES.PEOPLE]: {
      href: '/people',
      Icon: IconUsers,
    },
    [MODULES.REPORTS]: {
      href: '/reports',
      Icon: IconFileReport,
    },
    [MODULES.TIMESHEET]: {
      href: '/timesheet',
      Icon: IconClock,
    },
    [MODULES.ACTIVITY]: {
      href: '/activity',
      Icon: IconHistory,
    },
    [MODULES.SETTINGS]: {
      href: '/settings',
      Icon: IconSettings,
    },
    [MODULES.ADMIN]: {
      href: '/admin/',
      Icon: IconUserCog,
    },
    [MODULES.INVOICES]: {
      href: '/invoices/',
      Icon: IconFileInvoice,
    },
    [MODULES.BILLING_DASHBOARD]: {
      href: '/billing-dashboard',
      Icon: IconReportMoney,
    },
  },
  breadcrumbs: {
    project: {
      re: /\/projects\/\d+/,
      display: ':projectTitle',
    },
    assignments: {
      re: /\/assignments/,
      display: 'Assignments (:user)',
    },
    reports: {
      re: /\/reports\/\d+/,
      display: ':report Report',
    },
  },
};

export default CONFIG;
