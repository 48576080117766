import isEmpty from 'lodash/isEmpty';

/**
 * constructs a path from an endpoint
 * @param path - api path to query from, example /users/:userId
 * @param params - map of params to inject into endpoint, must match :key, example :userId
 */
export default function constructQueryPath(
  path: string,
  params?: { [key: string]: string }
): string {
  let apiPath = path.trim();

  if (typeof params === 'object' && !isEmpty(params)) {
    Object.entries(params).forEach(([key, value]) => {
      apiPath = apiPath.replace(`:${key}`.trim(), `${value}`.trim());
    });
  }

  return apiPath;
}
