import { useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { MODULES } from 'constants/app';
import useAuthStore from 'hooks/store/useAuthStore';

export default function AuthGuard() {
  const { isAuthenticated, hasPinged, ping } = useAuthStore();

  // may need to be updated to handle non-auth modules
  const onModuleRoute = Object.values(MODULES).find((m) =>
    window.location.href.includes(m)
  );

  // check if user is authenticated on initial load only
  useEffect(() => {
    if (!isAuthenticated && !hasPinged) {
      ping();
    }
  }, [hasPinged, isAuthenticated, ping]);

  if (!isAuthenticated && !hasPinged) return <></>;

  if (isAuthenticated && !onModuleRoute) {
    return <Navigate to="/" />;
  }

  if (!isAuthenticated && onModuleRoute) {
    return <Navigate to="/auth/login" />;
  }

  return <Outlet />;
}
