import { useState, useEffect, useCallback, ChangeEventHandler } from 'react';

import getTimeValueFromInput from 'helpers/data/getTimeValueFromInput';
import getHourDisplay from 'helpers/display/getHourDisplay';

interface TimeInputHookParams {
  defaultValue?: number;
}

export default function useTimeInput(params?: TimeInputHookParams) {
  const { defaultValue } = params || {};

  const [timeDisplay, setTimeDisplay] = useState(
    defaultValue ? getHourDisplay(defaultValue) : ''
  );
  const [value, setValue] = useState(defaultValue || 0);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setTimeDisplay(e.target.value);
    },
    []
  );

  const handleBlur = useCallback(() => {
    const timeValue = getTimeValueFromInput(timeDisplay);
    setValue(timeValue);
    setTimeDisplay(getHourDisplay(timeValue));
  }, [timeDisplay]);

  useEffect(() => {
    if (defaultValue !== undefined && defaultValue !== value) {
      setValue(defaultValue);
      setTimeDisplay(getHourDisplay(defaultValue || 0));
    }

    // eslint-disable-next-line
  }, [defaultValue]);

  return {
    value,
    inputValue: timeDisplay,
    handleChange,
    handleBlur,
  };
}
