import { useCallback } from 'react';

import BaseTable from 'components/common/Table/variants/BaseTable';

import { TableColumn } from 'components/common/Table/types';
import { InvoicePayment } from 'types/api';
import { BaseTableProps } from 'components/common/Table/types';
import { AUTH_USER_ID } from 'constants/api';

import useUser from 'hooks/users/useUser';

import getCurrencyDisplay from 'helpers/display/getCurrencyDisplay';
import getUserFullName from 'helpers/display/getUserFullName';
import useInstanceConfig from 'hooks/instance/useInstanceConfig';

export interface InvoicePaymentsTableProps {
  payments: InvoicePayment[];
  loading?: boolean;
}

const columns: TableColumn[] = [
  {
    id: 'amount',
    display: 'Amount',
  },
  {
    id: 'created_by',
    display: 'Added By',
  },
  {
    id: 'datetime_created',
    display: 'Creation DateTime',
  },
];

export default function InvoicePaymentsTable({
  payments,
  loading,
}: InvoicePaymentsTableProps) {
  const { formatUserTimezone } = useUser({ userId: AUTH_USER_ID });
  const { dateDisplay } = useInstanceConfig();

  const getDataDisplay: BaseTableProps<InvoicePayment>['getDataDisplay'] =
    useCallback(
      (key: string, row: InvoicePayment) => {
        const rowKey = key;

        if (key === 'amount') {
          return getCurrencyDisplay((row?.amount || 0) / 100);
        }

        if (key === 'created_by') {
          return getUserFullName(row.created_by_user);
        }

        if (key === 'datetime_created') {
          return formatUserTimezone(
            new Date(row.datetime_created || ''),
            `${dateDisplay} h:mm a zzz`
          );
        }

        return `${row[rowKey as keyof InvoicePayment]}`;
      },
      [dateDisplay, formatUserTimezone]
    );

  return (
    <BaseTable<InvoicePayment>
      placeholder="No payments made."
      columns={columns}
      rows={payments}
      getDataDisplay={getDataDisplay}
      loading={loading}
      tableProps={{
        sx: {
          tableLayout: 'fixed',
        },
      }}
    />
  );
}
