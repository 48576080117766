import { useMemo } from 'react';
import debounce from 'lodash/debounce';

export default function useDebounce(
  callback: (...args: any) => any,
  delay = 250
): (...args: any) => any {
  const debouncedCallback = useMemo(
    () => debounce(callback, delay),
    [callback, delay]
  );

  return debouncedCallback;
}
