export const Logger = {
  logMessage: (
    message: string,
    severity: 'warn' | 'info' | 'error',
    includeProd = false
  ) => {
    if (!includeProd && process.env.NODE_ENV === 'production') {
      return;
    }

    if (severity === 'warn') {
      // eslint-disable-next-line
      return console.warn(message);
    }

    if (severity === 'error') {
      // eslint-disable-next-line
      return console.error(message);
    }

    if (severity === 'info') {
      // eslint-disable-next-line
      return console.log(message);
    }
  },
  warn(message: string, includeProd = false) {
    this.logMessage(message, 'warn', includeProd);
  },
  info(message: string, includeProd = false) {
    this.logMessage(message, 'info', includeProd);
  },
  error(message: string, includeProd = false) {
    this.logMessage(message, 'error', includeProd);
  },
};
