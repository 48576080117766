import { Droppable } from 'react-beautiful-dnd';

import { TicketGroup } from './types';
import { DONE_COLUMN_LIMIT } from 'constants/project';

import TicketGroupListItem from './ListItem';

export interface TicketGroupBodyProps {
  ticketGroup: TicketGroup;
}

export default function TicketGroupBody({ ticketGroup }: TicketGroupBodyProps) {
  const getItems = () => {
    const isDone = ticketGroup.isDone;

    if (isDone) {
      return ticketGroup.items.slice(0, DONE_COLUMN_LIMIT);
    }

    return ticketGroup.items;
  };

  return (
    <Droppable droppableId={`dnd-list-${ticketGroup.id}`} direction="vertical">
      {(provided) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={{ minHeight: '48px' }}
        >
          {getItems().map((ticket, index) => (
            <TicketGroupListItem
              key={ticket.id}
              index={index}
              ticket={ticket}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}
