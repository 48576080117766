import { create } from 'zustand';
import { arrayMoveImmutable } from 'array-move';

import { ClientProject } from 'types/api';

interface ProjectsPageStoreState {
  projects: ClientProject[];
  setProjects: (project: ClientProject[]) => void;
  updateProject: (
    projectId: number,
    updateBody: Partial<ClientProject>
  ) => void;
  moveProject: (sourceIndex: number, destIndex: number) => void;
  sortProjects: () => ClientProject[];
}

const useProjectsPageStore = create<ProjectsPageStoreState>((set, get) => ({
  projects: [],
  setProjects: (projects) => {
    set((state) => ({ ...state, projects }));
  },
  updateProject: (projectId, updateBody) => {
    const _state = get();
    const projects = _state.projects;
    const foundIndex = projects.findIndex(
      (project) => project.id === projectId
    );

    if (foundIndex < 0) return;

    projects[foundIndex] = {
      ...projects[foundIndex],
      ...updateBody,
    };

    set((state) => ({ ...state, projects }));
  },
  moveProject: (sourceIndex, destIndex) => {
    const _state = get();
    let projects = _state.projects;
    projects = arrayMoveImmutable(projects, sourceIndex, destIndex).map(
      (project, index) => ({
        ...project,
        position: index,
      })
    );

    set((state) => ({ ...state, projects }));
  },
  sortProjects: () => {
    const _state = get();
    let projects = _state.projects;
    projects = projects.slice().sort((a, b) => {
      const aIsBookmarked = a.bookmarked;
      const bIsBookmarked = b.bookmarked;

      if (aIsBookmarked && !bIsBookmarked) return -1;
      if (!aIsBookmarked && bIsBookmarked) return 1;

      return 0;
    });

    _state.setProjects(projects);

    return projects;
  },
}));

export default useProjectsPageStore;
