import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Loader } from '@mantine/core';
import { DatePickerInput, DateValue } from '@mantine/dates';
import format from 'date-fns/format';
import { notifications } from '@mantine/notifications';

import BillingRecordsListInfinite from 'components/shared/BillingRecordsList/Infinite';
import UserMultiSelect from 'components/common/MultiSelect/variants/UserMultiSelect';
import BillableSelect from 'components/common/Select/variants/BillableSelect';

import useBillingRecordsInfinite from 'hooks/billing/useBillingRecordsInfinite';
import useBillingRecordsData from 'hooks/data/useBillingRecordsData';
import useDownloadStore from 'hooks/store/useDownloadStore';

import { BILLING_TYPE } from 'constants/billing';
import { DATE_FORMATS } from 'constants/date';

import { downloadBillingRecordsCSV } from 'utils/billing';
import getCurrencyDisplay from 'helpers/display/getCurrencyDisplay';

interface TimeHistoryProps {
  projectId: string;
}

export default function TimeHistory({ projectId }: TimeHistoryProps) {
  const { pushDownload } = useDownloadStore();
  const [userIds, setUserIds] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<DateValue[]>([]);
  const [billable, setBillable] = useState<string>('');

  const isInfinite = useMemo(
    () => dateRange.filter((d) => d).length === 0,
    [dateRange]
  );

  const hookParams = useMemo(() => {
    return {
      project_id: projectId,
      billing_type: BILLING_TYPE.TIME,
      fields: 'id,date,billing_amount,amount,billable',
      page: isInfinite ? 1 : null,
      page_size: isInfinite ? 30 : null,
      billable: billable,
      user_ids: userIds.join(','),
      date_start: dateRange[0] && format(dateRange[0], DATE_FORMATS.DATE_KEY),
      date_end: dateRange[1] && format(dateRange[1], DATE_FORMATS.DATE_KEY),
      embed: 'billing_amount',
    };
  }, [billable, dateRange, isInfinite, projectId, userIds]);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    editBillingRecord,
    deleteBillingRecord,
    isFetched,
    refetch,
    isLoading,
  } = useBillingRecordsInfinite(
    {
      params: hookParams,
    },
    !projectId
  );

  const { totalBillingAmount, totalBillingHours, totalNonBillingHours } =
    useBillingRecordsData({
      billingRecords: data?.pages?.[0]?.billing_records,
    });

  const totalsDisplay = useMemo(() => {
    return [
      totalBillingAmount && `${getCurrencyDisplay(totalBillingAmount / 100)}`,
      totalBillingHours && `${totalBillingHours / 60} HR (billable)`,
      totalNonBillingHours && `${totalNonBillingHours / 60} HR (non-billable)`,
    ].filter((b) => b);
  }, [totalBillingAmount, totalBillingHours, totalNonBillingHours]);

  useEffect(() => {
    if (projectId) {
      refetch();
    }
  }, [projectId, refetch]);

  return (
    <>
      <Box
        sx={(theme) => ({
          paddingLeft: theme.spacing.md,
          paddingRight: theme.spacing.md,
          display: 'flex',
          columnGap: theme.spacing.md,
          height: '64px',
        })}
      >
        <DatePickerInput
          type="range"
          label="Date Range"
          placeholder="Date Range"
          sx={{ minWidth: '200px' }}
          value={dateRange as [DateValue, DateValue]}
          onChange={(value) => setDateRange(value)}
        />
        <Box sx={{ width: '200px' }}>
          <BillableSelect
            label="Billable"
            defaultValue={billable}
            onChange={(option) => setBillable(option.value as string)}
          />
        </Box>
        <UserMultiSelect
          label="Users"
          placeholder="Users"
          defaultValue={userIds}
          onChange={(value) => setUserIds(value)}
        />
        <Button
          sx={{
            marginTop: 'auto',
            marginBottom: '4px',
            height: '36px',
            marginLeft: 'auto',
          }}
          disabled={!isFetched}
          onClick={() => {
            pushDownload({
              filename: `billingrecords.${Date.now()}.csv`,
              download: async () => {
                await downloadBillingRecordsCSV(hookParams);
                notifications.show({
                  message:
                    'CSV has been successfully downloaded to your computer!',
                  variant: 'info',
                });
              },
            });
          }}
        >
          Export to CSV
        </Button>
      </Box>
      <Box
        sx={(theme) => ({
          paddingLeft: theme.spacing.md,
          fontSize: theme.fontSizes.xs,
          color: theme.colors.gray[7],
          lineHeight: '100%',
          paddingTop: theme.spacing.xs,
          height: '16px',
          visibility: isInfinite ? 'hidden' : undefined,
        })}
      >
        {totalsDisplay.join(' | ')}
      </Box>
      <Box sx={{ display: 'flex' }}>
        {isLoading ? (
          <Loader sx={{ margin: 'auto' }} />
        ) : (
          <Box sx={{ flex: 1 }}>
            <BillingRecordsListInfinite
              hasNextPage={Boolean(isInfinite && hasNextPage)}
              isNextPageLoading={isFetchingNextPage}
              pages={data?.pages}
              loadNextPage={fetchNextPage}
              editBillingRecord={editBillingRecord}
              deleteBillingRecord={deleteBillingRecord}
              variant="time"
              fields={['amount', 'ticket', 'description', 'job_type']}
              isFetched={isFetched}
              isInfinite={isInfinite}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
