import BaseSelect from '../BaseSelect';
import TextSelectTarget from './TextSelectTarget';

import { JobTypeSelectProps } from './types';

export default function JobTypeSelectUI({
  onChange,
  defaultValue,
  label,
  variant,
  options,
  ...rest
}: JobTypeSelectProps) {
  let TargetComponent: JobTypeSelectProps['TargetComponent'] = undefined;
  if (variant === 'text') {
    TargetComponent = TextSelectTarget;
  }

  return (
    <BaseSelect
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      label={label}
      TargetComponent={TargetComponent}
      {...rest}
    />
  );
}
