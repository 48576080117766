import { Box, Text } from '@mantine/core';

import { SelectOption } from '../../types';

interface SelectTargetProps {
  selected: SelectOption;
  opened: boolean;
}

export default function TextSelectTarget({ selected }: SelectTargetProps) {
  return (
    <Box sx={{ border: '1px solid white' }}>
      <Text color="teal" align="center" weight="bold">
        {selected?.label}
      </Text>
    </Box>
  );
}
