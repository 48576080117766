import routes from 'routes';
import useModules from 'hooks/modules/useModules';
import { MODULES } from 'constants/app';
import useAuthStore from 'hooks/store/useAuthStore';

export default function useRoutes() {
  const { data, isLoading } = useModules();
  const { isAuthenticated } = useAuthStore();
  let filteredRoutes = routes;

  // just return routes for non-auth users, handle with auth guards
  if (isAuthenticated) {
    filteredRoutes = routes.filter((route) => {
      return (
        data?.modules?.find((m) => m.key === route.path) ||
        !Object.values(MODULES).includes(route.path as string)
      );
    });
  }

  // filter routes for authenticated users
  return {
    routes: filteredRoutes,
    loading: isLoading,
  };
}
