import { Loader, Timeline, Box, Text, ScrollArea } from '@mantine/core';
import { useCallback } from 'react';

import { BaseActivityListProps } from '../types';

import useTicketActivity from 'hooks/tickets/useTicketActivity';

import TicketActivityListItem from '../components/TicketActivityListItem';
import { Activity } from 'types/api';
import useActivity from 'hooks/common/useActivity';

interface TicketActivityListProps extends BaseActivityListProps {
  ticketId?: string | number;
  scrollable?: boolean;
}

function Highlighted({ message }: { message: string }) {
  return (
    <Text component="span" color="blue">
      {message}
    </Text>
  );
}

export default function TicketActivityList({
  ticketId,
  scrollable = true,
}: TicketActivityListProps) {
  const { data, isLoading } = useTicketActivity({
    ticketId,
    storeKey: 'edit-ticket-modal-activity',
  });
  const { getActionDisplay, getDateDisplay, getTargetDisplay, getUserDisplay } =
    useActivity({});
  const isEmpty = !data?.activity_items?.length;

  const getTimelineItemProps = useCallback(
    (activityItem: Activity) => {
      const title = (
        <Text size="sm" weight="bold">
          <Text component="span">
            {getTargetDisplay(activityItem)} {getActionDisplay(activityItem)} by
          </Text>{' '}
          <Highlighted message={getUserDisplay(activityItem)} />{' '}
          <Text component="span">{getDateDisplay(activityItem)}</Text>
        </Text>
      );

      return {
        title,
      };
    },
    [getActionDisplay, getDateDisplay, getTargetDisplay, getUserDisplay]
  );

  if (isLoading) return <Loader />;

  const Container = scrollable ? ScrollArea : Box;

  return (
    <Box
      sx={() => ({
        overflow: 'hidden',
        height: scrollable ? '100%' : 'auto',
        display: 'flex',
        width: '100%',
      })}
    >
      <Container sx={(theme) => ({ flex: 1, paddingRight: theme.spacing.md })}>
        {isEmpty ? (
          <Text sx={{ textAlign: 'center' }}>No activity available.</Text>
        ) : (
          <Timeline sx={{ width: '100%' }}>
            {data?.activity_items?.map((activityItem) => (
              <Timeline.Item
                key={activityItem.id}
                bullet={<Box />}
                bulletSize={12}
                {...getTimelineItemProps(activityItem)}
              >
                <TicketActivityListItem activityItem={activityItem} />
              </Timeline.Item>
            ))}
          </Timeline>
        )}
      </Container>
    </Box>
  );
}
