import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { notifications } from '@mantine/notifications';

import { ENDPOINTS } from 'constants/api';
import type { Client } from 'types/api';
import { ApiQueryKey } from 'types/api/query';

import type { AddClientBody } from 'utils/client';
import * as client from 'utils/client';

import useApiQuery from 'hooks/api/useApiQuery';
import useApiStore from 'hooks/store/useApiStore';

interface ClientsHookParams {
  storeKey?: ApiQueryKey;
  params?: any;
}

export default function useClients(hookParams?: ClientsHookParams) {
  const { invalidate } = useApiStore();
  const { storeKey, params } = hookParams || {};

  const queryResult = useApiQuery<{ clients?: Client[] }>({
    path: ENDPOINTS.CLIENTS,
    storeKey: storeKey,
    axiosConfig: {
      params,
    },
  });

  const invalidateQueries = useCallback(() => {
    queryResult.refetch();
    invalidate('list-clients');
    invalidate('list-clients-archived');
  }, [invalidate, queryResult]);

  const addClient = useCallback(
    async (clientBody: Partial<AddClientBody>) => {
      try {
        await client.addClient(clientBody);
        invalidateQueries();
        notifications.show({
          message: 'Client successfully added!',
          color: 'green',
        });
        return true;
      } catch (err) {
        const error = err as AxiosError;
        notifications.show({
          message: error.message,
          color: 'red',
        });
      }

      return false;
    },
    [invalidateQueries]
  );

  const updateClient = useCallback(
    async (clientId: number, clientBody: Partial<AddClientBody>) => {
      try {
        await client.editClient(clientId, clientBody);
        invalidateQueries();
        notifications.show({
          message: 'Client successfully updated!',
          color: 'green',
        });
        return true;
      } catch (err) {
        const error = err as AxiosError;
        notifications.show({
          message: error.message,
          color: 'red',
        });
      }

      return false;
    },
    [invalidateQueries]
  );

  return {
    ...queryResult,
    addClient,
    updateClient,
  };
}
