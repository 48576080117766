import { DatePickerInputProps } from '@mantine/dates';
import BaseDateRangePicker from './variants/BaseDateRangePicker';
import ArrowDateRangePicker from './variants/ArrowDateRangePicker';

interface DateRangePickerProps extends DatePickerInputProps<'range'> {
  variant?: 'base' | 'arrow';
}

export default function DateRangePicker({
  variant,
  ...props
}: DateRangePickerProps) {
  if (variant === 'arrow') {
    return <ArrowDateRangePicker {...props} />;
  }

  return <BaseDateRangePicker {...props} />;
}
