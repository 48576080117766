import { useParams } from 'react-router-dom';
import { Formik, useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { Stack } from '@mantine/core';
import capitalize from 'lodash/capitalize';
import format from 'date-fns/format';

import { InputParam } from 'types/app';

import ConsoleLayout from 'layouts/ConsoleLayout';
import ReportTable from './components/ReportTable';
import Filters from './components/Filters';

import useReport from 'hooks/reports/useReport';
import useReportRows from 'hooks/reports/useReportRows';
import useConfigStore from 'hooks/store/useConfigStore';
import { DATE_FORMATS } from 'constants/date';

interface ReportPageContentProps {
  reportId: string;
}

function ReportPageContent({ reportId }: ReportPageContentProps) {
  const { values, status, setStatus } = useFormikContext<any>();
  const { data, isLoading } = useReportRows<any>({ reportId, params: values });

  useEffect(() => {
    if (data?.filename && data.filename !== status.filename) {
      setStatus({
        ...status,
        filename: data.filename,
      });
    }

    // eslint-disable-next-line
  }, [data]);

  return <ReportTable rows={data?.rows || []} loading={isLoading} />;
}

export default function ReportPage() {
  const { reportId } = useParams();
  const { setBreadcrumbsVar } = useConfigStore();
  const { data, isLoading } = useReport({ reportId: reportId as string });
  const report = data?.report;

  const reportParams = useMemo(() => {
    return report?.report_params || {};
  }, [report]);

  const initialValues = useMemo(() => {
    const _initialValues: any = {};

    Object.entries(reportParams).forEach(([k, v]) => {
      const value = v as InputParam;
      _initialValues[k] = value.default_value || '';

      if (value.type === 'date' && !_initialValues[k]) {
        _initialValues[k] = format(new Date(), DATE_FORMATS.DATE_KEY);
      }
    });

    return _initialValues;
  }, [reportParams]);

  const filterParams = useMemo(() => {
    const params: InputParam[] = [];

    Object.entries(reportParams).forEach(([k, v]) => {
      const value = v as InputParam;
      const defaultValue = value.default_value || '';

      params.push({
        name: k,
        type: value.type || 'text',
        default_value: defaultValue,
        label: value.label || capitalize(k.replace('_', ' ')),
      });
    });

    return params;
  }, [reportParams]);

  useEffect(() => {
    if (data?.report?.name) {
      setBreadcrumbsVar('report', data?.report?.name);
    }
  }, [data, setBreadcrumbsVar]);

  return (
    <ConsoleLayout loading={isLoading}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        initialStatus={{
          filename: 'report.csv',
        }}
        onSubmit={() => {}}
      >
        {({ status }) => (
          <Stack>
            <Filters
              reportId={reportId || ''}
              reportParams={filterParams}
              filename={status.filename}
            />
            <ReportPageContent reportId={reportId || ''} />
          </Stack>
        )}
      </Formik>
    </ConsoleLayout>
  );
}
