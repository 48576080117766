import { ReactNode, useState } from 'react';
import {
  Card,
  Text,
  ActionIcon,
  Collapse,
  Group,
  Box,
  Loader,
} from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import AvailabilityRecordsTable from 'components/common/Table/variants/AvailabilityRecordsTable';

import useAvailabilityRecords from 'hooks/users/useAvailabilityRecords';

const columns = [
  {
    id: 'instance_user',
    display: 'Employee',
  },
  {
    id: 'date_start',
    display: 'Days Off',
  },
  {
    id: 'description',
    display: 'Reason',
  },
  {
    id: 'actions',
    display: '',
  },
];

interface AvailabilityGroupGroupProps {
  title?: string | ReactNode;
  params?: any;
}

export default function AvailabilityGroup({
  title,
  params,
}: AvailabilityGroupGroupProps) {
  const [expanded, setExpanded] = useState(true);
  const { data, isLoading } = useAvailabilityRecords({
    params,
  });

  if (!isLoading && !data?.availability_records?.length) {
    return <></>;
  }

  return (
    <Card withBorder shadow="md" padding={0}>
      <Group sx={(theme) => ({ padding: theme.spacing.xs })}>
        <ActionIcon onClick={() => setExpanded(!expanded)}>
          {expanded ? <IconChevronUp /> : <IconChevronDown />}
        </ActionIcon>
        <Text sx={{ fontWeight: 'bold', margin: 'auto 0', lineHeight: '100%' }}>
          <Group>
            {title} ({data?.total})
          </Group>
        </Text>
      </Group>
      {!isLoading ? (
        <Collapse in={expanded}>
          <AvailabilityRecordsTable
            availabilityRecords={data?.availability_records || []}
            columns={columns}
          />
        </Collapse>
      ) : (
        <Box sx={{ display: 'flex' }}>
          <Loader sx={{ margin: 'auto' }} />
        </Box>
      )}
    </Card>
  );
}
