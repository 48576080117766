import { useCallback, useMemo } from 'react';
import set from 'lodash/set';
import get from 'lodash/get';

import useClients from 'hooks/clients/useClients';
import useInstanceConfig from 'hooks/instance/useInstanceConfig';
import useProjects from 'hooks/projects/useProjects';
import useUsers from 'hooks/users/useUsers';

import { BillingRate } from 'types/api';
import { BillingRatesAggregateHookOutput } from './types';

interface BillingRatesAggregateHookParams {
  billingRates?: Partial<BillingRate>[];
}

const setBillingRate = (
  data: BillingRatesAggregateHookOutput,
  clientId: string | number,
  projectId: string | number,
  employeeId: string | number,
  jobType: string,
  billingRate?: Partial<BillingRate> | null
) => {
  set(
    data.client,
    `${clientId}.project.${projectId}.employee.${employeeId}.jobType.${jobType}`,
    billingRate || {
      rate: 0,
      client_id: clientId || null,
      project_id: projectId || null,
      employee_id: employeeId || null,
      job_type: jobType || null,
    }
  );
};

export default function useBillingRatesAggregate(
  hookParams?: BillingRatesAggregateHookParams
) {
  const { data: clientData } = useClients();
  const { data: projectData } = useProjects();
  const { data: employeeData } = useUsers();
  const { data: configData } = useInstanceConfig();
  const { billingRates } = hookParams || {};

  const aggregateData = useMemo(() => {
    const data: BillingRatesAggregateHookOutput = {
      client: {
        '': {
          project: {
            '': {
              employee: {
                '': {
                  jobType: {
                    '': { rate: 0 },
                  },
                },
              },
            },
          },
        },
      },
    };

    // fill empty billing rates
    clientData?.clients?.forEach((client) => {
      const clientId = client.id as number;
      const projectId = '';
      const employeeId = '';
      const jobType = '';

      setBillingRate(data, clientId, projectId, employeeId, jobType);
    });

    projectData?.projects?.forEach((project) => {
      const clientId = '';
      const projectId = project.id as number;
      const employeeId = '';
      const jobType = '';

      setBillingRate(data, clientId, projectId, employeeId, jobType);
    });

    employeeData?.users?.forEach((employee) => {
      const clientId = '';
      const projectId = '';
      const employeeId = employee.user_id;
      const jobType = '';

      setBillingRate(data, clientId, projectId, employeeId, jobType);
    });

    configData?.config?.job_types?.forEach((jobType) => {
      const clientId = '';
      const projectId = '';
      const employeeId = '';

      setBillingRate(data, clientId, projectId, employeeId, jobType);

      Object.keys(data.client[clientId].project[projectId].employee).forEach(
        (emId) => {
          setBillingRate(data, clientId, projectId, emId, jobType);
        }
      );
    });

    billingRates?.forEach((billingRate) => {
      const clientId = billingRate.client_id || '';
      const projectId = billingRate.project_id || '';
      const employeeId = billingRate.employee_id || '';
      const jobType = billingRate.job_type || '';

      setBillingRate(
        data,
        clientId,
        projectId,
        employeeId,
        jobType,
        billingRate
      );
    });

    return data;
  }, [
    billingRates,
    clientData?.clients,
    configData?.config?.job_types,
    employeeData?.users,
    projectData?.projects,
  ]);

  const getEmployeeBillingRates = useCallback(
    (employeeId: string | number) => {
      const clientId = '';
      const projectId = '';

      return get(
        aggregateData.client,
        `${clientId}.project.${projectId}.employee.${employeeId}.jobType`
      );
    },
    [aggregateData.client]
  );

  const getClientBillingRates = useCallback(
    (clientId: number | string) => {
      const projectId = '';
      const employeeId = '';

      return get(
        aggregateData.client,
        `${clientId}.project.${projectId}.employee.${employeeId}.jobType`
      );
    },
    [aggregateData.client]
  );

  const getProjectBillingRates = useCallback(
    (projectId: number | string) => {
      const clientId = '';
      const employeeId = '';

      return get(
        aggregateData.client,
        `${clientId}.project.${projectId}.employee.${employeeId}.jobType`
      );
    },
    [aggregateData.client]
  );

  const getInstanceBillingRates = useCallback(() => {
    const clientId = '';
    const projectId = '';
    const employeeId = '';

    return get(
      aggregateData.client,
      `${clientId}.project.${projectId}.employee.${employeeId}.jobType`
    );
  }, [aggregateData.client]);

  return {
    rateMap: aggregateData,
    getEmployeeBillingRates,
    getInstanceBillingRates,
    getClientBillingRates,
    getProjectBillingRates,
  };
}
