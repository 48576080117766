import { useState } from 'react';

import BasePopOver from '../BasePopOver';
import PopOverDropdown from './components/PopOverDropdown';
import PopOverTarget from './components/PopOverTarget';

interface DatePopOverProps {
  defaultValue?: Date;
  onChange?: (date: Date) => Promise<void>;
}

export default function DatePopOver({
  defaultValue,
  onChange,
}: DatePopOverProps) {
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <BasePopOver
      TargetComponent={PopOverTarget}
      DropdownComponent={PopOverDropdown}
      popoverProps={{
        closeOnClickOutside: false,
        opened: opened,
      }}
      dropdownProps={{
        onClose: () => setOpened(false),
        onSubmit: onChange,
        defaultValue,
      }}
      targetProps={{
        onClick: () => setOpened(!opened),
      }}
      width={300}
    />
  );
}
