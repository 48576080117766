import { Collapse } from '@mantine/core';

import ChangeLog from 'components/common/ChangeLog';

import { Activity } from 'types/api';

import useActivity from 'hooks/common/useActivity';
import { useEffect } from 'react';

interface TicketActivityListItemProps {
  activityItem?: Activity;
  expanded?: boolean;
  setExpanded?: (expand: boolean) => void;
}

export default function TicketActivityListItem({
  activityItem,
  expanded,
  setExpanded,
}: TicketActivityListItemProps) {
  const { displayFields } = useActivity({
    activityItem,
  });

  // collapse items with more than one value by default
  useEffect(() => {
    try {
      if (
        expanded === undefined &&
        typeof activityItem?.change_message === 'string'
      ) {
        const changeMessage = JSON.parse(activityItem.change_message);
        const values = changeMessage.values;
        const valueCount = Object.keys(values).length;

        if (valueCount > 2) {
          setExpanded?.(false);
        }
      }
    } catch (err) {
      // pass
    }
  }, [activityItem?.change_message, expanded, setExpanded]);

  return (
    <Collapse in={expanded !== false}>
      <ChangeLog {...displayFields} actionFlag={activityItem?.action_flag} />
    </Collapse>
  );
}
