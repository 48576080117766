import { create } from 'zustand';
import NiceModal from '@ebay/nice-modal-react';

interface ModalStoreState {
  modalStack: string[];
  pushModal: <P>(modalId: string, args?: P) => void;
  popModal: (modalId?: string) => void;
  peekStack: () => string;
}

// manually controlled modal stack to handle nested modals
const useModalStore = create<ModalStoreState>((set, get) => ({
  modalStack: [],
  pushModal: <P>(modalId: string, args?: P) => {
    set((state) => ({ ...state, modalStack: [...state.modalStack, modalId] }));
    NiceModal.show(modalId, args);
  },
  popModal: (modalId?: string) => {
    const state = get();

    if (modalId) {
      if (state.peekStack() === modalId) {
        NiceModal.hide(modalId);
        set((state) => ({
          ...state,
          modalStack: state.modalStack.slice(0, -1),
        }));
      }
    }
  },
  peekStack: () => {
    return get().modalStack.slice(-1)[0];
  },
}));

export default useModalStore;
