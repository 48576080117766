import { Paper } from '@mantine/core';

import { ModalFooterProps } from '../../types';
import { MODAL_HEADER_HEIGHT } from 'constants/component';

export default function ModalFooter({ children }: ModalFooterProps) {
  return (
    <Paper
      withBorder
      sx={{ borderRadius: 0, padding: 8, height: MODAL_HEADER_HEIGHT }}
    >
      {children}
    </Paper>
  );
}
