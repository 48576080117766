import { Group, Button } from '@mantine/core';

import { ButtonGroupProps } from './types';

export default function ButtonGroup({
  buttonProps = [],
  id = 'button-group',
  position = 'center',
}: ButtonGroupProps) {
  return (
    <Group position={position}>
      {buttonProps.map((props, index) => (
        <Button key={`${id}-${index}`} {...props} />
      ))}
    </Group>
  );
}
