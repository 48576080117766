import constructQueryPath from 'helpers/api/constructQueryPath';
import axios from './axios';
import { ENDPOINTS } from 'constants/api';
import { Client } from 'types/api';

export type AddClientBody = Pick<
  Client,
  | 'name'
  | 'address_city'
  | 'address_country'
  | 'address_line1'
  | 'address_line2'
  | 'address_state'
  | 'address_zip'
  | 'email'
  | 'invoice_email'
  | 'note'
  | 'phone'
  | 'is_active'
> & {
  budget_amount?: number;
  budget_type?: 'monthly' | 'total';
};

export async function addClient(clientBody: Partial<AddClientBody>) {
  return axios.put(constructQueryPath(ENDPOINTS.CLIENTS), clientBody);
}

export async function editClient(
  clientId: number,
  clientBody: Partial<AddClientBody>
) {
  return axios.patch(
    constructQueryPath(ENDPOINTS.CLIENT, { clientId: `${clientId}` }),
    clientBody
  );
}
