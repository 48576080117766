import { useCallback, useEffect, useState } from 'react';
import { ActionIcon, Box, rem } from '@mantine/core';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useQueryClient } from 'react-query';

import { ProjectTicket } from 'types/api';
import { MODALS } from 'constants/component';

import BaseModal from '../BaseModal';
import EditTicketModalMainContent from './MainContent';
import EditTicketModalSideContent from './SideContent';

import useTicket from 'hooks/tickets/useTicket';
import useModalStore from 'hooks/store/useModalStore';
import useApiStore from 'hooks/store/useApiStore';
import useProjectPageStore from 'hooks/store/useProjectPageStore';
import { IconX } from '@tabler/icons-react';

interface EditTicketModalProps {
  ticket?: ProjectTicket;
  queryKey?: string;
}

function EditTicketModal({ ticket, queryKey }: EditTicketModalProps) {
  const modal = useModal();
  const queryClient = useQueryClient();
  const { invalidateAny } = useApiStore();
  const { popModal } = useModalStore();
  const { clearErrors, modalOpen, setModalOpen } = useProjectPageStore();
  const {
    data,
    isLoading,
    addTicketEstimate,
    editTicketEstimate,
    deleteTicketEstimate,
    updateTicketSubscription,
    updateTicket,
  } = useTicket({
    ticketId: ticket?.id,
    params: { embed: 'estimates|attachments' },
    storeKey: 'edit-ticket-modal',
  });
  const [isEditing, setIsEditing] = useState(false);

  const handleTicketUpdate: typeof updateTicket = useCallback(
    async (ticketId, ticketBody) => {
      const response = await updateTicket(ticketId, ticketBody);

      // only title change, re-sync all billing records
      if (ticketBody['title']) {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'billing-records',
        });
      }

      if (queryKey) {
        invalidateAny(queryKey);
      }

      return response;
    },
    [invalidateAny, queryClient, queryKey, updateTicket]
  );

  useEffect(() => {
    if (modalOpen !== modal.visible) {
      setModalOpen(modal.visible);
    }
  }, [modal.visible, modalOpen, setModalOpen]);

  return (
    <BaseModal
      id={MODALS.EDIT_TICKET_MODAL}
      hideHeader
      loading={isLoading}
      isOpen={modal.visible}
      onClose={() => {
        setModalOpen(false);
        popModal(MODALS.EDIT_TICKET_MODAL);
        window.history.replaceState({}, '', window.location.href.split('?')[0]);
        clearErrors();
      }}
      scrollable={false}
    >
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%',
          position: 'relative',
        }}
      >
        <Box sx={{ width: `calc(100% - ${rem(324)})` }}>
          <EditTicketModalMainContent
            ticket={data?.ticket}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            updateTicket={handleTicketUpdate}
          />
        </Box>
        <Box sx={{ width: rem(324) }}>
          <EditTicketModalSideContent
            ticket={data?.ticket}
            addTicketEstimate={addTicketEstimate}
            deleteTicketEstimate={deleteTicketEstimate}
            updateTicketSubscription={updateTicketSubscription}
            editTicketEstimate={editTicketEstimate}
          />
        </Box>
        {!isEditing && (
          <ActionIcon
            sx={(theme) => ({
              position: 'absolute',
              left: 1,
              top: 1,
              cursor: 'pointer',
              borderRadius: theme.radius.xl,
            })}
            variant="filled"
            onClick={() => popModal(MODALS.EDIT_TICKET_MODAL)}
            size="xs"
          >
            <IconX />
          </ActionIcon>
        )}
      </Box>
    </BaseModal>
  );
}

export default NiceModal.create((props) => <EditTicketModal {...props} />);
