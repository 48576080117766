import { createStyles } from '@mantine/core';

import { TableRowProps } from '../../types';

const useStyles = createStyles((theme) => ({
  rowData: {
    textAlign: 'center',
  },
  row: {
    userSelect: 'none',
    '&:hover': {
      backgroundColor: theme.colors.gray[1],
      cursor: 'pointer',
    },
    '&:active': {
      backgroundColor: theme.colors.gray[2],
    },
  },
}));

export default function TableRow<R extends { id?: number | string }>({
  row,
  columns,
  getDataDisplay = (key, row) => `${row[key as keyof R]}`,
  onClick,
}: TableRowProps<R>) {
  const { classes, cx } = useStyles();

  return (
    <tr
      className={cx({
        [classes.row]: Boolean(onClick),
      })}
      onClick={() => onClick?.(row)}
    >
      {columns.map((col) => (
        <td key={col.id} className={classes.rowData}>
          {getDataDisplay(col.id as keyof R, row)}
        </td>
      ))}
    </tr>
  );
}
