import { useMemo } from 'react';
import { Button, Grid } from '@mantine/core';
import { IconCirclePlus } from '@tabler/icons-react';

import JsonItem from '../components/JsonItem';
import { BaseJsonEditorProps } from '../types';

export default function BaseJsonEditor({
  value,
  getValueDisplay,
  addable,
}: BaseJsonEditorProps) {
  const json = useMemo(() => {
    try {
      return JSON.parse(value);
    } catch (err) {
      // pass
    }

    return {};
  }, [value]);

  return (
    <>
      <Grid columns={12}>
        {Object.entries(json).map(([key, value]) => (
          <JsonItem
            key={key}
            name={key}
            value={
              getValueDisplay?.({ ...(value as object), name: key }) ||
              `${value}` ||
              ''
            }
          />
        ))}
        {addable && (
          <Grid.Col xs={12}>
            <Button variant="light" size="xs" leftIcon={<IconCirclePlus />}>
              Add Display Item
            </Button>
          </Grid.Col>
        )}
      </Grid>
    </>
  );
}
