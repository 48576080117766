/**
 * gets the human-readable string of a given time
 * @param time - time expressed as a decimal
 * @return time string in the format hour:min
 */
export default function getHourDisplay(time: number): string {
  const hour = Math.floor(time / 60) || 0;
  const deci = time - hour * 60 || 0;

  return `${hour}:${deci.toString().padStart(2, '0')}`;
}
