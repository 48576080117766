import { Paper, Text, Group, Box } from '@mantine/core';

import { ConsoleLayoutActionsProps } from '../types';

export default function ConsoleLayoutActions({
  children,
  title,
}: ConsoleLayoutActionsProps) {
  return (
    <Paper
      sx={{
        borderRadius: 0,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 16,
        paddingRight: 16,
      }}
      withBorder
    >
      <Group sx={{ justifyContent: 'space-between' }}>
        <Text weight="bold" size="xl">
          {title}
        </Text>
        <Box>{children}</Box>
      </Group>
    </Paper>
  );
}
