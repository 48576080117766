import { useState, useEffect } from 'react';
import { Group, Stack, ScrollArea } from '@mantine/core';
import { DragDropContext } from 'react-beautiful-dnd';
import getWeek from 'date-fns/getWeek';
import format from 'date-fns/format';

import ConsoleLayout from 'layouts/ConsoleLayout';
import ActionHeader from './components/ActionHeader';
import { SelectOption } from 'components/common/Select/types';
import TicketGroup from './components/TicketGroup';

import useTicketAssignments from 'hooks/tickets/useTicketAssignments';

import getDaysOfWeek from 'helpers/datetime/getDaysOfWeek';
import { TicketDateMap } from 'hooks/tickets/types';
import { DATE_FORMATS } from 'constants/date';
import { cloneDeep } from 'lodash';

export default function ManagePage() {
  const [week] = useState(getWeek(new Date()));
  const [selectedUser, setSelectedUser] = useState<SelectOption>();
  const { data: ticketAssignmentsData, updateAssignment } =
    useTicketAssignments({});
  const [ticketGroups, setTicketGroups] = useState<{
    [key: string]: TicketDateMap;
  }>({});

  useEffect(() => {
    const groups: { [key: string]: TicketDateMap } = {};
    const weekDays = getDaysOfWeek(week);

    weekDays.forEach((weekDay) => {
      const dateKey = format(weekDay, DATE_FORMATS.DATE_KEY);

      if (!groups[dateKey]) {
        groups[dateKey] = {
          id: dateKey,
          assignments:
            ticketAssignmentsData?.ticket_assignments?.filter(
              (assignment) => assignment.date_due === dateKey
            ) || [],
        };
      }
    });

    setTicketGroups(groups);
  }, [ticketAssignmentsData, week]);

  return (
    <ConsoleLayout>
      <DragDropContext
        onDragEnd={(params) => {
          const sourceKey = params.source.droppableId.replace('dnd-list-', '');
          const destKey =
            params.destination?.droppableId.replace('dnd-list-', '') || '';

          const assignmentIndex = ticketGroups[sourceKey].assignments.findIndex(
            (a) => a.id?.toString() === params.draggableId
          );

          if (assignmentIndex > -1) {
            const foundAssignment =
              ticketGroups[sourceKey].assignments[assignmentIndex];
            ticketGroups[destKey].assignments.push(foundAssignment);
            ticketGroups[sourceKey].assignments.splice(assignmentIndex, 1);

            setTicketGroups(cloneDeep(ticketGroups));

            // TODO: revert on error
            updateAssignment({
              assignmentId: `${foundAssignment.id}`,
              body: { date_due: destKey },
            }).then(() => {
              //
            });
          }
        }}
      >
        <Stack sx={{ height: '100%' }}>
          <ActionHeader
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          />
          <ScrollArea
            sx={{
              height: '100%',
            }}
          >
            <Group
              align="start"
              sx={() => ({
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
              })}
            >
              {Object.values(ticketGroups)
                .sort((a, b) => a.id.localeCompare(b.id))
                .slice(1, -1)
                .map((ticketGroup) => (
                  <TicketGroup key={ticketGroup.id} ticketGroup={ticketGroup} />
                ))}
            </Group>
          </ScrollArea>
        </Stack>
      </DragDropContext>
    </ConsoleLayout>
  );
}
