import { useCallback } from 'react';
import { notifications } from '@mantine/notifications';

import { ENDPOINTS } from 'constants/api';
import * as billing from 'utils/billing';

import useApiQueryInfinite from 'hooks/api/useApiQueryInfinite';
import useApiStore from 'hooks/store/useApiStore';

import { BillingRecordsHookParams } from './types';
import type { BillingRecord } from 'types/api';
import showApiErrNotif from 'helpers/api/showApiErrNotif';

export default function useBillingRecordsInfinite(
  hookParams: BillingRecordsHookParams,
  disabled?: boolean
) {
  const { params } = hookParams || {};
  const { invalidate } = useApiStore();

  const queryResult = useApiQueryInfinite<{
    billing_records?: BillingRecord[];
  }>({
    path: ENDPOINTS.BILLING_RECORDS,
    axiosConfig: {
      params,
    },
    queryOptions: {
      enabled: !disabled,
      refetchOnWindowFocus: false,
    },
    storeKey: 'list-project-billing-records-tab',
  });

  const editBillingRecord: typeof billing.editBillingRecord = useCallback(
    async (editBillingRecordParams) => {
      try {
        await billing.editBillingRecord(editBillingRecordParams);
        invalidate('user-alerts');
        queryResult.refetch();
        notifications.show({
          message: 'Billing Record has been successfully updated!',
          variant: 'success',
        });
      } catch (err) {
        showApiErrNotif(
          'Unable to update billing record. Try again later.',
          err
        );
      }
    },
    [invalidate, queryResult]
  );

  const deleteBillingRecord = useCallback(
    async (deleteBillingRecordParams) => {
      try {
        const res = await billing.deleteBillingRecord(
          deleteBillingRecordParams
        );
        invalidate('edit-ticket-modal');
        invalidate('edit-ticket-modal-activity');
        queryResult.refetch();
        notifications.show({
          message: 'Billing Record has been successfully deleted!',
          variant: 'success',
        });
        return res;
      } catch (err) {
        showApiErrNotif(
          'Unable to delete billing record. Try again later.',
          err
        );
      }
    },
    [invalidate, queryResult]
  ) as typeof billing.deleteBillingRecord;

  return {
    ...queryResult,
    editBillingRecord,
    deleteBillingRecord,
  };
}
