import { Stack } from '@mantine/core';

import ConfigSection from './ConfigSection';
import InstanceSection from './InstanceSection';

export default function GeneralTab() {
  return (
    <Stack spacing={12} sx={(theme) => ({ padding: theme.spacing.md })}>
      <InstanceSection />
      <ConfigSection />
    </Stack>
  );
}
