import { Box, rem } from '@mantine/core';

import BillingRatesGrid from './BillingRatesGrid';
import BillingRatesPreview from './BillingRatesPreview';

export default function BillingTab() {
  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing.md,
        display: 'flex',
        columnGap: theme.spacing.md,
        height: '100%',
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          columnGap: theme.spacing.md,
          width: '100%',
        })}
      >
        <Box sx={{ width: `calc(100% - ${rem(432)})` }}>
          <BillingRatesGrid />
        </Box>
        <Box sx={{ width: rem(400) }}>
          <Box sx={{ display: 'flex', width: rem(400), position: 'relative' }}>
            <Box>
              <BillingRatesPreview />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
