import { useEffect, useMemo } from 'react';

import { SelectOption, SelectProps } from '../../types';
import BaseSelect from '../BaseSelect';

import useInstanceConfig from 'hooks/instance/useInstanceConfig';
import useAppStore from 'hooks/store/useAppStore';

interface ExpenseTypeSelectProps extends SelectProps {
  defaultFirst?: boolean;
}

export default function ExpenseTypeSelect({
  onChange,
  defaultValue,
  ...rest
}: ExpenseTypeSelectProps) {
  const { data } = useInstanceConfig();
  const { lastExpenseType, setLastExpenseType } = useAppStore();

  const options: SelectOption[] = useMemo(() => {
    return (
      data?.config?.expense_types?.map((expenseType) => {
        return {
          value: expenseType,
          label: expenseType,
        };
      }) || []
    );
  }, [data]);

  useEffect(() => {
    if (defaultValue === undefined && lastExpenseType) {
      onChange?.({ value: lastExpenseType, label: '' });
    }
  }, [defaultValue, lastExpenseType, onChange]);

  return (
    <BaseSelect
      options={options}
      onChange={(selected) => {
        setLastExpenseType(selected.value as string);
        onChange?.(selected);
      }}
      defaultValue={defaultValue || lastExpenseType || undefined}
      {...rest}
    />
  );
}
