import { Checkbox, Stack, TextInput } from '@mantine/core';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import * as Yup from 'yup';

import BaseModal from '../BaseModal';
import ModalFooter from './ModalFooter';
import ClientsSelect from 'components/common/Select/variants/ClientsSelect';
import BillingTypeSelect from 'components/common/Select/variants/BillingTypeSelect';
import ProjectStatusSelect from 'components/common/Select/variants/ProjectStatusSelect';
import ProjectTicketStatusesMultiSelect from 'components/common/MultiSelect/variants/ProjectTicketStatusesMultiSelect';

import { SelectOption } from 'components/common/Select/types';

import useProjects from 'hooks/projects/useProjects';

import { ClientProject } from 'types/api';
import { MODALS } from 'constants/component';
import { BILLING_TYPE } from 'constants/billing';

import useModalStore from 'hooks/store/useModalStore';
import useInstanceConfig from 'hooks/instance/useInstanceConfig';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Project name is required'),
  key: Yup.string().required('Project key is required').max(8),
});

type FormValues = Pick<
  ClientProject,
  'title' | 'key' | 'client_id' | 'billing_type' | 'status' | 'billable'
> & {
  ticket_statuses: string[];
};

const initialValues: Partial<FormValues> = {
  title: '',
  key: '',
  client_id: undefined,
  billing_type: undefined,
  status: 'active',
  ticket_statuses: [],
  billable: false,
};

function AddProjectModalForm() {
  const { values, handleChange, setFieldValue, errors, touched } =
    useFormikContext<FormValues>();

  const handleClientChange = (newClient: SelectOption) => {
    setFieldValue('client_id', newClient.value);
  };

  const handleBillingTypeChange = (newBillingType: SelectOption) => {
    setFieldValue('billing_type', newBillingType.value);
  };

  const handleStatusChange = (newStatus: SelectOption) => {
    setFieldValue('status', newStatus.value);
  };

  const handleTicketStatusesChange = (newTicketStatuses: string[]) => {
    setFieldValue('ticket_statuses', newTicketStatuses);
  };

  return (
    <Stack sx={(theme) => ({ padding: theme.spacing.md })} spacing={8}>
      <TextInput
        required
        placeholder="Project name"
        name="title"
        label="Project Name"
        value={values.title}
        onChange={handleChange}
        onBlur={() => {
          if (!values.key && values.title) {
            const titleSplit = values.title.split(' ');
            let charArray: string[] = [];

            if (titleSplit.length >= 3) {
              setFieldValue(
                'key',
                (charArray = titleSplit.slice(0, 3).map((t) => t[0]))
              );
            } else if (titleSplit.length === 2) {
              charArray.push(
                titleSplit[0][0],
                titleSplit[0][1] || titleSplit[0][1],
                titleSplit[0][1] ? titleSplit[1][0] : titleSplit[1][1]
              );
            } else {
              charArray.push(...titleSplit.slice(0, 3));
            }

            setFieldValue('key', charArray.join('').toUpperCase());
          }
        }}
        error={touched.title && errors.title}
      />
      <TextInput
        required
        name="key"
        label="Key"
        placeholder="APK"
        onChange={handleChange}
        value={values.key}
        error={touched.key && errors.key}
      />
      <Checkbox
        label="Billable"
        name="billable"
        color="green"
        checked={values.billable}
        onChange={(e) => {
          setFieldValue('billable', e.target.value === 'on');
        }}
      />
      <ClientsSelect
        defaultFirst
        label="Client"
        onChange={handleClientChange}
        defaultValue={values.client_id}
        error={touched.client_id ? errors.client_id : undefined}
      />
      <BillingTypeSelect
        defaultValue={BILLING_TYPE.TIME}
        label="Billing Type"
        onChange={handleBillingTypeChange}
        error={touched.billing_type ? errors.billing_type : undefined}
      />
      <ProjectStatusSelect
        defaultValue="active"
        label="Status"
        onChange={handleStatusChange}
        error={touched.status ? errors.status : undefined}
      />
      <ProjectTicketStatusesMultiSelect
        label="Ticket Statuses"
        onChange={handleTicketStatusesChange}
        value={values.ticket_statuses}
        error={touched.ticket_statuses ? errors.ticket_statuses : undefined}
      />
    </Stack>
  );
}

function AddProjectModal() {
  const { data } = useInstanceConfig();
  const modal = useModal();
  const { popModal } = useModalStore();
  const { addProject } = useProjects();

  const handleClose = () => {
    popModal(MODALS.ADD_PROJECT_MODAL);
  };

  const handleSubmit = async (
    values: Partial<FormValues>,
    { resetForm }: FormikHelpers<Partial<FormValues>>
  ) => {
    if (
      await addProject({
        ...values,
        ticket_statuses: values.ticket_statuses,
      })
    ) {
      handleClose();
      resetForm();
    }
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ticket_statuses: data?.config?.ticket_statuses || [],
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ resetForm }) => (
        <BaseModal
          id={MODALS.ADD_PROJECT_MODAL}
          isOpen={modal.visible}
          onClose={() => {
            handleClose();
            resetForm();
          }}
          title="Add Project"
          height="auto"
          size="40vw"
          FooterComponent={ModalFooter}
        >
          <AddProjectModalForm />
        </BaseModal>
      )}
    </Formik>
  );
}

export default NiceModal.create((props) => <AddProjectModal {...props} />);
