import { useMemo } from 'react';
import { Stack, Text } from '@mantine/core';

import { BillingRecordsListProps } from './types';
import { BillingRecord } from 'types/api';
import { BaseInfiniteLoadingListProps } from 'components/common/InfiniteLoadingList/types';
import { TABBED_CONTENT_HEIGHT } from 'constants/component';

import InfiniteLoader from 'components/common/InfiniteLoader';
import ContainedRow from './components/ContainedRow';
import DateHeader from './components/DateHeader';

interface BillingRecordsListInfiniteProps extends BillingRecordsListProps {
  pages?: { billing_records?: BillingRecord[] }[];
  isFetched: boolean;
  isInfinite?: boolean;
}

export default function BillingRecordsListInfinite({
  pages = [],
  editBillingRecord,
  deleteBillingRecord,
  variant,
  isNextPageLoading,
  hasNextPage,
  loadNextPage,
  fields,
  isFetched,
  isInfinite,
}: BillingRecordsListInfiniteProps &
  Omit<
    BaseInfiniteLoadingListProps<BillingRecord>,
    'ItemComponent' | 'items'
  >) {
  const isEmpty = isFetched && pages.length === 0;

  const dateHeaders = useMemo(() => {
    const dateGroups: { [key: string]: number } = {};
    const billingRecords = pages?.flatMap(
      (page) => page.billing_records
    ) as BillingRecord[];

    billingRecords.forEach((billingRecord) => {
      const dateKey = billingRecord.date;
      const id = billingRecord.id as number;

      if (!dateGroups[dateKey]) {
        dateGroups[dateKey] = id;
      }
    });

    return dateGroups;
  }, [pages]);

  return (
    <InfiniteLoader
      height={`calc(${TABBED_CONTENT_HEIGHT} - 76px)`}
      onScrollEnd={loadNextPage}
      isFetchingNextPage={isNextPageLoading}
      hasNextPage={Boolean(hasNextPage)}
    >
      {isEmpty ? (
        <Text sx={{ textAlign: 'center' }}>No billing records available.</Text>
      ) : (
        pages?.map((page) =>
          page.billing_records?.map((billingRecord) => (
            <Stack key={billingRecord.id} spacing={2}>
              {dateHeaders[billingRecord.date] == billingRecord.id && (
                <DateHeader date={billingRecord.date} />
              )}
              <ContainedRow
                billingRecord={billingRecord}
                editBillingRecord={editBillingRecord}
                deleteBillingRecord={deleteBillingRecord}
                variant={variant}
                fields={fields}
                isInfinite={isInfinite}
              />
            </Stack>
          ))
        )
      )}
    </InfiniteLoader>
  );
}
