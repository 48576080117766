export const TICKET_PRIORITY_LABELS = [
  'Lowest',
  'Low',
  'Medium',
  'High',
  'Highest',
];

export const RULE_LABELS = {
  estimate_count: 'ESTIMATE',
  assignee: 'ASSIGNEE',
  comment_count: 'COMMENT',
  billing_record_count: 'TIME LOGGED',
};
