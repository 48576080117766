import { useCallback } from 'react';
import { notifications } from '@mantine/notifications';

import { ENDPOINTS } from 'constants/api';
import type { Client } from 'types/api';
import type { AddClientBody } from 'utils/client';

import useApiQuery from 'hooks/api/useApiQuery';
import useApiStore from 'hooks/store/useApiStore';

import * as client from 'utils/client';
import showApiErrNotif from 'helpers/api/showApiErrNotif';
import constructQueryPath from 'helpers/api/constructQueryPath';

interface ClientHookParams {
  clientId?: number;
}

export default function useClient({ clientId }: ClientHookParams) {
  const { invalidate } = useApiStore();

  const queryResult = useApiQuery<{ client?: Client }>({
    path: constructQueryPath(ENDPOINTS.CLIENT, {
      clientId: clientId?.toString() as string,
    }),
    queryOptions: {
      enabled: Boolean(clientId),
    },
  });

  const invalidateQueries = useCallback(() => {
    notifications.show({ message: 'Client successfully updateed!' });
    invalidate('list-clients');
    invalidate('list-clients-archived');
  }, [invalidate]);

  const editClient = useCallback(
    async (updateBody: Partial<AddClientBody>) => {
      try {
        await client.editClient(clientId as number, updateBody);
        invalidateQueries();
        queryResult.refetch();
        return true;
      } catch (err) {
        showApiErrNotif('Unable to update client. Try again later.', err);
      }
    },
    [clientId, invalidateQueries, queryResult]
  );

  return {
    ...queryResult,
    editClient,
  };
}
