import { ActionIcon, Box, ScrollArea, Tabs, createStyles } from '@mantine/core';

import ConsoleLayout from 'layouts/ConsoleLayout';
import ProjectsTable from './components/ProjectsTable';

import { HEADER_HEIGHT, MODALS, TABS_HEIGHT } from 'constants/component';

import useProjects from 'hooks/projects/useProjects';
import useModalStore from 'hooks/store/useModalStore';
import useAuthStore from 'hooks/store/useAuthStore';
import useAuthUser from 'hooks/users/useAuthUser';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { IconCirclePlus } from '@tabler/icons-react';
import useProjectsPageStore from 'hooks/store/useProjectsPageStore';
import useUserProjects from 'hooks/projects/useUserProjects';
import { useLocation } from 'react-router-dom';

type ProjectTab = 'active' | 'archived';

const useStyles = createStyles((theme) => ({
  tabsList: {
    paddingLeft: theme.spacing.lg,
    backgroundColor: theme.white,
  },
  tab: {
    fontSize: theme.fontSizes.sm,
    height: TABS_HEIGHT,
    fontWeight: 'bold',
  },
}));

function TabPanel({
  children,
  height,
  scrollable = true,
}: {
  children: ReactNode;
  height?: string;
  scrollable?: boolean;
}) {
  if (!scrollable) {
    return (
      <Box>
        <Box
          sx={(theme) => ({
            height: height || `calc(100vh - ${TABS_HEIGHT} - ${HEADER_HEIGHT})`,
            padding: theme.spacing.md,
          })}
        >
          {children}
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <ScrollArea>
        <Box
          sx={(theme) => ({
            height: height || `calc(100vh - ${TABS_HEIGHT} - ${HEADER_HEIGHT})`,
            padding: theme.spacing.md,
          })}
        >
          {children}
        </Box>
      </ScrollArea>
    </Box>
  );
}

export default function ProjectsPage() {
  const { classes } = useStyles();
  const [activeTab, setActiveTab] = useState<ProjectTab>('active');
  const [sort, setSort] = useState<string | undefined>();
  const { user, isLoading: userLoading } = useAuthUser();
  const { data, isLoading, embedMap, loadEmbeds } = useProjects({
    storeKey: 'list-projects',
    params: {
      status: activeTab,
      embed: 'user_data',
    },
  });
  const { bookmarkProject, rearrangeProjects } = useUserProjects();
  const { projects, setProjects, sortProjects, updateProject } =
    useProjectsPageStore();
  const { pushModal } = useModalStore();
  const { isAdmin } = useAuthStore();
  const location = useLocation();

  const tabs: ProjectTab[] = useMemo(() => {
    if (!isAdmin) {
      return ['active'];
    }

    return ['active', 'archived'];
  }, [isAdmin]);

  const handleBookmark = async (projectId: number, active: '0' | '1') => {
    updateProject(projectId, { bookmarked: active === '1' });
    sortProjects();
    return bookmarkProject(projectId, active);
  };

  const displayProjects = useMemo(() => {
    const isDesc = sort?.includes('-');
    const _projects = projects.map((project) => ({
      ...project,
      ...embedMap[project.id as number],
    }));

    if (sort === 'title' || sort === '-title') {
      return _projects.slice().sort((a, b) => {
        const first = isDesc ? b : a;
        const second = isDesc ? a : b;

        return first.title.localeCompare(second.title);
      });
    }

    if (sort === 'latest_activity' || sort === '-latest_activity') {
      return _projects.slice().sort((a, b) => {
        const first = isDesc ? b : a;
        const second = isDesc ? a : b;

        if (!first.latest_activity || !second.latest_activity) return 0;

        return (
          new Date(first.latest_activity).getTime() -
          new Date(second.latest_activity).getTime()
        );
      });
    }

    if (sort === 'client_budget' || sort === '-client_budget') {
      return _projects.slice().sort((a, b) => {
        const first = isDesc ? b : a;
        const second = isDesc ? a : b;

        const budgetFirst = first.client_budget;
        const budgetSecond = second.client_budget;

        return (budgetFirst || 0) - (budgetSecond || 0);
      });
    }

    return _projects;
  }, [embedMap, projects, sort]);

  useEffect(() => {
    setProjects(
      data?.projects
        ?.slice()
        ?.sort((a, b) => (a.position || 0) - (b.position || 0)) || []
    );
    sortProjects();
  }, [data, setProjects, sortProjects]);

  useEffect(() => {
    loadEmbeds(['client_budget', 'latest_activity']);

    // eslint-disable-next-line
  }, [location.pathname, data]);

  return (
    <>
      <ConsoleLayout
        disablePadding
        disableScroll
        loading={isLoading || userLoading}
      >
        <Tabs
          value={activeTab}
          onTabChange={(value) => setActiveTab(value as ProjectTab)}
        >
          <Tabs.List className={classes.tabsList}>
            {tabs.map((tab) => (
              <Tabs.Tab
                key={tab}
                value={tab}
                className={classes.tab}
                sx={(theme) => ({
                  color:
                    activeTab === tab
                      ? `${theme.colors.blue[8]} !important`
                      : theme.colors.gray[6],
                  textTransform: 'capitalize',
                })}
              >
                {tab.replace(/-/gm, ' ')}
              </Tabs.Tab>
            ))}
            {isAdmin && (
              <ActionIcon
                radius="xl"
                onClick={() => pushModal(MODALS.ADD_PROJECT_MODAL)}
                sx={(theme) => ({
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  marginLeft: 'auto',
                  marginRight: theme.spacing.md,
                })}
              >
                <IconCirclePlus />
              </ActionIcon>
            )}
          </Tabs.List>

          <Tabs.Panel value="active">
            <TabPanel scrollable={false}>
              <ProjectsTable
                user={user}
                projects={displayProjects || []}
                bookmarkProject={handleBookmark}
                rearrangeProjects={rearrangeProjects}
                sort={sort}
                setSort={setSort}
              />
            </TabPanel>
          </Tabs.Panel>

          <Tabs.Panel value="archived">
            <TabPanel scrollable={false}>
              <ProjectsTable
                user={user}
                projects={displayProjects || []}
                bookmarkProject={handleBookmark}
                rearrangeProjects={rearrangeProjects}
                sort={sort}
                setSort={setSort}
              />
            </TabPanel>
          </Tabs.Panel>
        </Tabs>
      </ConsoleLayout>
    </>
  );
}
