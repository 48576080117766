import { useState } from 'react';
import getWeek from 'date-fns/getWeek';
import { Box } from '@mantine/core';
import { DateValue } from '@mantine/dates';

import ActivityList from 'components/common/ActivityList/GroupedList/InstanceActivityGroupedList';
import DateRangePicker from 'components/common/DateRangePicker';
import { HEADER_HEIGHT } from 'constants/component';
import getDaysOfWeek from 'helpers/datetime/getDaysOfWeek';

export default function ActivityHistory() {
  const [start, , , , , , end] = getDaysOfWeek(getWeek(new Date()));
  const [dateRange, setDateRange] = useState<[DateValue, DateValue]>([
    start,
    end,
  ]);
  const actionHeight = '36px';

  return (
    <>
      <Box
        sx={(theme) => ({
          borderBottom: '1px solid',
          borderColor: theme.colors.gray[3],
          height: actionHeight,
        })}
      >
        <DateRangePicker
          variant="arrow"
          value={dateRange}
          onChange={setDateRange}
        />
      </Box>
      <ActivityList
        dateRange={dateRange}
        height={`calc(100vh - ${HEADER_HEIGHT} - ${actionHeight})`}
      />
    </>
  );
}
