import { ActionIcon, Tooltip } from '@mantine/core';
import { IconDiscount } from '@tabler/icons-react';

export default function PopOverTarget() {
  return (
    <Tooltip label="Discount Line Item">
      <ActionIcon color="yellow">
        <IconDiscount />
      </ActionIcon>
    </Tooltip>
  );
}
