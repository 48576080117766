import { useMemo } from 'react';
import { Stack, Grid, Box, ColProps, Text } from '@mantine/core';
import { useFormikContext } from 'formik';
import parse from 'date-fns/parse';
import format from 'date-fns/format';

import type { AddInvoiceRecordBody } from 'utils/billing';
import { DATE_FORMATS } from 'constants/date';

import getCurrencyDisplay from 'helpers/display/getCurrencyDisplay';
import * as billingUtils from 'utils/billing';

import useProjects from 'hooks/projects/useProjects';
import useClient from 'hooks/clients/useClient';
import useInstanceConfig from 'hooks/instance/useInstanceConfig';

export default function VerifyDraftForm() {
  const { values } = useFormikContext<Partial<AddInvoiceRecordBody>>();
  const { data } = useClient({ clientId: values.client_id });
  const { data: projectData } = useProjects();
  const { dateDisplay } = useInstanceConfig();

  const clientAddress = useMemo(() => {
    const client = data?.client;

    if (!client) return '';

    const addresses = [
      client?.address_line1,
      client?.address_line2,
      `${client?.address_city}, ${client?.address_state}, ${client?.address_zip}`,
      client?.address_country,
    ];

    return addresses.filter((a) => a).join('\n');
  }, [data?.client]);

  const projectsDisplay = useMemo(() => {
    return projectData?.projects
      ?.filter((project) =>
        values.projectIds?.includes(project.id?.toString() as string)
      )
      .map((project) => project.title);
  }, [projectData?.projects, values.projectIds]);

  const issueDate = useMemo(() => {
    if (values.issue_date) {
      return format(
        parse(values.issue_date, DATE_FORMATS.DATE_KEY, new Date()),
        dateDisplay
      );
    }

    return values.issue_date;
  }, [dateDisplay, values.issue_date]);

  const dueDate = useMemo(() => {
    if (!values.due_date || !values.issue_date) return values.due_date;

    const date = billingUtils.getInvoiceDate(
      values.due_date,
      parse(values.issue_date, DATE_FORMATS.DATE_KEY, new Date())
    );
    return format(date, dateDisplay);
  }, [values.due_date, values.issue_date, dateDisplay]);

  const filteredLineItems = useMemo(() => {
    return values.invoiceLineItems?.filter((lineItem) => !lineItem.deleted);
  }, [values.invoiceLineItems]);

  const sumTotal = useMemo(() => {
    return (
      filteredLineItems?.reduce(
        (a, b) => a + (b.quantity || 0) * (b.cost || 0),
        0
      ) || 0
    );
  }, [filteredLineItems]);

  const sumTax = useMemo(() => {
    return filteredLineItems?.reduce((a, b) => a + (b.tax || 0), 0) || 0;
  }, [filteredLineItems]);

  const sharedProps: ColProps = {
    sx: (theme) => ({
      borderBottom: `1px solid ${theme.colors.gray[3]}`,
    }),
  };

  return (
    <Stack>
      <Grid>
        <Grid.Col sm={3} {...sharedProps}>
          Invoice ID
        </Grid.Col>
        <Grid.Col sm={9} {...sharedProps}>
          {values.pub_id}
        </Grid.Col>
        <Grid.Col sm={3} {...sharedProps}>
          Issue Date
        </Grid.Col>
        <Grid.Col sm={9} {...sharedProps}>
          {issueDate}
        </Grid.Col>
        <Grid.Col sm={3} {...sharedProps}>
          Due Date
        </Grid.Col>
        <Grid.Col sm={9} {...sharedProps}>
          {dueDate}
        </Grid.Col>
        <Grid.Col sm={3} {...sharedProps}>
          Company Info
        </Grid.Col>
        <Grid.Col sm={9} {...sharedProps}>
          <Box component="pre" m={0}>
            {values.instance_info}
          </Box>
        </Grid.Col>
        <Grid.Col sm={3} {...sharedProps}>
          Client
        </Grid.Col>
        <Grid.Col sm={9} {...sharedProps}>
          {data?.client?.name}
        </Grid.Col>
        <Grid.Col sm={3} {...sharedProps}>
          Client Address
        </Grid.Col>
        <Grid.Col sm={9} {...sharedProps}>
          <Box component="pre" m={0}>
            {clientAddress}
          </Box>
        </Grid.Col>
        <Grid.Col sm={3} {...sharedProps}>
          Projects
        </Grid.Col>
        <Grid.Col sm={9} {...sharedProps}>
          <Box component="pre" m={0}>
            {projectsDisplay?.join('\n')}
          </Box>
        </Grid.Col>
        <Grid.Col sm={3} {...sharedProps}>
          Total
        </Grid.Col>
        <Grid.Col sm={9} {...sharedProps}>
          {getCurrencyDisplay(
            (sumTotal + sumTax - (values.discount || 0)) / 100
          )}
        </Grid.Col>
        <Grid.Col sm={3}>Line Items</Grid.Col>
        <Grid.Col sm={9}>
          <Box>
            <Grid columns={6}>
              <Grid.Col sm={2} {...sharedProps}>
                <Text>Description</Text>
              </Grid.Col>
              <Grid.Col sm={1} {...sharedProps}>
                <Text>Quantity</Text>
              </Grid.Col>
              <Grid.Col sm={1} {...sharedProps}>
                <Text>Cost</Text>
              </Grid.Col>
              <Grid.Col sm={1} {...sharedProps}>
                <Text>Amount</Text>
              </Grid.Col>
              <Grid.Col sm={1} {...sharedProps}>
                <Text>Tax</Text>
              </Grid.Col>
              {filteredLineItems?.map((lineItem) => (
                <>
                  <Grid.Col sm={2} {...sharedProps}>
                    {lineItem.description}
                  </Grid.Col>
                  <Grid.Col sm={1} {...sharedProps}>
                    {lineItem.quantity}
                  </Grid.Col>
                  <Grid.Col sm={1} {...sharedProps}>
                    {getCurrencyDisplay((lineItem?.cost || 0) / 100)}
                  </Grid.Col>
                  <Grid.Col sm={1} {...sharedProps}>
                    {getCurrencyDisplay(
                      ((lineItem.cost || 0) * (lineItem.quantity || 0)) / 100
                    )}
                  </Grid.Col>
                  <Grid.Col sm={1} {...sharedProps}>
                    {getCurrencyDisplay((lineItem.tax || 0) / 100)}
                  </Grid.Col>
                </>
              ))}
              <Grid.Col sm={3} />
              <Grid.Col sm={1}>Subtotal</Grid.Col>
              <Grid.Col sm={1}>{getCurrencyDisplay(sumTotal / 100)}</Grid.Col>
              <Grid.Col sm={1}>{getCurrencyDisplay(sumTax / 100)}</Grid.Col>
              <Grid.Col sm={3} />
              <Grid.Col sm={1} {...sharedProps}>
                Discount
              </Grid.Col>
              <Grid.Col sm={1} {...sharedProps}>
                -{getCurrencyDisplay((values.discount || 0) / 100)}
              </Grid.Col>
              <Grid.Col sm={1} {...sharedProps} />
              <Grid.Col sm={3} />
              <Grid.Col sm={1}>Total</Grid.Col>
              <Grid.Col sm={1}>
                {getCurrencyDisplay(
                  (sumTotal + sumTax - (values.discount || 0)) / 100
                )}
              </Grid.Col>
              <Grid.Col sm={1} />
            </Grid>
          </Box>
        </Grid.Col>
      </Grid>
    </Stack>
  );
}
