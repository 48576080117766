import { Group, Button } from '@mantine/core';
import { useFormikContext } from 'formik';
import { notifications } from '@mantine/notifications';

import BaseModalFooter from '../BaseModal/ModalFooter';
import { MODALS } from 'constants/component';

import useModalStore from 'hooks/store/useModalStore';
import type { AddInvoiceRecordBody } from 'utils/billing';
import * as billingUtils from 'utils/billing';

export default function ModalFooter() {
  const { popModal } = useModalStore();
  const {
    submitForm,
    resetForm,
    values,
    setFieldValue,
    status,
    setStatus,
    isSubmitting,
  } = useFormikContext<AddInvoiceRecordBody>();

  const handleCancel = () => {
    if (values.stepIndex === 0 || (values.stepIndex === 1 && values.id)) {
      popModal(MODALS.ADD_INVOICE_MODAL);
      resetForm();
    } else {
      setFieldValue('stepIndex', values.stepIndex - 1);
    }
  };

  const handleSubmit = async () => {
    if (values.stepIndex === 2) {
      await submitForm();
    } else {
      setFieldValue('stepIndex', values.stepIndex + 1);

      if (values.stepIndex === 0) {
        const params: any = {
          billable: !values.includeNonBillable ? '1' : undefined,
          project_ids: values.projectIds.join(','),
          generate: '1',
          issue_date: values.issue_date,
          billing_type: values.billingTypes.join(','),
          group_by: values.groupBy,
        };

        setStatus({
          ...status,
          loading: true,
        });

        try {
          const res = await billingUtils.getInvoiceLineItems(params);
          const invoiceLineItems = res.data.line_items;

          setFieldValue('invoiceLineItems', invoiceLineItems);
        } catch (err) {
          notifications.show({
            message: 'Unable to pull line items at this time. Try again later.',
            color: 'red',
          });
        }

        setStatus({
          ...status,
          loading: false,
        });
      }
    }
  };

  const getCancelButtonText = () => {
    if (values.stepIndex === 0 || (values.stepIndex === 1 && values.id)) {
      return 'Cancel';
    }

    return 'Go Back';
  };

  const getConfirmButtonText = () => {
    if (values.stepIndex === 2) {
      return 'Save Draft';
    }

    return 'Next';
  };

  return (
    <BaseModalFooter>
      <Group sx={{ justifyContent: 'flex-end' }}>
        <Button
          size="xs"
          variant="outline"
          onClick={handleCancel}
          disabled={isSubmitting}
        >
          {getCancelButtonText()}
        </Button>
        <Button size="xs" onClick={handleSubmit} loading={isSubmitting}>
          {getConfirmButtonText()}
        </Button>
      </Group>
    </BaseModalFooter>
  );
}
