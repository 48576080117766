import { useMemo } from 'react';
import format from 'date-fns/format';

import { UserMap } from 'hooks/billing/types';
import { TableColumn } from 'components/common/Table/types';

import BaseTable from 'components/common/Table/variants/BaseTable';
import TimesheetTableRow from './TableRow';

import getDaysOfWeek from 'helpers/datetime/getDaysOfWeek';
import { DATE_FORMATS } from 'constants/date';

export interface TimesheetTableProps {
  users: UserMap[];
  week: number;
  loading?: boolean;
}

export default function TimesheetTable({
  users,
  week,
  loading,
}: TimesheetTableProps) {
  const columns: TableColumn[] = useMemo(() => {
    const cols: TableColumn[] = [
      {
        id: 'ticket',
        display: 'Ticket',
        colSpan: 2,
      },
    ];

    const weekDays = getDaysOfWeek(week);

    weekDays.forEach((weekDay) => {
      cols.push({
        id: format(weekDay, DATE_FORMATS.DATE_KEY),
        display: format(weekDay, DATE_FORMATS.TIMESHEET_DATE),
      });
    });

    cols.push({
      id: 'total',
      display: 'Total',
    });
    return cols;
  }, [week]);

  return (
    <BaseTable<UserMap>
      columns={columns}
      rows={users}
      RowComponent={TimesheetTableRow}
      loading={loading}
      tableProps={{
        sx: {
          tableLayout: 'fixed',
        },
      }}
    />
  );
}
