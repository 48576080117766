import { useMemo } from 'react';

import BaseMultiSelect from '../BaseMultiSelect';

import useInstanceConfig from 'hooks/instance/useInstanceConfig';

import { MultiSelectProps } from '../../types';
import { Loader, SelectItem } from '@mantine/core';

export default function ProjectTicketStatusesMultiSelect({
  ...props
}: Partial<MultiSelectProps>) {
  const { data, isLoading } = useInstanceConfig();

  const ticketStatuses: SelectItem[] = useMemo(() => {
    return (
      data?.config?.ticket_statuses?.map((status: string) => {
        return {
          value: status,
          label: status,
        };
      }) || []
    );
  }, [data]);

  return (
    <BaseMultiSelect
      {...props}
      data={ticketStatuses}
      rightSection={isLoading ? <Loader size="xs" /> : undefined}
    />
  );
}
