import { ReactNode, useMemo } from 'react';
import { Table, ScrollArea, Text, Box, Card } from '@mantine/core';

import { BaseChangeLogProps } from '../../types';
import { Activity } from 'types/api';
import { ACTION_FLAG } from 'constants/activity';

interface ChangeItemProps {
  children?: ReactNode;
  color: string;
}

function ChangeItem({ children, color }: ChangeItemProps) {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.colors[color][0],
        color: theme.colors[color][6],
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        wordBreak: 'break-word',
      })}
    >
      {children}
    </Box>
  );
}

function BaseChangeLogRow({
  value,
  oldValue,
  field,
  actionFlag,
}: {
  value: string;
  oldValue: string;
  field: string;
  actionFlag?: Activity['action_flag'];
}) {
  const isEdit = actionFlag === ACTION_FLAG.EDIT;

  return (
    <tr>
      <td>
        <Text sx={{ textTransform: 'capitalize' }}>{field}</Text>
      </td>
      <td>
        <ChangeItem color="green">+ {value}</ChangeItem>
      </td>
      {isEdit && (
        <td>
          <ChangeItem color="red">- {oldValue}</ChangeItem>
        </td>
      )}
    </tr>
  );
}

export default function BaseChangeLog({
  values = {},
  oldValues = {},
  actionFlag,
}: BaseChangeLogProps) {
  const isNonEdit = actionFlag !== ACTION_FLAG.EDIT;
  const isDelete = actionFlag === ACTION_FLAG.DELETE;

  const rows = useMemo(() => {
    return Object.keys(values).map((key) => ({
      key,
      field: key.replace(/_/gm, ' '),
      value: values[key],
      oldValue: oldValues[key],
    }));
  }, [oldValues, values]);

  if (isDelete) return <></>;

  return (
    <Card padding={4} shadow="sm" withBorder>
      <ScrollArea>
        <Table verticalSpacing="sm">
          <thead>
            <tr>
              <th>Property name</th>
              <th>New value</th>
              {!isNonEdit && <th>Old value</th>}
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <BaseChangeLogRow
                {...row}
                key={row.key}
                actionFlag={actionFlag}
              />
            ))}
          </tbody>
        </Table>
      </ScrollArea>
    </Card>
  );
}
