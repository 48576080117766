import { useState, useEffect } from 'react';
import { Stack, Button, Box } from '@mantine/core';
import { DatePicker } from '@mantine/dates';

import { PopOverDropdownProps } from '../types';

function PopOverDropdownForm({
  onClose,
  onSubmit,
  defaultValue,
}: PopOverDropdownProps) {
  const [value, setValue] = useState<Date | null | undefined>(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <Stack sx={(theme) => ({ color: theme.black })} spacing={8}>
      <DatePicker value={value} onChange={setValue} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          size="xs"
          variant="outline"
          sx={(theme) => ({ marginRight: theme.spacing.xs, flexGrow: 1 })}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          size="xs"
          sx={{ flexGrow: 1 }}
          onClick={() => onSubmit?.(value as Date)}
        >
          Save
        </Button>
      </Box>
    </Stack>
  );
}

export default function PopOverDropdown({
  onClose,
  onSubmit,
  defaultValue,
}: PopOverDropdownProps) {
  const handleSubmit = async (date: Date) => {
    await onSubmit?.(date);
    onClose?.();
  };

  return (
    <PopOverDropdownForm
      onClose={onClose}
      onSubmit={handleSubmit}
      defaultValue={defaultValue}
    />
  );
}
