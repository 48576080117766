import { useCallback, useMemo } from 'react';
import { IconBell } from '@tabler/icons-react';
import { ActionIcon, Popover, Stack, Box, ColorSwatch } from '@mantine/core';

import NotificationItem from './NotificationItem';

import useUserNotifications from 'hooks/users/useUserNotifications';

export default function BellNotification() {
  const { data, updateNotification, isLoading } = useUserNotifications({
    userId: 0,
  });

  const hasUnread = useMemo(() => {
    if (!data?.notifications) return false;

    return data?.notifications?.filter((notif) => !notif.is_read)?.length > 0;
  }, [data]);

  const handleNotifRead = useCallback(
    async (notifId: number) => {
      await updateNotification({ notifId, body: { is_read: true } });
    },
    [updateNotification]
  );

  const handleNotifRemove = useCallback(
    async (notifId: number) => {
      await updateNotification({ notifId, body: { is_archived: true } });
    },
    [updateNotification]
  );

  return (
    <Popover width={400} position="bottom" withArrow shadow="md">
      <Popover.Target>
        <Box sx={{ position: 'relative' }}>
          {hasUnread && (
            <ColorSwatch
              color="red"
              sx={{
                height: '8px',
                width: '8px',
                top: 0,
                right: 0,
                position: 'absolute',
              }}
            />
          )}
          <ActionIcon>
            <IconBell />
          </ActionIcon>
        </Box>
      </Popover.Target>
      <Popover.Dropdown
        sx={{
          border: 'none',
          boxShadow: 'none',
          padding: 0,
        }}
      >
        <Stack>
          {data?.notifications?.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              onRead={() => handleNotifRead(notification.id as number)}
              onRemove={() => handleNotifRemove(notification.id as number)}
            />
          ))}
          {!isLoading && data?.notifications?.length === 0 && (
            <Box
              sx={(theme) => ({
                textAlign: 'center',
                padding: theme.spacing.md,
              })}
            >
              <Box>No notifications at this time</Box>
            </Box>
          )}
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
}
