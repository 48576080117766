import { Box } from '@mantine/core';

import { LiteTicketUser, ProjectTicket } from 'types/api';
import { UpdateTicketSubscriptionCallback } from '../types';

import ConfirmationPopOver from 'components/common/PopOver/variants/ConfirmationPopOver';
import UserPopOver from 'components/common/PopOver/variants/UserPopOver';
import Avatar from 'components/common/Avatar';

import getInitials from 'helpers/display/getInitials';
import getUserFullName from 'helpers/display/getUserFullName';
import hslToString from 'helpers/color/hslToString';
import generateHSL from 'helpers/color/generateHSL';

interface SubscribersGroupProps {
  ticket?: ProjectTicket;
  subscribers?: LiteTicketUser[];
  updateTicketSubscription?: UpdateTicketSubscriptionCallback;
}

interface SubscriberUserProps {
  subscriber: LiteTicketUser;
  onConfirm?: () => void;
}

function SubscriberUser({ subscriber, onConfirm }: SubscriberUserProps) {
  const fullName = getUserFullName(subscriber);
  const initials = getInitials({ fullName });
  const color = hslToString(generateHSL(fullName));

  return (
    <ConfirmationPopOver onConfirm={onConfirm}>
      <Avatar
        size="sm"
        radius="xl"
        title={fullName}
        sx={{ marginLeft: -4 }}
        styles={{
          placeholder: {
            backgroundColor: color,
            color: 'white',
          },
        }}
      >
        {initials}
      </Avatar>
    </ConfirmationPopOver>
  );
}

export default function SubscribersGroup({
  ticket,
  subscribers = [],
  updateTicketSubscription,
}: SubscribersGroupProps) {
  const handleFilter = (userId: string) => {
    return !ticket?.subscribed_users?.find(
      (user: LiteTicketUser) => user.id?.toString() === userId.toString()
    );
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {subscribers.map((subscriber) => (
        <SubscriberUser
          key={subscriber.id}
          subscriber={subscriber}
          onConfirm={() =>
            updateTicketSubscription?.(
              `${ticket?.id}`,
              `${subscriber.id}`,
              false
            )
          }
        />
      ))}
      <UserPopOver
        updateUserSubscription={async (userId: string) => {
          updateTicketSubscription?.(`${ticket?.id}`, userId, true);
        }}
        filter={handleFilter}
      />
    </Box>
  );
}
