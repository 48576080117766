import { useEffect, useRef, useState } from 'react';
import {
  Text,
  Group,
  Button,
  rem,
  useMantineTheme,
  createStyles,
  Box,
} from '@mantine/core';
import { Dropzone, FileWithPath, MIME_TYPES } from '@mantine/dropzone';
import { IconCloudUpload, IconX, IconDownload } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: rem(30),
  },
  dropzone: {
    borderWidth: rem(1),
    paddingBottom: rem(50),
  },
  icon: {
    color: theme.colors.gray[4],
  },
  control: {
    position: 'absolute',
    width: rem(250),
    left: `calc(50% - ${rem(125)})`,
    bottom: rem(-20),
  },
}));

interface ImageDropzoneProps {
  onDrop: (files: FileWithPath[]) => void;
  value?: FileWithPath;
  label?: string;
  loading?: boolean;
}

export default function ImageDropzone({
  onDrop,
  value,
  label,
  loading,
}: ImageDropzoneProps) {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const openRef = useRef<() => void>(null);
  const reader = useRef(new FileReader());
  const [imageRef, setImageRef] = useState<string>();

  useEffect(() => {
    const currentReader = reader.current;

    const loadImage = () => {
      const result = currentReader.result;
      setImageRef(result as string);
    };

    currentReader.addEventListener('load', loadImage);

    return () => {
      currentReader.removeEventListener('load', loadImage);
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      <Text>{label}</Text>
      <Dropzone
        openRef={openRef}
        onDrop={(files) => {
          reader.current.readAsDataURL(files[0]);
          onDrop(files);
        }}
        className={classes.dropzone}
        radius="md"
        accept={[
          MIME_TYPES.gif,
          MIME_TYPES.png,
          MIME_TYPES.jpeg,
          MIME_TYPES.svg,
        ]}
        loading={loading}
      >
        {!value ? (
          <div style={{ pointerEvents: 'none' }}>
            <Group sx={{ justifyContent: 'center' }}>
              <Dropzone.Accept>
                <IconDownload
                  style={{ width: rem(50), height: rem(50) }}
                  color={theme.colors.blue[6]}
                  stroke={1.5}
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX
                  style={{ width: rem(50), height: rem(50) }}
                  color={theme.colors.red[6]}
                  stroke={1.5}
                />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconCloudUpload
                  style={{ width: rem(50), height: rem(50) }}
                  stroke={1.5}
                />
              </Dropzone.Idle>
            </Group>

            <Text ta="center" fw={700} fz="lg" mt="xl">
              <Dropzone.Accept>Drop files here</Dropzone.Accept>
              <Dropzone.Idle>Upload image</Dropzone.Idle>
            </Text>
          </div>
        ) : (
          <Box sx={{ height: rem(128), display: 'flex' }}>
            <Box
              component="img"
              src={imageRef || value?.name}
              sx={{ height: '100%', width: 'auto', margin: 'auto' }}
            />
          </Box>
        )}
      </Dropzone>

      <Button
        className={classes.control}
        size="md"
        radius="xl"
        onClick={() => openRef.current?.()}
        loading={loading}
      >
        Select files
      </Button>
    </div>
  );
}
