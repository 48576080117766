import { useState, useEffect } from 'react';
import { Tabs, createStyles, ActionIcon, Box } from '@mantine/core';
import { useSearchParams } from 'react-router-dom';

import ConsoleLayout from 'layouts/ConsoleLayout';
import ClientsTable from './components/ClientsTable';

import useClients from 'hooks/clients/useClients';
import { MODALS, TABS_HEIGHT } from 'constants/component';
import useModalStore from 'hooks/store/useModalStore';
import { IconCirclePlus } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
  tabsList: {
    paddingLeft: theme.spacing.lg,
    backgroundColor: theme.white,
  },
  tab: {
    fontSize: theme.fontSizes.sm,
    height: TABS_HEIGHT,
    fontWeight: 'bold',
  },
}));

type ClientTab = 'active' | 'archived';

const tabs: ClientTab[] = ['active', 'archived'];

export default function ClientsPage() {
  const { classes } = useStyles();
  const [activeTab, setActiveTab] = useState<ClientTab>('active');
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading } = useClients({
    storeKey: activeTab === 'active' ? 'list-clients' : 'list-clients-archived',
    params: {
      is_active: activeTab === 'active' ? '1' : '0',
      embed: 'covered_budget',
    },
  });
  const { pushModal } = useModalStore();

  const handleTabChange = (value: string) => {
    setActiveTab(value as ClientTab);
    setSearchParams({
      tab: value,
    });
  };

  useEffect(() => {
    const tab = searchParams.get('tab') as ClientTab;
    if (tabs.includes(tab)) {
      setActiveTab(tab);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <ConsoleLayout disablePadding disableScroll loading={isLoading}>
      <Tabs value={activeTab} onTabChange={handleTabChange}>
        <Tabs.List className={classes.tabsList}>
          {tabs.map((tab) => (
            <Tabs.Tab
              key={tab}
              value={tab}
              className={classes.tab}
              sx={(theme) => ({
                color:
                  activeTab === tab
                    ? `${theme.colors.blue[8]} !important`
                    : theme.colors.gray[6],
                textTransform: 'capitalize',
              })}
            >
              {tab.replace(/-/gm, ' ')}
            </Tabs.Tab>
          ))}
          <ActionIcon
            sx={(theme) => ({
              marginLeft: 'auto',
              marginTop: 'auto',
              marginBottom: 'auto',
              marginRight: theme.spacing.md,
            })}
            onClick={() => pushModal(MODALS.ADD_CLIENT_MODAL)}
          >
            <IconCirclePlus />
          </ActionIcon>
        </Tabs.List>

        <Tabs.Panel value="active" sx={{ overflow: 'visible' }}>
          <Box
            sx={(theme) => ({
              padding: theme.spacing.md,
              overflow: 'visible',
            })}
          >
            <ClientsTable clients={data?.clients || []} />
          </Box>
        </Tabs.Panel>

        <Tabs.Panel value="archived" sx={{ overflow: 'visible' }}>
          <Box
            sx={(theme) => ({
              padding: theme.spacing.md,
              overflow: 'visible',
            })}
          >
            <ClientsTable clients={data?.clients || []} />
          </Box>
        </Tabs.Panel>
      </Tabs>
    </ConsoleLayout>
  );
}
