import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { ModalProps } from '../../types';
import { BillingRecord, ClientProject, ProjectTicket } from 'types/api';
import { MODALS } from 'constants/component';
import { BILLING_TYPE } from 'constants/billing';

import BaseModal from '../BaseModal';

import useTicketBillingRecords from 'hooks/billing/useTicketBillingRecords';
import useModalStore from 'hooks/store/useModalStore';
import BillingRecordsList from 'components/shared/BillingRecordsList';
import AddExpenseForm from './components/AddExpenseForm';
import { ScrollArea } from '@mantine/core';

interface ListExpensesModalProps extends ModalProps {
  billingRecords?: BillingRecord[];
  ticket?: ProjectTicket;
  project?: ClientProject;
  showAdd?: boolean;
}

function ListExpensesModal({
  ticket,
  project,
  id,
  showAdd,
  ...props
}: ListExpensesModalProps) {
  const modal = useModal();
  const { popModal } = useModalStore();
  const { data, editBillingRecord, deleteBillingRecord, isLoading } =
    useTicketBillingRecords({
      ticketId: ticket?.id || undefined,
      projectId: project?.id || undefined,
      params: {
        billing_type: BILLING_TYPE.EXPENSE,
      },
      storeKey: 'list-expenses-modal',
    });

  const handleClose = () => {
    popModal(id);
  };

  return (
    <>
      <BaseModal
        isOpen={modal.visible}
        onClose={handleClose}
        size="800px"
        title="Expenses"
        includesBackdrop
        id={id}
        scrollable={false}
        height="80vh"
        {...props}
      >
        <ScrollArea sx={{ height: '75vh' }}>
          <AddExpenseForm ticket={ticket} project={project} showAdd={showAdd} />
          <BillingRecordsList
            billingRecords={data?.billing_records}
            editBillingRecord={editBillingRecord}
            deleteBillingRecord={deleteBillingRecord}
            variant="expense"
            loading={isLoading}
          />
        </ScrollArea>
      </BaseModal>
    </>
  );
}

export default NiceModal.create((props) => (
  <ListExpensesModal {...props} id={MODALS.LIST_EXPENSES_MODAL} />
));
