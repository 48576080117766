import { useCallback } from 'react';
import { ENDPOINTS } from 'constants/api';
import type { TicketAssignment } from 'types/api';

import useApiQuery from 'hooks/api/useApiQuery';
import {
  AssignmentBody,
  updateAssignment as updateAssignmentCallback,
} from 'utils/ticket';
import showApiErrNotif from 'helpers/api/showApiErrNotif';
import { notifications } from '@mantine/notifications';

export interface TicketsHookParams {
  params?: any;
}

export default function useTicketAssignments(hookParams: TicketsHookParams) {
  const { params } = hookParams || {};

  const queryResult = useApiQuery<{ ticket_assignments?: TicketAssignment[] }>({
    path: ENDPOINTS.TICKET_ASSIGNMENTS,
    axiosConfig: {
      params,
    },
  });

  const updateAssignment = useCallback(
    async ({
      assignmentId,
      body,
    }: {
      assignmentId: string;
      body: Partial<AssignmentBody>;
    }) => {
      try {
        await updateAssignmentCallback({ assignmentId, body });
        notifications.show({
          message: 'Assignment successfully updated!',
          variant: 'success',
        });
        queryResult.refetch();
      } catch (err) {
        showApiErrNotif('Unable to update assignment. Try again later.', err);
      }
    },
    [queryResult]
  );

  return {
    ...queryResult,
    updateAssignment,
  };
}
