import { useState } from 'react';
import { TextInput, TextInputProps, ActionIcon } from '@mantine/core';
import { IconClipboardCopy, IconClipboardCheck } from '@tabler/icons-react';

export default function ClipboardInputTextField(props: TextInputProps) {
  const [copied, setCopied] = useState(false);

  return (
    <TextInput
      readOnly
      rightSection={
        <ActionIcon
          color={copied ? 'green' : 'blue'}
          onClick={() => {
            navigator.clipboard.writeText(props.value as string);
            setCopied(true);
          }}
        >
          {copied ? <IconClipboardCheck /> : <IconClipboardCopy />}
        </ActionIcon>
      }
      {...props}
    />
  );
}
