import { Card, Stack } from '@mantine/core';

import TicketGroupTitle from './Title';
import TicketGroupBody from './Body';
import { TicketDateMap } from 'hooks/tickets/types';

export interface TicketGroupProps {
  ticketGroup: TicketDateMap;
}

export default function TicketGroup({ ticketGroup }: TicketGroupProps) {
  return (
    <Card
      withBorder
      p={4}
      sx={(theme) => ({
        backgroundColor: theme.colors.gray[1],
        width: '300px',
      })}
    >
      <Stack spacing={4}>
        <TicketGroupTitle ticketGroup={ticketGroup} />
        <TicketGroupBody ticketGroup={ticketGroup} />
      </Stack>
    </Card>
  );
}
