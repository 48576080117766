import { BaseJsonEditorProps } from './types';
import ConfigJsonEditor from './variants/Config';
import BaseJsonEditor from './variants/Base';

interface JsonEditorProps extends BaseJsonEditorProps {
  variant?: 'base' | 'config';
}

export default function JsonEditor({ variant, ...props }: JsonEditorProps) {
  if (variant === 'config') {
    return <ConfigJsonEditor {...props} />;
  }

  return <BaseJsonEditor {...props} />;
}
