import { SelectItem } from '@mantine/core';

import BaseMultiSelect from '../BaseMultiSelect';

import { MultiSelectProps } from '../../types';
import { RULE_LABELS } from 'constants/ticket';

const ticketRules: SelectItem[] = [
  {
    value: 'estimate_count',
    label: RULE_LABELS['estimate_count'],
  },
  {
    value: 'assignee',
    label: RULE_LABELS['assignee'],
  },
  {
    value: 'comment_count',
    label: RULE_LABELS['comment_count'],
  },
  {
    value: 'billing_record_count',
    label: RULE_LABELS['billing_record_count'],
  },
];

export default function TicketRulesMultiSelect({
  ...props
}: Partial<MultiSelectProps>) {
  return <BaseMultiSelect {...props} data={ticketRules} />;
}
