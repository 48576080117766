import { useCallback, useState } from 'react';
import { notifications } from '@mantine/notifications';
import { AxiosError } from 'axios';

import { ENDPOINTS, INVALIDATE_GROUP } from 'constants/api';
import type { ClientProject } from 'types/api';
import type { AddProjectBody } from 'utils/project';
import { ApiQueryKey } from 'types/api/query';

import * as projectUtils from 'utils/project';

import useApiQuery from 'hooks/api/useApiQuery';
import useApiStore from 'hooks/store/useApiStore';

import showApiErrNotif from 'helpers/api/showApiErrNotif';
import axios from 'utils/axios';
import constructQueryPath from 'helpers/api/constructQueryPath';

interface ProjectsHookParams {
  params?: any;
  storeKey?: ApiQueryKey;
}

export default function useProjects(hookParams?: ProjectsHookParams) {
  const { params, storeKey } = hookParams || {};
  const [loadingEmbeds, setLoadingEmbeds] = useState(false);
  const { invalidateGroup, invalidatePath, invalidate } = useApiStore();
  const [embedMap, setEmbedMap] = useState<{
    [projectId: number]: Partial<ClientProject>;
  }>({});

  const queryResult = useApiQuery<{ projects?: ClientProject[] }>({
    path: ENDPOINTS.PROJECTS,
    axiosConfig: {
      params: params,
    },
    storeKey: storeKey,
  });

  const loadEmbeds = useCallback(
    async (embed?: (keyof ClientProject)[]) => {
      if (!embed?.length || loadingEmbeds) return;
      setLoadingEmbeds(true);

      const map: { [projectId: number]: Partial<ClientProject> } = {};

      const res = await axios.get(constructQueryPath(ENDPOINTS.PROJECTS), {
        params: {
          ...params,
          fields: ['id'].concat(embed || []).join(','),
          embed: embed?.join('|'),
        },
      });

      res.data?.projects?.forEach((project: Partial<ClientProject>) => {
        const projectId = project.id as number;
        map[projectId] = project;
      });

      setEmbedMap(map);
      setLoadingEmbeds(false);
    },
    [loadingEmbeds, params]
  );

  const addProject = async (projectbody: Partial<AddProjectBody>) => {
    try {
      await projectUtils.addProject(projectbody);
      invalidateGroup(INVALIDATE_GROUP.PROJECT);
      invalidatePath(['activity']);
      queryResult.refetch();
      notifications.show({
        message: 'Project successfully added!',
        color: 'green',
      });
      return true;
    } catch (err) {
      const error = err as AxiosError;
      notifications.show({
        message: error.message,
        color: 'red',
      });
    }

    return false;
  };

  const updateProject = useCallback(
    async ({ projectId, projectBody }) => {
      try {
        await projectUtils.updateProject({ projectId, projectBody });
        await queryResult.refetch();
        invalidateGroup(INVALIDATE_GROUP.PROJECT);
        invalidatePath(['activity']);
        invalidate('project-page');
        notifications.show({
          message: 'Project successfully updated!',
          variant: 'success',
        });
        return true;
      } catch (err) {
        showApiErrNotif('Unable to update project.', err);
      }
    },
    [invalidate, invalidateGroup, invalidatePath, queryResult]
  ) as typeof projectUtils.updateProject;

  return {
    ...queryResult,
    embedMap,
    loadEmbeds,
    addProject,
    updateProject,
  };
}
