import { MouseEvent, useMemo } from 'react';
import format from 'date-fns/format';
import { Box, Group, Text, createStyles } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

import getDaysOfWeek from 'helpers/datetime/getDaysOfWeek';
import { DATE_FORMATS } from 'constants/date';

const useStyles = createStyles((theme) => ({
  iconButton: {
    '&:hover': {
      color: theme.colors.blue,
      cursor: 'pointer',
    },
  },
}));

interface TimesheetHeaderProps {
  week: number;
  setWeek: (week: number) => void;
}

export default function TimesheetHeader({
  week,
  setWeek,
}: TimesheetHeaderProps) {
  const { classes } = useStyles();

  const [dateRangeDisplay, monthYearDisplay] = useMemo(() => {
    const [start, , , , , , end] = getDaysOfWeek(week);

    const _dateRangeDisplay = [
      format(start, DATE_FORMATS.TIMESHEET_DATE),
      format(end, DATE_FORMATS.TIMESHEET_DATE),
    ].join(' - ');

    const _monthYearDisplay = [format(start, DATE_FORMATS.MONTH_YEAR)];

    // covers different year case as well
    if (start.getMonth() !== end.getMonth()) {
      _monthYearDisplay.push(format(end, DATE_FORMATS.MONTH_YEAR));
    }

    return [_dateRangeDisplay, _monthYearDisplay.join(' - ')];
  }, [week]);

  const handleWeekChange = (e: MouseEvent, dir: number) => {
    e.preventDefault();
    e.stopPropagation();

    setWeek(week + dir);
  };

  return (
    <Box mb={16}>
      <Box sx={{ display: 'flex' }}>
        <Text sx={{ margin: 'auto', fontWeight: 'bold' }}>
          {monthYearDisplay}
        </Text>
      </Box>
      <Group sx={{ justifyContent: 'center', userSelect: 'none' }}>
        <IconChevronLeft
          className={classes.iconButton}
          onClick={(e) => handleWeekChange(e, -1)}
        />
        <Text sx={{ userSelect: 'all' }}>{dateRangeDisplay}</Text>
        <IconChevronRight
          className={classes.iconButton}
          onClick={(e) => handleWeekChange(e, 1)}
        />
      </Group>
    </Box>
  );
}
