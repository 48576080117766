import { notifications } from '@mantine/notifications';

import useApiQuery from 'hooks/api/useApiQuery';

import { ENDPOINTS } from 'constants/api';
import { BillingRecordsHookParams } from './types';
import type { Invoice } from 'types/api';

import * as billingUtils from 'utils/billing';
import showApiErrNotif from 'helpers/api/showApiErrNotif';

export default function useInvoices(
  hookParams?: BillingRecordsHookParams,
  disabled?: boolean
) {
  const { params, storeKey } = hookParams || {};

  const queryResult = useApiQuery<{ invoices?: Invoice[] }>({
    path: ENDPOINTS.INVOICES,
    axiosConfig: {
      params,
    },
    queryOptions: {
      enabled: !disabled,
    },
    storeKey: storeKey,
  });

  const voidInvoice = async (invoiceId: number) => {
    try {
      await billingUtils.voidInvoice(invoiceId);
      queryResult.refetch();
      notifications.show({
        message: 'Invoice successfully removed!',
        color: 'green',
      });
      return true;
    } catch (err) {
      showApiErrNotif(
        'Unable to disable invoice at this time. Try again later.',
        err
      );
    }

    return false;
  };

  return {
    ...queryResult,
    voidInvoice,
  };
}
