import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Formik, FormikProps, useFormikContext } from 'formik';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Box, JsonInput, ScrollArea, Stack } from '@mantine/core';

import BaseModal from '../BaseModal';
import ModalFooter from './ModalFooter';

import { MODALS } from 'constants/component';

import useModalStore from 'hooks/store/useModalStore';

import * as billingUtils from 'utils/billing';
import showApiErrNotif from 'helpers/api/showApiErrNotif';
import { notifications } from '@mantine/notifications';
import { UserLaborCost } from 'types/api';

interface FormValues {
  userLaborCosts: string;
}

interface UploadUserLaborCostsModal {
  file?: File;
}

function UploadUserLaborCostsForm() {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  return (
    <Stack sx={(theme) => ({ padding: theme.spacing.xl })}>
      <JsonInput
        autosize
        formatOnBlur
        validationError
        label="User Labor Costs"
        value={values.userLaborCosts}
        onChange={(val) => setFieldValue('userLaborCosts', val)}
      />
    </Stack>
  );
}

function UploadUserLaborCostsModal({ file }: UploadUserLaborCostsModal) {
  const modal = useModal();
  const { popModal } = useModalStore();
  const reader = useRef(new FileReader());
  const formikRef =
    useRef() as MutableRefObject<FormikProps<FormValues> | null>;
  const [fileResult, setFileResult] = useState('');

  const handleClose = (force = false) => {
    if (!formikRef.current?.isSubmitting || force) {
      popModal(MODALS.UPLOAD_USER_LABOR_COSTS_MODAL);
    }
  };

  const handleSubmit = async (values: Partial<FormValues>) => {
    try {
      const userLaborCosts: UserLaborCost[] = [];

      const jsonObj = JSON.parse(values.userLaborCosts as string);

      Object.entries(jsonObj).forEach(([userId, _costEntries]) => {
        const costEntries: UserLaborCost[] = _costEntries as UserLaborCost[];
        costEntries.forEach((entry) => {
          userLaborCosts.push({
            ...entry,
            instance_user_id: parseInt(userId),
          });
        });
      });

      await billingUtils.addUserLaborCosts(userLaborCosts);
      formikRef.current?.resetForm();
      notifications.show({
        message: 'User Labor Costs successfully updated!',
        color: 'green',
      });
    } catch (err) {
      showApiErrNotif(
        'Unable to add user labor costs at this time. Try again later.',
        err
      );
    } finally {
      handleClose(true);
    }
  };

  useEffect(() => {
    const currentReader = reader.current;

    const loadImage = () => {
      const result = currentReader.result;
      setFileResult(result as string);
    };

    currentReader.addEventListener('load', loadImage);

    return () => {
      currentReader.removeEventListener('load', loadImage);
    };
  }, []);

  useEffect(() => {
    if (file) {
      reader.current.readAsText(file);
    }
  }, [file]);

  return (
    <Formik<FormValues>
      initialValues={{
        userLaborCosts: fileResult || '{}',
      }}
      onSubmit={handleSubmit}
      innerRef={formikRef}
      enableReinitialize
    >
      <BaseModal
        id={MODALS.UPLOAD_USER_LABOR_COSTS_MODAL}
        isOpen={modal.visible}
        onClose={handleClose}
        title="Upload User Labor Costs"
        height="80vh"
        size="40vw"
        FooterComponent={ModalFooter}
      >
        <ScrollArea sx={{ height: '75vh' }}>
          <UploadUserLaborCostsForm />
        </ScrollArea>
      </BaseModal>
    </Formik>
  );
}

export default NiceModal.create((props) => (
  <UploadUserLaborCostsModal {...props} />
));
