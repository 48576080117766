import { SelectOption, SelectProps } from '../../types';
import BaseSelect from '../BaseSelect';

import SelectTarget from './SelectTarget';

interface BudgetTypeSelectProps extends SelectProps {}

export default function BudgetTypeSelect({
  onChange,
  defaultValue,
  label,
  ...props
}: BudgetTypeSelectProps) {
  const options: SelectOption[] = [
    {
      label: 'Recurring (reset on invoice)',
      value: 'monthly',
    },
    {
      label: 'Total',
      value: 'total',
    },
  ];

  return (
    <BaseSelect
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      label={label}
      TargetComponent={SelectTarget}
      {...props}
    />
  );
}
