import { useMemo } from 'react';
import { DateInput } from '@mantine/dates';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

import { SelectOption, SelectProps } from '../../types';
import BaseSelect from '../BaseSelect';
import { DATE_FORMATS } from 'constants/date';

const options: SelectOption[] = [
  {
    label: 'Issue Date',
    value: 'issue-date',
  },
  {
    label: 'Ten Days From Issue Date',
    value: 'ten-days',
  },
  {
    label: 'Fifteen Days From Issue Date',
    value: 'fifteen-days',
  },
  {
    label: 'Thirty Days From Issue Date',
    value: 'thirty-days',
  },
  {
    label: 'Sixty Days From Issue Date',
    value: 'sixty-days',
  },
  {
    label: '1 Month From Issue Date',
    value: 'one-month',
  },
  {
    label: 'First Day of Month After Issue',
    value: 'first-day-of-issue-month',
  },
  {
    label: 'Last Day of Issue Month',
    value: 'last-day-of-issue-month',
  },
  {
    label: 'Custom',
    value: 'custom',
  },
];

interface InvoiceDueDateSelectProps extends SelectProps {
  defaultFirst?: boolean;
}

export default function InvoiceDueDateSelect({
  onChange,
  defaultValue,
  label,
  ...rest
}: InvoiceDueDateSelectProps) {
  const foundOption = useMemo(() => {
    return (
      options.find((option) => option.value === defaultValue)?.value || 'custom'
    );
  }, [defaultValue]);

  const customDateValue = useMemo(() => {
    try {
      const date = parse(
        defaultValue as string,
        DATE_FORMATS.DATE_KEY,
        new Date()
      );

      if (date.toString() === 'Invalid Date') {
        throw new Error();
      }

      return date;
    } catch (err) {
      return new Date();
    }
  }, [defaultValue]);

  return (
    <>
      <BaseSelect
        options={options}
        onChange={onChange}
        label={label}
        defaultValue={foundOption}
        {...rest}
      />
      {foundOption === 'custom' && (
        <DateInput
          label={`Custom ${label}`}
          value={customDateValue}
          onChange={(date) => {
            onChange?.({
              label: format(date as Date, DATE_FORMATS.DATE_KEY),
              value: format(date as Date, DATE_FORMATS.DATE_KEY),
            });
          }}
          minDate={new Date()}
        />
      )}
    </>
  );
}
