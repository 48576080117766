import { Box, createStyles } from '@mantine/core';
import { Draggable } from 'react-beautiful-dnd';
import { IconGripVertical } from '@tabler/icons-react';

import { TableRowProps } from '../../types';

const useStyles = createStyles((theme) => ({
  rowData: {
    textAlign: 'center',
  },
  row: {
    userSelect: 'none',
    '&:hover': {
      backgroundColor: theme.colors.gray[1],
      cursor: 'pointer',
    },
    '&:active': {
      backgroundColor: theme.colors.gray[2],
    },
  },
}));

export default function TableRow<R extends { id?: number | string }>({
  row,
  columns,
  getDataDisplay = (key, row) => `${row[key as keyof R]}`,
  onClick,
  index,
  disableDrag,
}: TableRowProps<R>) {
  const { classes, cx } = useStyles();

  return (
    <Draggable
      key={row.id}
      draggableId={row.id?.toString() as string}
      index={index as number}
      isDragDisabled={disableDrag}
    >
      {(provided) => (
        <tr
          className={cx({
            [classes.row]: Boolean(onClick),
          })}
          onClick={() => onClick?.(row)}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <td
            className={classes.rowData}
            {...(disableDrag ? {} : provided.dragHandleProps)}
          >
            {!disableDrag && (
              <Box sx={{ display: 'flex' }}>
                <IconGripVertical size="16px" style={{ margin: 'auto' }} />
              </Box>
            )}
          </td>
          {columns.map((col) => (
            <td key={col.id} className={classes.rowData}>
              {getDataDisplay(col.id as keyof R, row)}
            </td>
          ))}
        </tr>
      )}
    </Draggable>
  );
}
