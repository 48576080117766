import { useState } from 'react';
import {
  createStyles,
  Avatar,
  Paper,
  rem,
  Textarea,
  Stack,
  Box,
  Grid,
} from '@mantine/core';

import CommentRichTextEditor from 'components/common/RichTextEditor/variants/Comment';

import { TicketCommentBody } from 'utils/ticket';

import useAuthUser from 'hooks/users/useAuthUser';
import { TicketComment } from 'types/api';

const useStyles = createStyles((theme) => ({
  comment: {
    paddingBottom: theme.spacing.xl,
  },

  body: {
    paddingLeft: rem(54),
    paddingTop: theme.spacing.sm,
    fontSize: theme.fontSizes.sm,
  },

  content: {
    '& > p:last-child': {
      marginBottom: 0,
    },
  },
}));

interface AddCommentItemProps {
  onSubmit: (
    body: TicketCommentBody,
    attachments?: File[]
  ) => Promise<TicketComment | undefined>;
  loading?: boolean;
  highlight?: boolean;
}

export default function AddCommentItem({
  onSubmit,
  highlight,
}: AddCommentItemProps) {
  const [showEditor, setShowEditor] = useState(false);
  const [content, setContent] = useState('');
  const { classes } = useStyles();
  const { initials } = useAuthUser();
  const [attachments, setAttachments] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (htmlContent: string = '') => {
    setLoading(true);

    onSubmit(
      { content: htmlContent || content },
      attachments?.filter((attachment) => attachment.size)
    )
      .then(() => {
        setContent('');
        setShowEditor(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Paper radius="md" className={classes.comment}>
      <Stack spacing={12}>
        <Grid>
          <Grid.Col sm={2} md={1}>
            <Avatar radius="xl">{initials}</Avatar>
          </Grid.Col>
          <Grid.Col sm={10} md={11}>
            <Box>
              {!showEditor && (
                <Textarea
                  autosize
                  placeholder="Add comment..."
                  onFocus={() => setShowEditor(true)}
                  styles={(theme) => ({
                    input: {
                      backgroundColor: highlight
                        ? theme.colors.yellow[1]
                        : undefined,
                    },
                  })}
                />
              )}
              {showEditor && (
                <CommentRichTextEditor
                  content={content}
                  onChange={(value) => setContent(value)}
                  attachments={attachments}
                  setAttachments={setAttachments}
                  onCancel={() => {
                    setContent('');
                    setAttachments([]);
                    setShowEditor(false);
                  }}
                  onSubmit={
                    content || attachments?.length
                      ? () => handleSubmit?.()
                      : undefined
                  }
                  loading={loading}
                  getKeyboardShortcuts={(editor) => ({
                    'Shift-Enter': () => {
                      handleSubmit(editor.getHTML());
                      return true;
                    },
                  })}
                />
              )}
            </Box>
          </Grid.Col>
        </Grid>
      </Stack>
    </Paper>
  );
}
