/**
 * @param {number} value - The value to round
 * @param {number} multiple - The multiple to round the value with
 * @param {boolean} roundDown - Whether to round down or now
 */
export default function roundToNearestMultiple(
  value: number,
  multiple: number,
  roundDown: boolean = false
) {
  const mod = value % multiple;

  if (value === 0 || mod === 0 || multiple === 0) return value;

  if (roundDown) {
    return value - mod;
  }

  return value + (multiple - mod);
}
