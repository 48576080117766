import { Popover, UnstyledButton } from '@mantine/core';

import { PopOverProps } from '../../types';
import PopOverTarget from './components/PopOverTarget';
import PopOverDropdown from './components/PopOverDropdown';

interface TargetProps {}
interface DropdownProps {}

export default function BasePopOver<DP = DropdownProps, TP = TargetProps>({
  TargetComponent = PopOverTarget,
  DropdownComponent = PopOverDropdown,
  popoverProps,
  targetProps,
  dropdownProps,
  width = 200,
}: PopOverProps<TP, DP>) {
  return (
    <Popover
      width={width}
      position="bottom"
      withArrow
      shadow="md"
      {...popoverProps}
    >
      <Popover.Target>
        <UnstyledButton
          onClick={() => popoverProps?.onChange?.(!popoverProps?.opened)}
        >
          <TargetComponent {...targetProps} />
        </UnstyledButton>
      </Popover.Target>
      <Popover.Dropdown>
        <DropdownComponent {...dropdownProps} />
      </Popover.Dropdown>
    </Popover>
  );
}
