import { useCallback } from 'react';
import formatRelative from 'date-fns/formatRelative';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';

import formatTimeZone from 'helpers/datetime/formatTimeZone';
import convertTimeZone from 'helpers/datetime/convertTimeZone';

import { ENDPOINTS } from 'constants/api';
import type { InstanceUser } from 'types/api';
import { ApiQueryKey } from 'types/api/query';

import useApiQuery from 'hooks/api/useApiQuery';
import useInstanceConfig from 'hooks/instance/useInstanceConfig';

export interface UserHookParams {
  userId: number | string;
  params?: any;
  storeKey?: ApiQueryKey;
}

export default function useUser({ userId, params, storeKey }: UserHookParams) {
  const { dateDisplay } = useInstanceConfig();

  const queryResult = useApiQuery<{ user: InstanceUser }>({
    path: ENDPOINTS.USER,
    pathParams: {
      userId: `${userId}`,
    },
    queryOptions: {
      // handle null / undefined cases
      enabled: Boolean(userId?.toString()),
    },
    axiosConfig: {
      params,
    },
    storeKey: storeKey,
  });

  const formatUserTimezoneRelative = useCallback(
    (date: Date) => {
      const user = queryResult.data?.user;

      const diff = differenceInDays(date, new Date());

      if (Math.abs(diff) > 6) {
        return format(date, dateDisplay);
      }

      return formatRelative(
        convertTimeZone(date, user?.timezone || 'America/New_York'),
        convertTimeZone(new Date(), user?.timezone || 'America/New_York')
      );
    },
    [queryResult, dateDisplay]
  );

  const formatUserTimezone = useCallback(
    (date: Date, formatString: string) => {
      const user = queryResult.data?.user;

      try {
        return formatTimeZone(
          date,
          user?.timezone || 'America/New_York',
          formatString
        );
      } catch (err) {
        return date?.toString();
      }
    },
    [queryResult.data?.user]
  );

  return {
    ...queryResult,
    formatUserTimezone,
    formatUserTimezoneRelative,
  };
}
