import { notifications } from '@mantine/notifications';
import { AxiosError } from 'axios';
import { capitalize } from 'lodash';

export default function showApiErrNotif(message: string, err: any) {
  const error = err as AxiosError<{ errors: { [key: string]: string[] } }>;
  const errors = error.response?.data?.errors as { [key: string]: string[] };

  let displayMessage = message;

  if (typeof errors === 'object') {
    displayMessage = '';

    Object.entries(errors).forEach(([key, value]) => {
      notifications.show({
        message: `${capitalize(key)}: ${value.join(',')}`,
        color: 'red',
      });
    });

    return;
  }

  if (err?.response?.data?.message) {
    displayMessage = err.response.data.message;
  }

  notifications.show({
    message: displayMessage,
    color: 'red',
  });
}
