import { notifications } from '@mantine/notifications';

import { ENDPOINTS } from 'constants/api';
import type { Invoice } from 'types/api';
import { ApiQueryKey } from 'types/api/query';

import constructQueryPath from 'helpers/api/constructQueryPath';
import showApiErrNotif from 'helpers/api/showApiErrNotif';
import * as billingUtils from 'utils/billing';
import * as fileUtils from 'utils/file';

import useApiQuery from 'hooks/api/useApiQuery';
import useApiStore from 'hooks/store/useApiStore';

interface InvoiceHookParams {
  invoiceId?: number;
  params?: any;
  storeKey?: ApiQueryKey;
}

export default function useInvoice({
  invoiceId,
  params,
  storeKey,
}: InvoiceHookParams) {
  const { invalidate } = useApiStore();
  const queryResult = useApiQuery<{ invoice?: Invoice }>({
    path: constructQueryPath(ENDPOINTS.INVOICE, { invoiceId: `${invoiceId}` }),
    axiosConfig: {
      params,
    },
    queryOptions: {
      enabled: Boolean(invoiceId),
    },
    storeKey: storeKey,
  });

  const updateInvoiceStatus = async (status: 'approved' | 'voided') => {
    try {
      await billingUtils.updateInvoiceStatus(`${invoiceId}`, status);
      invalidate('list-invoices');
      queryResult.refetch();
      notifications.show({
        message: 'Invoice status successfully updated!',
        color: 'green',
      });
      return true;
    } catch (err) {
      showApiErrNotif('Unable to update invoice status.', err);
    }

    return false;
  };

  const downloadInvoice = async () => {
    try {
      await fileUtils.downloadPDF(
        constructQueryPath(ENDPOINTS.INVOICE, { invoiceId: `${invoiceId}` }),
        queryResult?.data?.invoice?.filename
      );
      notifications.show({
        message: 'Invoice successfully downloaded!',
        color: 'green',
      });
    } catch (err) {
      showApiErrNotif('Unable to download invoice.', err);
    }
  };

  const voidInvoice = async () => {
    try {
      if (!invoiceId) throw new Error('Invalid Invoice ID');

      await billingUtils.voidInvoice(invoiceId as number);

      if (queryResult?.data?.invoice?.status !== 'draft') {
        queryResult.refetch();
      }

      invalidate('list-invoices');

      return true;
    } catch (err) {
      showApiErrNotif('', err);
    }
  };

  const sendInvoice = async (values: {
    emails: string[];
    invoice_email_topic: string;
    invoice_email_body: string;
  }) => {
    try {
      if (!invoiceId) throw new Error('Invalid Invoice ID');

      await billingUtils.sendInvoice(`${invoiceId}`, values);
      queryResult.refetch();
      invalidate('list-invoices');
      notifications.show({
        message: 'Invoice successfully sent!',
        color: 'green',
      });
      return true;
    } catch (err) {
      showApiErrNotif(
        'Unable to send invoice at this time. Try again later',
        err
      );
    }
    return false;
  };

  const addInvoicePayment = async (amount: number) => {
    try {
      if (!invoiceId) throw new Error('Invalid Invoice ID');

      await billingUtils.addInvoicePayment(`${invoiceId}`, amount);
      notifications.show({
        message: 'Payment successfully added!',
        color: 'green',
      });
      return true;
    } catch (err) {
      showApiErrNotif(
        'Unable to add invoice payment at this time. Try again later',
        err
      );
    }

    return false;
  };

  return {
    ...queryResult,
    updateInvoiceStatus,
    downloadInvoice,
    voidInvoice,
    sendInvoice,
    addInvoicePayment,
  };
}
