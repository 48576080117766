import PopOverProvider, { usePopOverContext } from 'contexts/PopOverProvider';
import LineItemDiscountPopOverUI from './components/PopOverUI';
import { InvoiceLineItem } from 'types/api';

interface LineItemDiscountPopOverProps {
  onChange: (lineItem: Partial<InvoiceLineItem>) => void;
  opened?: boolean;
  value?: number;
  lineItem?: Partial<InvoiceLineItem>;
  PopOverTarget?: () => JSX.Element;
}

function LineItemDiscountPopOverContent({
  ...props
}: LineItemDiscountPopOverProps) {
  const { isOpen, showPopOver } = usePopOverContext();
  return (
    <LineItemDiscountPopOverUI
      {...props}
      opened={isOpen}
      onPopOverChange={showPopOver}
    />
  );
}

export default function LineItemDiscountPopOver({
  ...props
}: LineItemDiscountPopOverProps) {
  return (
    <PopOverProvider>
      <LineItemDiscountPopOverContent {...props} />
    </PopOverProvider>
  );
}
