import { useMemo } from 'react';

import { SelectProps } from 'components/common/Select/types';
import BaseSelect from 'components/common/Select/variants/BaseSelect';

import useProjectTicketStatuses from 'hooks/tickets/useProjectTicketStatuses';

interface TicketStatusSelectProps extends SelectProps {
  projectId?: number | string;
}

export default function ProjectTicketStatusSelect({
  onChange,
  projectId,
  defaultValue,
  label,
  filter = () => true,
}: TicketStatusSelectProps) {
  const { data } = useProjectTicketStatuses({ projectId: projectId as number });

  const options = useMemo(() => {
    return data?.ticket_statuses?.filter(filter)?.map((ticketStatus) => {
      return {
        value: ticketStatus,
        label: ticketStatus,
      };
    });
  }, [data?.ticket_statuses, filter]);

  return (
    <BaseSelect
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      label={label}
    />
  );
}
