import { BaseChangeLogProps } from './types';

import BaseChangeLog from './variants/BaseChangeLog';

interface ChangeLogProps extends BaseChangeLogProps {
  variant?: 'base';
}

export default function ChangeLog({ ...props }: ChangeLogProps) {
  return <BaseChangeLog {...props} />;
}
