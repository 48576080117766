import { Paper } from '@mantine/core';

import { ModalFooterProps } from '../../types';

export default function ModalFooter({ children }: ModalFooterProps) {
  return (
    <Paper withBorder sx={{ borderRadius: 0, padding: 8 }}>
      {children}
    </Paper>
  );
}
