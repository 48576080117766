import { ENDPOINTS } from 'constants/api';
import type { ClientProject } from 'types/api';

import useApiQuery from 'hooks/api/useApiQuery';
import { ApiQueryKey } from 'types/api/query';

export interface ProjectHookParams {
  projectId?: number | string;
  storeKey?: ApiQueryKey;
  refetchOnMount?: boolean;
}

export default function useProject({
  projectId,
  storeKey,
  refetchOnMount,
}: ProjectHookParams) {
  const queryResult = useApiQuery<{ project?: ClientProject }>({
    path: ENDPOINTS.PROJECT,
    pathParams: {
      projectId: `${projectId}`,
    },
    queryOptions: {
      // handle null / undefined cases
      enabled: Boolean(projectId),
      refetchOnMount,
    },
    storeKey,
  });

  return {
    ...queryResult,
  };
}
