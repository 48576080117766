import { Stack, Button, Box } from '@mantine/core';
import { useState } from 'react';

import InstanceUserSelect from 'components/common/Select/variants/InstanceUserSelect';

import { UserPopOverDropdownProps } from '../types';

function PopOverDropdownForm({
  onClose,
  onSubmit,
  additionalProps = {},
}: UserPopOverDropdownProps) {
  const [user, setUser] = useState<string>();
  const { selectProps } = additionalProps;

  return (
    <Stack sx={(theme) => ({ color: theme.black })} spacing={8}>
      <InstanceUserSelect
        label="User"
        onChange={(option) => setUser(option.value?.toString()?.trim())}
        {...selectProps}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          size="xs"
          variant="outline"
          sx={(theme) => ({ marginRight: theme.spacing.xs })}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button size="xs" onClick={() => onSubmit?.(user as string)}>
          Submit
        </Button>
      </Box>
    </Stack>
  );
}

export default function PopOverDropdown({
  onClose,
  onSubmit,
  additionalProps,
}: UserPopOverDropdownProps) {
  const handleSubmit = async (userId: string) => {
    await onSubmit?.(userId);
    onClose?.();
  };

  return (
    <PopOverDropdownForm
      onClose={onClose}
      additionalProps={additionalProps}
      onSubmit={handleSubmit}
    />
  );
}
