import {
  Box,
  Checkbox,
  Grid,
  Text,
  Tooltip,
  createStyles,
  rem,
} from '@mantine/core';
import { useState } from 'react';

import BillingTypeDisplay from './BillingTypeDisplay';
import ContentSection from './ContentSection';
import { BILLING_TYPE } from 'constants/billing';
import { ProjectTicket } from 'types/api';
import useTicketBillingRecordsAggregate from 'hooks/billing/useTicketBillingRecordsAggregate';
import { TicketBody } from 'utils/ticket';

import { MODALS } from 'constants/component';
import { TICKET_PRIORITY_LABELS } from 'constants/ticket';
import ColorSlider from 'components/common/Slider/variants/ColorSlider';
import useModalStore from 'hooks/store/useModalStore';
import { BillingType } from 'types/app';
import useProjectPageStore from 'hooks/store/useProjectPageStore';
import { BillableType } from '../types';
import useBillingRecords from 'hooks/billing/useBillingRecords';

const useStyles = createStyles((theme) => ({
  label: {
    fontWeight: 600,
    fontSize: theme.fontSizes.sm,
  },
}));

interface BillingSectionProps {
  ticket?: ProjectTicket;
  handleTicketUpdate: (ticketBody: Partial<TicketBody>) => Promise<boolean>;
}

const labelWidth = 5;
const inputWidth = 12 - labelWidth;

export default function BillingSection({
  ticket,
  handleTicketUpdate,
}: BillingSectionProps) {
  const { classes } = useStyles();
  const { ruleErrors } = useProjectPageStore();
  const [expenseBillableType, setExpenseBillableType] =
    useState<BillableType>('all');
  const [timeBillableType, setTimeBillableType] = useState<BillableType>('all');
  const { data, isLoading: billingRecordsLoading } = useBillingRecords(
    {
      params: { ticket_id: ticket?.id },
      storeKey: 'edit-ticket-billing-records',
    },
    !ticket?.id
  );
  const { totalTime, totalExpense } = useTicketBillingRecordsAggregate({
    billingRecords: data?.billing_records || [],
    expenseBillableType,
    timeBillableType,
  });
  const { pushModal } = useModalStore();

  const handleCheck = async (checked: boolean) => {
    if (ticket?.billable !== checked) {
      await handleTicketUpdate({ billable: checked });
    }
  };

  const handleAmountClick = (billingType: BillingType) => {
    const modalId =
      billingType === BILLING_TYPE.EXPENSE
        ? MODALS.LIST_EXPENSES_MODAL
        : MODALS.LIST_BILLING_RECORDS_MODAL;
    pushModal(modalId, {
      ticket: ticket || {},
      params: {
        ticket_id: ticket?.id,
      },
    });
  };

  const handleHideFromClientCheck = async (hidden: boolean) => {
    await handleTicketUpdate({
      hide_from_client: hidden,
    });
  };

  const handleBlockedCheck = async (blocked: boolean) => {
    await handleTicketUpdate({
      blocked: blocked,
    });
  };

  const handlePriorityChange = async (priority: number) => {
    await handleTicketUpdate({
      priority,
    });
  };

  const handleBillableTypeChange = (
    billableType: BillableType,
    billingType: BillingType
  ) => {
    let newBillableType: BillableType = 'all';

    if (billableType === 'all') {
      newBillableType = 'billable';
    } else if (billableType === 'billable') {
      newBillableType = 'non-billable';
    }

    if (billingType === 'expense') {
      setExpenseBillableType(newBillableType);
    } else if (billingType === 'time') {
      setTimeBillableType(newBillableType);
    }
  };

  return (
    <ContentSection title="Billing">
      <Grid>
        {ticket?.project?.billable && (
          <>
            <Grid.Col xs={labelWidth}>
              <Text className={classes.label}>Billable</Text>
            </Grid.Col>
            <Grid.Col xs={inputWidth}>
              <Tooltip
                position="top-start"
                label={
                  ticket?.project?.billable
                    ? 'Is this ticket billable?'
                    : 'Project is not billable.'
                }
              >
                <Box>
                  <Checkbox
                    color="green"
                    defaultChecked={Boolean(
                      ticket?.billable && ticket?.project?.billable
                    )}
                    onChange={(e) => handleCheck(e.target.checked)}
                    disabled={!ticket?.project?.billable}
                  />
                </Box>
              </Tooltip>
            </Grid.Col>
          </>
        )}
        <Grid.Col xs={labelWidth}>
          <Text className={classes.label}>Priority</Text>
        </Grid.Col>
        <Grid.Col xs={inputWidth}>
          <Box sx={{ paddingRight: rem(16) }}>
            <ColorSlider
              value={ticket?.priority}
              onChange={handlePriorityChange}
              label={(value) => {
                return TICKET_PRIORITY_LABELS[value - 1] || '';
              }}
            />
          </Box>
        </Grid.Col>
        <Grid.Col xs={labelWidth}>
          <Text
            sx={(theme) => ({
              fontWeight: 600,
              fontSize: theme.fontSizes.sm,
            })}
          >
            Hide From Client
          </Text>
        </Grid.Col>
        <Grid.Col xs={inputWidth}>
          <Checkbox
            color="green"
            defaultChecked={Boolean(ticket?.hide_from_client)}
            onChange={(e) => handleHideFromClientCheck(e.target.checked)}
          />
        </Grid.Col>
        <Grid.Col xs={labelWidth}>
          <Text
            sx={(theme) => ({
              fontWeight: 600,
              fontSize: theme.fontSizes.sm,
            })}
          >
            Stuck
          </Text>
        </Grid.Col>
        <Grid.Col xs={inputWidth}>
          <Checkbox
            color="green"
            defaultChecked={Boolean(ticket?.blocked)}
            onChange={(e) => handleBlockedCheck(e.target.checked)}
          />
        </Grid.Col>
        <Grid.Col xs={labelWidth}>
          <Text className={classes.label}>Time</Text>
        </Grid.Col>
        <Grid.Col xs={inputWidth}>
          <BillingTypeDisplay
            billingType={BILLING_TYPE.TIME}
            amount={totalTime}
            onClick={() => handleAmountClick(BILLING_TYPE.TIME)}
            ticket={ticket}
            highlight={ruleErrors.includes('billing_record_count')}
            billableType={timeBillableType}
            onBillableChange={(billableType) =>
              handleBillableTypeChange(billableType, 'time')
            }
            loading={billingRecordsLoading}
          />
        </Grid.Col>
        <Grid.Col xs={labelWidth}>
          <Text className={classes.label}>Expense</Text>
        </Grid.Col>
        <Grid.Col xs={inputWidth}>
          <BillingTypeDisplay
            billingType={BILLING_TYPE.EXPENSE}
            amount={totalExpense}
            onClick={() => handleAmountClick(BILLING_TYPE.EXPENSE)}
            ticket={ticket}
            billableType={expenseBillableType}
            onBillableChange={(billableType) =>
              handleBillableTypeChange(billableType, 'expense')
            }
            loading={billingRecordsLoading}
          />
        </Grid.Col>
      </Grid>
    </ContentSection>
  );
}
