import { useEffect, useMemo, useState } from 'react';
import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Grid,
  Group,
  NumberInput,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import LineItemDiscountPopOver from 'components/common/PopOver/variants/LineItemDiscountPopOver';
import CurrencyInput from 'components/common/UserInput/variants/CurrencyInput';
import { useFormikContext } from 'formik';

import { InvoiceLineItem } from 'types/api';
import { AddInvoiceRecordBody } from 'utils/billing';

const gridLabelProps = {
  size: 'xs',
  sx: { marginTop: 'auto' },
};

function InvLineItem({
  invoiceLineItem,
  onRemove,
  index,
}: {
  invoiceLineItem: Partial<InvoiceLineItem & { deleted?: boolean }>;
  onRemove: () => void;
  index: number;
}) {
  const { handleChange, values, setFieldValue } =
    useFormikContext<AddInvoiceRecordBody>();
  const [description, setDescription] = useState<string>(
    invoiceLineItem.description || ''
  );
  const [quantity, setQuantity] = useState<number>(
    invoiceLineItem.quantity || 0
  );
  const [cost, setCost] = useState<number>(invoiceLineItem.cost || 0);
  const [tax, setTax] = useState<number>(invoiceLineItem.tax || 0);

  const amount = useMemo(() => {
    return (invoiceLineItem.cost || 0) * (invoiceLineItem.quantity || 0);
  }, [invoiceLineItem.cost, invoiceLineItem.quantity]);

  useEffect(() => {
    const invItem = values.invoiceLineItems[index];
    setDescription(invItem.description || '');
    setQuantity(invItem.quantity || 0);
    setCost(invItem.cost || 0);
    setTax(invItem.tax || 0);
  }, [index, values]);

  if (invoiceLineItem.deleted) return <></>;

  return (
    <>
      <Grid.Col xs={2}>
        <TextInput
          size="xs"
          name={`invoiceLineItems.${index}.description`}
          sx={{ marginTop: 'auto' }}
          value={description || ''}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          onBlur={(e) => {
            handleChange({
              target: {
                name: e.target.name,
                value: description,
              },
            });
          }}
        />
      </Grid.Col>
      <Grid.Col xs={1}>
        <NumberInput
          size="xs"
          name={`invoiceLineItems.${index}.quantity`}
          sx={{ marginTop: 'auto' }}
          value={quantity}
          onChange={(value) => {
            setQuantity(value || 0);
          }}
          onBlur={(e) => {
            handleChange({
              target: {
                name: e.target.name,
                value: quantity,
              },
            });
          }}
          readOnly={Boolean(invoiceLineItem.record_ids)}
          precision={2}
        />
      </Grid.Col>
      <Grid.Col xs={1}>
        <CurrencyInput
          size="xs"
          name={`invoiceLineItems.${index}.cost`}
          sx={{ marginTop: 'auto' }}
          value={cost / 100}
          onChange={(value) => {
            setCost((value || 0) * 100);
          }}
          onBlur={(e) => {
            handleChange({
              target: {
                name: e.target.name,
                value: cost,
              },
            });
          }}
          readOnly={Boolean(invoiceLineItem.record_ids)}
        />
      </Grid.Col>
      <Grid.Col xs={1}>
        <CurrencyInput
          readOnly
          size="xs"
          sx={{ marginTop: 'auto' }}
          value={amount / 100}
        />
      </Grid.Col>
      <Grid.Col xs={1}>
        <CurrencyInput
          size="xs"
          name={`invoiceLineItems.${index}.tax`}
          sx={{ marginTop: 'auto' }}
          value={tax / 100}
          onChange={(value) => {
            setTax((value || 0) * 100);
          }}
          onBlur={(e) => {
            handleChange({
              target: {
                name: e.target.name,
                value: tax,
              },
            });
          }}
        />
      </Grid.Col>
      <Grid.Col xs={1}>
        <Group>
          <Tooltip label="Remove Line Item">
            <ActionIcon onClick={onRemove} color="red">
              <IconTrash />
            </ActionIcon>
          </Tooltip>
          {(values.invoiceLineItems[index].cost || 0) > 0 && (
            <LineItemDiscountPopOver
              lineItem={values.invoiceLineItems[index]}
              onChange={(lineItem) => {
                const invoiceLineItems = values.invoiceLineItems.slice(
                  0,
                  index + 1
                );
                invoiceLineItems.push(
                  lineItem,
                  ...values.invoiceLineItems.slice(index + 1)
                );
                setFieldValue('invoiceLineItems', invoiceLineItems);
              }}
            />
          )}
        </Group>
      </Grid.Col>
    </>
  );
}

export default function InvoiceLineItems() {
  const { values, setFieldValue, handleChange } =
    useFormikContext<AddInvoiceRecordBody>();

  const filteredLineItems = useMemo(() => {
    return values.invoiceLineItems.filter((lineItem) => !lineItem.deleted);
  }, [values.invoiceLineItems]);

  const sumAmount = useMemo(() => {
    return filteredLineItems.reduce((a, b) => {
      return a + ((b.cost || 0) * (b.quantity || 0) || 0);
    }, 0);
  }, [filteredLineItems]);

  const sumTax = useMemo(() => {
    return filteredLineItems.reduce((a, b) => {
      return a + (b.tax || 0);
    }, 0);
  }, [filteredLineItems]);

  return (
    <Stack spacing={8}>
      <Box>
        <Text>Line Items</Text>
      </Box>
      <Box>
        <Group sx={(theme) => ({ paddingBottom: theme.spacing.md })}>
          <Button
            size="xs"
            leftIcon={<IconPlus />}
            onClick={() => {
              setFieldValue('invoiceLineItems', [
                ...values.invoiceLineItems,
                {
                  cost: 0,
                  description: `Line Item #${
                    values.invoiceLineItems.length + 1
                  }`,
                  quantity: 1,
                },
              ]);
            }}
          >
            Add Line Item
          </Button>
        </Group>
        <Grid columns={7}>
          <Grid.Col xs={2} sx={{ display: 'flex' }}>
            <Text {...gridLabelProps}>Description</Text>
          </Grid.Col>
          <Grid.Col xs={1}>
            <Text {...gridLabelProps}>Quantity</Text>
          </Grid.Col>
          <Grid.Col xs={1}>
            <Text {...gridLabelProps}>Cost</Text>
          </Grid.Col>
          <Grid.Col xs={1}>
            <Text {...gridLabelProps}>Amount</Text>
          </Grid.Col>
          <Grid.Col xs={1}>
            <Text {...gridLabelProps}>Tax</Text>
          </Grid.Col>
          <Grid.Col xs={1} />
          {values.invoiceLineItems.map((item, index) => (
            <InvLineItem
              key={`${item?.description}${item?.cost}`}
              index={index}
              invoiceLineItem={item}
              onRemove={() => {
                if (item?.id) {
                  handleChange({
                    target: {
                      name: `invoiceLineItems.${index}.deleted`,
                      value: true,
                    },
                  });
                } else {
                  const updatedItems = [...values.invoiceLineItems];
                  delete updatedItems[index];
                  setFieldValue(
                    'invoiceLineItems',
                    updatedItems.filter((item) => item)
                  );
                }
              }}
            />
          ))}
          {Boolean(sumTax || sumAmount) && (
            <>
              <Grid.Col xs={4} />
              <Grid.Col xs={3}>
                <Divider
                  labelPosition="center"
                  label="SUBTOTAL"
                  labelProps={{
                    color: 'gray',
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={4} />
              <Grid.Col xs={1}>
                <CurrencyInput readOnly value={sumAmount / 100} size="xs" />
              </Grid.Col>
              <Grid.Col xs={1}>
                <CurrencyInput readOnly value={sumTax / 100} size="xs" />
              </Grid.Col>
              <Grid.Col xs={4} />
              <Grid.Col xs={3}>
                <Divider
                  labelPosition="center"
                  label="DISCOUNT"
                  labelProps={{
                    color: 'gray',
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={5} />
              <Grid.Col xs={1} sx={{ textAlign: 'center' }}>
                {values.discount === 0 ? (
                  <LineItemDiscountPopOver
                    lineItem={{
                      cost: sumAmount + sumTax,
                      quantity: 1,
                    }}
                    onChange={(lineItem) => {
                      setFieldValue(
                        'discount',
                        Math.abs(lineItem.cost as number)
                      );
                    }}
                    PopOverTarget={() => {
                      return (
                        <Button size="xs" sx={{ width: '100%' }}>
                          Add Discount
                        </Button>
                      );
                    }}
                  />
                ) : (
                  <TextInput
                    readOnly
                    size="xs"
                    value={`$ ${((values.discount || 0) / 100).toFixed(2)}`}
                    rightSection={
                      <Tooltip label="Remove discount">
                        <ActionIcon
                          size="xs"
                          onClick={() => setFieldValue('discount', 0)}
                        >
                          <IconTrash />
                        </ActionIcon>
                      </Tooltip>
                    }
                  />
                )}
              </Grid.Col>
              <Grid.Col xs={4} />
              <Grid.Col xs={3}>
                <Divider
                  labelPosition="center"
                  label="TOTAL"
                  labelProps={{
                    color: 'gray',
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={5} />
              <Grid.Col xs={1}>
                <CurrencyInput
                  readOnly
                  value={(sumTax + sumAmount - (values.discount || 0)) / 100}
                  size="xs"
                />
              </Grid.Col>
            </>
          )}
        </Grid>
      </Box>
    </Stack>
  );
}
