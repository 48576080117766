import { useState } from 'react';
import { Badge, Box, Group, TextInput, rem, ActionIcon } from '@mantine/core';
import { IconX, IconArrowRight } from '@tabler/icons-react';

import { BaseTagsInputProps } from '../types';

interface TagRemoveButtonProps {
  onClick?: () => void;
}

function TagRemoveButton({ onClick }: TagRemoveButtonProps) {
  return (
    <ActionIcon
      size="xs"
      color="blue"
      radius="xl"
      variant="transparent"
      onClick={onClick}
    >
      <IconX size={rem(10)} />
    </ActionIcon>
  );
}

export default function BaseTagsInput({
  tags,
  onAdd,
  onRemove,
  inputProps,
}: BaseTagsInputProps) {
  const [tagValue, setTagValue] = useState('');

  const handleTagAdd = () => {
    if (tagValue) {
      onAdd?.(tagValue);
      setTagValue('');
    }
  };

  return (
    <Box>
      <TextInput
        {...inputProps}
        value={tagValue}
        onChange={(e) => setTagValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key.toLowerCase() === 'enter') {
            handleTagAdd();
          }
        }}
        rightSection={
          <ActionIcon
            color="blue"
            sx={(theme) => ({
              backgroundColor: theme.colors.blue,
              color: theme.white,
            })}
            onClick={handleTagAdd}
          >
            <IconArrowRight />
          </ActionIcon>
        }
      />
      <Group sx={(theme) => ({ paddingTop: theme.spacing.sm })}>
        {tags?.map((tag) => (
          <Badge
            key={tag}
            rightSection={<TagRemoveButton onClick={() => onRemove?.(tag)} />}
          >
            {tag}
          </Badge>
        ))}
      </Group>
    </Box>
  );
}
