import { ReactNode, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Group, Tabs, createStyles, Box } from '@mantine/core';
import { DragDropContext, OnDragEndResponder } from 'react-beautiful-dnd';
import { notifications } from '@mantine/notifications';

import Activity from './components/Activity';
import DraggableContainer from 'components/common/DraggableContainer';
import ConsoleLayout from 'layouts/ConsoleLayout';
import TicketGroup from './components/TicketGroup';
import TimeHistory from './components/TimeHistory';

import useProject from 'hooks/projects/useProject';
import useProjectTickets from 'hooks/tickets/useProjectTickets';
import useModalStore from 'hooks/store/useModalStore';
import useConfigStore from 'hooks/store/useConfigStore';
import useApiStore from 'hooks/store/useApiStore';
import useProjectPageStore from 'hooks/store/useProjectPageStore';

import { updateTicket } from 'utils/ticket';
import type { TicketGroup as TicketGroupType } from './components/TicketGroup/types';
import { HEADER_HEIGHT, MODALS, TABS_HEIGHT } from 'constants/component';
import Search from 'components/common/Search';
import ToggleButton from 'components/common/ToggleButton';
import DropdownButton from 'components/common/DropdownButton';

type ProjectTab = 'tickets' | 'time-records' | 'activity';

const tabs: ProjectTab[] = ['tickets', 'time-records', 'activity'];

const useStyles = createStyles((theme) => ({
  tabsList: {
    paddingLeft: theme.spacing.lg,
    backgroundColor: theme.white,
  },
  tab: {
    fontSize: theme.fontSizes.sm,
    height: TABS_HEIGHT,
    fontWeight: 'bold',
  },
}));

function TabPanel({ children }: { children: ReactNode }) {
  return (
    <DraggableContainer
      height={`calc(100vh - ${TABS_HEIGHT} - ${HEADER_HEIGHT})`}
    >
      {children}
    </DraggableContainer>
  );
}

export default function ProjectPage() {
  const { classes } = useStyles();
  const { invalidate } = useApiStore();
  const { projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pushModal } = useModalStore();
  const { data: projectData, isLoading: projectLoading } = useProject({
    projectId: projectId,
    storeKey: 'project-page',
  });
  const {
    data: ticketData,
    isLoading: ticketsLoading,
    rearrangeTickets,
  } = useProjectTickets({
    projectId: projectId,
    storeKey: 'list-project-tickets',
    params: {
      fields: [
        'id',
        'title',
        'status',
        'position',
        'is_done',
        'pub_id',
        'priority',
        'date_due',
        'assignee',
        'project_id',
        'comment_count',
        'estimate_count',
        'billing_record_count',
        'blocked',
        'unread_comment_count',
        'latest_unread_mention',
      ].join(','),
    },
  });
  const { setBreadcrumbsVar } = useConfigStore();
  const {
    setTicketGroups,
    moveTicket,
    getTicketGroups,
    setSearchValue,
    setRules,
    filters,
    setFilters,
  } = useProjectPageStore();
  const ticketGroups = getTicketGroups(projectId as string);
  const [activeTab, setActiveTab] = useState<ProjectTab>('tickets');

  const handleDragEnd: OnDragEndResponder = ({ destination, source }) => {
    const sourceGroup: TicketGroupType = ticketGroups[source.droppableId];
    const destGroup = ticketGroups[destination?.droppableId as string];

    if (sourceGroup && destGroup) {
      const ticket = sourceGroup.items[source.index];
      const isSame = sourceGroup.id === destGroup.id;

      try {
        moveTicket(
          projectId as string,
          sourceGroup.id,
          source.index,
          destGroup.id,
          destination?.index as number
        );

        const promises: Promise<any>[] = [];

        if (!isSame) {
          const isDone = destGroup.id === 'done';

          promises.push(
            updateTicket(ticket.id as number, {
              status: isDone
                ? sourceGroup.id?.toString()
                : destGroup.id?.toString(),
              is_done: isDone,
            })
          );
        }

        promises.push(
          rearrangeTickets(
            destGroup.items
              .slice()
              .sort((a, b) => (a.position || 0) - (b.position || 0))
              .map((item) => item.id as number)
          )
        );

        if (!isSame) {
          promises.push(
            rearrangeTickets(
              sourceGroup.items
                .slice()
                .sort((a, b) => (a.position || 0) - (b.position || 0))
                .map((item) => item.id as number)
            )
          );
        }

        const promise = async () => {
          for (let i = 0; i < promises.length; i++) {
            await promises[i];
          }
        };

        promise().then(() => {
          invalidate('edit-ticket-modal');
        });
      } catch (err) {
        const error = err as Error;
        notifications.show({
          message: error.message,
          color: 'red',
        });
      }
    }
  };

  const handleTabChange = (value: string) => {
    setActiveTab(value as ProjectTab);
    setSearchParams({
      tab: value,
    });
  };

  useEffect(() => {
    setRules(`${projectId}`, projectData?.project?.status_rules);
  }, [projectData?.project?.status_rules, projectId, setRules]);

  useEffect(() => {
    const project = projectData?.project;
    const tickets = ticketData?.tickets || [];
    const ticketStatuses = project?.ticket_statuses || [];

    const newTicketGroups: { [id: string]: TicketGroupType } = {};

    ticketStatuses.forEach((ticketStatus) => {
      newTicketGroups[`dnd-list-${ticketStatus}`] = {
        id: ticketStatus,
        name: ticketStatus,
        items: [],
      };
    });

    newTicketGroups['dnd-list-done'] = {
      id: 'done',
      name: 'Done',
      items: [],
      isDone: true,
    };

    tickets.forEach((ticket) => {
      let status = ticket.status;

      if (ticket.is_done) {
        status = 'done';
      }

      const groupKey = `dnd-list-${status}`;

      if (newTicketGroups[groupKey]) {
        newTicketGroups[groupKey].items.push(ticket);
      }
    });

    Object.values(newTicketGroups).map((group) => {
      group.items.sort((a, b) => (a.position || 0) - (b.position || 0));
    });

    setTicketGroups(projectId as string, newTicketGroups);

    // eslint-disable-next-line
  }, [projectData, ticketData]);

  useEffect(() => {
    if (projectData?.project?.title)
      setBreadcrumbsVar?.('projectTitle', projectData?.project?.title);
  }, [projectData, setBreadcrumbsVar]);

  useEffect(() => {
    setSearchValue('');

    const ticketId = searchParams.get('ticket_id');
    if (ticketId) {
      pushModal(MODALS.EDIT_TICKET_MODAL, { ticket: { id: ticketId } });
    }

    const tab = searchParams.get('tab') as ProjectTab;
    if (tabs.includes(tab)) {
      setActiveTab(tab);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ConsoleLayout
        disablePadding
        disableScroll
        loading={projectLoading || ticketsLoading}
      >
        <Tabs value={activeTab} onTabChange={handleTabChange}>
          <Tabs.List className={classes.tabsList}>
            {tabs.map((tab) => (
              <Tabs.Tab
                key={tab}
                value={tab}
                className={classes.tab}
                sx={(theme) => ({
                  color:
                    activeTab === tab
                      ? `${theme.colors.blue[8]} !important`
                      : theme.colors.gray[6],
                  textTransform: 'capitalize',
                })}
              >
                {tab.replace(/-/gm, ' ')}
              </Tabs.Tab>
            ))}
            <Box
              sx={(theme) => ({
                margin: 'auto',
                marginRight: theme.spacing.lg,
                display: 'flex',
              })}
            >
              <Box sx={{ marginTop: 'auto', marginBottom: 'auto' }}>
                <Search variant="icon" onSearch={setSearchValue} />
              </Box>
              <Group spacing={2}>
                <Box sx={{ marginTop: 'auto', marginBottom: 'auto' }}>
                  <ToggleButton
                    parentVariant="blockedticket"
                    value={filters.blocked as string}
                    setValue={(val) =>
                      setFilters({
                        ...filters,
                        blocked: val as typeof filters.blocked,
                      })
                    }
                  />
                </Box>
                <Box sx={{ marginTop: 'auto', marginBottom: 'auto' }}>
                  <DropdownButton
                    variant="projectpage"
                    additionalProps={{ project: projectData?.project }}
                  />
                </Box>
              </Group>
            </Box>
          </Tabs.List>

          <Tabs.Panel value="tickets">
            <TabPanel>
              <Group
                align="start"
                sx={(theme) => ({
                  flexWrap: 'nowrap',
                  padding: theme.spacing.lg,
                })}
              >
                <DragDropContext onDragEnd={handleDragEnd}>
                  {Object.values(ticketGroups).map((ticketGroup) => (
                    <TicketGroup
                      key={ticketGroup.id}
                      ticketGroup={ticketGroup}
                    />
                  ))}
                </DragDropContext>
              </Group>
            </TabPanel>
          </Tabs.Panel>

          <Tabs.Panel value="time-records">
            <TimeHistory
              projectId={
                activeTab === 'time-records' ? (projectId as string) : ''
              }
            />
          </Tabs.Panel>

          <Tabs.Panel value="activity">
            <Activity
              projectId={activeTab === 'activity' ? (projectId as string) : ''}
            />
          </Tabs.Panel>
        </Tabs>
      </ConsoleLayout>
    </>
  );
}
