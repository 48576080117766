import { useMemo } from 'react';
import parse from 'date-fns/parse';

import AvailabilityRecordsRow from './AvailabilityRecordsRow';

import { BaseTableProps, TableColumn } from 'components/common/Table/types';
import BaseTable from 'components/common/Table/variants/BaseTable';

import { AvailabilityRecord } from 'types/api';
import { DATE_FORMATS } from 'constants/date';
import { AvailabilityRecordRow } from '../../types';

interface AvailabilityRecordsTableProps
  extends Partial<BaseTableProps<AvailabilityRecordRow>> {
  availabilityRecords?: AvailabilityRecord[];
  columns?: TableColumn[];
}

export default function AvailabilityRecordsTable({
  availabilityRecords,
  columns = [
    {
      id: 'date_start',
      display: 'Days Off',
    },
    {
      id: 'description',
      display: 'Reason',
    },
    {
      id: 'status',
      display: 'Status',
    },
    {
      id: 'actions',
      display: '',
    },
  ],
  ...props
}: AvailabilityRecordsTableProps) {
  const rows = useMemo(() => {
    const availabilityRecordRows: { [year: number]: AvailabilityRecordRow } =
      {};

    availabilityRecords?.forEach((record) => {
      const date = parse(
        record.date_end || record.date_start,
        DATE_FORMATS.DATE_KEY,
        new Date()
      );
      const year = date.getFullYear();

      if (!availabilityRecordRows[year]) {
        availabilityRecordRows[year] = {
          id: year,
          year: year,
          availabilityRecords: [],
        };
      }

      availabilityRecordRows[year].availabilityRecords.push(record);
    });

    return Object.values(availabilityRecordRows).reverse();
  }, [availabilityRecords]);

  return (
    <BaseTable<AvailabilityRecordRow>
      {...props}
      columns={columns}
      rows={rows}
      RowComponent={AvailabilityRecordsRow}
      tableProps={{
        sx: {
          tableLayout: 'fixed',
        },
      }}
    />
  );
}
