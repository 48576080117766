import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { Stack, Textarea, rem } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { Formik, FormikProps, useFormikContext } from 'formik';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import * as Yup from 'yup';

import BaseModal from '../BaseModal';
import ModalFooter from './ModalFooter';

import { DATE_FORMATS } from 'constants/date';
import { MODALS } from 'constants/component';

import useModalStore from 'hooks/store/useModalStore';
import useAvailabilityRecords from 'hooks/users/useAvailabilityRecords';
import { AvailabilityRecordUpdateBody } from 'utils/user';
import { AvailabilityRecord } from 'types/api';
import getUserFullName from 'helpers/display/getUserFullName';

const validationSchema = Yup.object().shape({
  description: Yup.string(),
  date_start: Yup.string().required('Date is required'),
  date_end: Yup.string().nullable(),
});

type FormValues = Partial<AvailabilityRecordUpdateBody>;

function EditAvailabilityRecordForm({
  baseDateRange,
}: {
  baseDateRange: Date[];
}) {
  const [dateRange, setDateRange] = useState<Date[]>(baseDateRange);
  const { values, handleChange, setFieldValue, errors } =
    useFormikContext<FormValues>();

  const handleDateChange = (dateRange: [Date, Date]) => {
    const [start, end] = dateRange;

    if (!start && !end) return;

    setDateRange(dateRange);

    setFieldValue('date_start', format(start, DATE_FORMATS.DATE_KEY));

    if (end) {
      setFieldValue('date_end', format(end, DATE_FORMATS.DATE_KEY));
    } else {
      setFieldValue('date_end', null);
    }
  };

  useEffect(() => {
    setDateRange(baseDateRange);
  }, [baseDateRange]);

  return (
    <Stack sx={() => ({ padding: 16, paddingBottom: 32 })} spacing={8}>
      <DatePickerInput
        required
        type="range"
        name="date_range"
        label="Days to Take Off"
        value={dateRange as [Date, Date]}
        labelSeparator={
          dateRange.filter((d) => d).length === 2 ? undefined : ''
        }
        onChange={handleDateChange}
        error={errors.date_start || errors.date_end}
      />
      <Textarea
        name="description"
        label="Reason"
        value={values.description}
        onChange={handleChange}
        error={errors.description}
      />
    </Stack>
  );
}

interface EditAvailabilityRecordModalProps {
  availabilityRecord?: AvailabilityRecord;
}

function EditAvailabilityRecordModal({
  availabilityRecord,
}: EditAvailabilityRecordModalProps) {
  const formikRef =
    useRef() as MutableRefObject<FormikProps<FormValues> | null>;
  const { popModal } = useModalStore();
  const modal = useModal();
  const { updateAvailabilityRecord } = useAvailabilityRecords();

  const handleClose = () => {
    popModal(MODALS.EDIT_AVAILABILITY_RECORD_MODAL);
    formikRef.current?.resetForm();
  };

  const handleSubmit = async (values: FormValues) => {
    if (
      await updateAvailabilityRecord(availabilityRecord?.id as number, values)
    ) {
      handleClose();
    }
  };

  const initialValues: FormValues = useMemo(() => {
    return {
      description: availabilityRecord?.description || '',
      date_start: availabilityRecord?.date_start || undefined,
      date_end: availabilityRecord?.date_end || undefined,
    };
  }, [
    availabilityRecord?.date_end,
    availabilityRecord?.date_start,
    availabilityRecord?.description,
  ]);

  const baseDateRange = useMemo(() => {
    const dateRange: Date[] = [];
    const dateStart = availabilityRecord?.date_start;
    const dateEnd = availabilityRecord?.date_end;

    if (dateStart) {
      dateRange.push(parse(dateStart, DATE_FORMATS.DATE_KEY, new Date()));
    }

    if (dateEnd) {
      dateRange.push(parse(dateEnd, DATE_FORMATS.DATE_KEY, new Date()));
    }

    return dateRange;
  }, [availabilityRecord?.date_end, availabilityRecord?.date_start]);

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
    >
      <BaseModal
        id={MODALS.EDIT_AVAILABILITY_RECORD_MODAL}
        isOpen={modal.visible}
        onClose={handleClose}
        title={`Edit Availability Record for ${getUserFullName(
          availabilityRecord?.instance_user
        )}`}
        height={rem(464)}
        size="40vw"
        FooterComponent={ModalFooter}
      >
        <EditAvailabilityRecordForm baseDateRange={baseDateRange} />
      </BaseModal>
    </Formik>
  );
}

export default NiceModal.create((props) => (
  <EditAvailabilityRecordModal {...props} />
));
