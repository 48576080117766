import { ActionIcon, Tooltip } from '@mantine/core';
import {
  IconArrowBigDownFilled,
  IconArrowBigDownLinesFilled,
  IconArrowBigDown,
} from '@tabler/icons-react';
import { useSpring, animated } from '@react-spring/web';

import { BasePriorityAmountProps } from '../types';
import { useMemo } from 'react';

export default function IconPrioritySelector({
  amount,
  setAmount,
}: BasePriorityAmountProps) {
  const props = useSpring({
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(180deg)',
    },
    reverse: amount < 1,
    immediate: amount === 0,
  });

  const Icon = useMemo(() => {
    if (amount === 0 || amount === 3) {
      return IconArrowBigDownFilled;
    }

    if (amount === 1 || amount === 2) {
      return IconArrowBigDown;
    }

    if (amount === 4) {
      return IconArrowBigDownLinesFilled;
    }

    return null;
  }, [amount]);

  const handleClick = () => {
    setAmount((amount + 1) % 5);
  };

  const getColor = () => {
    if (amount < 2) {
      return 'blue';
    }

    return 'red';
  };

  return (
    Icon && (
      <Tooltip label={`P${5 - amount}`}>
        <animated.div style={props}>
          <ActionIcon
            size="xs"
            radius="xl"
            color={getColor()}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              handleClick();
            }}
          >
            <Icon />
          </ActionIcon>
        </animated.div>
      </Tooltip>
    )
  );
}
