import { ReactNode } from 'react';
import { Text, Divider, Stack, Box } from '@mantine/core';

interface SubSectionProps {
  title?: string | ReactNode;
  children?: ReactNode;
}

export default function SubSection({ children, title }: SubSectionProps) {
  return (
    <Box sx={(theme) => ({ marginLeft: theme.spacing.sm })}>
      <Text
        weight="bold"
        sx={(theme) => ({
          lineHeight: '100%',
          fontSize: theme.fontSizes.sm,
          color: theme.colors.gray[8],
        })}
      >
        {title}
      </Text>
      <Divider
        sx={(theme) => ({
          marginTop: theme.spacing.sm,
          marginBottom: theme.spacing.sm,
        })}
      />
      <Stack spacing={12}>{children}</Stack>
    </Box>
  );
}
