import { SelectOption, SelectProps } from '../../types';
import BaseSelect from '../BaseSelect';

interface AvailabilityRecordStatusSelectProps extends SelectProps {}

export default function AvailabilityRecordStatusSelect({
  onChange,
  defaultValue,
  label,
  ...props
}: AvailabilityRecordStatusSelectProps) {
  const options: SelectOption[] = [
    {
      label: 'Approved',
      value: 'approved',
    },
    {
      label: 'Denied',
      value: 'denied',
    },
  ];

  return (
    <BaseSelect
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      label={label}
      {...props}
    />
  );
}
