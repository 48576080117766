import { useMemo } from 'react';

import { SelectOption, SelectProps } from '../../types';
import BaseSelect from '../BaseSelect';

import countriesJSON from 'jsons/countries.json';

interface CountrySelectProps extends SelectProps {
  defaultFirst?: boolean;
}

export default function CountrySelect({
  defaultFirst,
  onChange,
  defaultValue,
  label,
  ...props
}: CountrySelectProps) {
  const options: SelectOption[] = useMemo(() => {
    return (
      countriesJSON?.map((country) => {
        return {
          value: country.code,
          label: country.name,
        };
      }) || []
    );
  }, []);

  return (
    <BaseSelect
      defaultFirst={defaultFirst}
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      label={label}
      {...props}
    />
  );
}
