import { useState } from 'react';

import BasePopOver from '../BasePopOver';
import PopOverDropdown from './components/PopOverDropdown';
import PopOverTarget from './components/PopOverTarget';

interface UserPopOverProps {
  updateUserSubscription?: (userId: string) => Promise<void>;
  filter?: (value: string) => boolean;
}

export default function UserPopOver({
  updateUserSubscription,
  filter,
}: UserPopOverProps) {
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <BasePopOver
      TargetComponent={PopOverTarget}
      DropdownComponent={PopOverDropdown}
      popoverProps={{
        closeOnClickOutside: false,
        opened: opened,
      }}
      dropdownProps={{
        onClose: () => setOpened(false),
        onSubmit: updateUserSubscription,
        additionalProps: {
          selectProps: {
            filter,
          },
        },
      }}
      targetProps={{
        onClick: () => setOpened(!opened),
      }}
    />
  );
}
