import { MutableRefObject, useEffect, useRef } from 'react';
import { Stack, TextInput, Button, Box } from '@mantine/core';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import JobTypeSelect from 'components/common/Select/variants/JobTypeSelect';

import { PopOverDropdownProps } from '../types';
import useTimeInput from 'hooks/common/useTimeInput';
import { TicketEstimateBody } from 'utils/ticket';

const validationSchema = Yup.object().shape({
  time: Yup.number()
    .required('Time is required')
    .moreThan(0, 'Time must be greater than zero'),
  job_type: Yup.string().required('Job type is required'),
});

function PopOverDropdownForm({
  onClose,
  additionalProps = {},
}: PopOverDropdownProps) {
  const { values, setFieldValue, submitForm, isSubmitting, errors, touched } =
    useFormikContext<TicketEstimateBody>();
  const { inputValue, value, handleBlur, handleChange } = useTimeInput({
    defaultValue: values.time,
  });
  const { selectProps } = additionalProps;
  const timeRef = useRef({ shouldSubmit: false }) as MutableRefObject<{
    shouldSubmit: boolean;
  }>;

  useEffect(() => {
    if (value !== values.time) {
      setFieldValue('time', value).then(() => {
        if (timeRef.current.shouldSubmit) {
          submitForm();
          timeRef.current.shouldSubmit = false;
        }
      });
    }

    // eslint-disable-next-line
  }, [value, values.time]);

  return (
    <Stack sx={(theme) => ({ color: theme.black })} spacing={8}>
      <JobTypeSelect
        defaultFirst
        defaultValue={values.job_type}
        label="Job Type"
        onChange={(option) =>
          setFieldValue('job_type', option.value?.toString()?.trim())
        }
        {...selectProps}
        error={touched.job_type && errors.job_type}
      />
      <TextInput
        required
        label="Time (HR)"
        labelProps={{ style: { fontWeight: 'bold', fontSize: '12px' } }}
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleBlur();
            timeRef.current.shouldSubmit = true;
          }
        }}
        error={touched.time && errors.time}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          size="xs"
          variant="outline"
          sx={(theme) => ({ marginRight: theme.spacing.xs })}
          onClick={onClose}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button size="xs" onClick={submitForm} loading={isSubmitting}>
          Save
        </Button>
      </Box>
    </Stack>
  );
}

export default function PopOverDropdown({
  onClose,
  onSubmit,
  additionalProps,
  initialValues,
}: PopOverDropdownProps) {
  const handleSubmit = async (values: Partial<TicketEstimateBody>) => {
    const success = await onSubmit?.(values as TicketEstimateBody);

    if (success) {
      onClose?.();
    }
  };

  return (
    <Formik<Partial<TicketEstimateBody>>
      enableReinitialize
      initialValues={
        initialValues || {
          time: 0,
          job_type: undefined,
        }
      }
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <PopOverDropdownForm
        onClose={onClose}
        additionalProps={additionalProps}
      />
    </Formik>
  );
}
