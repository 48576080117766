import { Box, Text } from '@mantine/core';

import { TicketAssignment } from 'types/api';

interface AssignmentItemProps {
  assignment: TicketAssignment;
  index: number;
}

export default function AssignmentItem({ assignment }: AssignmentItemProps) {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.colors.blue[1],
        borderRadius: '4px',
        padding: 4 * assignment.time,
        textAlign: 'center',
      })}
    >
      <Text>{assignment.job_type}</Text>
      <Text>{assignment.time} HR</Text>
    </Box>
  );
}
