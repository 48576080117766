import { Group, Button } from '@mantine/core';
import { useFormikContext } from 'formik';

import BaseModalFooter from '../BaseModal/ModalFooter';

import { MODALS } from 'constants/component';
import { SendInvoiceFormValues } from './types';

import useModalStore from 'hooks/store/useModalStore';

export default function ModalFooter() {
  const { submitForm, isSubmitting, values, status } =
    useFormikContext<SendInvoiceFormValues>();
  const { popModal } = useModalStore();

  const handleCancel = () => {
    if (!isSubmitting) {
      popModal(MODALS.SEND_INVOICE_MODAL);
    }
  };

  const getButtonText = () => {
    if (status.tabIndex === 0) {
      return 'Preview';
    }

    if (values.sendBy === 'mailto') {
      return 'Mark as Sent';
    }

    return 'Send';
  };

  return (
    <BaseModalFooter>
      <Group sx={{ justifyContent: 'flex-end' }}>
        <Button
          size="xs"
          variant="outline"
          onClick={handleCancel}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button size="xs" onClick={submitForm} loading={isSubmitting}>
          {getButtonText()}
        </Button>
      </Group>
    </BaseModalFooter>
  );
}
