import AvailabilityRecordsTable from 'components/common/Table/variants/AvailabilityRecordsTable';

import useAvailabilityRecords from 'hooks/users/useAvailabilityRecords';

interface AvailabilityTabProps {
  userId?: string;
}

export default function AvailabilityTab({ userId }: AvailabilityTabProps) {
  const { data } = useAvailabilityRecords({
    params: { sort: '-date_start', user_id: userId },
  });

  return (
    <AvailabilityRecordsTable
      availabilityRecords={data?.availability_records}
    />
  );
}
