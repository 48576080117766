import { Group } from '@mantine/core';

import { SelectOption } from 'components/common/Select/types';
import InstanceUserSelect from 'components/common/Select/variants/InstanceUserSelect';
import UnassignedList from '../UnassignedList';

interface ActionHeaderProps {
  selectedUser?: SelectOption;
  setSelectedUser?: (selected: SelectOption) => void;
}

export default function ActionHeader({ setSelectedUser }: ActionHeaderProps) {
  return (
    <Group>
      <UnassignedList />
      <InstanceUserSelect label="Employee" onChange={setSelectedUser} />
    </Group>
  );
}
