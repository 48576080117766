import { useMemo } from 'react';
import { Loader, SelectItem } from '@mantine/core';

import BaseMultiSelect from '../BaseMultiSelect';

import useUsers from 'hooks/users/useUsers';

import { MultiSelectProps } from '../../types';
import { InstanceUser } from 'types/api';
import getUserFullName from 'helpers/display/getUserFullName';

export default function UserMultiSelect({
  filterBy = () => true,
  ...props
}: Partial<MultiSelectProps>) {
  const { data, isLoading } = useUsers();
  const users: SelectItem[] = useMemo(() => {
    return (
      data?.users
        ?.map((user: InstanceUser) => {
          return {
            value: user.id?.toString() || '',
            label: getUserFullName(user),
          };
        })
        ?.filter(filterBy) || []
    );
  }, [data?.users, filterBy]);

  return (
    <BaseMultiSelect
      {...props}
      data={users}
      rightSection={isLoading ? <Loader size="xs" /> : undefined}
    />
  );
}
