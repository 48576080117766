import { useMemo } from 'react';
import { Box, Grid, Stack, TextInput, Textarea, Text } from '@mantine/core';
import { useFormikContext } from 'formik';

import generateMailtoLink from 'helpers/display/generateMailtoLink';
import { SendInvoiceFormValues } from '../types';

function LinkPreview() {
  const { values } = useFormikContext<SendInvoiceFormValues>();

  const link = useMemo(() => {
    return generateMailtoLink(
      values.emails || [],
      values.emailContent.subject || '',
      values.emailContent.body || '',
      values.emailContent.cc?.split(','),
      values.emailContent.bcc?.split(',')
    );
  }, [values]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Text sx={{ marginRight: 4 }}>To:</Text>
      <Box
        component="a"
        href={link}
        sx={(theme) => ({ color: theme.colors.blue })}
      >
        {link}
      </Box>
    </Box>
  );
}

export default function MailtoGenerator() {
  const { values, handleChange } = useFormikContext<SendInvoiceFormValues>();

  return (
    <Stack>
      <Grid columns={12} gutter={4}>
        <Grid.Col sm={12}>
          <TextInput
            name="emailContent.subject"
            label="Subject"
            onChange={handleChange}
            value={values.emailContent.subject}
          />
        </Grid.Col>
        <Grid.Col sm={12}>
          <Textarea
            autosize
            minRows={4}
            maxRows={8}
            name="emailContent.body"
            label="Body"
            onChange={handleChange}
            value={values.emailContent.body}
          />
        </Grid.Col>
      </Grid>
      <LinkPreview />
    </Stack>
  );
}
