import { Alert, Text } from '@mantine/core';
import { useState } from 'react';

import { BasePageAlertProps } from '../types';

export default function BasePageAlert({
  alert,
  onClose,
  ...props
}: BasePageAlertProps) {
  const [hidden, setHidden] = useState(false);

  if (!alert || hidden) return <></>;

  return (
    <Alert
      withCloseButton
      closeButtonLabel="x"
      onClose={() => {
        setHidden(true);
        onClose?.();
      }}
      {...props}
    >
      <Text>{alert.message}</Text>
    </Alert>
  );
}
