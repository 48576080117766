import { ActionIcon, Badge, Box, Tooltip, rem } from '@mantine/core';
import { Interweave } from 'interweave';
import {
  IconArchive,
  IconEdit,
  IconSquareRoundedArrowUp,
} from '@tabler/icons-react';

import { TableColumn } from 'components/common/Table/types';
import { Client } from 'types/api';
import { BaseTableProps } from 'components/common/Table/types';
import { HEADER_HEIGHT, MODALS, TABS_HEIGHT } from 'constants/component';

import useModalStore from 'hooks/store/useModalStore';
import getCurrencyDisplay from 'helpers/display/getCurrencyDisplay';
import useClients from 'hooks/clients/useClients';

import BaseTable from 'components/common/Table/variants/BaseTable';

export interface ClientsTableProps {
  clients: Client[];
}

const columns: TableColumn[] = [
  {
    id: 'name',
    display: 'Name',
  },
  {
    id: 'email',
    display: 'Email',
  },
  {
    id: 'phone',
    display: 'Phone',
  },
  {
    id: 'budget_amount',
    display: 'Budget',
  },
  {
    id: 'note',
    display: 'Note',
  },
  {
    id: 'action',
    display: '',
  },
];

const TableAction = ({ client }: { client: Client }) => {
  const { pushModal } = useModalStore();
  const { updateClient } = useClients();

  const handleEditClick = () => {
    pushModal(MODALS.ADD_CLIENT_MODAL, { clientId: client.id });
  };

  const handleArchiveClick = () => {
    pushModal(MODALS.CONFIRM, {
      title: 'Archive Client',
      body: 'Are you sure you want to archive this client?',
      onConfirm: () =>
        updateClient(client.id as number, {
          is_active: !client.is_active,
        }),
    });
  };

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'flex-end',
        columnGap: theme.spacing.xs,
      })}
    >
      <ActionIcon color="blue" onClick={handleEditClick}>
        <IconEdit />
      </ActionIcon>
      <ActionIcon
        color={client.is_active ? 'red' : 'green'}
        onClick={handleArchiveClick}
      >
        {client.is_active ? <IconArchive /> : <IconSquareRoundedArrowUp />}
      </ActionIcon>
    </Box>
  );
};

const getDataDisplay: BaseTableProps<Client>['getDataDisplay'] = (key, row) => {
  if (key === 'action') {
    return <TableAction client={row} />;
  }

  if (key === 'note') {
    return <Interweave content={row[key]} />;
  }

  if (key === 'budget_amount') {
    if (!row.budget_amount) return 'None';

    const amountDisplay =
      `$${((row.budget_amount || 0) / 100)?.toString()}` || '';

    let color = 'green';
    const msg = `${getCurrencyDisplay(
      (row.covered_budget || 0) / 100
    )} billable out of ${getCurrencyDisplay((row.budget_amount || 0) / 100)}`;

    if ((row.covered_budget || 0) >= row.budget_amount) {
      color = 'red';
    }

    return (
      <Tooltip label={msg}>
        <Badge color={color} sx={{ cursor: 'pointer' }}>
          {amountDisplay} {row.budget_type}
        </Badge>
      </Tooltip>
    );
  }

  return `${row[key as keyof Client]}`;
};

export default function ClientsTable({ clients }: ClientsTableProps) {
  return (
    <BaseTable<Client>
      columns={columns}
      rows={clients}
      getDataDisplay={getDataDisplay}
      height={`calc(100vh - ${HEADER_HEIGHT} - ${TABS_HEIGHT} - ${rem(24)})`}
    />
  );
}
