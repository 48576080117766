import BaseRichTextEditor from './variants/Base';

import { BaseRichTextEditorProps } from './types';

interface RichTextEditorProps extends BaseRichTextEditorProps {
  variant?: 'base' | 'comment';
}

export default function RichTextEditor({
  variant = 'base',
  ...props
}: RichTextEditorProps) {
  if (variant === 'base' || variant === 'comment') {
    return <BaseRichTextEditor {...props} />;
  }

  return <></>;
}
