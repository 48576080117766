import urlSerialize from 'helpers/api/urlSerialize';

export default function generateMailtoLink(
  to: string[],
  subject: string,
  body: string,
  cc?: string[],
  bcc?: string[]
) {
  const params: any = {
    subject: subject,
    body: body,
  };

  if (Array.isArray(cc) && cc?.filter((email) => email)?.length) {
    params['cc'] = cc?.join(', ');
  }

  if (Array.isArray(bcc) && bcc?.filter((email) => email)?.length) {
    params['bcc'] = bcc?.join(', ');
  }

  const baseLink = `mailto: ${to.join(', ')}`;

  return `${baseLink}?${urlSerialize(params)}`;
}
