import { BaseDropdownButtonProps } from './types';
import BaseDropdownButton from './variants/BaseDropdownButton';
import ProjectPageDropdownButton from './variants/ProjectPageDropdownButton';

interface DropdownButtonProps extends BaseDropdownButtonProps {
  variant?: 'base' | 'projectpage';
}

export default function DropdownButton({
  variant,
  ...props
}: DropdownButtonProps) {
  if (variant === 'projectpage') {
    return <ProjectPageDropdownButton {...props} />;
  }

  return <BaseDropdownButton {...props} />;
}
