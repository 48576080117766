import { ProjectTicket } from 'types/api';

export default function getTicketDisplay(
  ticket: Partial<ProjectTicket>,
  projectKey?: string
) {
  if (!ticket) return 'Ticket';

  const _projectKey = projectKey || ticket.project?.key;
  const ticketId = ticket.pub_id;
  const ticketTitle = ticket.title;

  return `${_projectKey}-${ticketId}: ${ticketTitle}`;
}
