import { useMemo } from 'react';

import { SelectProps } from 'components/common/Select/types';
import BaseSelect from 'components/common/Select/variants/BaseSelect';

import useInstanceConfig from 'hooks/instance/useInstanceConfig';

interface TicketStatusSelectProps extends SelectProps {}

export default function TicketStatusSelectBase({
  onChange,
  defaultValue,
  label,
  filter = () => true,
}: TicketStatusSelectProps) {
  const { data } = useInstanceConfig();

  const options = useMemo(() => {
    return data?.config?.ticket_statuses
      ?.filter(filter)
      ?.map((ticketStatus) => {
        return {
          value: ticketStatus,
          label: ticketStatus,
        };
      });
  }, [data?.config?.ticket_statuses, filter]);

  return (
    <BaseSelect
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      label={label}
    />
  );
}
