import add from 'date-fns/add';
import format from 'date-fns/format';

import { DATE_FORMATS, RELATIVE_DATE_LABELS } from 'constants/date';
import getWeek from 'date-fns/getWeek';
import getDaysOfWeek from './getDaysOfWeek';

/**
 * parses a string of words into a date
 * @param dateString string in the form <number> <days | weeks | months>. Ex: -1 days = yesterday
 * @param dateFormat date-fns formatting of the date
 * @return parsed date, parsed date label
 */
export default function getDateFromWords(
  dateString: string,
  dateFormat: string
): [Date | null, string] {
  const [amount, dateType] = dateString.split(' ');
  let [label] =
    RELATIVE_DATE_LABELS[dateString as keyof typeof RELATIVE_DATE_LABELS] || [];
  let dateDisplay = null;

  if (isNaN(parseInt(amount))) return [null, label || 'N/A'];

  const amountNum = parseInt(amount);
  const isNegative = amountNum < 0;
  const adjustment = {
    [dateType]: amountNum,
  };
  const adjustedDate = add(new Date(), adjustment);

  if (!label && amountNum === 0) {
    label = `This ${dateType.slice(0, -1)}`;

    if (dateType === 'weeks') {
      label += ' (through today)';
      dateDisplay = '';
    }

    if (dateType === 'years') {
      label = `${adjustedDate.getFullYear().toString()} (through today)`;
      dateDisplay = '';
    }

    if (dateType === 'months') {
      label = `${format(
        adjustedDate,
        DATE_FORMATS.MONTH_YEAR
      )} (through today)`;
      dateDisplay = '';
    }
  }

  if (!label && amountNum === -1) {
    label = `Last ${dateType.slice(0, -1)}`;
    dateDisplay = '';

    if (dateType === 'years') {
      label = `${adjustedDate.getFullYear()} (${label})`;
    }
  }

  if (!label) {
    label = `${Math.abs(amountNum)} ${dateType.slice(
      0,
      Math.abs(amountNum) === 1 ? -1 : undefined
    )} ${isNegative ? 'ago' : 'in the future'}`;
  }

  if (dateType === 'weeks' && dateDisplay === null) {
    const [start, , , , , , end] = getDaysOfWeek(getWeek(new Date()));
    dateDisplay = `${format(start, dateFormat)} - ${format(end, dateFormat)}`;
  }

  if (dateDisplay === null) {
    dateDisplay = `${format(adjustedDate, dateFormat)}`;
  }

  return [adjustedDate, [label, dateDisplay].filter((l) => l).join(' - ')];
}
