import { RouteObject, Navigate } from 'react-router-dom';

import AuthGuard from 'components/layout/AuthGuard';

import ClientsPage from 'pages/Clients';
import ProjectsPage from './pages/Projects';
import ProjectPage from 'pages/Project';
import LoginPage from 'pages/Login';
import PeoplePage from 'pages/People';
import AssignmentsPage from 'pages/Assignments';
import TimesheetPage from 'pages/Timesheet';
import ManagePage from 'pages/Manage';
import ActivityPage from 'pages/Activity';
import TestPage from 'pages/Test';
import SettingsPage from 'pages/Settings';
import InvoicesPage from 'pages/Invoices';
import BillingDashboardPage from 'pages/BillingDashboard';
import ReportsPage from 'pages/Reports';
import ReportPage from 'pages/Report';

const routes: RouteObject[] = [
  {
    path: 'clients',
    Component: AuthGuard,
    children: [
      {
        path: '',
        Component: ClientsPage,
      },
    ],
  },
  {
    path: 'people',
    Component: AuthGuard,
    children: [
      {
        path: '',
        Component: PeoplePage,
      },
    ],
  },
  {
    path: 'projects',
    Component: AuthGuard,
    children: [
      {
        path: '',
        Component: ProjectsPage,
      },
      {
        path: ':projectId',
        Component: ProjectPage,
      },
    ],
  },
  {
    path: 'assignments',
    Component: AuthGuard,
    children: [
      {
        path: '',
        Component: AssignmentsPage,
      },
    ],
  },
  {
    path: 'timesheet',
    Component: AuthGuard,
    children: [
      {
        path: '',
        Component: TimesheetPage,
      },
    ],
  },
  {
    path: 'activity',
    Component: AuthGuard,
    children: [
      {
        path: '',
        Component: ActivityPage,
      },
    ],
  },
  {
    path: 'invoices',
    Component: AuthGuard,
    children: [
      {
        path: '',
        Component: InvoicesPage,
      },
    ],
  },
  {
    path: 'reports',
    Component: AuthGuard,
    children: [
      {
        path: '',
        Component: ReportsPage,
      },
      {
        path: ':reportId',
        Component: ReportPage,
      },
    ],
  },
  {
    path: 'manage',
    Component: AuthGuard,
    children: [
      {
        path: '',
        Component: ManagePage,
      },
    ],
  },
  {
    path: 'settings',
    Component: AuthGuard,
    children: [
      {
        path: '',
        Component: SettingsPage,
      },
    ],
  },
  {
    path: 'billing-dashboard',
    Component: AuthGuard,
    children: [
      {
        path: '',
        Component: BillingDashboardPage,
      },
    ],
  },
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        Component: LoginPage,
      },
    ],
  },
  {
    path: 'test',
    children: [
      {
        path: '',
        Component: TestPage,
      },
    ],
  },
  {
    path: '*',
    Component: () => <Navigate to="projects" />,
  },
];

export default routes;
