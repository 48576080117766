import { useMemo } from 'react';

import useBillingRecords from './useBillingRecords';
import useUsers from 'hooks/users/useUsers';
import useAvailabilityRecordsAggregate from 'hooks/users/useAvailabilityRecordsAggregate';

import { BillingRecordsHookParams, UserMap } from './types';
import { ProjectTicket } from 'types/api';

export default function useBillingRecordsAggregate(
  hookParams: BillingRecordsHookParams
) {
  const { data, isLoading } = useBillingRecords(hookParams);
  const { data: userData, isLoading: userLoading } = useUsers({
    params: { id: hookParams?.params?.['user_id'], active: '1' },
  });
  const { aggregateData: arAggregateData, isLoading: aggLoading } =
    useAvailabilityRecordsAggregate();

  const aggregateData = useMemo(() => {
    const users: { [id: number]: UserMap } = {};

    userData?.users?.forEach((user) => {
      const userId = user.id as number;
      users[userId] = {
        id: userId,
        user: user,
        projects: {},
        availabilityRecords: arAggregateData.users[`${user.id}`]?.dates,
        total: {
          ticket: { title: 'Total' },
          dates: {},
        },
      };
    });

    data?.billing_records?.forEach((billingRecord) => {
      const userId = billingRecord?.employee_id;
      const projectId = billingRecord?.ticket?.project?.id as number;
      const ticketId = billingRecord?.ticket?.id;

      if (!projectId || !ticketId || !userId) return;

      const userMap = users[userId];
      if (!userMap && billingRecord.employee) {
        users[userId] = {
          id: userId,
          user: billingRecord.employee,
          projects: {},
          availabilityRecords: arAggregateData.users[userId]?.dates,
          total: {
            ticket: { title: 'Total' },
            dates: {},
          },
        };
      }

      let projectMap = users[userId].projects[projectId];
      if (!projectMap && billingRecord?.ticket?.project) {
        users[userId].projects[projectId] = {
          id: projectId,
          project: billingRecord?.ticket?.project,
          tickets: {},
        };
        projectMap = users[userId].projects[projectId];
      }

      const userMapTotal = users[userId].total;
      let ticketMap = projectMap.tickets[ticketId];
      let ticketMapTotal = projectMap.tickets[0];

      if (!ticketMap) {
        projectMap.tickets[ticketId] = {
          ticket: billingRecord?.ticket as ProjectTicket,
          dates: {},
        };
        ticketMap = projectMap.tickets[ticketId];
      }

      if (!ticketMapTotal) {
        projectMap.tickets[0] = {
          ticket: { title: 'Total' },
          dates: {},
        };
        ticketMapTotal = projectMap.tickets[0];
      }

      const dateString = billingRecord.date;

      const dateKeys = [dateString, 'total'];
      const ticketMaps = [ticketMap, ticketMapTotal, userMapTotal];

      dateKeys.forEach((dateKey) => {
        ticketMaps.forEach((tMap) => {
          if (!tMap.dates[dateKey]) {
            tMap.dates[dateKey] = [];
          }

          tMap.dates[dateKey].push(billingRecord);
        });
      });
    });

    return users;
  }, [arAggregateData.users, data?.billing_records, userData?.users]);

  const loading = isLoading || userLoading || aggLoading;

  return {
    aggregateData,
    loading,
  };
}
