import { useRef, useEffect, useState, MutableRefObject, useMemo } from 'react';
import {
  Stack,
  TextInput,
  Textarea,
  Checkbox,
  Tooltip,
  Box,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { Formik, FormikProps, useFormikContext } from 'formik';
import format from 'date-fns/format';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import * as Yup from 'yup';

import BaseModal from '../BaseModal';
import ModalFooter from './ModalFooter';
import JobTypeSelect from 'components/common/Select/variants/JobTypeSelect';
import Autocomplete from 'components/common/Autocomplete';

import { AddBillingRecordBody } from 'utils/billing';

import useTimeInput from 'hooks/common/useTimeInput';
import useModalStore from 'hooks/store/useModalStore';
import useBillingRecords from 'hooks/billing/useBillingRecords';

import { AddBillingRecordModalProps } from './types';
import { DATE_FORMATS } from 'constants/date';
import { MODALS } from 'constants/component';
import { SelectOption } from 'components/common/Select/types';
import ReporteeUserSelect from 'components/common/Select/variants/ReporteeUserSelect';
import useAuthUser from 'hooks/users/useAuthUser';

const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .required('Amount is required')
    .moreThan(0, 'Amount must be greater than 0'),
  description: Yup.string().required('Description is required'),
  ticket_id: Yup.number().required('Ticket is required'),
});

function AddBillingRecordForm({ ticket }: AddBillingRecordModalProps) {
  const { values, handleChange, setFieldValue, errors, touched } =
    useFormikContext<AddBillingRecordBody>();
  const {
    handleChange: handleTimeChange,
    handleBlur,
    inputValue,
    value,
  } = useTimeInput({
    defaultValue: values.amount,
  });
  const [date, setDate] = useState<Date>(new Date());

  const handleJobTypeChange = (newJobType: SelectOption) => {
    setFieldValue('job_type', newJobType.value);
  };

  const handleAssignedUserSelect = (assigned: SelectOption) => {
    setFieldValue('employee_id', assigned.value);
  };

  const handleTicketSelect = (newTicketId: string) => {
    setFieldValue('ticket_id', parseInt(newTicketId));
  };

  const checkboxTooltip = useMemo(() => {
    if (!ticket?.project?.billable) {
      return 'This project is unbillable.';
    }

    if (!ticket?.billable) {
      return 'This ticket is unbillable.';
    }

    return 'Was this work billable?';
  }, [ticket]);

  useEffect(() => {
    setFieldValue('amount', value);

    // eslint-disable-next-line
  }, [value]);

  return (
    <Stack sx={(theme) => ({ padding: theme.spacing.lg })} spacing={16}>
      {!ticket && (
        <Autocomplete
          variant="ticket"
          label="Ticket"
          value={values.ticket_id?.toString() || ''}
          onChange={handleTicketSelect}
        />
      )}
      <ReporteeUserSelect
        label="Person"
        defaultValue={values?.employee_id}
        onChange={handleAssignedUserSelect}
      />
      <TextInput
        required
        name="amount"
        label="Amount"
        value={inputValue}
        onChange={handleTimeChange}
        onBlur={handleBlur}
        error={touched.amount && errors.amount}
        placeholder="0:00"
      />
      <Tooltip label={checkboxTooltip} position="top-start">
        <Box>
          <Checkbox
            name="billable"
            label="Billable"
            checked={values.billable}
            onChange={handleChange}
            color="green"
            error={touched.billable && errors.billable}
            disabled={!ticket?.project?.billable || !ticket?.billable}
          />
        </Box>
      </Tooltip>
      <DatePickerInput
        label="Date"
        name="date"
        value={date}
        onChange={(newDate) => {
          setDate(newDate as Date);

          if (newDate instanceof Date) {
            setFieldValue(
              'date',
              format(newDate as Date, DATE_FORMATS.DATE_KEY)
            );
          } else {
            setFieldValue('date', newDate);
          }
        }}
        error={touched.date && errors.date}
      />
      <JobTypeSelect
        defaultFirst
        defaultValue={values.job_type}
        label="Job Type"
        onChange={handleJobTypeChange}
      />
      <Textarea
        required
        name="description"
        label="Description"
        value={values.description}
        onChange={handleChange}
        error={touched.description && errors.description}
      />
    </Stack>
  );
}

function AddBillingRecordModal({ ticket, userId }: AddBillingRecordModalProps) {
  const { user } = useAuthUser();
  const initialValues: Partial<AddBillingRecordBody> = {
    amount: 0,
    description: '',
    date: format(new Date(), DATE_FORMATS.DATE_KEY),
    billable: Boolean(ticket?.project?.billable && ticket?.billable),
    job_type: undefined,
    employee_id: userId || user?.id,
    ticket_id: ticket?.id,
  };
  const formikRef = useRef() as MutableRefObject<FormikProps<
    Partial<AddBillingRecordBody>
  > | null>;
  const { popModal } = useModalStore();
  const modal = useModal();
  const { addBillingRecord } = useBillingRecords();

  const handleClose = () => {
    popModal(MODALS.ADD_BILLING_RECORD_MODAL);
    formikRef?.current?.resetForm();
  };

  const handleSubmit = async (values: Partial<AddBillingRecordBody>) => {
    if (
      await addBillingRecord({
        body: values,
        ticketId: values?.ticket_id as number,
      })
    ) {
      handleClose();
    }
  };

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <BaseModal
        id={MODALS.ADD_BILLING_RECORD_MODAL}
        isOpen={modal.visible}
        onClose={handleClose}
        title="Add Billing Record"
        height="70vh"
        size="40vw"
        FooterComponent={ModalFooter}
      >
        <AddBillingRecordForm ticket={ticket} />
      </BaseModal>
    </Formik>
  );
}

export default NiceModal.create((props) => (
  <AddBillingRecordModal {...props} />
));
