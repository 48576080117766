import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Text } from '@mantine/core';
import { MODALS } from 'constants/component';

import getTicketDisplay from 'helpers/display/getTicketDisplay';
import getUserFullName from 'helpers/display/getUserFullName';
import useActivity from 'hooks/common/useActivity';
import useModalStore from 'hooks/store/useModalStore';
import { Activity, ProjectTicket } from 'types/api';

function Highlighted({ message }: { message: string }) {
  return (
    <Text component="span" color="blue">
      {message}
    </Text>
  );
}

export default function ActivityDisplay({
  activityItem,
}: {
  activityItem: Activity;
}) {
  const { getActionDisplay, getDateDisplay, getTargetDisplay, getUserDisplay } =
    useActivity({});
  const { pushModal } = useModalStore();
  const navigate = useNavigate();

  const renderPrefix = useMemo(() => {
    if (activityItem?.ticket) {
      return (
        <Text
          color="blue"
          component="span"
          sx={(theme) => ({
            '&:hover': {
              color: theme.colors.blue[8],
              cursor: 'pointer',
            },
            '&:active': {
              color: theme.colors.blue[9],
            },
          })}
          onClick={() => {
            pushModal(MODALS.EDIT_TICKET_MODAL, {
              ticket: activityItem.ticket,
            });
          }}
        >
          {getTicketDisplay(
            activityItem.ticket as Partial<ProjectTicket>,
            activityItem.ticket?.project_key
          )}
        </Text>
      );
    }

    if (activityItem?.project) {
      return (
        <Text
          color="blue"
          component="span"
          sx={(theme) => ({
            '&:hover': {
              color: theme.colors.blue[8],
              cursor: 'pointer',
            },
            '&:active': {
              color: theme.colors.blue[9],
            },
          })}
          onClick={() => {
            navigate(`/projects/${activityItem?.project?.id}`);
          }}
        >
          [{activityItem?.project?.key}] {activityItem?.project?.title}
        </Text>
      );
    }

    if (activityItem?.instance_user) {
      return (
        <Text
          color="blue"
          component="span"
          sx={(theme) => ({
            '&:hover': {
              color: theme.colors.blue[8],
              cursor: 'pointer',
            },
            '&:active': {
              color: theme.colors.blue[9],
            },
          })}
          onClick={() => {
            navigate(`/assignments/${activityItem?.instance_user?.id}`);
          }}
        >
          {getUserFullName(activityItem?.instance_user)}
        </Text>
      );
    }

    return (
      <Text color="blue" component="span">
        {process.env.REACT_APP_INSTANCE_NAME || window.INSTANCE_NAME}
      </Text>
    );
  }, [
    activityItem?.instance_user,
    activityItem?.project,
    activityItem.ticket,
    navigate,
    pushModal,
  ]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Text
        size="sm"
        weight="bold"
        sx={{ marginTop: 'auto', marginBottom: 'auto' }}
      >
        {renderPrefix}
        {': '}
        <Text component="span">
          {getTargetDisplay(activityItem)} {getActionDisplay(activityItem)} by
        </Text>{' '}
        <Highlighted message={getUserDisplay(activityItem)} />{' '}
        <Text component="span">{getDateDisplay(activityItem)}</Text>
      </Text>
    </Box>
  );
}
