import { Menu, ActionIcon } from '@mantine/core';
import { IconCoin, IconPlus } from '@tabler/icons-react';

import { BaseDropdownButtonProps } from '../../types';
import { MODALS } from 'constants/component';

import useModalStore from 'hooks/store/useModalStore';

interface ProjectPageDropdownButtonProps extends BaseDropdownButtonProps {}

export default function ProjectPageDropdownButton({
  menuProps,
  additionalProps,
}: ProjectPageDropdownButtonProps) {
  const { pushModal } = useModalStore();
  const { project } = additionalProps || {};

  return (
    <Menu shadow="md" width={200} {...menuProps}>
      <Menu.Target>
        <ActionIcon variant="subtle" radius="xl">
          <IconPlus />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          sx={(theme) => ({ padding: theme.spacing.sm })}
          icon={<IconCoin size={14} />}
          onClick={() => {
            pushModal(MODALS.LIST_EXPENSES_MODAL, { project, showAdd: true });
          }}
        >
          Add Expense
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
