import { useMemo, useRef } from 'react';
import {
  Box,
  Group,
  Stack,
  Text,
  Button,
  FileButton,
  ActionIcon,
  rem,
  Textarea,
} from '@mantine/core';

import ConsoleLayout from 'layouts/ConsoleLayout';
import BillingTable from './components/BillingTable';

import useInstanceConfig from 'hooks/instance/useInstanceConfig';
import useModalStore from 'hooks/store/useModalStore';

import getDateFromWords from 'helpers/datetime/getDateFromWords';
import getDateRangeFromWords from 'helpers/datetime/getDateRangeFromWords';

import { MODALS } from 'constants/component';
import { IconInfoCircle } from '@tabler/icons-react';

const userLaborCostExample = `{
  <userid>: [
     {
          "date_start" : "2023-01-01",
          "date_end" : "2023-01-15",
          "laborcost_per_workday" : 43.25
     },
     ....
  ],
  .....
}`;

export default function BillingDashboardPage() {
  const { data, isLoading, dateDisplay } = useInstanceConfig();
  const { pushModal } = useModalStore();

  const configBillingDashboardDates = data?.config?.billing_dashboard_dates;
  const resetRef = useRef<() => void>(null);

  const billingDashboardDates = useMemo(() => {
    return configBillingDashboardDates?.map((dateString) =>
      getDateFromWords(dateString, dateDisplay)
    );
  }, [configBillingDashboardDates, dateDisplay]);

  const showUploadUserLaborCostModal = (file: File) => {
    pushModal(MODALS.UPLOAD_USER_LABOR_COSTS_MODAL, { file });
    resetRef.current?.();
  };

  return (
    <ConsoleLayout loading={isLoading}>
      <Stack>
        <Group>
          <FileButton
            onChange={showUploadUserLaborCostModal}
            accept="application/json"
            resetRef={resetRef}
          >
            {(props) => (
              <Button
                {...props}
                size="xs"
                variant="outline"
                rightIcon={
                  <ActionIcon
                    color="blue"
                    size="xs"
                    onClick={(e) => {
                      e.stopPropagation();
                      pushModal(MODALS.INFO, {
                        title: 'Upload User Labor Costs Example',
                        body: (
                          <Box>
                            <Textarea
                              autosize
                              readOnly
                              value={userLaborCostExample}
                            />
                          </Box>
                        ),
                        modalProps: {
                          size: rem(600),
                        },
                      });
                    }}
                  >
                    <IconInfoCircle />
                  </ActionIcon>
                }
              >
                Upload User Labor Costs{' '}
              </Button>
            )}
          </FileButton>
        </Group>
        {billingDashboardDates?.map(([, label], index) => {
          const [dateStart, dateEnd] = getDateRangeFromWords(
            configBillingDashboardDates?.[index]
          );

          if (!dateStart) return <></>;

          return (
            <Box key={label}>
              <Text size="xl" weight="bold" transform="capitalize">
                {label}
              </Text>
              <BillingTable dateStart={dateStart} dateEnd={dateEnd} />
            </Box>
          );
        })}
      </Stack>
    </ConsoleLayout>
  );
}
