import { Box } from '@mantine/core';

import { PopOverTargetProps } from '../types';
import EstimateGroupItem from 'components/common/Modal/variants/EditTicketModal/SideContent/EstimatesSection/EstimateGroupItem';

export default function PopOverTargetEdit({
  onClick,
  additionalProps,
}: PopOverTargetProps) {
  const { estimate, deleteTicketEstimate } = additionalProps || {};

  return (
    <Box onClick={onClick}>
      <EstimateGroupItem
        estimate={estimate}
        deleteTicketEstimate={deleteTicketEstimate}
      />
    </Box>
  );
}
