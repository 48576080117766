import { useCallback } from 'react';
import {
  Group,
  Button,
  Stack,
  TypographyStylesProvider,
  createStyles,
  Box,
} from '@mantine/core';
import { Interweave } from 'interweave';
import { useFormikContext } from 'formik';

import RichTextEditor from 'components/common/RichTextEditor';
import { ProjectTicket } from 'types/api';

const useStyles = createStyles(() => ({
  description: {
    '& p': {
      marginBottom: '0px !important',
    },
  },
}));

interface MainContentDescriptionProps {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}

type FormValues = Partial<ProjectTicket & { attachments: File[] }>;

export default function MainContentDescription({
  isEditing,
  setIsEditing,
}: MainContentDescriptionProps) {
  const { classes } = useStyles();
  const { values, submitForm, isSubmitting, setFieldValue, resetForm } =
    useFormikContext<FormValues>();

  const handleChange = useCallback(
    (value: string) => {
      setFieldValue('description', value);
    },
    [setFieldValue]
  );

  if (isEditing) {
    return (
      <Stack
        sx={(theme) => ({
          paddingLeft: theme.spacing.md,
        })}
      >
        <RichTextEditor
          hasAttachments
          content={values.description as string}
          onChange={handleChange}
          attachments={values.attachments}
          setAttachments={(attachments) => {
            setFieldValue('attachments', attachments);
          }}
        />
        <Group sx={{ justifyContent: 'flex-end' }}>
          <Button
            size="xs"
            variant="outline"
            onClick={() => {
              setIsEditing(false);
              resetForm();
            }}
          >
            Cancel
          </Button>
          <Button size="xs" onClick={submitForm} loading={isSubmitting}>
            Save
          </Button>
        </Group>
      </Stack>
    );
  }

  return (
    <TypographyStylesProvider>
      <Box sx={(theme) => ({ padding: theme.spacing.md })}>
        <Interweave
          className={classes.description}
          content={values.description}
        />
      </Box>
    </TypographyStylesProvider>
  );
}
