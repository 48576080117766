import isEqual from 'lodash/isEqual';
import { QueryClient } from 'react-query';
import { ApiQueryKey } from 'types/api/query';
import { create } from 'zustand';

interface ApiStoreState {
  queryClient: QueryClient | null;
  setQueryClient: (queryClient: QueryClient) => void;
  queryKeys: { [key: string]: string[] };
  isQueryKeySet: (key: ApiQueryKey, queryKey: string[]) => boolean;
  setQueryKey: (key: ApiQueryKey, queryKey: string[]) => void;
  invalidate: (key: ApiQueryKey) => void;
  invalidateAny: (key: string) => void;
  invalidateGroup: (group: ApiQueryKey[]) => void;
  invalidateEndpoint: (endpoint: string) => void;
  invalidatePath: (queryKeys: string[]) => void;
}

const useApiStore = create<ApiStoreState>((set, get) => ({
  queryClient: null,
  queryKeys: {},
  setQueryClient: (queryClient: QueryClient) => {
    set((state) => ({
      ...state,
      queryClient,
    }));
  },
  isQueryKeySet: (key, queryKey) => {
    const state = get();
    return isEqual(state.queryKeys[key], queryKey);
  },
  setQueryKey: (key, queryKey) => {
    const state = get();

    if (!state.isQueryKeySet(key, queryKey)) {
      set((state) => ({
        ...state,
        queryKeys: {
          ...state.queryKeys,
          [key]: queryKey,
        },
      }));
    }
  },
  invalidate: (key: ApiQueryKey) => {
    const state = get();
    state.invalidateAny(key);
  },
  invalidateAny(key: string) {
    const state = get();
    const queryKey = state.queryKeys[key];
    if (state.queryClient && queryKey) {
      state.queryClient.invalidateQueries(queryKey);
    }
  },
  invalidateGroup(group: ApiQueryKey[]) {
    const state = get();
    group.forEach((key) => {
      state.invalidateAny(key);
    });
  },
  invalidateEndpoint(endpoint: string) {
    const state = get();
    state.queryClient?.invalidateQueries({
      predicate: (query) => {
        const endpointParts = endpoint.split('/');
        let i = 0;

        return endpointParts.reduce((a, part) => {
          if (part.includes(':')) {
            i += 1;
            return true;
          }

          a &&= query.queryKey[i] === part;
          i += 1;

          return a;
        }, true);
      },
    });
  },
  invalidatePath(queryKeys: string[]) {
    const state = get();
    state.queryClient?.invalidateQueries({
      predicate: (query) => {
        let result = true;

        queryKeys.forEach((value, index) => {
          result &&= !value || query.queryKey[index] === value;
        });

        return result;
      },
    });
  },
}));

export default useApiStore;
