import {
  Box,
  Stack,
  Notification,
  ActionIcon,
  useMantineTheme,
  TypographyStylesProvider,
} from '@mantine/core';
import { Interweave } from 'interweave';
import { IconX, IconCircleDot } from '@tabler/icons-react';

import { InstanceNotification } from 'types/api';

interface NotificationItemProps {
  notification: InstanceNotification;
  onRemove: () => void;
  onRead: () => void;
}

function renderTitle(notification: InstanceNotification): string {
  if (notification.notif_type === 'mention') {
    // TODO: resolve
    // return `${getUserFullName(notification.created_by)} mentioned you on ${
    //   notification.ticket?.title
    // }!`;
  }

  return '';
}

function NotificationItemBody({
  notification,
}: {
  notification: InstanceNotification;
}): JSX.Element {
  return (
    <TypographyStylesProvider>
      <Box
        sx={(theme) => ({
          cursor: 'pointer',
          '&:hover': { backgroundColor: theme.colors.gray[1] },
          padding: theme.spacing.xs,
          border: `1px solid ${theme.colors.gray[5]}`,
          borderRadius: theme.radius.sm,
        })}
      >
        <Interweave content={notification.ticket?.description} />
      </Box>
    </TypographyStylesProvider>
  );
}

export default function NotificationItem({
  notification,
  onRemove,
  onRead,
}: NotificationItemProps) {
  const theme = useMantineTheme();

  return (
    <Box>
      <Notification
        color={notification.is_read ? 'gray' : 'blue'}
        title={renderTitle(notification)}
        withCloseButton={false}
      >
        <Box sx={{ display: 'flex' }}>
          <NotificationItemBody notification={notification} />
          <Stack
            sx={(theme) => ({ marginLeft: theme.spacing.xs })}
            spacing={theme.spacing.xs}
          >
            <ActionIcon title="Mark as read" onClick={onRead}>
              <IconCircleDot size="16px" />
            </ActionIcon>
            <ActionIcon title="Remove notification" onClick={onRemove}>
              <IconX size="16px" />
            </ActionIcon>
          </Stack>
        </Box>
      </Notification>
    </Box>
  );
}
