import ReactDOM from 'react-dom/client';
import NiceModal from '@ebay/nice-modal-react';
import App from './App';
import reportWebVitals from './helpers/misc/reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <NiceModal.Provider>
    <App />
  </NiceModal.Provider>
);

reportWebVitals();
