import {
  createStyles,
  rem,
  Text,
  Group,
  Badge,
  Stack,
  Tooltip,
  Box,
  Avatar,
  useMantineTheme,
} from '@mantine/core';
import styled from '@emotion/styled';
import { Draggable } from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IconAlertCircle,
  IconLockPause,
  IconMessage,
  IconTag,
} from '@tabler/icons-react';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import differenceInDays from 'date-fns/differenceInDays';

import { ProjectTicket, User } from 'types/api';
import { MODALS } from 'constants/component';

import useModalStore from 'hooks/store/useModalStore';
import getTicketDisplay from 'helpers/display/getTicketDisplay';
import useProject from 'hooks/projects/useProject';
import { DATE_FORMATS } from 'constants/date';
import getInitials from 'helpers/display/getInitials';
import getUserFullName from 'helpers/display/getUserFullName';
import TicketPriorityDisplay from 'components/common/TicketPriorityDisplay';
import useInstanceConfig from 'hooks/instance/useInstanceConfig';

const useStyles = createStyles(
  (theme, { mentioned }: { mentioned: boolean }) => ({
    item: {
      ...theme.fn.focusStyles(),
      display: 'flex',
      alignItems: 'center',
      borderRadius: theme.radius.md,
      border: `${rem(1)} solid ${
        mentioned ? theme.colors.yellow[8] : theme.colors.gray[2]
      }`,
      padding: `${theme.spacing.sm} ${theme.spacing.sm}`,
      backgroundColor:
        theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
      marginBottom: '0.25rem',
      boxShadow: theme.shadows.sm,
    },

    itemDragging: {
      boxShadow: theme.shadows.lg,
    },
  })
);

function TicketBlocked({ ticket }: { ticket: ProjectTicket }) {
  const theme = useMantineTheme();

  if (!ticket?.blocked) {
    return <></>;
  }

  return (
    <Tooltip label="Stuck">
      <Box sx={() => ({ display: 'flex' })}>
        <IconLockPause
          size="24px"
          style={{ marginTop: 'auto', marginBottom: 'auto' }}
          color={theme.colors.gray[6]}
        />
      </Box>
    </Tooltip>
  );
}

function TicketDueDate({ ticket }: { ticket: ProjectTicket }) {
  const { dateDisplay } = useInstanceConfig();

  if (!ticket?.date_due || ticket.is_done) {
    return <></>;
  }

  const date = parse(ticket.date_due, DATE_FORMATS.DATE_KEY, new Date());
  const today = new Date();
  const diff = differenceInDays(today, date);

  return (
    <Badge
      color={diff >= 1 ? 'red' : 'blue'}
      leftSection={
        diff >= 0 ? (
          <Box sx={{ display: 'flex' }}>
            <IconAlertCircle
              size="16px"
              style={{ marginTop: 'auto', marginBottom: 'auto' }}
            />
          </Box>
        ) : (
          <></>
        )
      }
    >
      <Text>Due {format(date, dateDisplay)}</Text>
    </Badge>
  );
}

function TicketPerson({ ticket }: { ticket: ProjectTicket }) {
  if (!ticket?.assignee) {
    return <></>;
  }

  return (
    <Tooltip label={getUserFullName(ticket?.assignee)}>
      <Avatar size="sm" radius="xl" color="blue">
        {getInitials({
          firstName: ticket?.assignee?.first_name,
          lastName: ticket?.assignee?.last_name,
        })}
      </Avatar>
    </Tooltip>
  );
}

const AnimationWrapper = styled.div`
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
    }
  }
`;

function TicketComments({ ticket }: { ticket: ProjectTicket }) {
  const theme = useMantineTheme();

  if (!ticket?.comment_count) {
    return <></>;
  }

  const commentCount = ticket.comment_count || 0;
  const hasUnread = ticket.unread_comment_count;
  const hasUnreadMention = ticket.latest_unread_mention && hasUnread;
  const mentionedUser: User = ticket.latest_unread_mention?.user;
  const Icon = hasUnreadMention ? IconTag : IconMessage;

  const getTooltipLabel = () => {
    let plural = commentCount !== 1;

    if (hasUnreadMention && ticket.latest_unread_mention?.user) {
      return `${getUserFullName(
        ticket.latest_unread_mention?.user
      )} has mentioned you!`;
    }

    if (hasUnread) {
      plural = ticket.unread_comment_count !== 1;
      return `${ticket.unread_comment_count} unread comment${
        plural ? 's' : ''
      }`;
    }

    return `${commentCount} comment${plural ? 's' : ''}`;
  };

  const getCommentCountDisplay = () => {
    if (mentionedUser && hasUnreadMention) {
      return (
        <Avatar size="xs">
          {getInitials({
            firstName: mentionedUser.first_name,
            lastName: mentionedUser.last_name,
          })}
        </Avatar>
      );
    }

    if (commentCount > 99) {
      return '99+';
    }

    return commentCount;
  };

  const getColor = () => {
    if (hasUnread) {
      return theme.colors.yellow[8];
    }

    return theme.colors.gray[6];
  };

  return (
    <Tooltip label={getTooltipLabel()}>
      <Box sx={() => ({ display: 'flex', position: 'relative' })}>
        <AnimationWrapper
          style={{ marginTop: 'auto', marginBottom: 'auto', height: '24px' }}
        >
          <Icon
            size="24px"
            style={{
              animation: hasUnreadMention
                ? 'pulse-animation 2s infinite'
                : undefined,
              borderRadius: '50%',
            }}
            color={getColor()}
          />
        </AnimationWrapper>
        <Box
          sx={{ position: 'absolute', right: -6, bottom: -4, width: '16px' }}
        >
          <Text size="xs" color={getColor()} align="left">
            {getCommentCountDisplay()}
          </Text>
        </Box>
      </Box>
    </Tooltip>
  );
}

export interface TicketGroupListItemProps {
  ticket: ProjectTicket;
  index: number;
}

export default function TicketGroupListItem({
  ticket,
  index,
}: TicketGroupListItemProps) {
  const navigate = useNavigate();

  const hasUnread = ticket.unread_comment_count;
  const hasUnreadMention = Boolean(ticket.latest_unread_mention && hasUnread);

  const { classes, cx } = useStyles({ mentioned: hasUnreadMention });
  const { pushModal } = useModalStore();
  const { projectId } = useParams();
  const { data } = useProject({ projectId });

  const handleClick = () => {
    if (projectId) {
      navigate(`/projects/${projectId}?ticket_id=${ticket.id}`);
    }
    pushModal(MODALS.EDIT_TICKET_MODAL, { ticket });
  };

  return (
    <Draggable key={ticket.id} index={index} draggableId={`${ticket.id}`}>
      {(provided, snapshot) => {
        return (
          <div
            className={cx(classes.item, {
              [classes.itemDragging]: snapshot.isDragging,
            })}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            onClick={handleClick}
            onMouseEnter={() => {
              window.DISABLE_DRAG_CONTAINER = true;
            }}
            onMouseLeave={() => {
              window.DISABLE_DRAG_CONTAINER = false;
            }}
          >
            <Stack sx={{ width: '100%' }} spacing={0}>
              <Text>{getTicketDisplay(ticket, data?.project?.key)}</Text>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ width: rem(32) }}>
                  <TicketComments ticket={ticket} />
                </Box>
                <Group sx={{ justifyContent: 'flex-end' }} spacing={2}>
                  <TicketDueDate ticket={ticket} />
                  <TicketPerson ticket={ticket} />
                  <TicketPriorityDisplay ticket={ticket} />
                  <TicketBlocked ticket={ticket} />
                </Group>
              </Box>
            </Stack>
          </div>
        );
      }}
    </Draggable>
  );
}
