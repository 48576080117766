import { ActionIcon } from '@mantine/core';
import { IconCalendar } from '@tabler/icons-react';

import { PopOverTargetProps } from '../types';

export default function PopOverTarget({ onClick }: PopOverTargetProps) {
  return (
    <ActionIcon color="blue">
      <IconCalendar onClick={onClick} />
    </ActionIcon>
  );
}
