import { ENDPOINTS } from 'constants/api';
import type { InstanceAlert } from 'types/api';

import useApiQuery from 'hooks/api/useApiQuery';

import constructQueryPath from 'helpers/api/constructQueryPath';
import * as userUtils from 'utils/user';
import { ApiQueryKey } from 'types/api/query';

export interface UserAlertsHookParams {
  userId?: number;
  limit?: number;
  refetchInterval?: number;
  params?: any;
  storeKey?: ApiQueryKey;
}

export default function useUserAlerts({
  userId,
  limit = 1,
  refetchInterval,
  params,
  storeKey,
}: UserAlertsHookParams) {
  const queryResult = useApiQuery<{
    alerts?: InstanceAlert[];
  }>({
    path: constructQueryPath(ENDPOINTS.USER_ALERTS, { userId: `${userId}` }),
    axiosConfig: {
      params: {
        page_size: limit,
        page: 1,
        ...params,
      },
    },
    queryOptions: {
      enabled: Boolean(userId),
      refetchInterval: refetchInterval,
    },
    storeKey: storeKey,
  });

  const updateUserAlert = async (
    alertId: number,
    body: Partial<InstanceAlert>
  ) => {
    try {
      await userUtils.updateAlert({ userId: userId as number, alertId, body });
      queryResult.refetch();
    } catch (err) {
      // pass
    }
  };

  return {
    ...queryResult,
    updateUserAlert,
  };
}
