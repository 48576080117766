import { Navigate } from 'react-router-dom';

/**
 * Blank slate page for testing novel functionality that is not so easily
 * testable on existing pages.
 */
export default function TestPage() {
  // only allow this page to exist in dev
  if (process.env.NODE_ENV === 'production') return <Navigate to="" />;

  return <div></div>;
}
