import { Box, Stack, Loader } from '@mantine/core';

import { BillingRecordsListProps } from './types';

import * as billing from 'utils/billing';

import EditableRow from './components/EditableRow';
import DateHeader from './components/DateHeader';

export default function BillingRecordsList({
  billingRecords = [],
  editBillingRecord,
  deleteBillingRecord,
  variant,
  loading,
  fields = ['amount', 'description', 'job_type'],
}: BillingRecordsListProps) {
  const groups = billing.groupBillingRecordsByDate(billingRecords);
  const isEmpty = Object.entries(groups).length === 0 && !loading;

  if (loading)
    return (
      <Box
        sx={(theme) => ({
          width: '100%',
          height: '100%',
          display: 'flex',
          paddingTop: theme.spacing.lg,
        })}
      >
        <Loader sx={{ margin: 'auto' }} />
      </Box>
    );

  return (
    <>
      <Stack sx={{ padding: 12 }} spacing={2}>
        {Object.entries(groups)
          .sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime())
          .map(([date, records]) => (
            <Stack key={date} spacing={2}>
              <DateHeader date={date} />
              {records.map((billingRecord) => (
                <EditableRow
                  key={billingRecord.id}
                  billingRecord={billingRecord}
                  editBillingRecord={editBillingRecord}
                  deleteBillingRecord={deleteBillingRecord}
                  variant={variant}
                  fields={fields}
                />
              ))}
            </Stack>
          ))}
        {isEmpty && (
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            No billing records found.
          </Box>
        )}
      </Stack>
    </>
  );
}
