import { Stack, Text } from '@mantine/core';

import { BillingRecord, ProjectTicket } from 'types/api';
import { BaseInfiniteLoadingListProps } from 'components/common/InfiniteLoadingList/types';

import InfiniteLoader from 'components/common/InfiniteLoader';
import ContainedRow from './components/ContainedRow';

interface TicketListInfiniteProps {
  pages?: { tickets?: ProjectTicket[] }[];
  isFetched: boolean;
  isInfinite?: boolean;
  height: string;
  hideTicket?: (ticket?: ProjectTicket) => boolean;
  projectKey?: string;
}

export default function TicketListInfinite({
  pages = [],
  isNextPageLoading,
  hasNextPage,
  loadNextPage,
  isFetched,
  isInfinite,
  height,
  hideTicket,
  projectKey,
}: TicketListInfiniteProps &
  Omit<
    BaseInfiniteLoadingListProps<BillingRecord>,
    'ItemComponent' | 'items'
  >) {
  const isEmpty = isFetched && pages.length === 0;

  return (
    <InfiniteLoader
      height={height}
      onScrollEnd={loadNextPage}
      isFetchingNextPage={isNextPageLoading}
      hasNextPage={Boolean(hasNextPage)}
    >
      {isEmpty ? (
        <Text sx={{ textAlign: 'center' }}>No tickets found.</Text>
      ) : (
        <Stack spacing={4} sx={(theme) => ({ marginBottom: theme.spacing.sm })}>
          {pages?.map((page) =>
            page.tickets?.map((ticket) => (
              <ContainedRow
                key={ticket.id}
                ticketId={ticket.id}
                isInfinite={isInfinite}
                hideTicket={hideTicket}
                projectKey={projectKey}
              />
            ))
          )}
        </Stack>
      )}
    </InfiniteLoader>
  );
}
