import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  Alert,
  Box,
  List,
  Text,
  TextInput,
  ActionIcon,
  Loader,
} from '@mantine/core';
import { IconAlertCircle, IconSearch } from '@tabler/icons-react';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import { useUncontrolled } from '@mantine/hooks';

import { ModalProps } from '../../types';
import { ProjectTicket } from 'types/api';
import { MODALS } from 'constants/component';
import { DATE_FORMATS } from 'constants/date';

import BaseModal from '../BaseModal';
import TicketListInfinite from 'components/shared/TicketList/Infinite';

import useModalStore from 'hooks/store/useModalStore';
import useTicketsInfinite from 'hooks/tickets/useTicketsInfinite';
import { useState } from 'react';
import useInstanceConfig from 'hooks/instance/useInstanceConfig';

interface ListTicketsModalProps extends ModalProps {
  params?: any;
  title?: string;
  ticket?: ProjectTicket;
  paramsMap?: { [key: string]: string };
  projectKey?: string;
}

interface ParamListItemProps {
  keyName: string;
  value: string;
}

function ParamListItem({ keyName, value }: ParamListItemProps) {
  const { dateDisplay } = useInstanceConfig();

  const booleanFields = ['is_done'];

  let displayValue = value as string;

  if (keyName.includes('date')) {
    const date = parse(value, DATE_FORMATS.DATE_KEY, new Date());
    displayValue = format(date, dateDisplay);
  }

  if (booleanFields.includes(keyName)) {
    displayValue = value === '1' ? 'True' : 'False';
  }

  return (
    <List.Item key={keyName}>
      <Text
        component="span"
        sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
      >
        {keyName.replace('_id', '').replace('_', ' ')}
      </Text>
      : {displayValue}
    </List.Item>
  );
}

function ListTicketsModal({
  params,
  title,
  paramsMap,
  projectKey,
  ...props
}: ListTicketsModalProps) {
  const modal = useModal();
  const { popModal } = useModalStore();
  const [value, handleChange] = useUncontrolled<string>({});
  const [searchValue, setSearchValue] = useState('');
  const {
    data,
    isLoading,
    isFetched,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useTicketsInfinite({
    params: {
      page: 1,
      page_size: 20,
      sort: '-datetime_updated',
      search: searchValue ? `title|${searchValue}` : undefined,
      ...params,
    },
    storeKey: 'list-tickets-modal',
  });

  const handleClose = () => {
    popModal(MODALS.LIST_TICKETS_MODAL);
    setSearchValue('');
  };

  return (
    <>
      <BaseModal
        isOpen={modal.visible}
        onClose={handleClose}
        size="800px"
        title={title || 'Tickets'}
        scrollable={false}
        {...props}
      >
        <Alert icon={<IconAlertCircle size="1rem" />} color="blue">
          Filtered By:
          <List>
            {Boolean(Object.keys(params || {}).length) &&
              Object.entries(params || {})
                .filter(([k, v]) => k && v)
                .map(([key, value]) => (
                  <ParamListItem
                    key={key}
                    keyName={key}
                    value={(paramsMap?.[key] || value) as string}
                  />
                ))}
          </List>
        </Alert>
        <Box
          sx={(theme) => ({
            paddingLeft: theme.spacing.md,
            paddingRight: theme.spacing.md,
          })}
        >
          <TextInput
            size="xs"
            label="Search"
            onChange={(e) => handleChange(e.target.value)}
            rightSection={
              <ActionIcon
                color="blue"
                radius="xl"
                onClick={() => {
                  setSearchValue(value);
                }}
              >
                <IconSearch />
              </ActionIcon>
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setSearchValue(value);
              }
            }}
          />
        </Box>
        <Box
          sx={(theme) => ({
            backgroundColor: theme.colors.gray[0],
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          })}
        >
          {isLoading ? (
            <Loader
              sx={(theme) => ({
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: theme.spacing.md,
              })}
            />
          ) : (
            <TicketListInfinite
              pages={data?.pages || []}
              isFetched={isFetched}
              loadNextPage={fetchNextPage}
              hasNextPage={Boolean(hasNextPage)}
              isNextPageLoading={isFetchingNextPage}
              height="68vh"
              projectKey={projectKey}
            />
          )}
        </Box>
      </BaseModal>
    </>
  );
}

export default NiceModal.create((props) => (
  <ListTicketsModal {...props} id={MODALS.LIST_TICKETS_MODAL} />
));
