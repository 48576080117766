import { ActionIcon, Box, Grid, Text, TextInput } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import { useFormikContext } from 'formik';

import { ProjectTicket } from 'types/api';

import MainContentMetadata from './Metadata';

interface MainContentTitleProps {
  ticket?: ProjectTicket;
  title: string;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}

export default function MainContentTitle({
  title,
  ticket,
  isEditing,
  setIsEditing,
}: MainContentTitleProps) {
  const { values, handleChange } = useFormikContext<Partial<ProjectTicket>>();

  return (
    <Box
      sx={(theme) => ({
        position: 'relative',
        marginBottom: theme.spacing.xs,
      })}
    >
      <Grid columns={12}>
        {!isEditing && <Grid.Col xs={1} />}
        <Grid.Col xs={isEditing ? 12 : 10}>
          {isEditing ? (
            <TextInput
              name="title"
              value={values.title}
              onChange={handleChange}
            />
          ) : (
            <>
              <Text align="center" weight="bold" sx={{ lineHeight: '100%' }}>
                {title}
              </Text>
              <MainContentMetadata ticket={ticket} isEditing={isEditing} />
            </>
          )}
        </Grid.Col>
        {!isEditing && (
          <Grid.Col xs={1}>
            {!isEditing && (
              <ActionIcon
                variant="subtle"
                color="blue"
                sx={{ float: 'right' }}
                onClick={() => setIsEditing(!isEditing)}
              >
                <IconEdit />
              </ActionIcon>
            )}
          </Grid.Col>
        )}
      </Grid>
    </Box>
  );
}
