import BaseSelect from '../BaseSelect';
import SelectTarget from './SelectTarget';

import { ReporteeUserSelectProps } from './types';

export default function ReporteeUserSelectUI({
  onChange,
  defaultValue,
  label,
  options,
  width,
  variant,
  ...props
}: ReporteeUserSelectProps) {
  let TargetComponent: ReporteeUserSelectProps['TargetComponent'] = undefined;
  let dropdownWidth = width;

  if (variant === 'avatar') {
    TargetComponent = SelectTarget;

    if (!dropdownWidth) {
      dropdownWidth = '200px';
    }
  }

  return (
    <BaseSelect
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      label={label}
      TargetComponent={TargetComponent}
      width={dropdownWidth}
      {...props}
    />
  );
}
