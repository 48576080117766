import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Box } from '@mantine/core';

import BaseModal from '../BaseModal';

import { MODALS } from 'constants/component';
import useModalStore from 'hooks/store/useModalStore';
import useApiStore from 'hooks/store/useApiStore';

function TriggerInvalidation() {
  const { invalidate } = useApiStore();
  return (
    <Button onClick={() => invalidate('list-project-activity-tab')}>
      Invalidate
    </Button>
  );
}

function DebugModal() {
  const modal = useModal();
  const { popModal } = useModalStore();

  const handleClose = () => {
    popModal(MODALS.DEBUG);
  };

  return (
    <BaseModal
      id={MODALS.DEBUG}
      isOpen={modal.visible}
      onClose={handleClose}
      title="Debug"
      height="auto"
      size="80vw"
    >
      <Box sx={(theme) => ({ padding: theme.spacing.xl })}>
        <TriggerInvalidation />
      </Box>
    </BaseModal>
  );
}

export default NiceModal.create((props) => <DebugModal {...props} />);
