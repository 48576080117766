import { useRef, MutableRefObject, useEffect, useMemo } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Box, Radio, Stack } from '@mantine/core';
import { Formik, useFormikContext, FormikProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import BaseModal from '../BaseModal';
import EmailList from './components/EmailList';
import ModalFooter from './ModalFooter';
import MailtoGenerator from './components/MailtoGenerator';

import { SendInvoiceFormValues } from './types';
import { MODALS } from 'constants/component';
import { Invoice } from 'types/api';

import useModalStore from 'hooks/store/useModalStore';
import useInvoice from 'hooks/billing/useInvoice';
import ClipboardInput from 'components/common/ClipboardInput';
import generateMailtoLink from 'helpers/display/generateMailtoLink';
import useInstanceConfig from 'hooks/instance/useInstanceConfig';

const validationSchema = Yup.object().shape({
  emails: Yup.array().of(Yup.string()).required().min(1, 'Emails is required'),
  sendBy: Yup.string().oneOf(['mailto', 'autosend']),
});

interface SendInvoiceModalProps {
  invoiceId?: number;
}

interface ModalContentProps {
  invoice?: Invoice;
}

function ModalContent({ invoice }: ModalContentProps) {
  const { values, setFieldValue, status } =
    useFormikContext<SendInvoiceFormValues>();

  const link = useMemo(() => {
    const { subject, cc, bcc, body } = values.emailContent || {};

    return generateMailtoLink(
      values.emails || [],
      subject || '',
      body || '',
      cc?.split(',') || [],
      bcc?.split(',') || []
    );
  }, [values.emailContent, values.emails]);

  if (status.tabIndex === 1 && values.sendBy === 'mailto') {
    return (
      <Stack spacing={4}>
        <Box>
          To:{' '}
          <ClipboardInput
            inputProps={{
              value: values.emails.join(','),
            }}
          />{' '}
        </Box>
        <Box>
          Subject:{' '}
          <ClipboardInput
            inputProps={{
              value: values.emailContent?.subject || '',
            }}
          />
        </Box>
        <Box>
          Body:{' '}
          <ClipboardInput
            inputProps={{
              value: values.emailContent?.body || '',
              minRows: 4,
            }}
          />
        </Box>
        <Box>
          Mail To:{' '}
          <Box
            component="a"
            href={link}
            sx={(theme) => ({ color: theme.colors.blue[8] })}
          >
            {link}
          </Box>
        </Box>
        <Box>
          Invoice Link:{' '}
          <Box component="a" target="_blank" href={invoice?.download_link}>
            Download
          </Box>
        </Box>
      </Stack>
    );
  }

  return (
    <>
      <EmailList
        primaryEmail={invoice?.client?.invoice_email}
        secondaryEmail={invoice?.client?.email}
        options={[
          ...new Set(
            [
              invoice?.client?.invoice_email || '',
              invoice?.client?.email || '',
            ].filter((email) => email)
          ),
        ]}
      />
      <Radio.Group
        withAsterisk
        name="sendBy"
        label="Send Via:"
        value={values.sendBy}
        onChange={(value) => {
          setFieldValue('sendBy', value);
        }}
      >
        <Stack sx={(theme) => ({ padding: theme.spacing.xs })}>
          <Radio value="mailto" label="Generate mailto link" />
          <Radio disabled value="autosend" label="Send to selected emails" />
        </Stack>
      </Radio.Group>
      {values.sendBy === 'mailto' && <MailtoGenerator />}
    </>
  );
}

function SendInvoiceModal({ invoiceId }: SendInvoiceModalProps) {
  const modal = useModal();
  const formikRef =
    useRef() as MutableRefObject<FormikProps<SendInvoiceFormValues> | null>;
  const { popModal } = useModalStore();
  const { data, isLoading, sendInvoice } = useInvoice({ invoiceId });
  const invoice = data?.invoice;
  const invoiceEmails = invoice?.emails?.split(',') || [];
  const { data: configData } = useInstanceConfig();

  const handleClose = () => {
    popModal(MODALS.SEND_INVOICE_MODAL);
  };

  const handleSubmit = async (
    values: SendInvoiceFormValues,
    { setStatus }: FormikHelpers<SendInvoiceFormValues>
  ) => {
    if (formikRef.current?.status?.tabIndex === 0) {
      setStatus({
        ...formikRef.current?.status,
        tabIndex: 1,
      });
      return;
    }

    if (
      await sendInvoice({
        emails: values.emails,
        invoice_email_body: values.emailContent.body || '',
        invoice_email_topic: values.emailContent.subject || '',
      })
    ) {
      handleClose();
    }
  };

  useEffect(() => {
    if (!modal.visible) {
      formikRef.current?.resetForm();
    }
  }, [modal]);

  return (
    <Formik<SendInvoiceFormValues>
      innerRef={formikRef}
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={{
        sendBy: 'mailto',
        emailContent: {
          to: invoice?.emails,
          cc: '',
          bcc: '',
          body:
            invoice?.invoice_email_body ||
            configData?.config?.invoice_email_body ||
            '',
          subject:
            invoice?.invoice_email_topic ||
            configData?.config?.invoice_email_topic ||
            '',
        },
        emails: invoiceEmails?.filter((email) => email)?.length
          ? invoiceEmails
          : [data?.invoice?.client?.invoice_email || ''].filter(
              (email) => email
            ),
      }}
      initialStatus={{
        tabIndex: 0,
      }}
      onSubmit={handleSubmit}
    >
      <BaseModal
        id={MODALS.SEND_INVOICE_MODAL}
        isOpen={modal.visible}
        onClose={handleClose}
        title="Send Invoice"
        height="auto"
        size="40vw"
        loading={isLoading}
        FooterComponent={ModalFooter}
      >
        <Box sx={(theme) => ({ padding: theme.spacing.md })}>
          <ModalContent invoice={data?.invoice} />
        </Box>
      </BaseModal>
    </Formik>
  );
}

export default NiceModal.create((props) => <SendInvoiceModal {...props} />);
