import { Menu, Button, Box, ActionIcon, Tooltip } from '@mantine/core';
import { IconChevronUp, IconChevronDown } from '@tabler/icons-react';
import { useMemo } from 'react';

interface ProjectSort {
  sort?: string;
  setSort?: (sort?: string) => void;
}

const SORT_OPTIONS = [
  {
    label: 'Saved Position',
    value: undefined,
  },
  {
    label: 'Name',
    value: 'title',
  },
  {
    label: 'Latest Activity',
    value: 'latest_activity',
  },
  {
    label: 'Remaining Budget',
    value: 'client_budget',
  },
];

export default function ProjectSort({ sort, setSort }: ProjectSort) {
  const isDesc = Boolean(sort?.includes('-'));

  const sortDisplay = useMemo(() => {
    return SORT_OPTIONS.find(
      (option) => option.value === sort || `-${option.value}` === sort
    )?.label;
  }, [sort]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <Button
            sx={(theme) => ({
              marginBottom: theme.spacing.xs,
              textTransform: 'capitalize',
            })}
            size="xs"
            variant="subtle"
          >
            Sort By: {sortDisplay || 'Saved Position'}
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          {SORT_OPTIONS.map((option) => (
            <Menu.Item
              sx={{ textAlign: 'center' }}
              key={option.label}
              onClick={() => {
                option.value
                  ? setSort?.(`-${option.value}`)
                  : setSort?.(undefined);
              }}
            >
              {option.label}
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
      {sort && (
        <Tooltip label={isDesc ? 'DESC' : 'ASC'}>
          <ActionIcon
            variant="filled"
            color="blue"
            onClick={() => {
              setSort?.(isDesc ? sort.slice(1) : `-${sort}`);
            }}
          >
            {isDesc ? <IconChevronDown /> : <IconChevronUp />}
          </ActionIcon>
        </Tooltip>
      )}
    </Box>
  );
}
