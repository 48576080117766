import { useState } from 'react';
import { Skeleton, rem, Card, Box, Text, Checkbox } from '@mantine/core';

import { ProjectTicket } from 'types/api';

import useTicket from 'hooks/tickets/useTicket';
import useAuthUser from 'hooks/users/useAuthUser';
import useModalStore from 'hooks/store/useModalStore';
import { MODALS } from 'constants/component';
import getTicketDisplay from 'helpers/display/getTicketDisplay';
import useInstanceConfig from 'hooks/instance/useInstanceConfig';

interface ContainerRowProps {
  isInfinite?: boolean;
  ticketId?: number;
  projectKey?: string;
  hideTicket?: (ticket?: ProjectTicket) => boolean;
}

export default function ContainedRow({
  ticketId,
  hideTicket,
  projectKey,
}: ContainerRowProps) {
  const { pushModal } = useModalStore();
  const { dateDisplay } = useInstanceConfig();
  const [loading, setLoading] = useState(false);
  const height = rem(40);
  const { data, isLoading, updateTicket } = useTicket({
    ticketId: ticketId,
    params: {
      fields: 'id,title,is_done,datetime_updated',
    },
  });
  const { formatUserTimezone } = useAuthUser();
  const ticket = data?.ticket;

  if (isLoading) {
    return <Skeleton height={height} radius="md" />;
  }

  if (hideTicket?.(ticket)) {
    return <></>;
  }

  return (
    <Card shadow="md" radius="md" sx={{ height: height, width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Checkbox
          radius="xl"
          color="green"
          sx={{ cursor: 'pointer' }}
          checked={ticket?.is_done}
          disabled={loading}
          onChange={async () => {
            setLoading(true);
            try {
              await updateTicket(ticketId as number, {
                is_done: !ticket?.is_done,
              });
            } finally {
              setLoading(false);
            }
          }}
        />
        <Text
          sx={(theme) => ({
            ':hover': { textDecoration: 'underline', color: theme.colors.blue },
            cursor: 'pointer',
          })}
          onClick={() => {
            pushModal(MODALS.EDIT_TICKET_MODAL, { ticket: ticket });
          }}
        >
          {getTicketDisplay(ticket as ProjectTicket, projectKey)}
        </Text>
        {Boolean(ticket?.datetime_updated) && (
          <Text size="xs" sx={(theme) => ({ color: theme.colors.gray[6] })}>
            Updated:{' '}
            {formatUserTimezone(
              new Date(ticket?.datetime_updated as string),
              `${dateDisplay} h:mm a`
            )}
          </Text>
        )}
      </Box>
    </Card>
  );
}
