import { Breadcrumbs, Anchor } from '@mantine/core';
import useBreadcrumbs from 'hooks/app/useBreadcrumbs';

export interface BreadCrumbsProps {}

export default function BreadCrumbs() {
  const { breadcrumbs } = useBreadcrumbs();

  return (
    <>
      <Breadcrumbs>
        {breadcrumbs.map((item, index) => (
          <Anchor
            href={`/${item.href}`}
            key={index}
            sx={(theme) => ({
              textTransform: 'capitalize',
              color: theme.white,
            })}
          >
            {item.display}
          </Anchor>
        ))}
      </Breadcrumbs>
    </>
  );
}
