import { useMemo } from 'react';

import { SelectProps } from '../../types';
import BaseSelect from '../BaseSelect';
import useProjects from 'hooks/projects/useProjects';

interface ProjectSelectProps extends SelectProps {}

export default function ProjectSelect({
  onChange,
  defaultValue,
  label,
  readonly,
  ...props
}: ProjectSelectProps) {
  const { data } = useProjects();

  const options = useMemo(() => {
    return data?.projects?.map((project) => {
      return {
        value: project.id as number,
        label: project.title,
      };
    });
  }, [data]);

  return (
    <BaseSelect
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      label={label}
      readonly={readonly}
      {...props}
    />
  );
}
