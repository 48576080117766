import { BaseToggleButtonProps } from './types';
import BaseToggleButton from './variants/Base';
import BlockedTicketToggleButton from './variants/BlockedTicket';

interface ToggleButtonProps extends BaseToggleButtonProps {
  parentVariant?: 'base' | 'blockedticket';
}

export default function ToggleButton({
  parentVariant,
  ...props
}: ToggleButtonProps) {
  if (parentVariant === 'blockedticket') {
    return <BlockedTicketToggleButton {...props} />;
  }

  return <BaseToggleButton {...props} />;
}
