import { SelectOption, SelectProps } from '../../types';
import BaseSelect from '../BaseSelect';

import SelectTarget from './SelectTarget';

interface BillableSelectProps extends SelectProps {}

export default function BillableSelect({
  onChange,
  defaultValue,
  label,
}: BillableSelectProps) {
  const options: SelectOption[] = [
    {
      label: 'All',
      value: '',
    },
    {
      label: 'Billable',
      value: '1',
    },
    {
      label: 'Non-Billable',
      value: '0',
    },
  ];

  return (
    <BaseSelect
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      label={label}
      TargetComponent={SelectTarget}
      sx={{
        marginTop: 'none',
        marginBottom: 'none',
        height: '100%',
      }}
    />
  );
}
