import { Card, Stack } from '@mantine/core';
import { useParams } from 'react-router-dom';

import TicketGroupTitle from './Title';
import TicketGroupBody from './Body';
import TicketGroupFooter from './Footer';
import { TicketGroup as TicketGroupType } from './types';

export interface TicketGroupProps {
  ticketGroup: TicketGroupType;
}

export default function TicketGroup({ ticketGroup }: TicketGroupProps) {
  const { projectId } = useParams();

  return (
    <Card
      withBorder
      p={4}
      sx={(theme) => ({
        backgroundColor: ticketGroup.isDone
          ? theme.colors.green[1]
          : theme.colors.gray[1],
        minWidth: '300px',
        maxWidth: '300px',
      })}
    >
      <Stack spacing={4}>
        <TicketGroupTitle ticketGroup={ticketGroup} />
        <TicketGroupBody ticketGroup={ticketGroup} />
        <TicketGroupFooter
          ticketGroup={ticketGroup}
          projectId={projectId || '0'}
        />
      </Stack>
    </Card>
  );
}
