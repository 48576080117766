/**
 * formats a number to a currency human-readable display
 * @param {number} amount currency number to format
 * @param {string} locale local input to Intl.NumberFormat constructor
 * @param {string} currencyType currency type to pass to currency options in constructor
 * @return formatted currency value as string
 */
export default function getCurrencyDisplay(
  amount: number,
  locale: string = 'en-US',
  currencyType: string = 'USD'
) {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyType,
  });
  return formatter.format(amount);
}
