import { useCallback, useEffect, useMemo } from 'react';
import { notifications } from '@mantine/notifications';

import { ENDPOINTS } from 'constants/api';
import type { InstanceConfig } from 'types/api';
import { ApiQueryKey } from 'types/api/query';
import { DATE_FORMATS } from 'constants/date';
import type { UpdateInstanceConfigCallback } from 'utils/instance';

import * as instanceUtils from 'utils/instance';
import showApiErrNotif from 'helpers/api/showApiErrNotif';

import useApiQuery from 'hooks/api/useApiQuery';
import useApiStore from 'hooks/store/useApiStore';

export interface InstanceConfigHookParams {
  storeKey?: ApiQueryKey;
  params?: any;
}

export default function useInstanceConfig(
  hookParams: InstanceConfigHookParams = {
    storeKey: 'instance-config',
  }
) {
  const { storeKey, params } = hookParams;
  const { invalidatePath } = useApiStore();

  const queryResult = useApiQuery<{
    config?: InstanceConfig;
  }>({
    path: ENDPOINTS.INSTANCE_CONFIG,
    storeKey: storeKey,
    axiosConfig: {
      params,
    },
  });

  const updateInstanceConfig: UpdateInstanceConfigCallback = useCallback(
    async (data) => {
      try {
        const statusRules = data.project_status_rules;
        if (statusRules) {
          delete statusRules[''];

          Object.entries({ ...statusRules }).forEach(([key, value]) => {
            if (!Array.isArray(value) || value.length === 0) {
              delete statusRules[key];
            }
          });
        }

        if (await instanceUtils.updateInstanceConfig(data)) {
          queryResult.refetch();
          invalidatePath(['activity']);
          notifications.show({
            message: 'Instance config successfully updated!',
            variant: 'success',
          });
          return true;
        } else {
          throw new Error('Patch request did not respond with a 200.');
        }
      } catch (err) {
        showApiErrNotif(
          'Unable to update instance config at this time. Try again later.',
          err
        );
      }

      return false;
    },
    [invalidatePath, queryResult]
  );

  const dateDisplay = useMemo(() => {
    try {
      const meta = JSON.parse(queryResult?.data?.config?.display_meta || '{}');
      return meta.date.value || DATE_FORMATS.PRIMARY;
    } catch (err) {
      // pass
    }

    return DATE_FORMATS.PRIMARY;
  }, [queryResult.data]);

  useEffect(() => {
    window.DATE_DISPLAY = dateDisplay;
  }, [dateDisplay]);

  return {
    ...queryResult,
    dateDisplay,
    updateInstanceConfig,
  };
}
