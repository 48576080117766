import { useState } from 'react';
import { Box } from '@mantine/core';
import { DateValue } from '@mantine/dates';
import getWeek from 'date-fns/getWeek';

import ActivityList from 'components/common/ActivityList/GroupedList/UserActivityGroupedList';
import DateRangePicker from 'components/common/DateRangePicker';
import { TABBED_CONTENT_HEIGHT } from 'constants/component';
import getDaysOfWeek from 'helpers/datetime/getDaysOfWeek';

interface ActivityTabProps {
  userId?: string;
}

export default function ActivityTab({ userId }: ActivityTabProps) {
  const [start, , , , , , end] = getDaysOfWeek(getWeek(new Date()));
  const [dateRange, setDateRange] = useState<[DateValue, DateValue]>([
    start,
    end,
  ]);
  const actionHeight = '36px';

  return (
    <>
      <Box
        sx={(theme) => ({
          borderBottom: '1px solid',
          borderColor: theme.colors.gray[3],
          height: actionHeight,
        })}
      >
        <DateRangePicker
          variant="arrow"
          value={dateRange}
          onChange={setDateRange}
        />
      </Box>
      <ActivityList
        userId={userId}
        dateRange={dateRange}
        setDateRange={setDateRange}
        height={`calc(${TABBED_CONTENT_HEIGHT} - ${actionHeight})`}
      />
    </>
  );
}
