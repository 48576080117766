import { Modal } from '@mantine/core';

import { MODAL_HEADER_HEIGHT } from 'constants/component';
import { ModalHeaderProps } from '../../types';

export default function ModalHeader({ title, onClose }: ModalHeaderProps) {
  return (
    <Modal.Header
      sx={(theme) => ({
        backgroundColor: theme.black,
        color: theme.white,
        zIndex: 100,
        height: MODAL_HEADER_HEIGHT,
      })}
    >
      <Modal.Title>{title}</Modal.Title>
      <Modal.CloseButton
        onClick={(e) => {
          e.preventDefault();

          onClose?.();
        }}
      />
    </Modal.Header>
  );
}
