import { MutableRefObject, useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { Stack, Button, Box, NumberInput } from '@mantine/core';
import isEmpty from 'lodash/isEmpty';

import Section from '../../section/Section';
import ImageDropzone from 'components/common/ImageDropzone';

import useInstance from 'hooks/instance/useInstance';

interface FormValues {
  logo?: File;
  next_invoice_id?: number;
}

export default function InstanceSection() {
  const { data, isLoading, updateInstance } = useInstance();
  const formikRef = useRef() as MutableRefObject<FormikProps<
    Partial<FormValues>
  > | null>;

  const handleSubmit = async (values: FormValues) => {
    const formValues = { ...values };

    Object.keys(values).forEach((key) => {
      if (!formikRef?.current?.status?.[key]) {
        delete formValues[key as keyof typeof formValues];
      }
    });

    if (isEmpty(formValues)) {
      return;
    }

    await updateInstance(formValues);
  };

  return (
    <Section
      title={
        window.INSTANCE_NAME ||
        process.env.REACT_APP_INSTANCE_NAME ||
        'Instance'
      }
      loading={isLoading}
    >
      <Formik<FormValues>
        innerRef={formikRef}
        enableReinitialize
        onSubmit={handleSubmit}
        initialStatus={{
          logo: false,
          next_invoice_id: false,
        }}
        initialValues={{
          logo: data?.instance?.logo
            ? new File([], data?.instance?.logo)
            : undefined,
          next_invoice_id: data?.instance?.next_invoice_id || 1,
        }}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          submitForm,
          resetForm,
          status,
          setStatus,
          initialStatus,
        }) => (
          <Stack>
            <ImageDropzone
              label="Logo"
              onDrop={(files) => {
                setStatus({
                  ...status,
                  logo: true,
                });
                setFieldValue('logo', files[0]);
              }}
              loading={isSubmitting}
              value={values?.logo}
            />
            <NumberInput
              name="next_invoice_id"
              label="Next Invoice ID"
              value={values?.next_invoice_id}
              onChange={(value) => {
                setStatus({
                  ...status,
                  next_invoice_id: true,
                });
                setFieldValue('next_invoice_id', value);
              }}
            />
            <Box
              sx={(theme) => ({
                padding: theme.spacing.xs,
                display: 'flex',
                justifyContent: 'flex-start',
                columnGap: '12px',
              })}
            >
              <Button
                variant="outline"
                radius="md"
                onClick={() => {
                  setStatus(initialStatus);
                  resetForm();
                }}
                disabled={isSubmitting}
              >
                Reset
              </Button>
              <Button
                variant="filled"
                radius="md"
                onClick={() => submitForm()}
                loading={isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Stack>
        )}
      </Formik>
    </Section>
  );
}
