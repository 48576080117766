import { AxiosError } from 'axios';
import { ENDPOINTS } from 'constants/api';
import axios from 'utils/axios';

export interface LoginBody {
  username: string;
  password: string;
}

export interface GoogleCredential {
  credential: string | undefined;
}

export async function logIn(loginData: LoginBody | GoogleCredential) {
  try {
    let endpoint = ENDPOINTS.LOGIN;
    if ('credential' in loginData) {
      endpoint = ENDPOINTS.SSO_LOGIN;
    }
    await axios.post(
      endpoint,
      loginData,
      { baseURL: process.env.REACT_APP_API_BASE_URL }
    );
    return {
      success: true,
    };
  } catch (err) {
    const error = err as AxiosError;
    return {
      success: false,
      message: error.message,
    };
  }
}

export async function ping() {
  try {
    const res = await axios.get<{ is_authenticated?: boolean }>(
      ENDPOINTS.PING,
      { baseURL: process.env.REACT_APP_API_BASE_URL }
    );
    return res.data;
  } catch (err) {
    return {};
  }
}

export async function logout() {
  return axios.post(
    ENDPOINTS.LOGOUT,
    {},
    { baseURL: process.env.REACT_APP_API_BASE_URL }
  );
}
