import { useMemo, useCallback } from 'react';

import { BillableType } from 'components/common/Modal/variants/EditTicketModal/types';
import { BILLING_TYPE } from 'constants/billing';
import { TicketBillingRecord } from 'types/api';

interface TicketBillingRecordsAggregateHookParams {
  billingRecords: TicketBillingRecord[];
  expenseBillableType?: BillableType;
  timeBillableType?: BillableType;
}

export default function useTicketBillingRecordsAggregate({
  billingRecords,
  expenseBillableType,
  timeBillableType,
}: TicketBillingRecordsAggregateHookParams) {
  const filterByBillableType = useCallback(
    (
      billingRecord: TicketBillingRecord,
      billableType: BillableType = 'all'
    ) => {
      if (billableType === 'billable') {
        return billingRecord.billable;
      }

      if (billableType === 'non-billable') {
        return !billingRecord.billable;
      }

      return true;
    },
    []
  );

  const aggregateData = useMemo(() => {
    const totalTime = billingRecords
      .filter(
        (billingRecord) =>
          billingRecord.billing_type === BILLING_TYPE.TIME &&
          filterByBillableType(billingRecord, timeBillableType)
      )
      .reduce((a, b) => a + b.amount, 0);

    const totalExpense = billingRecords
      .filter(
        (billingRecord) =>
          billingRecord.billing_type === BILLING_TYPE.EXPENSE &&
          filterByBillableType(billingRecord, expenseBillableType)
      )
      .reduce((a, b) => a + b.amount, 0);

    return {
      totalExpense,
      totalTime,
    };
  }, [
    billingRecords,
    expenseBillableType,
    filterByBillableType,
    timeBillableType,
  ]);

  return {
    ...aggregateData,
  };
}
