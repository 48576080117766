import { Box, Avatar } from '@mantine/core';
import { IconUser } from '@tabler/icons-react';

import { SelectOption } from '../../types';
import getInitials from 'helpers/display/getInitials';

interface SelectTargetProps {
  selected: SelectOption;
  opened: boolean;
}

export default function SelectTarget({ selected }: SelectTargetProps) {
  return (
    <Box>
      <Avatar size="sm" radius="xl">
        {!selected?.label ? (
          <IconUser size="16px" />
        ) : (
          getInitials({ fullName: selected?.label as string })
        )}
      </Avatar>
    </Box>
  );
}
