import { InstanceUser } from 'types/api';

/**
 * get fullname (first + last) of a user
 * @param user - user to get full name from
 */
export default function getUserFullName(
  user?: Pick<InstanceUser, 'first_name' | 'last_name'>
): string {
  if (!user || typeof user !== 'object') {
    return '';
  }

  const fullName = user.last_name;

  if (!fullName) {
    return user?.first_name || '';
  }

  return `${user.first_name} ${user.last_name}`.trim();
}
