import { Group, Text } from '@mantine/core';
import { TicketGroup } from './types';
import { DONE_COLUMN_LIMIT } from 'constants/project';
import { MODALS } from 'constants/component';

import useModalStore from 'hooks/store/useModalStore';
import { useParams } from 'react-router-dom';
import useProject from 'hooks/projects/useProject';

export interface TicketGroupTitleProps {
  ticketGroup: TicketGroup;
}

export default function TicketGroupTitle({
  ticketGroup,
}: TicketGroupTitleProps) {
  const { pushModal } = useModalStore();
  const { projectId } = useParams();
  const { data } = useProject({ projectId });
  const isDone = ticketGroup.isDone;
  const isOverMax = isDone && ticketGroup.items.length > DONE_COLUMN_LIMIT;

  return (
    <Group
      display="flex"
      sx={(theme) => ({
        textAlign: 'center',
        justifyContent: 'center',
        borderBottom: '1px solid',
        borderColor: theme.colors.gray[4],
        gap: 4,
      })}
    >
      <Text transform="uppercase" weight="bold">
        {ticketGroup.name}
      </Text>
      <Text
        size="xs"
        sx={(theme) => ({
          color: theme.colors.gray[6],
          textDecoration: isOverMax ? 'underline' : undefined,
        })}
        onClick={() => {
          if (isOverMax) {
            pushModal(MODALS.LIST_TICKETS_MODAL, {
              params: {
                is_done: '1',
                project_id: projectId,
              },
              paramsMap: {
                project_id: data?.project?.title,
              },
              title: 'Completed Tickets',
              projectKey: data?.project?.key,
            });
          }
        }}
      >
        ({ticketGroup.items.length})
      </Text>
    </Group>
  );
}
