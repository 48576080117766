import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import {
  Table as MantineTable,
  Card,
  createStyles,
  Box,
  Loader,
  rem,
} from '@mantine/core';

import { BaseTableProps } from 'components/common/Table/types';

import TableCol from './TableCol';
import TableRow from './TableRow';

const useStyles = createStyles((theme) => ({
  col: {
    zIndex: 2,
    backgroundColor: theme.black,
    '& > div': {
      color: theme.white,
      textAlign: 'center',
    },
  },
}));

interface DraggableTableProps<R extends { id?: number | string }>
  extends BaseTableProps<R> {
  id: string;
  handleDragEnd: OnDragEndResponder;
  disableDrag?: boolean;
}

export default function DraggableTable<R extends { id?: number | string }>({
  columns,
  rows,
  tableProps,
  ColComponent = TableCol,
  RowComponent = TableRow,
  getDataDisplay,
  onRowClick,
  colGroup,
  loading,
  height,
  id,
  handleDragEnd,
  disableDrag,
}: DraggableTableProps<R>) {
  const { classes } = useStyles();

  if (!rows?.length) {
    return (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        {loading ? <Loader /> : 'No results found.'}
      </Box>
    );
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Card
        withBorder
        p={0}
        sx={(theme) => ({
          overflow: 'visible',
          maxHeight: height,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: rem(9),
          },
          '&::-webkit-scrollbar-track': {
            background: theme.colors.gray[4],
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.colors.gray[5],
            borderRadius: theme.radius.md,
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: theme.colors.gray[6],
          },
        })}
      >
        <MantineTable miw={800} verticalSpacing="sm" {...tableProps}>
          {colGroup}
          <thead style={{ position: 'sticky', top: 0, zIndex: 100 }}>
            <tr>
              <th className={classes.col}></th>
              {columns?.map((col) => (
                <th colSpan={col.colSpan} key={col.id} className={classes.col}>
                  <Box>
                    <ColComponent key={col.id} col={col} />
                  </Box>
                </th>
              ))}
            </tr>
          </thead>
          <Droppable droppableId={id} isDropDisabled={disableDrag}>
            {(provided) => (
              <tbody
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ maxHeight: height, width: '100%', overflow: 'auto' }}
              >
                {rows?.map((row, index) => (
                  <RowComponent
                    key={row.id}
                    row={row}
                    columns={columns}
                    getDataDisplay={getDataDisplay}
                    onClick={onRowClick}
                    index={index}
                    disableDrag={disableDrag}
                  />
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </MantineTable>
      </Card>
    </DragDropContext>
  );
}
