import { Group, Badge, ActionIcon, rem } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

import { TicketEstimate } from 'types/api';
import { DeleteTicketEstimateCallback } from '../../types';

interface EstimateItemProps {
  estimate?: TicketEstimate;
  deleteTicketEstimate?: DeleteTicketEstimateCallback;
}

export default function EstimateGroupItem({
  estimate,
  deleteTicketEstimate,
}: EstimateItemProps) {
  return (
    <Group
      sx={(theme) => ({
        justifyContent: 'space-between',
        color: theme.white,
      })}
    >
      <Badge
        rightSection={
          <ActionIcon
            component="div"
            size="xs"
            color="blue"
            radius="xl"
            variant="transparent"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              return deleteTicketEstimate?.(estimate?.id || 0);
            }}
          >
            <IconX size={rem(10)} />
          </ActionIcon>
        }
      >
        {estimate?.job_type} - {(estimate?.time || 0) / 60} HR (
        {(estimate?.completion || 0) * 100}%)
      </Badge>
    </Group>
  );
}
