import { useState } from 'react';

import BasePopOver from '../../BasePopOver';
import PopOverDropdown from '../components/PopOverDropdown';
import PopOverTarget from '../components/PopOverTargetEdit';

import {
  AddTicketEstimateCallback,
  DeleteTicketEstimateCallback,
} from 'components/common/Modal/variants/EditTicketModal/types';
import { TicketEstimate } from 'types/api';

interface EstimatePopOverProps {
  updateTicketEstimate?: AddTicketEstimateCallback;
  filter?: (value: string) => boolean;
  estimate?: TicketEstimate;
  deleteTicketEstimate?: DeleteTicketEstimateCallback;
}

export default function EstimatePopOverEdit({
  updateTicketEstimate,
  filter,
  estimate,
  deleteTicketEstimate,
}: EstimatePopOverProps) {
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <BasePopOver
      TargetComponent={PopOverTarget}
      DropdownComponent={PopOverDropdown}
      popoverProps={{
        closeOnClickOutside: false,
        opened: opened,
      }}
      dropdownProps={{
        onClose: () => setOpened(false),
        onSubmit: updateTicketEstimate,
        initialValues: {
          time: estimate?.time || 0,
          job_type: estimate?.job_type,
        },
        additionalProps: {
          selectProps: {
            filter,
          },
        },
      }}
      targetProps={{
        onClick: () => setOpened(!opened),
        additionalProps: {
          estimate: estimate,
          deleteTicketEstimate: deleteTicketEstimate,
        },
      }}
    />
  );
}
