import { createStyles, rem, Text, Stack } from '@mantine/core';
import { Draggable } from 'react-beautiful-dnd';

import { TicketAssignment } from 'types/api';

import AssignmentItem from './EstimateItem';
import getTicketDisplay from 'helpers/display/getTicketDisplay';

const useStyles = createStyles((theme) => ({
  item: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.radius.md,
    border: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: `${theme.spacing.sm} ${theme.spacing.sm}`,
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
    marginBottom: theme.spacing.xs,
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },
}));

export interface TicketGroupListItemProps {
  assignment: TicketAssignment;
  index: number;
}

export default function TicketGroupListItem({
  assignment,
  index,
}: TicketGroupListItemProps) {
  const { classes, cx } = useStyles();

  return (
    <Draggable
      key={assignment.id}
      index={index}
      draggableId={`${assignment.id}`}
    >
      {(provided, snapshot) => (
        <div
          className={cx(classes.item, {
            [classes.itemDragging]: snapshot.isDragging,
          })}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Stack>
            <Text>{getTicketDisplay(assignment.ticket)}</Text>
            <AssignmentItem
              key={assignment.id}
              assignment={assignment}
              index={index}
            />
          </Stack>
        </div>
      )}
    </Draggable>
  );
}
