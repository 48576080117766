import { useCallback } from 'react';
import { ENDPOINTS } from 'constants/api';
import type { TicketComment } from 'types/api';

import constructQueryPath from 'helpers/api/constructQueryPath';
import type { TicketCommentBody } from 'utils/ticket';
import * as ticketUtils from 'utils/ticket';
import showApiErrNotif from 'helpers/api/showApiErrNotif';
import { notifications } from '@mantine/notifications';

import useApiQuery from 'hooks/api/useApiQuery';
import useApiStore from 'hooks/store/useApiStore';

export interface TicketCommentsHookParams {
  params?: any;
  ticketId: string;
}

export default function useTicketComments(
  hookParams: TicketCommentsHookParams
) {
  const { invalidate } = useApiStore();
  const { params, ticketId } = hookParams || {};

  const queryResult = useApiQuery<{ comments?: TicketComment[] }>({
    path: constructQueryPath(ENDPOINTS.TICKET_COMMENTS, {
      ticketId,
    }),
    axiosConfig: {
      params,
    },
    queryOptions: {
      enabled: Boolean(ticketId),
    },
  });

  const addTicketComment = useCallback(
    async (body: TicketCommentBody, attachments?: File[]) => {
      try {
        const res = await ticketUtils.addTicketComment(ticketId, body);

        if (attachments?.length) {
          await ticketUtils.updateCommentAttachments(
            parseInt(ticketId),
            res.data.comment.id as number,
            attachments
          );
        }

        notifications.show({
          message: 'Comment has been successfully added!',
          variant: 'success',
        });
        invalidate('edit-ticket-modal');
        invalidate('edit-ticket-modal-activity');
        invalidate('list-project-tickets');
        queryResult.refetch();

        return res.data.comment;
      } catch (err) {
        showApiErrNotif('Unable to add comment. Try again later.', err);
      }
    },
    [invalidate, queryResult, ticketId]
  );

  return {
    ...queryResult,
    addTicketComment,
  };
}
