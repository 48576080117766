import { notifications } from '@mantine/notifications';
import { Box, Button, Stack, Tooltip } from '@mantine/core';
import { AxiosError } from 'axios';

import DetailsSection from './DetailsSection';
import EstimatesSection from './EstimatesSection';
import BillingSection from './BillingSection';

import { TicketBody, updateTicket } from 'utils/ticket';
import {
  AddTicketEstimateCallback,
  DeleteTicketEstimateCallback,
  EditTicketEstimateCallback,
  UpdateTicketSubscriptionCallback,
} from '../types';
import { ProjectTicket } from 'types/api';
import { INVALIDATE_GROUP } from 'constants/api';

import useApiStore from 'hooks/store/useApiStore';
import useProjectPageStore from 'hooks/store/useProjectPageStore';
import { useState } from 'react';

interface EditTicketModalSideContentProps {
  ticket?: ProjectTicket;
  addTicketEstimate?: AddTicketEstimateCallback;
  editTicketEstimate?: EditTicketEstimateCallback;
  deleteTicketEstimate?: DeleteTicketEstimateCallback;
  updateTicketSubscription?: UpdateTicketSubscriptionCallback;
}

export default function EditTicketModalSideContent({
  ticket,
  addTicketEstimate,
  editTicketEstimate,
  deleteTicketEstimate,
  updateTicketSubscription,
}: EditTicketModalSideContentProps) {
  const { invalidate, invalidateGroup, invalidatePath } = useApiStore();
  const { moveTicketById } = useProjectPageStore();
  const [loading, setLoading] = useState(false);

  const handleTicketUpdate = async (ticketBody: Partial<TicketBody>) => {
    const refreshPageKeys: (keyof TicketBody)[] = ['status'];

    try {
      setLoading(true);
      await updateTicket(ticket?.id as number, ticketBody);
      invalidateGroup(
        INVALIDATE_GROUP.TICKET.filter(
          (group) => group !== 'list-project-tickets'
        )
      );
      invalidatePath(['projects', '', 'tickets']);
      let hasRefetchedPage = false;
      refreshPageKeys.forEach((key) => {
        if (ticketBody[key] && !hasRefetchedPage) {
          hasRefetchedPage = true;
          invalidate('list-project-tickets');
        }
      });

      notifications.show({
        message: 'Ticket successfully updated!',
        color: 'green',
      });
      return true;
    } catch (err) {
      const error = err as AxiosError;
      notifications.show({
        message: error.message,
        color: 'red',
      });
    } finally {
      setLoading(false);
    }

    return false;
  };

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.black,
        color: theme.white,
        padding: theme.spacing.sm,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
      })}
    >
      <Stack
        spacing={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <DetailsSection
          ticket={ticket}
          handleTicketUpdate={handleTicketUpdate}
          updateTicketSubscription={updateTicketSubscription}
        />
        <EstimatesSection
          ticket={ticket}
          estimates={ticket?.estimates || []}
          addTicketEstimate={addTicketEstimate}
          deleteTicketEstimate={deleteTicketEstimate}
          editTicketEstimate={editTicketEstimate}
        />
        <BillingSection
          ticket={ticket}
          handleTicketUpdate={handleTicketUpdate}
        />
      </Stack>
      <Tooltip label={ticket?.is_done ? 'Un-mark as Done' : 'Mark as Done'}>
        <Button
          color={ticket?.is_done ? 'green' : 'blue'}
          variant={ticket?.is_done ? 'filled' : 'outline'}
          loading={loading}
          onClick={() => {
            try {
              moveTicketById(
                ticket?.project_id?.toString() as string,
                ticket?.status as string,
                ticket?.id as number,
                'done'
              );
              handleTicketUpdate({ is_done: !ticket?.is_done });
            } catch (err) {
              const error = err as Error;
              notifications.show({
                message: error.message,
                color: 'red',
              });
            }
          }}
        >
          {ticket?.is_done ? 'Done' : 'Mark as Done'}
        </Button>
      </Tooltip>
    </Box>
  );
}
