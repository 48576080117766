import { Loader, Select } from '@mantine/core';

import { BaseAutocompleteProps } from '../../types';

export default function BaseAutocomplete({
  data,
  loading,
  ...props
}: BaseAutocompleteProps) {
  return (
    <Select
      searchable
      maxDropdownHeight={400}
      nothingFound={loading ? 'Loading...' : 'No items found.'}
      filter={(value, item) =>
        item.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item.value.toLowerCase().includes(value.toLowerCase().trim())
      }
      rightSection={loading ? <Loader size="xs" /> : undefined}
      {...props}
      data={data || []}
    />
  );
}
