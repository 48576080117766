import { ENDPOINTS, INVALIDATE_GROUP } from 'constants/api';
import type { ProjectTicket } from 'types/api';
import { notifications } from '@mantine/notifications';

import constructQueryPath from 'helpers/api/constructQueryPath';
import { useCallback } from 'react';
import {
  TicketEstimateBody,
  addTicketEstimate as addTicketEstimateCallback,
  editTicketEstimate as editTicketEstimateCallback,
  deleteTicketEstimate as deleteTicketEstimateCallback,
  updateTicketSubscription as updateTicketSubscriptionCallback,
  updateTicket as updateTicketCallback,
} from 'utils/ticket';
import showApiErrNotif from 'helpers/api/showApiErrNotif';
import { ApiQueryKey } from 'types/api/query';

import useApiStore from 'hooks/store/useApiStore';
import useApiQuery from 'hooks/api/useApiQuery';

export interface TicketHookParams {
  ticketId?: number;
  params?: any;
  storeKey?: ApiQueryKey;
}

export default function useTicket({
  ticketId,
  params,
  storeKey,
}: TicketHookParams) {
  const { invalidateGroup, invalidatePath } = useApiStore();

  const queryResult = useApiQuery<{ ticket: ProjectTicket }>({
    path: constructQueryPath(ENDPOINTS.TICKET, { ticketId: `${ticketId}` }),
    axiosConfig: {
      params,
    },
    queryOptions: {
      enabled: Boolean(ticketId),
    },
    storeKey,
  });

  const addTicketEstimate = useCallback(
    async (
      body: TicketEstimateBody,
      estimateTicketId: number = ticketId || 0
    ) => {
      try {
        await addTicketEstimateCallback(estimateTicketId as number, body);
        invalidateGroup(INVALIDATE_GROUP.TICKET);
        invalidatePath(['activity']);
        queryResult.refetch();
        notifications.show({
          message: 'Estimate successfully added!',
          variant: 'success',
        });
        return true;
      } catch (err) {
        showApiErrNotif('Unable to add ticket estimate.', err);
      }

      return false;
    },
    [invalidateGroup, invalidatePath, queryResult, ticketId]
  );

  const editTicketEstimate = useCallback(
    async (estimateTicketId: number, body: TicketEstimateBody) => {
      try {
        await editTicketEstimateCallback(
          ticketId as number,
          estimateTicketId as number,
          body
        );
        invalidateGroup(INVALIDATE_GROUP.TICKET);
        invalidatePath(['activity']);
        queryResult.refetch();
        notifications.show({
          message: 'Estimate successfully updated!',
          variant: 'success',
        });
        return true;
      } catch (err) {
        showApiErrNotif('Unable to add ticket estimate.', err);
      }

      return false;
    },
    [invalidateGroup, invalidatePath, queryResult, ticketId]
  );

  const deleteTicketEstimate = useCallback(
    async (estimateId: number, estimateTicketId: number = ticketId || 0) => {
      try {
        await deleteTicketEstimateCallback(estimateTicketId, estimateId);
        invalidateGroup(INVALIDATE_GROUP.TICKET);
        invalidatePath(['activity']);
        queryResult.refetch();
        notifications.show({
          message: 'Estimate successfully deleted!',
          variant: 'success',
        });
        return true;
      } catch (err) {
        showApiErrNotif('Unable to delete ticket estimate.', err);
      }

      return false;
    },
    [invalidateGroup, invalidatePath, queryResult, ticketId]
  );

  const updateTicketSubscription = useCallback(
    async (subTicketId = `${ticketId}`, userId: string, subscribe = true) => {
      try {
        await updateTicketSubscriptionCallback({
          userId,
          ticketId: subTicketId.toString(),
          subscribe,
        });
        invalidateGroup(INVALIDATE_GROUP.TICKET);
        invalidatePath(['activity']);
        queryResult.refetch();

        notifications.show({
          message: 'User subscription successfully updated!',
          variant: 'success',
        });
        return true;
      } catch (err) {
        showApiErrNotif('Unable to update user subscription.', err);
      }

      return false;
    },
    [invalidateGroup, invalidatePath, queryResult, ticketId]
  );

  const updateTicket = useCallback(
    async (ticketId, ticketBody) => {
      try {
        await updateTicketCallback(ticketId, ticketBody);
        invalidateGroup(INVALIDATE_GROUP.TICKET);
        invalidatePath(['activity']);
        queryResult.refetch();

        notifications.show({
          message: 'Ticket has been successfully updated!',
          variant: 'success',
        });
        return true;
      } catch (err) {
        showApiErrNotif('Unable to update ticket at this time.', err);
      }

      return false;
    },
    [invalidateGroup, invalidatePath, queryResult]
  ) as typeof updateTicketCallback;

  return {
    ...queryResult,
    addTicketEstimate,
    editTicketEstimate,
    deleteTicketEstimate,
    updateTicketSubscription,
    updateTicket,
  };
}
