import { MutableRefObject, useRef, useState } from 'react';
import format from 'date-fns/format';
import { Stack, Textarea, rem } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { Formik, FormikProps, useFormikContext } from 'formik';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import * as Yup from 'yup';

import BaseModal from '../BaseModal';
import ModalFooter from './ModalFooter';

import { DATE_FORMATS } from 'constants/date';
import { MODALS } from 'constants/component';
import { AUTH_USER_ID } from 'constants/api';
import { SelectOption } from 'components/common/Select/types';

import useModalStore from 'hooks/store/useModalStore';
import useAvailabilityRecords from 'hooks/users/useAvailabilityRecords';

import ReporteeUserSelect from 'components/common/Select/variants/ReporteeUserSelect';
import useAuthUser from 'hooks/users/useAuthUser';

const validationSchema = Yup.object().shape({
  instance_user_id: Yup.number().required('Employee is required'),
  description: Yup.string(),
  date_start: Yup.string().required('Date is required'),
  date_end: Yup.string().nullable(),
});

interface FormValues {
  instance_user_id?: number;
  description: string;
  date_start?: string;
  date_end?: string;
}

const initialValues: FormValues = {
  instance_user_id: AUTH_USER_ID,
  description: '',
  date_start: undefined,
  date_end: undefined,
};

function AddAvailabilityRecordForm() {
  const [dateRange, setDateRange] = useState<Date[]>([]);
  const { values, handleChange, setFieldValue, errors } =
    useFormikContext<FormValues>();

  const handleDateChange = (dateRange: [Date, Date]) => {
    const [start, end] = dateRange;

    if (!start && !end) return;

    setDateRange(dateRange);

    setFieldValue('date_start', format(start, DATE_FORMATS.DATE_KEY));

    if (end) {
      setFieldValue('date_end', format(end, DATE_FORMATS.DATE_KEY));
    } else {
      setFieldValue('date_end', null);
    }
  };

  const handleUserChange = (selected: SelectOption) => {
    setFieldValue('instance_user_id', selected.value);
  };

  return (
    <Stack sx={() => ({ padding: 16, paddingBottom: 32 })} spacing={8}>
      <ReporteeUserSelect
        required
        label="Employee"
        defaultValue={values.instance_user_id}
        onChange={handleUserChange}
        error={errors.instance_user_id}
      />
      <DatePickerInput
        required
        type="range"
        name="date_range"
        label="Days to Take Off"
        value={dateRange as [Date, Date]}
        labelSeparator={
          dateRange.filter((d) => d).length === 2 ? undefined : ''
        }
        onChange={handleDateChange}
        error={errors.date_start || errors.date_end}
      />
      <Textarea
        name="description"
        label="Reason"
        value={values.description}
        onChange={handleChange}
        error={errors.description}
      />
    </Stack>
  );
}

interface AddAvailabilityRecordModalProps {
  userId?: number;
}

function AddAvailabilityRecordModal({
  userId,
}: AddAvailabilityRecordModalProps) {
  const formikRef =
    useRef() as MutableRefObject<FormikProps<FormValues> | null>;
  const { popModal } = useModalStore();
  const modal = useModal();
  const { addAvailabilityRecord } = useAvailabilityRecords();
  const { user } = useAuthUser();

  const handleClose = (force = false) => {
    if (!formikRef.current?.isSubmitting || force) {
      popModal(MODALS.ADD_AVAILABILITY_RECORD_MODAL);
      formikRef.current?.resetForm();
    }
  };

  const handleSubmit = async (values: FormValues) => {
    if (
      await addAvailabilityRecord({
        ...values,
        instance_user_id: values.instance_user_id || user?.id,
      })
    ) {
      handleClose(true);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        ...initialValues,
        instance_user_id: userId,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
    >
      <BaseModal
        id={MODALS.ADD_AVAILABILITY_RECORD_MODAL}
        isOpen={modal.visible}
        onClose={handleClose}
        title="Take Time Off"
        height={rem(464)}
        size="40vw"
        FooterComponent={ModalFooter}
      >
        <AddAvailabilityRecordForm />
      </BaseModal>
    </Formik>
  );
}

export default NiceModal.create((props) => (
  <AddAvailabilityRecordModal {...props} />
));
