import { rem } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { TableColumn } from 'components/common/Table/types';
import { InstanceReport } from 'types/api';
import { BaseTableProps } from 'components/common/Table/types';
import { HEADER_HEIGHT, TABS_HEIGHT } from 'constants/component';

import BaseTable from 'components/common/Table/variants/BaseTable';

export interface ReportsTableProps {
  reports: InstanceReport[];
}

const columns: TableColumn[] = [
  {
    id: 'display',
    display: 'Name',
  },
];

const getDataDisplay: BaseTableProps<InstanceReport>['getDataDisplay'] = (
  key,
  row
) => {
  return `${row[key as keyof InstanceReport]}`;
};

export default function ReportsTable({ reports }: ReportsTableProps) {
  const navigate = useNavigate();

  return (
    <BaseTable<InstanceReport>
      columns={columns}
      rows={reports}
      getDataDisplay={getDataDisplay}
      height={`calc(100vh - ${HEADER_HEIGHT} - ${TABS_HEIGHT} - ${rem(24)})`}
      onRowClick={(row) => {
        navigate(`/reports/${row.id}`);
      }}
    />
  );
}
