import { useCallback } from 'react';
import { notifications } from '@mantine/notifications';

import useApiQuery from 'hooks/api/useApiQuery';
import useApiStore from 'hooks/store/useApiStore';

import { BillingRecordHookParams } from './types';
import { BillingRecord } from 'types/api';
import { ENDPOINTS } from 'constants/api';

import * as billing from 'utils/billing';
import constructQueryPath from 'helpers/api/constructQueryPath';
import showApiErrNotif from 'helpers/api/showApiErrNotif';

export default function useBillingRecord(hookParams: BillingRecordHookParams) {
  const { invalidate } = useApiStore();
  const { billingRecordId, storeKey, isInfinite } = hookParams || {};

  const queryResult = useApiQuery<{ billing_record: BillingRecord }>({
    path: constructQueryPath(ENDPOINTS.BILLING_RECORD, {
      billingRecordId: billingRecordId.toString(),
    }),
    storeKey: storeKey,
  });

  const editBillingRecord: typeof billing.editBillingRecord = useCallback(
    async (editBillingRecordParams) => {
      try {
        await billing.editBillingRecord(editBillingRecordParams);
        queryResult.refetch();
        invalidate('user-alerts');

        // refresh project billing records on date change
        // always refetch if finite list
        if (!isInfinite || editBillingRecordParams.body.date) {
          invalidate('list-project-billing-records-tab');
        }

        notifications.show({
          message: 'Billing Record has been successfully updated!',
          variant: 'success',
        });
      } catch (err) {
        showApiErrNotif(
          'Unable to update billing record. Try again later.',
          err
        );
      }
    },
    [invalidate, isInfinite, queryResult]
  );

  return {
    ...queryResult,
    editBillingRecord,
  };
}
