import { ENDPOINTS } from 'constants/api';
import type { ProjectTicket } from 'types/api';
import { ApiQueryKey } from 'types/api/query';

import useApiQueryInfinite from 'hooks/api/useApiQueryInfinite';

interface TicketsInfiniteHookParams {
  params?: any;
  storeKey?: ApiQueryKey;
}

export default function useTicketsInfinite(
  hookParams: TicketsInfiniteHookParams,
  disabled?: boolean
) {
  const { params, storeKey } = hookParams || {};

  const queryResult = useApiQueryInfinite<{
    tickets?: ProjectTicket[];
  }>({
    path: ENDPOINTS.TICKETS,
    axiosConfig: {
      params,
    },
    queryOptions: {
      enabled: !disabled,
      refetchOnWindowFocus: false,
    },
    storeKey: storeKey,
  });

  return {
    ...queryResult,
  };
}
