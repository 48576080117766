import { QueryKey, UseQueryOptions, useQuery } from 'react-query';
import { AxiosRequestConfig } from 'axios';

import constructQueryKey from 'helpers/api/constructQueryKey';
import fetcher from 'helpers/api/fetcher';
import urlSerialize from 'helpers/api/urlSerialize';

import useApiStore from 'hooks/store/useApiStore';

import { ApiQueryKey } from 'types/api/query';
import { useEffect } from 'react';

export interface ApiQueryHookParams<R> {
  path: string;
  pathParams?: { [key: string]: string };
  axiosConfig?: AxiosRequestConfig;
  queryOptions?:
    | Omit<
        UseQueryOptions<
          R & { total?: number },
          unknown,
          R & { total?: number },
          QueryKey
        >,
        'queryKey'
      >
    | undefined;
  storeKey?: ApiQueryKey;
}

export default function useApiQuery<R>({
  path,
  pathParams,
  axiosConfig,
  queryOptions,
  storeKey,
}: ApiQueryHookParams<R>) {
  const { setQueryKey, isQueryKeySet } = useApiStore();
  const queryKey = constructQueryKey(path, pathParams);
  const apiPath = queryKey.join('/');
  const apiQueryKey = queryKey.concat([
    axiosConfig?.params ? urlSerialize(axiosConfig.params) : '',
  ]);

  useEffect(() => {
    if (storeKey && !isQueryKeySet(storeKey, apiQueryKey)) {
      setQueryKey(storeKey, apiQueryKey);
    }
  }, [apiQueryKey, isQueryKeySet, setQueryKey, storeKey]);

  return useQuery<R & { total?: number }>(
    apiQueryKey,
    async () => fetcher(apiPath, axiosConfig),
    queryOptions
  );
}
