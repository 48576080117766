import { formatInTimeZone } from 'date-fns-tz';
import enUS from 'date-fns/locale/en-US';

export default function formatTimeZone(
  date: Date,
  timeZone = 'America/New_York',
  formatString = 'M/dd/yyyy h:mm a zzz'
) {
  try {
    return formatInTimeZone(new Date(date), timeZone, formatString, {
      locale: enUS,
    });
  } catch (err) {
    return '';
  }
}
