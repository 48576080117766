import { ENDPOINTS } from 'constants/api';

import useApiQuery from 'hooks/api/useApiQuery';

export interface ProjectTicketStatusesHookParams {
  projectId: number | string;
}

export default function useProjectTicketStatuses({
  projectId,
}: ProjectTicketStatusesHookParams) {
  const queryResult = useApiQuery<{ ticket_statuses?: string[] }>({
    path: ENDPOINTS.PROJECT_TICKET_STATUSES,
    pathParams: {
      projectId: `${projectId}`,
    },
    queryOptions: {
      enabled: Boolean(projectId?.toString()),
    },
  });

  return {
    ...queryResult,
  };
}
