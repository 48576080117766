import add from 'date-fns/add';
import getWeek from 'date-fns/getWeek';

import getDaysOfWeek from './getDaysOfWeek';

/**
 * parses a string of words into a date range
 * @param dateString string in the form <number> <days | weeks | months>. Ex: -1 days = yesterday
 * @return start_date, end_date in an array
 */
export default function getDateRangeFromWords(dateString: string): Date[] {
  const [amount, dateType] = dateString.split(' ');
  const amountNum = parseInt(amount);
  const today = new Date();

  if (isNaN(amountNum)) {
    return [];
  }

  const adjustment = {
    [dateType]: amountNum,
  };
  const adjustedDate = add(today, adjustment);

  if (amountNum === 0) {
    if (dateType === 'weeks') {
      const [weekStart] = getDaysOfWeek(getWeek(adjustedDate));
      return [weekStart, today];
    }

    if (dateType === 'months') {
      const firstDay = new Date(today);
      firstDay.setDate(1);
      return [firstDay, today];
    }

    if (dateType === 'years') {
      const yearStart = new Date(today);
      yearStart.setMonth(0, 1);
      return [yearStart, today];
    }

    return [adjustedDate, today];
  }

  const startDate = adjustedDate;
  const endDate = adjustedDate;

  if (dateType === 'weeks') {
    const [firstDate, , , , , , lastDate] = getDaysOfWeek(
      getWeek(adjustedDate),
      adjustedDate
    );
    return [firstDate, lastDate];
  }

  if (dateType === 'months') {
    const firstDate = new Date(adjustedDate);
    firstDate.setDate(1);

    const lastDate = add(new Date(adjustedDate), { months: 1 });
    lastDate.setDate(-1);

    return [firstDate, lastDate];
  }

  if (dateType === 'years') {
    const firstDate = new Date(adjustedDate);
    firstDate.setMonth(0);
    firstDate.setDate(1);

    const lastDate = new Date(adjustedDate);
    lastDate.setFullYear(lastDate.getFullYear() + 1, 0, 0);

    return [firstDate, lastDate];
  }

  return [startDate, endDate];
}
