import { ENDPOINTS } from 'constants/api';
import type { InstanceUserStat } from 'types/api';
import { ApiQueryKey } from 'types/api/query';

import useApiQuery from 'hooks/api/useApiQuery';

interface UsersHookParams {
  params?: any;
  storeKey?: ApiQueryKey;
}

export default function useUserStats(hookParams?: UsersHookParams) {
  const { params, storeKey } = hookParams || {};
  const queryResult = useApiQuery<{ user_stats?: InstanceUserStat[] }>({
    path: ENDPOINTS.USER_STATS,
    axiosConfig: {
      params,
    },
    storeKey: storeKey,
  });

  return {
    ...queryResult,
  };
}
