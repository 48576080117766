import { useMemo } from 'react';
import { ActionIcon, Box, Text, Tooltip, rem } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import {
  IconBookmark,
  IconBookmarkFilled,
  IconCoin,
  IconEdit,
} from '@tabler/icons-react';

import { ClientProject, InstanceUser } from 'types/api';
import { BaseTableProps } from 'components/common/Table/types';
import { TableColumn } from 'components/common/Table/types';
import { HEADER_HEIGHT, MODALS, TABS_HEIGHT } from 'constants/component';

import ButtonGroup from 'components/common/ButtonGroup';

import useModalStore from 'hooks/store/useModalStore';
import useAuthStore from 'hooks/store/useAuthStore';
import DraggableTable from 'components/common/Table/variants/DraggableTable';
import useProjectsPageStore from 'hooks/store/useProjectsPageStore';
import { OnDragEndResponder } from 'react-beautiful-dnd';
import ProjectSort from '../ProjectSort';
import useAuthUser from 'hooks/users/useAuthUser';
import getCurrencyDisplay from 'helpers/display/getCurrencyDisplay';

export interface ProjectsTableProps {
  projects: ClientProject[];
  user?: InstanceUser;
  bookmarkProject: (
    projectId: number,
    active: '0' | '1'
  ) => Promise<true | undefined>;
  rearrangeProjects: (
    userId?: number,
    projectIds?: number[]
  ) => Promise<boolean>;
  sort?: string;
  setSort: (sort?: string) => void;
}

function ProjectsTableActions({ project }: { project: ClientProject }) {
  const { pushModal } = useModalStore();
  const { isAdmin } = useAuthStore();

  if (!isAdmin) return <></>;

  return (
    <ButtonGroup
      buttonProps={[
        {
          children: <IconEdit />,
          size: 'xs',
          onClick: (e) => {
            e.stopPropagation();

            pushModal(MODALS.EDIT_PROJECT_MODAL, { project });
          },
        },
      ]}
    />
  );
}

export default function ProjectsTable({
  projects,
  user,
  bookmarkProject,
  rearrangeProjects,
  sort,
  setSort,
}: ProjectsTableProps) {
  const navigate = useNavigate();
  const { isAdmin } = useAuthStore();
  const { moveProject, sortProjects } = useProjectsPageStore();
  const { formatUserTimezoneRelative } = useAuthUser();

  const handleRowClick = (row: ClientProject) => {
    navigate(`/projects/${row.id}`);
  };

  const columns: TableColumn[] = useMemo(() => {
    const baseColumns: TableColumn[] = [
      {
        id: 'favorite',
        display: '',
      },
      {
        id: 'title',
        display: 'Name',
      },
      {
        id: 'key',
        display: 'Key',
      },
      {
        id: 'client',
        display: 'Client',
      },
      {
        id: 'billing_type',
        display: 'Billing Type',
      },
      {
        id: 'billable',
        display: 'Billable',
      },
      {
        id: 'latest_activity',
        display: 'Latest Activity',
      },
    ];

    if (isAdmin) {
      baseColumns.push({
        id: 'client_budget',
        display: 'Remaining Budget',
      });
      baseColumns.push({
        id: 'actions',
        display: 'Actions',
      });
    }

    return baseColumns;
  }, [isAdmin]);

  const getDataDisplay: BaseTableProps<ClientProject>['getDataDisplay'] = (
    key,
    row
  ) => {
    const rowId = row.id as number;
    const isBookmarked = row.bookmarked;

    if (key === 'client') {
      return row[key]?.name || '';
    }

    if (key === 'favorite') {
      return (
        <ActionIcon
          title={isBookmarked ? 'Unbookmark project?' : 'Bookmark project?'}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            bookmarkProject(rowId, isBookmarked ? '0' : '1');
          }}
        >
          {isBookmarked ? <IconBookmarkFilled /> : <IconBookmark />}
        </ActionIcon>
      );
    }

    if (key === 'billing_type') {
      const display = row?.[key]?.split(',').join(' & ');
      return <Text transform="capitalize">{display}</Text>;
    }

    if (key === 'billable') {
      const billable = row[key];

      return (
        <Box
          title={billable ? 'Billable' : 'Non-billable'}
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            color: billable ? theme.colors.green[5] : theme.colors.gray[5],
          })}
        >
          <IconCoin />
        </Box>
      );
    }

    if (key === 'actions') {
      return <ProjectsTableActions project={row} />;
    }

    if (key === 'latest_activity') {
      if (row.latest_activity === undefined) {
        return 'Loading...';
      }

      return formatUserTimezoneRelative(
        new Date(row.latest_activity as string)
      );
    }

    if (key === 'client_budget') {
      if (row.client_budget === undefined) {
        return 'Loading...';
      }

      const isNegative = (row.client_budget || 0) < 0;
      const budgetTooltipDisplay = getCurrencyDisplay(
        Math.abs((row.client_budget || 0) / 100)
      );
      const budgetDisplay = getCurrencyDisplay(
        Math.max(row.client_budget || 0, 0) / 100
      );

      return (
        <Tooltip
          label={`${budgetTooltipDisplay} ${
            isNegative ? 'overbudget' : 'remaining'
          }`}
        >
          <Text
            color={isNegative ? 'red' : undefined}
            weight={isNegative ? 'bold' : undefined}
          >
            {budgetDisplay}
          </Text>
        </Tooltip>
      );
    }

    const rowKey = key as keyof ClientProject;

    return `${row[rowKey]}`;
  };

  const handleDragEnd: OnDragEndResponder = ({ destination, source }) => {
    if (source.index !== undefined && destination?.index !== undefined) {
      moveProject(source.index, destination.index);
      const _projects = sortProjects();
      rearrangeProjects(
        user?.id,
        _projects.map((project) => project.id as number)
      );
    }
  };

  return (
    <>
      <ProjectSort sort={sort} setSort={setSort} />
      <DraggableTable<ClientProject>
        id="client-projects"
        columns={columns}
        rows={projects}
        getDataDisplay={getDataDisplay}
        onRowClick={handleRowClick}
        height={`calc(100vh - ${HEADER_HEIGHT} - ${TABS_HEIGHT} - ${rem(56)})`}
        handleDragEnd={handleDragEnd}
        disableDrag={Boolean(sort)}
      />
    </>
  );
}
