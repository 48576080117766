import { useMemo, useCallback } from 'react';

import { Activity } from 'types/api';

import useUser from 'hooks/users/useUser';
import useUsers from 'hooks/users/useUsers';
import { AUTH_USER_ID } from 'constants/api';
import getUserFullName from 'helpers/display/getUserFullName';
import { ACTION_FLAG } from 'constants/activity';
import splitByPascalCase from 'helpers/display/splitByPascalCase';

interface ActivityHookParams {
  activityItem?: Activity;
}

export default function useActivity({ activityItem }: ActivityHookParams) {
  const { formatUserTimezoneRelative } = useUser({ userId: AUTH_USER_ID });
  const { data: instanceUsers } = useUsers();

  const displayFields = useMemo(() => {
    try {
      const { values_display: values, old_values_display: oldValues } =
        JSON.parse(activityItem?.change_message || '{}');
      return {
        values,
        oldValues,
      };
    } catch (err) {
      return {};
    }
  }, [activityItem?.change_message]);

  const getUserDisplay = useCallback(
    (activity = activityItem) => {
      const foundUser = instanceUsers?.users?.find(
        (user) => user.id === activity?.user.id
      );
      return getUserFullName(foundUser);
    },
    [activityItem, instanceUsers?.users]
  );

  const getDateDisplay = useCallback(
    (activity = activityItem) => {
      if (!activity?.datetime_created) return '';

      return formatUserTimezoneRelative(new Date(activity?.datetime_created));
    },
    [activityItem, formatUserTimezoneRelative]
  );

  const getTargetDisplay = useCallback(
    (activity = activityItem) => {
      try {
        const { target, target_display } = JSON.parse(
          activity?.change_message || '{}'
        );
        const targetModel = splitByPascalCase(
          target?.model?.replace('Instance', '') || 'Ticket'
        );

        return `${targetModel} ${target_display}`.trim();
      } catch (err) {
        // pass
      }

      return 'Ticket';
    },
    [activityItem]
  );

  const getActionDisplay = useCallback(
    (activity = activityItem) => {
      if (activity?.action_flag === ACTION_FLAG.ADD) {
        return 'created';
      }

      if (activity?.action_flag === ACTION_FLAG.DELETE) {
        return 'deleted';
      }

      return 'updated';
    },
    [activityItem]
  );

  return {
    getUserDisplay,
    getDateDisplay,
    getTargetDisplay,
    getActionDisplay,
    displayFields,
  };
}
