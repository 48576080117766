import { useState } from 'react';
import format from 'date-fns/format';
import getWeek from 'date-fns/getWeek';

import TimesheetHeader from './components/TimesheetHeader';
import TimesheetTable from './components/TimesheetTable';

import getDaysOfWeek from 'helpers/datetime/getDaysOfWeek';
import useBillingRecordsAggregate from 'hooks/billing/useBillingRecordsAggregate';
import { DATE_FORMATS } from 'constants/date';

interface BillingRecordsTimesheetProps {
  params?: any;
}

export default function BillingRecordsTimesheet({
  params,
}: BillingRecordsTimesheetProps) {
  const [week, setWeek] = useState(getWeek(new Date()));
  const [firstDay, , , , , , lastDay] = getDaysOfWeek(week);
  const { aggregateData, loading } = useBillingRecordsAggregate({
    params: {
      date_start: format(firstDay, DATE_FORMATS.DATE_KEY),
      date_end: format(lastDay, DATE_FORMATS.DATE_KEY),
      ...params,
    },
    storeKey: 'user-billing-records-timesheet',
  });

  return (
    <>
      <TimesheetHeader week={week} setWeek={setWeek} />
      <TimesheetTable
        week={week}
        users={Object.values(aggregateData) || []}
        loading={loading}
      />
    </>
  );
}
