import { useState, useRef, MutableRefObject } from 'react';
import { TextInput, ActionIcon, rem } from '@mantine/core';
import { IconSearch as SearchIcon, IconX } from '@tabler/icons-react';

import { SearchBaseProps } from '../types';

export default function IconSearch({ onSearch }: SearchBaseProps) {
  const [expanded, setExpanded] = useState(false);
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

  const closeSearch = () => {
    onSearch?.('');
    setExpanded(false);
    inputRef.current.value = '';
  };

  const handleClick = () => {
    if (!expanded) {
      setExpanded(true);

      // run on next render frame
      setTimeout(() => {
        inputRef.current.focus();
      }, 1);
    } else {
      closeSearch();
    }
  };

  return (
    <TextInput
      ref={inputRef}
      onFocus={() => {
        if (!expanded) {
          setExpanded(true);
        }
      }}
      onBlur={() => {
        if (!inputRef.current.value) {
          closeSearch();
        }
      }}
      onChange={(e) => {
        onSearch?.(e.target.value);
      }}
      radius="xl"
      size="md"
      styles={{
        input: {
          display: !expanded ? 'none' : undefined,
        },
      }}
      rightSectionWidth={42}
      rightSection={
        <ActionIcon size={32} radius="xl" onClick={handleClick}>
          {expanded ? (
            <IconX style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
          ) : (
            <SearchIcon
              style={{ width: rem(18), height: rem(18) }}
              stroke={1.5}
            />
          )}
        </ActionIcon>
      }
    />
  );
}
