import { ActionIcon } from '@mantine/core';
import { BaseToggleButtonProps } from '../types';
import { isEmpty } from 'lodash';

export default function BaseToggleButton({
  value,
  displayMap,
  setValue,
  ...props
}: BaseToggleButtonProps) {
  const renderChildren = () => {
    return displayMap?.[value] ?? <></>;
  };

  const handleClick = () => {
    if (isEmpty(displayMap)) return;

    const values = Object.keys(displayMap);
    const activeIndex = values.findIndex((val) => val === value);

    if (activeIndex < 0) return;

    setValue(values[(activeIndex + 1) % values.length]);
  };

  return (
    <ActionIcon radius="xl" onClick={handleClick} {...props}>
      {renderChildren()}
    </ActionIcon>
  );
}
