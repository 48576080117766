import { useCallback } from 'react';
import { notifications } from '@mantine/notifications';
import { AxiosError } from 'axios';

import { ENDPOINTS, INVALIDATE_GROUP } from 'constants/api';
import type { InstanceUser } from 'types/api';

import useApiQuery from 'hooks/api/useApiQuery';
import useApiStore from 'hooks/store/useApiStore';

import { inviteUserByEmail as inviteUserByEmailCallback } from 'utils/user';
import showApiErrNotif from 'helpers/api/showApiErrNotif';
import * as userUtils from 'utils/user';
import { ApiQueryKey } from 'types/api/query';

interface UsersHookParams {
  params?: any;
  storeKey?: ApiQueryKey;
}

export default function useUsers(hookParams?: UsersHookParams) {
  const { params, storeKey } = hookParams || {};
  const { invalidateGroup, invalidatePath } = useApiStore();
  const queryResult = useApiQuery<{ users?: InstanceUser[] }>({
    path: ENDPOINTS.USERS,
    axiosConfig: {
      params,
    },
    storeKey: storeKey,
  });

  const inviteUserByEmail = useCallback(
    async (params) => {
      try {
        await inviteUserByEmailCallback(params);
        queryResult.refetch();
        notifications.show({
          message: 'User has been invited!',
          variant: 'success',
        });
      } catch (err) {
        const error = err as AxiosError;
        showApiErrNotif('Unable to invite user', error);
      }
    },
    [queryResult]
  ) as typeof inviteUserByEmailCallback;

  const updateUser: typeof userUtils.updateUser = useCallback(
    async (params) => {
      try {
        await userUtils.updateUser(params);

        invalidateGroup(INVALIDATE_GROUP.SELF);
        invalidateGroup(INVALIDATE_GROUP.USER);
        invalidatePath(['activity']);
        notifications.show({ message: 'User successfully updated!' });
        return true;
      } catch (err) {
        showApiErrNotif('Unable to able user. Try again later.', err);
      }

      return false;
    },
    [invalidateGroup, invalidatePath]
  );

  return {
    ...queryResult,
    inviteUserByEmail,
    updateUser,
  };
}
