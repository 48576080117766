import { useCallback, useState } from 'react';
import { Button, Group, rem, Stack, NumberInput } from '@mantine/core';
import { IconPercentage } from '@tabler/icons-react';

import { LineItemDiscountPopOverDropdownProps } from '../types';

import InvoiceLineItemPaymentSelect from 'components/common/Select/variants/InvoiceLineItemPaymentTypeSelect';
import { LINE_ITEM_PAYMENT_TYPE } from 'constants/billing';
import CurrencyInput from 'components/common/UserInput/variants/CurrencyInput';

export default function PopOverDropdown({
  onReset,
  onSave,
  lineItem,
}: LineItemDiscountPopOverDropdownProps) {
  const lineItemCost = (lineItem?.cost || 0) * (lineItem?.quantity || 0);
  const [paymentType, setPaymentType] = useState(LINE_ITEM_PAYMENT_TYPE.FLAT);
  const [amount, setAmount] = useState(0);

  const handleReset = useCallback(() => {
    onReset?.();
  }, [onReset]);

  return (
    <Stack>
      <InvoiceLineItemPaymentSelect
        label="Cost Type"
        defaultValue={paymentType}
        onChange={(option) => {
          setPaymentType(option.value as string);
        }}
      />
      {paymentType === LINE_ITEM_PAYMENT_TYPE.FLAT ? (
        <CurrencyInput
          label="Flat Discount"
          value={amount}
          onChange={(value) => {
            setAmount(value as number);
          }}
          min={0}
          max={lineItemCost}
        />
      ) : (
        <NumberInput
          value={amount * 100}
          onChange={(value) => {
            setAmount((value as number) / 100);
          }}
          rightSection={<IconPercentage />}
          min={0}
          max={100}
          step={1}
        />
      )}
      <Group
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingTop: rem(6),
        }}
      >
        <Button size="xs" variant="outline" onClick={handleReset}>
          Cancel
        </Button>
        <Button
          size="xs"
          onClick={() =>
            onSave?.({
              tax: 0,
              cost: -(paymentType === LINE_ITEM_PAYMENT_TYPE.FLAT
                ? amount * 100
                : lineItemCost * amount),
              description: 'Discount',
              quantity: 1,
            })
          }
        >
          Save
        </Button>
      </Group>
    </Stack>
  );
}
