import {
  Tooltip,
  useMantineTheme,
  createStyles,
  Group,
  ActionIcon,
} from '@mantine/core';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import {
  IconReload,
  IconCircleCheck,
  IconCircleX,
  IconCheck,
  IconX,
  IconEdit,
} from '@tabler/icons-react';
import capitalize from 'lodash/capitalize';

import { AvailabilityRecordRow } from '../../types';
import { TableRowProps } from 'components/common/Table/types';
import { AvailabilityRecord } from 'types/api';
import { DATE_FORMATS } from 'constants/date';

import useModalStore from 'hooks/store/useModalStore';
import { MODALS } from 'constants/component';
import usePermissions from 'hooks/auth/usePermissions';
import { PERMISSIONS } from 'constants/role';
import getUserFullName from 'helpers/display/getUserFullName';
import useInstance from 'hooks/instance/useInstance';
import useInstanceConfig from 'hooks/instance/useInstanceConfig';

const useStyles = createStyles((theme) => ({
  rowTitle: {
    backgroundColor: theme.colors.gray[0],
    fontWeight: 'bold',
  },
  rowData: {
    textAlign: 'center',
  },
}));

function DataDisplay({
  rowKey,
  row,
}: {
  rowKey: keyof AvailabilityRecord | 'actions';
  row: AvailabilityRecord;
}) {
  const theme = useMantineTheme();
  const { pushModal } = useModalStore();
  const { hasPermission } = usePermissions();
  const { getDifferenceInBusinessDays } = useInstance();
  const { dateDisplay } = useInstanceConfig();

  if (rowKey === 'instance_user') {
    return <>{getUserFullName(row.instance_user)}</>;
  }

  if (rowKey === 'actions') {
    return (
      <Group>
        {row.status !== 'approved' &&
          hasPermission(
            'availability record',
            PERMISSIONS.APPROVE_OR_DENY,
            row.instance_user_id
          ) && (
            <Tooltip label="Approve">
              <ActionIcon
                variant="light"
                color="green"
                onClick={() => {
                  pushModal(MODALS.REVIEW_AVAILABILITY_RECORd_MODAL, {
                    availabilityRecord: row,
                    status: 'approved',
                  });
                }}
              >
                <IconCheck />
              </ActionIcon>
            </Tooltip>
          )}
        {row.status !== 'denied' &&
          hasPermission(
            'availability record',
            PERMISSIONS.APPROVE_OR_DENY,
            row.instance_user_id
          ) && (
            <Tooltip label="Deny">
              <ActionIcon
                variant="light"
                color="red"
                onClick={() => {
                  pushModal(MODALS.REVIEW_AVAILABILITY_RECORd_MODAL, {
                    availabilityRecord: row,
                    status: 'denied',
                  });
                }}
              >
                <IconX />
              </ActionIcon>
            </Tooltip>
          )}
        {hasPermission(
          'availability record',
          PERMISSIONS.APPROVE_OR_DENY,
          row.instance_user_id
        ) && (
          <Tooltip label="Edit">
            <ActionIcon
              variant="light"
              color="blue"
              onClick={() => {
                pushModal(MODALS.EDIT_AVAILABILITY_RECORD_MODAL, {
                  availabilityRecord: row,
                });
              }}
            >
              <IconEdit />
            </ActionIcon>
          </Tooltip>
        )}
      </Group>
    );
  }

  if (!row[rowKey]) return <>N/A</>;

  if (rowKey === 'date_start') {
    const dateRange = [];
    const dateStart = parse(row[rowKey], DATE_FORMATS.DATE_KEY, new Date());
    let dayCount = 1;

    dateRange.push(format(dateStart, dateDisplay));

    const dateEnd =
      row['date_end'] &&
      parse(row['date_end'] as string, DATE_FORMATS.DATE_KEY, new Date());

    if (dateEnd instanceof Date) {
      dateRange.push(format(dateEnd, dateDisplay));

      dayCount = Math.abs(getDifferenceInBusinessDays(dateStart, dateEnd));
    }

    return (
      <>
        {dateRange.join(' - ')} ({dayCount} day{dayCount !== 1 && 's'})
      </>
    );
  }

  if (rowKey === 'status') {
    const status = row[rowKey];
    const tooltip = row['status_reason'] || capitalize(row['status']);

    if (status === 'approved') {
      return (
        <Tooltip label={tooltip}>
          <IconCircleCheck color={theme.colors.green[6]} />
        </Tooltip>
      );
    }

    if (status === 'denied') {
      return (
        <Tooltip label={tooltip}>
          <IconCircleX color={theme.colors.red[6]} />
        </Tooltip>
      );
    }

    return (
      <Tooltip label="Pending">
        <IconReload color={theme.colors.gray[6]} />
      </Tooltip>
    );
  }

  return <>{row[rowKey]}</>;
}

export default function AvailabilityRecordsRow({
  row,
  columns,
}: TableRowProps<AvailabilityRecordRow>) {
  const { classes } = useStyles();
  const { sumAvailabilityRecords } = useInstance();
  const dayOffCount = sumAvailabilityRecords(row.availabilityRecords);

  return (
    <>
      <tr className={classes.rowTitle}>
        <td colSpan={columns.length}>
          {row.year} ({dayOffCount} day{dayOffCount !== 1 && 's'})
        </td>
      </tr>
      {row.availabilityRecords.map((record) => (
        <tr key={record.id}>
          {columns.map((col) => (
            <td key={col.id} className={classes.rowData}>
              <DataDisplay
                rowKey={col.id as keyof AvailabilityRecord}
                row={record}
              />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
}
