import { ENDPOINTS } from 'constants/api';
import type { ProjectTicket } from 'types/api';

import useApiQuery from 'hooks/api/useApiQuery';

export interface TicketsHookParams {
  params?: any;
}

export default function useTickets(hookParams?: TicketsHookParams) {
  const { params } = hookParams || {};

  const queryResult = useApiQuery<{ tickets?: ProjectTicket[] }>({
    path: ENDPOINTS.TICKETS,
    axiosConfig: {
      params,
    },
  });

  return {
    ...queryResult,
  };
}
