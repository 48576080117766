import { FunctionComponent, ReactNode } from 'react';
import { Text, Divider, Stack, Card, Box, Loader } from '@mantine/core';

interface SectionProps {
  title?: string | ReactNode;
  children?: ReactNode;
  noCard?: boolean;
  loading?: boolean;
}

export default function Section({
  children,
  title,
  noCard,
  loading,
}: SectionProps) {
  let Component: FunctionComponent<any> = Card;
  let componentProps: any = {
    withBorder: true,
    shadow: 'md',
  };

  if (noCard) {
    Component = Box;
    componentProps = {};
  }

  return (
    <Component {...componentProps}>
      {loading ? (
        <>
          <Box sx={(theme) => ({ padding: theme.spacing.xl, display: 'flex' })}>
            <Loader sx={{ margin: 'auto' }} />
          </Box>
        </>
      ) : (
        <>
          <Text weight="bold" sx={{ lineHeight: '100%' }}>
            {title}
          </Text>
          <Divider
            sx={(theme) => ({
              marginTop: theme.spacing.sm,
              marginBottom: theme.spacing.sm,
            })}
          />
          <Stack spacing={24}>{children}</Stack>
        </>
      )}
    </Component>
  );
}
