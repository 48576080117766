import {
  ScrollArea,
  Box,
  Tabs,
  createStyles,
  Menu,
  ActionIcon,
  rem,
} from '@mantine/core';
import { ReactNode, useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IconCirclePlus } from '@tabler/icons-react';

import ConsoleLayout from 'layouts/ConsoleLayout';
import PeopleTable from './components/PeopleTable';

import useUsers from 'hooks/users/useUsers';

import {
  MODALS,
  TABBED_CONTENT_HEIGHT,
  TABS_HEIGHT,
} from 'constants/component';
import AvailabilityTab from './components/AvailabilityTab';
import useModalStore from 'hooks/store/useModalStore';
import useAuthStore from 'hooks/store/useAuthStore';

type PeopleTab = 'active' | 'disabled' | 'availability';

const useStyles = createStyles((theme) => ({
  tabsList: {
    paddingLeft: theme.spacing.lg,
    backgroundColor: theme.white,
  },
  tab: {
    fontSize: theme.fontSizes.sm,
    height: TABS_HEIGHT,
    fontWeight: 'bold',
  },
}));

function TabPanel({ children }: { children: ReactNode }) {
  return (
    <ScrollArea>
      <Box
        sx={(theme) => ({
          height: TABBED_CONTENT_HEIGHT,
          padding: theme.spacing.md,
        })}
      >
        {children}
      </Box>
    </ScrollArea>
  );
}

export default function PeoplePage() {
  const { classes } = useStyles();
  const { isAdmin } = useAuthStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pushModal } = useModalStore();
  const [activeTab, setActiveTab] = useState<PeopleTab>('active');
  const { data, isLoading, updateUser } = useUsers({
    params: { active: activeTab === 'disabled' ? '0' : '1' },
    storeKey:
      activeTab === 'active' ? 'list-active-users' : 'list-inactive-users',
  });

  const tabs: PeopleTab[] = useMemo(() => {
    const baseTabs = ['active', 'disabled', 'availability'] as PeopleTab[];

    return baseTabs.filter((tab) => {
      return isAdmin || tab !== 'disabled';
    });
  }, [isAdmin]);

  const users = data?.users || [];

  const handleTabChange = (value: string) => {
    setActiveTab(value as PeopleTab);
    setSearchParams({
      tab: value,
    });
  };

  useEffect(() => {
    const tab = searchParams.get('tab') as PeopleTab;
    if (tabs.includes(tab)) {
      setActiveTab(tab);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <ConsoleLayout disableScroll disablePadding loading={isLoading}>
      <Tabs value={activeTab} onTabChange={handleTabChange}>
        <Tabs.List className={classes.tabsList}>
          {tabs.map((tab) => (
            <Tabs.Tab
              key={tab}
              value={tab}
              className={classes.tab}
              sx={(theme) => ({
                color:
                  activeTab === tab
                    ? `${theme.colors.blue[8]} !important`
                    : theme.colors.gray[6],
                textTransform: 'capitalize',
              })}
            >
              {tab.replace(/-/gm, ' ')}
            </Tabs.Tab>
          ))}
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Box
                sx={(theme) => ({
                  marginLeft: 'auto',
                  marginRight: theme.spacing.md,
                  display: 'flex',
                })}
              >
                <ActionIcon sx={{ margin: 'auto 0' }}>
                  <IconCirclePlus />
                </ActionIcon>
              </Box>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                onClick={() => pushModal(MODALS.ADD_AVAILABILITY_RECORD_MODAL)}
                icon={<IconCirclePlus size={rem(16)} />}
              >
                Add Availability Record
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Tabs.List>

        <Tabs.Panel value="active">
          <TabPanel>
            <PeopleTable users={users} updateUser={updateUser} />
          </TabPanel>
        </Tabs.Panel>

        <Tabs.Panel value="disabled">
          <TabPanel>
            <PeopleTable users={users} updateUser={updateUser} />
          </TabPanel>
        </Tabs.Panel>

        <Tabs.Panel value="availability">
          <TabPanel>
            <AvailabilityTab />
          </TabPanel>
        </Tabs.Panel>
      </Tabs>
    </ConsoleLayout>
  );
}
