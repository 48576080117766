import { useCallback, useMemo } from 'react';

import { AUTH_USER_ID } from 'constants/api';
import useUser from './useUser';
import getInitials from 'helpers/display/getInitials';
import * as userUtils from 'utils/user';
import useAppStore from 'hooks/store/useAppStore';
import useProjectPageStore from 'hooks/store/useProjectPageStore';
import { Logger } from 'utils/logger';

export default function useAuthUser() {
  const { viewedComments, viewComment: viewCommentLocally } = useAppStore();
  const { viewTicketComments } = useProjectPageStore();

  const { data, ...rest } = useUser({
    userId: AUTH_USER_ID,
    storeKey: 'auth-user',
    params: {
      embed: 'permissions',
    },
  });

  const user = useMemo(() => {
    return data?.user;
  }, [data]);

  const viewComment = useCallback(
    async (commentId: number, projectId: number, ticketId: number) => {
      try {
        const viewed = viewedComments[commentId];
        if (!viewed) {
          await userUtils.viewComment({ commentId });
          viewCommentLocally(commentId);
          viewTicketComments(projectId, ticketId);
        }
      } catch (err) {
        const error = err as Error;
        Logger.error(error.message);
      }
    },
    [viewCommentLocally, viewTicketComments, viewedComments]
  );

  return {
    user,
    initials: getInitials({
      firstName: user?.first_name,
      lastName: user?.last_name,
    }),
    viewedComments,
    viewComment,
    ...rest,
  };
}
