import {
  TextInput,
  PasswordInput,
  Paper,
  Title,
  Container,
  Button,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import {
  GoogleOAuthProvider,
  GoogleLogin,
  CredentialResponse,
} from '@react-oauth/google';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import * as Yup from 'yup';

import useAuth from 'hooks/auth/useAuth';

const initialValues = {
  username: '',
  password: '',
};

const validationSchema = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string().required(),
});

type FormValues = typeof initialValues;

function LoginPageForm() {
  const { submitForm, isSubmitting, values, handleChange, errors, touched } =
    useFormikContext<FormValues>();

  return (
    <Paper component="form" withBorder shadow="md" p={30} mt={30} radius="md">
      <TextInput
        name="username"
        label="Username"
        placeholder="johnsmith"
        required
        value={values.username || ''}
        onChange={handleChange}
        error={touched.username && errors.username}
      />
      <PasswordInput
        name="password"
        label="Password"
        placeholder="Your password"
        required
        mt="md"
        value={values.password || ''}
        onChange={handleChange}
        error={touched.password && errors.password}
      />
      <Button
        type="button"
        fullWidth
        mt="xl"
        onClick={submitForm}
        loading={isSubmitting}
      >
        Sign In
      </Button>
    </Paper>
  );
}

export default function LoginPage() {
  const { logIn } = useAuth();

  const handleSubmit = async (
    values: FormValues,
    { setErrors }: FormikHelpers<FormValues>
  ) => {
    const success = await logIn(values);

    if (success) {
      window.location.href = '/projects';
    } else {
      setErrors({ username: 'Invalid username or password.' });
    }
  };

  const handleGoogleLogin = async (response: CredentialResponse) => {
    const success = await logIn({ credential: response.credential });

    if (success) {
      window.location.href = '/projects';
    } else {
      notifications.show({
        message: 'SSO login error.',
        color: 'red',
      });
    }
  };

  return (
    <Container size={420} my={40}>
      <Title align="center">Login</Title>

      {process.env.REACT_APP_DEBUG === '1' && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnChange={true}
        >
          <LoginPageForm />
        </Formik>
      )}
      <GoogleOAuthProvider clientId="927942416525-dg27kf0dh6n8hb8ifq2ulmjgnvs9bmgq.apps.googleusercontent.com">
        <GoogleLogin onSuccess={handleGoogleLogin} />
      </GoogleOAuthProvider>
    </Container>
  );
}
