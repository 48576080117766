import { useMemo } from 'react';

import { SelectOption, SelectProps } from '../../types';
import BaseSelect from '../BaseSelect';

import statesJSON from 'jsons/states.json';
import provincesJSON from 'jsons/provinces.json';

interface StateSelectProps extends SelectProps {
  defaultFirst?: boolean;
  country?: string;
}

export default function StateSelect({
  defaultFirst,
  onChange,
  defaultValue,
  label,
  country,
  ...props
}: StateSelectProps) {
  const options: SelectOption[] = useMemo(() => {
    if (country?.toUpperCase() === 'CA') {
      return Object.entries(provincesJSON)?.map(([code, state]) => {
        return {
          value: code,
          label: state,
        };
      });
    }

    return (
      Object.entries(statesJSON)?.map(([code, state]) => {
        return {
          value: code,
          label: state,
        };
      }) || []
    );
  }, [country]);

  return (
    <BaseSelect
      defaultFirst={defaultFirst}
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      label={label}
      {...props}
    />
  );
}
