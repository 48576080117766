import { SelectOption, SelectProps } from '../../types';
import BaseSelect from '../BaseSelect';

import { LINE_ITEM_PAYMENT_TYPE } from 'constants/billing';

interface InvoiceLineItemPaymentTypeSelect extends SelectProps {}

export default function InvoiceLineItemPaymentSelect({
  onChange,
  defaultValue,
  label,
}: InvoiceLineItemPaymentTypeSelect) {
  const options: SelectOption[] = [
    {
      label: 'Flat',
      value: LINE_ITEM_PAYMENT_TYPE.FLAT,
    },
    {
      label: 'Percentage',
      value: LINE_ITEM_PAYMENT_TYPE.PERCENTAGE,
    },
  ];

  return (
    <BaseSelect
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      label={label}
    />
  );
}
