import {
  logIn as authLogIn,
  logout as authLogOut,
  LoginBody,
  GoogleCredential
} from 'utils/auth';

import useAuthStore from 'hooks/store/useAuthStore';

export default function useAuth() {
  const { setIsAuthenticated } = useAuthStore();

  const logIn = async (loginData: LoginBody | GoogleCredential) => {
    const { success } = await authLogIn(loginData);

    if (success) {
      setIsAuthenticated(true);
    }

    return success;
  };

  const logOut = async () => {
    await authLogOut();
    setIsAuthenticated(false);
  };

  return {
    logIn,
    logOut,
  };
}
