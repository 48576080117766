import { ENDPOINTS } from 'constants/api';
import type { InstanceModule } from 'types/api';

import useApiQuery from 'hooks/api/useApiQuery';
import useAuthStore from 'hooks/store/useAuthStore';

export default function useModules() {
  const { isAuthenticated } = useAuthStore();
  const queryResult = useApiQuery<{ modules?: InstanceModule[] }>({
    path: ENDPOINTS.MODULES,
    queryOptions: {
      enabled: isAuthenticated,
    },
  });

  return {
    ...queryResult,
  };
}
