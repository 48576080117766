import { useMemo } from 'react';

import { BillingRecord } from 'types/api';

interface BillingRecordsDataHookParams {
  billingRecords?: BillingRecord[];
}

interface BillingRecordsDataHookOutput {
  totalBillingHours: number;
  totalBillingAmount: number;
  totalNonBillingHours: number;
}

export default function useBillingRecordsData({
  billingRecords,
}: BillingRecordsDataHookParams): BillingRecordsDataHookOutput {
  const data: BillingRecordsDataHookOutput = useMemo(() => {
    let totalBillingHours = 0;
    let totalBillingAmount = 0;
    let totalNonBillingHours = 0;

    billingRecords?.forEach((billingRecord) => {
      if (billingRecord.billable) {
        totalBillingHours += billingRecord.amount || 0;
        totalBillingAmount += billingRecord.billing_amount || 0;
      } else {
        totalNonBillingHours += billingRecord.amount || 0;
      }
    });

    return {
      totalBillingHours,
      totalBillingAmount,
      totalNonBillingHours,
    };
  }, [billingRecords]);

  return {
    ...data,
  };
}
