import { ActionIcon, Box, Group, Tooltip, rem } from '@mantine/core';
import { RichTextEditor as Editor } from '@mantine/tiptap';
import { KeyboardShortcutCommand, Editor as EditorType } from '@tiptap/react';
import { IconPhotoPlus } from '@tabler/icons-react';
import {
  ChangeEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { BaseRichTextEditorProps } from '../../types';

import ButtonGroup from 'components/common/ButtonGroup';
import useWYSIWYG from 'hooks/app/useWYSIWYG';
import AttachmentPreview from 'components/common/Attachment/Preview';

interface CommentRichTextEditorProps extends BaseRichTextEditorProps {
  onCancel?: () => void;
  onSubmit?: () => void;
  attachments?: File[];
  setAttachments?: (attachments: File[]) => void;
  loading?: boolean;
  getKeyboardShortcuts?: (editor: EditorType) => {
    [key: string]: KeyboardShortcutCommand;
  };
}

export default function CommentRichTextEditor({
  content,
  onChange,
  editorProps,
  onCancel,
  onSubmit,
  attachments,
  setAttachments,
  loading,
  getKeyboardShortcuts,
}: CommentRichTextEditorProps) {
  const { editor } = useWYSIWYG({
    content,
    onChange,
    autofocus: true,
    getKeyboardShortcuts,
  });
  const reader = useRef(new FileReader());
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

  const handleImageUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const targetFile = e.target.files?.[0];

      if (!targetFile) return;

      if (targetFile?.type?.includes('image')) {
        reader.current.readAsDataURL(targetFile);
      } else {
        setAttachments?.([...(attachments || []), targetFile]);
      }
    },
    [attachments, setAttachments]
  );

  useEffect(() => {
    const currentReader = reader.current;

    const loadImage = () => {
      const result = currentReader.result;
      editor?.commands.insertContent(`<img src="${result}" />`);

      if (inputRef.current) {
        inputRef.current.value = '';
      }
    };

    currentReader.addEventListener('load', loadImage);

    return () => {
      currentReader.removeEventListener('load', loadImage);
    };
  }, [content, editor, onChange]);

  return (
    <Box>
      <Editor editor={editor} {...editorProps}>
        <Editor.Toolbar sticky>
          <Editor.ControlsGroup>
            <Editor.Bold />
            <Editor.Italic />
            <Editor.Underline />
            <Editor.Strikethrough />
          </Editor.ControlsGroup>

          <Editor.ControlsGroup>
            <Editor.BulletList />
            <Editor.OrderedList />
          </Editor.ControlsGroup>

          <Editor.ControlsGroup>
            <Editor.Link />
          </Editor.ControlsGroup>

          <Editor.ControlsGroup>
            <Editor.Code />
            <Editor.Blockquote />
          </Editor.ControlsGroup>

          <Group>
            <Tooltip label="Add attachment">
              <ActionIcon
                size="xs"
                component="label"
                sx={(theme) => ({
                  border: `1px solid ${theme.colors.gray[4]}`,
                  color: theme.colors.gray[8],
                  height: rem(26),
                  width: rem(26),
                })}
                disabled={loading}
              >
                <input
                  ref={inputRef}
                  type="file"
                  hidden
                  onChange={handleImageUpload}
                />
                <IconPhotoPlus size={rem(16)} />
              </ActionIcon>
            </Tooltip>
          </Group>
        </Editor.Toolbar>

        <Editor.Content
          sx={(theme) => ({
            maxHeight: '40vh',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: rem(9),
            },
            '&::-webkit-scrollbar-track': {
              background: theme.colors.gray[4],
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.colors.gray[5],
              borderRadius: theme.radius.md,
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: theme.colors.gray[6],
            },
          })}
        />

        {Boolean(attachments?.length) && (
          <Group
            sx={(theme) => ({
              padding: theme.spacing.xs,
              borderBottomLeftRadius: theme.radius.sm,
              borderBottomRightRadius: theme.radius.sm,
            })}
          >
            {attachments?.map((attachment) => (
              <AttachmentPreview
                key={attachment.name}
                file={attachment}
                onRemove={
                  attachment?.size && !loading
                    ? () => {
                        setAttachments?.(
                          attachments.filter((a) => a.name !== attachment.name)
                        );
                      }
                    : undefined
                }
              />
            ))}
          </Group>
        )}
      </Editor>
      <Box sx={(theme) => ({ paddingTop: theme.spacing.xs })}>
        <ButtonGroup
          position="right"
          buttonProps={[
            {
              children: 'Cancel',
              variant: 'outline',
              color: 'blue',
              onClick: onCancel,
              size: 'xs',
              disabled: Boolean(loading),
            },
            {
              children: 'Submit',
              variant: 'filled',
              color: 'blue',
              onClick: onSubmit,
              size: 'xs',
              loading: Boolean(loading),
            },
          ].filter((b) => Boolean(b.onClick))}
        />
      </Box>
    </Box>
  );
}
