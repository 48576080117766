import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Box, Group } from '@mantine/core';
import { ReactNode, useCallback, useState } from 'react';

import BaseModal from '../BaseModal';

import { MODALS } from 'constants/component';
import useModalStore from 'hooks/store/useModalStore';
import ModalFooter from './ModalFooter';

interface ConfirmationModalProps {
  title?: string;
  body?: ReactNode;
  onConfirm?: () => Promise<void>;
}

function ConfirmationModal({ title, body, onConfirm }: ConfirmationModalProps) {
  const modal = useModal();
  const { popModal } = useModalStore();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    if (!loading) {
      popModal(MODALS.CONFIRM);
    }
  };

  const ConfirmationModalFooter = useCallback(() => {
    return (
      <ModalFooter>
        <Group sx={{ justifyContent: 'flex-end' }}>
          <Button
            size="xs"
            variant="outline"
            onClick={() => popModal(MODALS.CONFIRM)}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            size="xs"
            onClick={() => {
              setLoading(true);
              onConfirm?.()
                .then(() => {
                  popModal(MODALS.CONFIRM);
                })
                ?.finally(() => {
                  setLoading(false);
                });
            }}
            loading={loading}
          >
            Submit
          </Button>
        </Group>
      </ModalFooter>
    );
  }, [loading, onConfirm, popModal]);

  return (
    <BaseModal
      id={MODALS.CONFIRM}
      isOpen={modal.visible}
      onClose={handleClose}
      title={title}
      height="auto"
      size="auto"
      FooterComponent={ConfirmationModalFooter}
    >
      <Box sx={(theme) => ({ padding: theme.spacing.xl })}>{body}</Box>
    </BaseModal>
  );
}

export default NiceModal.create((props) => <ConfirmationModal {...props} />);
