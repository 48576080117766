import { Avatar } from '@mantine/core';

import { UserPopOverTargetProps } from '../types';

export default function PopOverTarget({ onClick }: UserPopOverTargetProps) {
  return (
    <Avatar size="sm" radius="xl" onClick={onClick} sx={{ marginLeft: -4 }}>
      +
    </Avatar>
  );
}
