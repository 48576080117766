import { useMemo } from 'react';
import { Box, Text, rem } from '@mantine/core';
import { IconCoin } from '@tabler/icons-react';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { useNavigate } from 'react-router-dom';

import { TableColumn } from 'components/common/Table/types';
import BaseTable from 'components/common/Table/variants/BaseTable';

import getUserFullName from 'helpers/display/getUserFullName';
import useModalStore from 'hooks/store/useModalStore';

import { ProjectTicket } from 'types/api';
import { BaseTableProps } from 'components/common/Table/types';
import { DATE_FORMATS } from 'constants/date';
import { HEADER_HEIGHT, MODALS, TABS_HEIGHT } from 'constants/component';
import getTicketDisplay from 'helpers/display/getTicketDisplay';
import TicketPriorityDisplay from 'components/common/TicketPriorityDisplay';
import useInstanceConfig from 'hooks/instance/useInstanceConfig';

export interface AssignmentsTableProps {
  tickets: ProjectTicket[];
}

const columns: TableColumn[] = [
  {
    id: 'project',
    display: 'Project',
  },
  {
    id: 'title',
    display: 'Title',
  },
  {
    id: 'status',
    display: 'Status',
  },
  {
    id: 'billable',
    display: 'Billable',
  },
  {
    id: 'date_due',
    display: 'Due Date',
  },
  {
    id: 'priority',
    display: 'Priority',
  },
];

export default function AssignmentsTable({ tickets }: AssignmentsTableProps) {
  const { pushModal } = useModalStore();
  const { dateDisplay } = useInstanceConfig();
  const navigate = useNavigate();

  const getDataDisplay = useMemo(() => {
    const _getDataDisplay: BaseTableProps<ProjectTicket>['getDataDisplay'] = (
      key,
      row
    ) => {
      const rowKey = key as keyof ProjectTicket;
      const isBlocked = row.blocked;

      if (row[rowKey] === undefined || row[rowKey] === null) return 'N/A';

      if (rowKey === 'project') {
        return (
          <Text
            sx={(theme) => ({
              color: theme.colors.blue[8],
              cursor: 'pointer',
              ':hover': {
                color: theme.colors.blue[4],
              },
            })}
            onClick={() => navigate(`/projects/${row.project?.id}`)}
          >
            {row.project?.title || 'N/A'}
          </Text>
        );
      }

      if (rowKey === 'title') {
        return (
          <Text
            sx={(theme) => ({
              color: theme.colors.blue[8],
              cursor: 'pointer',
              ':hover': {
                color: theme.colors.blue[4],
              },
            })}
            onClick={() => pushModal(MODALS.EDIT_TICKET_MODAL, { ticket: row })}
          >
            {getTicketDisplay(row) || ''}
          </Text>
        );
      }

      if (rowKey === 'priority') {
        if (!row[rowKey]) {
          row[rowKey] = 1;
        }

        return <TicketPriorityDisplay includeLowPriority ticket={row} />;
      }

      if (rowKey === 'assignee') {
        return getUserFullName(row[rowKey]);
      }

      if (rowKey === 'status') {
        if (isBlocked) {
          return `${row[rowKey]} (Stuck)`;
        }

        return row[rowKey];
      }

      if (rowKey === 'billable') {
        const billable = row[rowKey];

        return (
          <Box
            title={billable ? 'Billable' : 'Non-billable'}
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'center',
              color: billable ? theme.colors.green[5] : theme.colors.gray[5],
            })}
          >
            <IconCoin />
          </Box>
        );
      }

      if (rowKey === 'date_due') {
        const date = parse(
          row[rowKey] as string,
          DATE_FORMATS.DATE_KEY,
          new Date()
        );
        const isOverdue = new Date() > date;

        return (
          <Text
            sx={(theme) => ({
              color: isOverdue ? theme.colors.red[7] : undefined,
              fontWeight: isOverdue ? 'bold' : undefined,
            })}
          >
            {format(date, dateDisplay)}
          </Text>
        );
      }

      return `${row[rowKey]}`;
    };

    return _getDataDisplay;
  }, [dateDisplay, navigate, pushModal]);

  return (
    <BaseTable<ProjectTicket>
      columns={columns}
      rows={tickets}
      getDataDisplay={getDataDisplay}
      tableProps={{
        sx: {
          tableLayout: 'fixed',
        },
      }}
      height={`calc(100vh - ${HEADER_HEIGHT} - ${TABS_HEIGHT} - ${rem(84)})`}
    />
  );
}
