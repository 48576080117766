import { ENDPOINTS } from 'constants/api';
import type { Activity } from 'types/api';

import useApiQuery from 'hooks/api/useApiQuery';
import constructQueryPath from 'helpers/api/constructQueryPath';
import { ApiQueryKey } from 'types/api/query';

export interface TicketActivityHookParams {
  ticketId?: string | number;
  storeKey?: ApiQueryKey;
}

export default function useTicketActivity(
  hookParams: TicketActivityHookParams
) {
  const { ticketId, storeKey } = hookParams || {};

  const queryResult = useApiQuery<{ activity_items?: Activity[] }>({
    path: constructQueryPath(ENDPOINTS.TICKET_ACTIVITY, {
      ticketId: `${ticketId}`,
    }),
    queryOptions: {
      enabled: Boolean(ticketId),
    },
    storeKey,
  });

  return {
    ...queryResult,
  };
}
