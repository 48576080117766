import { useCallback, useState } from 'react';
import {
  ActionIcon,
  createStyles,
  Collapse,
  Group,
  Text,
  rem,
  Box,
  MantineTheme,
} from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import isEmpty from 'lodash/isEmpty';

import { TableRowProps } from 'components/common/Table/types';
import { BILLING_TYPE } from 'constants/billing';
import { MODALS } from 'constants/component';
import { ProjectMap, TicketMap, UserMap } from 'hooks/billing/types';

import getHourDisplay from 'helpers/display/getHourDisplay';
import getUserFullName from 'helpers/display/getUserFullName';
import useModalStore from 'hooks/store/useModalStore';
import getTicketDisplay from 'helpers/display/getTicketDisplay';
import { ClientProject, InstanceUser } from 'types/api';
import useUserAlerts from 'hooks/users/useUserAlerts';
import useAuthUser from 'hooks/users/useAuthUser';

const useStyles = createStyles((theme) => ({
  projectRowData: {
    padding: '0px !important',
  },
  rowData: {
    textAlign: 'center',
  },
  row: {
    userSelect: 'none',
    border: 'none',
    '&:hover': {
      backgroundColor: theme.colors.gray[1],
      cursor: 'pointer',
    },
    '&:active': {
      backgroundColor: theme.colors.gray[2],
    },
  },
  rowTitle: {
    backgroundColor: theme.colors.gray[2],
    fontWeight: 'bold',
  },
  rowTitleUser: {
    width: rem(400),
  },
  rowTitleItem: {
    backgroundColor: theme.colors.gray[2],
  },
  rowTitleSub: {
    backgroundColor: theme.colors.gray[0],
    fontWeight: 'bold',
  },
  rowTitleSubItem: {
    backgroundColor: theme.colors.gray[0],
  },
  table: {
    width: '100%',
    border: 'none',
    tableLayout: 'fixed',
    borderSpacing: '0px',
    '& th': {
      padding: '0px',
    },
    '& tbody tr td': {
      padding: '0px',
    },
  },
}));

interface DataDisplayProps {
  col: string;
  row: TicketMap;
  dayOff?: boolean;
  user?: InstanceUser;
  project?: ClientProject;
  colStart?: string;
  colEnd?: string;
  error?: boolean;
}

const colSpan = 10;

function DataDisplay({
  col,
  row,
  dayOff,
  user,
  project,
  colStart,
  colEnd,
  error,
}: DataDisplayProps) {
  const { pushModal } = useModalStore();
  let handleClick: (() => void) | null = () =>
    pushModal(MODALS.LIST_BILLING_RECORDS_MODAL, {
      ticket: row.ticket,
      params: {
        user_id: user?.id,
        project_id: project?.id,
        ticket_id: row.ticket.id,
        date: col === 'total' ? undefined : col,
        date_start: col === 'total' ? colStart : undefined,
        date_end: col === 'total' ? colEnd : undefined,
      },
      includesBackdrop: true,
      paramsMap: {
        user_id: getUserFullName(user),
        project_id: project?.title,
        ticket_id: getTicketDisplay(row.ticket),
      },
    });
  let isTotal = false;
  let isTime = true;
  let display = '0:00';

  if (!row) return <></>;

  if (col === 'employee') {
    display = getUserFullName(row.ticket.assignee);
    isTime = false;
  }

  if (col === 'ticket') {
    display = getTicketDisplay(row.ticket);
  }

  if (row.ticket.title?.toLowerCase() === 'total') {
    isTotal = true;

    if (col === 'ticket') {
      handleClick = null;
      display = 'Total';
    }
  }

  const totalTime = row.dates[col]
    ?.filter(
      (billingRecord) => billingRecord.billing_type === BILLING_TYPE.TIME
    )
    .reduce((a, b) => a + b.amount, 0);

  if (totalTime) {
    display = getHourDisplay(totalTime);
  }

  const getColor = (theme: MantineTheme) => {
    if (error) {
      return theme.colors.red[8];
    }

    if (col === 'total') {
      return theme.colors.blue[8];
    }

    return undefined;
  };

  return (
    <Text
      sx={(theme) => ({
        cursor: handleClick ? 'pointer' : undefined,
        textAlign: col === 'ticket' ? 'left' : 'center',
        fontWeight: col === 'total' || isTotal ? 'bold' : undefined,
        color: getColor(theme),
        '&:hover': {
          color: handleClick ? theme.colors.blue[8] : undefined,
        },
        textDecoration: dayOff && isTime ? 'line-through' : undefined,
        height: rem(22),
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      })}
      onClick={() => handleClick?.()}
    >
      {display}
    </Text>
  );
}

export default function TimesheetTableRow({
  row,
  columns,
  onClick,
}: TableRowProps<UserMap>) {
  const { classes, cx } = useStyles();
  const [userExpanded, setUserExpanded] = useState(true);
  const { user } = useAuthUser();
  const { data } = useUserAlerts({
    userId: user?.id,
    params: {
      notif_type: 'missing-time',
    },
    limit: 999,
    refetchInterval: 5000 * 60, // poll every 5 minutes
    storeKey: 'missing-time',
  });

  const TicketRow = useCallback(
    ({ ticket, project }: { ticket: TicketMap; project: ClientProject }) => {
      return (
        <table className={classes.table}>
          <thead style={{ display: 'none' }}>
            <tr>
              <th colSpan={2}></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              {columns.map((col, colIndex) => (
                <td
                  key={col.id}
                  className={classes.rowData}
                  colSpan={colIndex === 0 ? 2 : 1}
                >
                  <Box
                    sx={{
                      width: '100%',
                    }}
                  >
                    <DataDisplay
                      col={col.id}
                      row={ticket}
                      dayOff={Boolean(row.availabilityRecords?.[col.id])}
                      project={project}
                      user={row.user}
                      colStart={columns[1].id}
                      colEnd={columns.at(-2)?.id}
                    />
                  </Box>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      );
    },
    [classes.rowData, classes.table, columns, row.availabilityRecords, row.user]
  );

  const ProjectRow = useCallback(
    ({ project }: { project: ProjectMap }) => {
      const [projectExpanded, setProjectExpanded] = useState(true);

      return (
        <table className={classes.table}>
          <thead style={{ display: 'none' }}>
            <tr>
              <th colSpan={2}></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className={classes.rowTitleSub} colSpan={2}>
                <Group>
                  <ActionIcon
                    onClick={() => setProjectExpanded(!projectExpanded)}
                  >
                    {projectExpanded ? <IconChevronUp /> : <IconChevronDown />}
                  </ActionIcon>
                  <Text>{project.project.title}</Text>
                </Group>
              </td>
              {columns.slice(1).map((col) => {
                const total = row.projects[project.id]?.tickets[0];

                return (
                  <td className={classes.rowTitleSubItem} key={col.id}>
                    <DataDisplay
                      row={total}
                      col={col.id}
                      dayOff={Boolean(row.availabilityRecords?.[col.id])}
                      project={project.project}
                      user={row.user}
                      colStart={columns[1].id}
                      colEnd={columns.at(-2)?.id}
                    />
                  </td>
                );
              })}
            </tr>
            <tr
              key={project.id}
              className={cx({
                [classes.row]: Boolean(onClick),
              })}
              onClick={() => onClick?.(row)}
            >
              <td colSpan={colSpan}>
                <Collapse in={projectExpanded}>
                  <table className={classes.table}>
                    <thead style={{ display: 'none' }}>
                      <tr>
                        <th colSpan={2}></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td colSpan={colSpan}>
                          {Object.values(project.tickets)
                            .reverse()
                            .filter((ticket) => ticket.ticket.id)
                            .map((ticket) => (
                              <TicketRow
                                key={ticket.ticket.id}
                                ticket={ticket}
                                project={project.project}
                              />
                            ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Collapse>
              </td>
            </tr>
          </tbody>
        </table>
      );
    },
    [
      TicketRow,
      classes.row,
      classes.rowTitleSub,
      classes.rowTitleSubItem,
      classes.table,
      columns,
      cx,
      onClick,
      row,
    ]
  );

  return (
    <>
      <tr className={classes.rowTitle} onClick={() => onClick?.(row)}>
        <td colSpan={2} className={classes.rowTitleUser}>
          <Group>
            <ActionIcon onClick={() => setUserExpanded(!userExpanded)}>
              {userExpanded ? <IconChevronUp /> : <IconChevronDown />}
            </ActionIcon>
            <Text>{getUserFullName(row.user)}</Text>
          </Group>
        </td>
        {columns.slice(1).map((col) => {
          const total = row.total;
          const isDayOff = Boolean(row.availabilityRecords?.[col.id]);
          const isMissingTime =
            row.user.id === user?.id &&
            Boolean(
              data?.alerts?.find((alert) => {
                if (isEmpty(alert.metadata)) return false;

                const alertDate = alert.metadata?.['target_date'];

                if (!alertDate) return false;

                return alertDate == col.id;
              })
            );

          return (
            <td className={classes.rowTitleItem} key={col.id}>
              {isDayOff && <Text sx={{ textAlign: 'center' }}>OFF</Text>}
              <DataDisplay
                row={total}
                col={col.id}
                dayOff={isDayOff}
                user={row.user}
                colStart={columns[1].id}
                colEnd={columns.at(-2)?.id}
                error={isMissingTime}
              />
            </td>
          );
        })}
      </tr>
      <tr
        className={cx({
          [classes.row]: Boolean(onClick),
        })}
        onClick={() => onClick?.(row)}
      >
        <td colSpan={colSpan} className={classes.projectRowData}>
          <Collapse in={userExpanded}>
            {Object.values(row.projects).map((project) => (
              <ProjectRow key={project.id} project={project} />
            ))}
          </Collapse>
        </td>
      </tr>
    </>
  );
}
