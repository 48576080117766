import { ENDPOINTS } from 'constants/api';
import type { InstanceReport } from 'types/api';

import useApiQuery from 'hooks/api/useApiQuery';
import constructQueryPath from 'helpers/api/constructQueryPath';

interface ReportHookParams {
  reportId: number | string;
}

export default function useReport(hookParams: ReportHookParams) {
  const { reportId } = hookParams;

  const queryResult = useApiQuery<{ report?: InstanceReport }>({
    path: constructQueryPath(ENDPOINTS.REPORT, { reportId: `${reportId}` }),
    queryOptions: {
      enabled: Boolean(reportId),
    },
  });

  return {
    ...queryResult,
  };
}
