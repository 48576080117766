import { useMemo } from 'react';

import { SelectProps } from '../../types';
import BaseSelect from '../BaseSelect';
import useProjectTickets from 'hooks/tickets/useProjectTickets';
import getTicketDisplay from 'helpers/display/getTicketDisplay';

interface ProjectTicketSelectProps extends SelectProps {
  projectId: number | string;
}

export default function ProjectTicketSelect({
  onChange,
  defaultValue,
  label,
  projectId,
}: ProjectTicketSelectProps) {
  const { data } = useProjectTickets({ projectId });

  const options = useMemo(() => {
    return data?.tickets?.map((ticket) => {
      return {
        value: ticket.id as number,
        label: getTicketDisplay(ticket),
      };
    });
  }, [data]);

  return (
    <BaseSelect
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      label={label}
    />
  );
}
