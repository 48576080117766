import { useMemo } from 'react';
import { Loader, SelectItem } from '@mantine/core';

import BaseMultiSelect from '../BaseMultiSelect';

import { MultiSelectProps } from '../../types';
import { ClientProject } from 'types/api';
import useProjects from 'hooks/projects/useProjects';

interface ProjectMultiSelectProps extends MultiSelectProps {
  params?: any;
  filterByProject?: (project: ClientProject) => boolean;
}

export default function ProjectMultiSelect({
  filterBy = () => true,
  filterByProject = () => true,
  params,
  ...props
}: Partial<ProjectMultiSelectProps>) {
  const { data, isLoading } = useProjects({ params });

  const projects: SelectItem[] = useMemo(() => {
    return (
      data?.projects
        ?.filter(filterByProject)
        ?.map((project: ClientProject) => {
          return {
            value: project.id?.toString() || '',
            label: project?.title,
          };
        })
        ?.filter(filterBy) || []
    );
  }, [data?.projects, filterBy, filterByProject]);

  return (
    <BaseMultiSelect
      {...props}
      data={projects}
      rightSection={isLoading ? <Loader size="xs" /> : undefined}
      readOnly={isLoading}
    />
  );
}
