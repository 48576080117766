import {
  createStyles,
  Header,
  Container,
  Group,
  rem,
  Box,
} from '@mantine/core';

import { HEADER_HEIGHT } from 'constants/component';

import BellNotification from 'components/layout/BellNotification';
import DownloadNotification from 'components/layout/DownloadNotification';
import BreadCrumbs from 'components/layout/BreadCrumbs';
import useUserAlerts from 'hooks/users/useUserAlerts';
import useAuthUser from 'hooks/users/useAuthUser';
import PageAlert from 'components/common/PageAlert';
import useApiStore from 'hooks/store/useApiStore';

const useStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  linkLabel: {
    marginRight: rem(5),
  },
}));

export default function ConsoleLayoutHeader() {
  const { classes } = useStyles();
  const { user } = useAuthUser();
  const { invalidate } = useApiStore();
  const { data, updateUserAlert } = useUserAlerts({
    userId: user?.id,
    limit: 1,
    refetchInterval: 5000 * 60, // poll every 5 minutes
    storeKey: 'user-alerts',
  });

  return (
    <>
      {data?.alerts?.map((alert) => (
        <PageAlert
          key={alert.id}
          alert={alert}
          onClose={() => {
            updateUserAlert(alert.id as number, { dismissed: true }).then(
              () => {
                invalidate('missing-time');
              }
            );
          }}
        />
      ))}
      <Header
        height={HEADER_HEIGHT}
        sx={(theme) => ({
          width: '100%',
          zIndex: 100,
          backgroundColor: theme.black,
        })}
      >
        <Container className={classes.inner} fluid>
          <Group></Group>
          <BreadCrumbs />
          <Box sx={{ display: 'flex' }}>
            <DownloadNotification />
            <BellNotification />
          </Box>
        </Container>
      </Header>
    </>
  );
}
