import { ENDPOINTS } from 'constants/api';
import type { InstanceReport } from 'types/api';

import useApiQuery from 'hooks/api/useApiQuery';

export default function useReports() {
  const queryResult = useApiQuery<{ reports?: InstanceReport[] }>({
    path: ENDPOINTS.REPORTS,
  });

  return {
    ...queryResult,
  };
}
