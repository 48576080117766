/**
 * See https://date-fns.org/v2.8.1/docs/format
 */
export const DATE_FORMATS = {
  /** date format to send to api */
  DATE_KEY: 'yyyy-MM-dd',
  /** datetime format to send to api */
  DATETIME_KEY: 'yyyy-MM-dd HH:mm:ss',
  PRIMARY: 'd MMMM yyyy',
  TIMESHEET_DATE: 'eee MM/dd',
  MONTH_YEAR: 'MMMM yyyy',
};

export const RELATIVE_DATE_LABELS = {
  '-0 days': ['Today'],
  '-1 days': ['Yesterday'],
};
