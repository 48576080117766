import { Button, Group } from '@mantine/core';

import { ConfirmationPopOverDropdownProps } from '../types';

export default function PopOverDropdown({
  onClose,
  onConfirm,
}: ConfirmationPopOverDropdownProps) {
  return (
    <Group>
      <Button size="xs" variant="outline" onClick={onClose}>
        Cancel
      </Button>
      <Button size="xs" onClick={onConfirm}>
        Unsubscribe
      </Button>
    </Group>
  );
}
