import ConsoleLayout from 'layouts/ConsoleLayout';
import ActivityHistory from './ActivityHistory';

export default function ActivityPage() {
  return (
    <ConsoleLayout disableScroll disablePadding>
      <ActivityHistory />
    </ConsoleLayout>
  );
}
