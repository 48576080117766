import {
  Card,
  Box,
  createStyles,
  rem,
  Text,
  MantineTheme,
  Indicator,
  useMantineTheme,
  Skeleton,
} from '@mantine/core';
import { IconPlus, IconCoin, IconCoinOff } from '@tabler/icons-react';

import { BILLING_TYPE } from 'constants/billing';
import { BillingType } from 'types/app';

import { MODALS } from 'constants/component';
import getHourDisplay from 'helpers/display/getHourDisplay';
import { ProjectTicket } from 'types/api';
import { AddBillingRecordModalProps } from '../../AddBillingRecordModal/types';

import useModalStore from 'hooks/store/useModalStore';
import { BillableType } from '../types';

const useStyles = createStyles((theme) => ({
  iconButton: {
    borderRight: '1px solid',
    borderColor: theme.colors.gray[4],
    display: 'flex',
    padding: rem(8),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
    '&:active': {
      backgroundColor: theme.colors.gray[2],
    },
  },
  displayText: {
    padding: rem(8),
    color: theme.black,
    fontWeight: 'bold',
    flex: 1,
    fontSize: theme.fontSizes.lg,
    textAlign: 'center',
    '&:hover': {
      color: theme.colors.blue,
      cursor: 'pointer',
    },
  },
  suffix: {
    borderColor: theme.colors.gray[4],
    padding: rem(8),
    color: theme.black,
    fontSize: theme.fontSizes.lg,
    fontWeight: 'bold',
    width: '48px',
    textAlign: 'right',
  },
}));

interface BillingTypeDisplayProps {
  billingType: BillingType;
  amount: number;
  onClick?: () => void;
  ticket?: ProjectTicket;
  highlight?: boolean;
  billableType?: BillableType;
  onBillableChange?: (billableType: BillableType) => void;
  loading?: boolean;
}

function BillingTypeSuffix({
  billingType,
  onClick,
  billableType,
}: {
  billingType: BillingType;
  onClick?: () => void;
  billableType?: BillableType;
}) {
  const theme = useMantineTheme();
  let display = 'HR';

  if (billingType === BILLING_TYPE.EXPENSE) {
    display = 'USD';
  }

  const getColor = (theme: MantineTheme) => {
    if (billableType === 'billable') {
      return theme.colors.green[6];
    } else if (billableType === 'non-billable') {
      return theme.colors.gray[6];
    }

    return theme.colors.dark;
  };

  const getHoverColor = (theme: MantineTheme) => {
    if (billableType === 'billable') {
      return theme.colors.gray[6];
    } else if (billableType === 'non-billable') {
      return theme.colors.dark;
    }

    return theme.colors.green[4];
  };

  const getIndicatorIcon = () => {
    if (billableType === 'billable') {
      return <IconCoin color={theme.colors.green[4]} size="12px" />;
    } else if (billableType === 'non-billable') {
      return <IconCoinOff color={theme.colors.gray[4]} size="12px" />;
    }
  };

  return (
    <Indicator color="transparent" label={getIndicatorIcon()}>
      <Text
        sx={(theme) => ({
          cursor: 'pointer',
          color: getColor(theme),
          ':hover': {
            color: getHoverColor(theme),
          },
          userSelect: 'none',
        })}
        onClick={onClick}
      >
        {display}
      </Text>
    </Indicator>
  );
}

function getBillingAmountDisplay(
  billingType: BillingType,
  amount: number
): string {
  if (billingType === BILLING_TYPE.TIME) {
    return getHourDisplay(amount);
  }

  return (amount / 100).toFixed(2);
}

export default function BillingTypeDisplay({
  billingType,
  amount,
  onClick,
  ticket,
  highlight,
  billableType,
  onBillableChange,
  loading = false,
}: BillingTypeDisplayProps) {
  const { classes } = useStyles();
  const { pushModal } = useModalStore();

  const handlePlusClick = () => {
    const modalId =
      billingType === BILLING_TYPE.EXPENSE
        ? MODALS.LIST_EXPENSES_MODAL
        : MODALS.ADD_BILLING_RECORD_MODAL;

    pushModal<AddBillingRecordModalProps>(modalId, {
      ticket,
      showAdd: true,
    });
  };

  return (
    <Card
      sx={(theme) => ({
        padding: '0 !important',
        backgroundColor: highlight ? theme.colors.yellow[1] : undefined,
      })}
    >
      <Skeleton visible={loading}>
        <Box sx={{ display: 'flex' }}>
          <Box className={classes.iconButton} onClick={handlePlusClick}>
            <IconPlus />
          </Box>
          <Box className={classes.displayText} onClick={onClick}>
            {getBillingAmountDisplay(billingType, amount)}
          </Box>
          <Box className={classes.suffix}>
            <BillingTypeSuffix
              billingType={billingType}
              onClick={() => onBillableChange?.(billableType as BillableType)}
              billableType={billableType}
            />
          </Box>
        </Box>
      </Skeleton>
    </Card>
  );
}
