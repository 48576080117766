import { ENDPOINTS } from 'constants/api';

import useApiQuery from 'hooks/api/useApiQuery';
import constructQueryPath from 'helpers/api/constructQueryPath';

interface ReportRowsHookParams {
  reportId?: number | string;
  params?: any;
}

export default function useReportRows<T>(hookParams: ReportRowsHookParams) {
  const { reportId, params } = hookParams;

  const queryResult = useApiQuery<{ rows?: T[]; filename: string }>({
    path: constructQueryPath(ENDPOINTS.REPORT_ROWS, {
      reportId: `${reportId}`,
    }),
    queryOptions: {
      enabled: Boolean(reportId),
    },
    axiosConfig: {
      params,
    },
  });

  return {
    ...queryResult,
  };
}
