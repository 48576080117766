import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Box } from '@mantine/core';
import { ReactNode } from 'react';

import BaseModal from '../BaseModal';
import ModalFooter from './ModalFooter';

import { MODALS } from 'constants/component';
import { ModalProps } from '../../types';

import useModalStore from 'hooks/store/useModalStore';

interface InfoModalProps {
  title?: string;
  body?: ReactNode;
  modalProps?: ModalProps;
}

function InfoModal({ title, body, modalProps }: InfoModalProps) {
  const modal = useModal();
  const { popModal } = useModalStore();

  const handleClose = () => {
    popModal(MODALS.INFO);
  };

  return (
    <BaseModal
      id={MODALS.INFO}
      isOpen={modal.visible}
      onClose={handleClose}
      title={title}
      height="auto"
      size="auto"
      FooterComponent={ModalFooter}
      {...modalProps}
    >
      <Box sx={(theme) => ({ padding: theme.spacing.xl })}>{body}</Box>
    </BaseModal>
  );
}

export default NiceModal.create((props) => <InfoModal {...props} />);
