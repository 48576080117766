import { Box, Group, Loader, Stack, Text } from '@mantine/core';
import { useMemo, useState } from 'react';

import AssignmentsTable from './AssignmentsTable';
import useTickets from 'hooks/tickets/useTickets';
import ToggleButton from 'components/common/ToggleButton';

interface TicketsTabProps {
  userId?: string;
}

export default function TicketsTab({ userId }: TicketsTabProps) {
  const [filters, setFilters] = useState({
    blocked: 'all',
  });

  const { data, isLoading, isFetched } = useTickets({
    params: {
      user_id: userId,
      sort: '-priority|date_due+nulls_last',
      fields: [
        'id',
        'title',
        'status',
        'billable',
        'date_due',
        'priority',
        'project',
        'pub_id',
        'blocked',
      ].join(','),
    },
  });

  const tickets = useMemo(() => {
    let _tickets = data?.tickets || [];

    if (filters.blocked === 'none') {
      _tickets = _tickets.filter((ticket) => !ticket.blocked);
    }

    if (filters.blocked === 'only') {
      _tickets = _tickets.filter((ticket) => ticket.blocked);
    }

    return _tickets;
  }, [data?.tickets, filters.blocked]);

  return (
    <Stack spacing={8} sx={(theme) => ({ paddingBottom: theme.spacing.md })}>
      {isLoading && (
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Loader sx={{ margin: 'auto' }} />
        </Box>
      )}
      {isFetched && (
        <Stack>
          <Group
            sx={(theme) => ({
              border: '1px solid',
              borderColor: theme.colors.gray[4],
              borderRadius: theme.radius.sm,
              position: 'relative',
              padding: theme.spacing.xs,
              paddingTop: theme.spacing.sm,
            })}
          >
            <Box
              sx={(theme) => ({
                backgroundColor: 'white',
                position: 'absolute',
                top: -12,
                left: 4,
                fontSize: theme.fontSizes.sm,
              })}
            >
              <Text color="gray">Filter by</Text>
            </Box>
            <Box title="Stuck?">
              <ToggleButton
                parentVariant="blockedticket"
                value={filters.blocked}
                setValue={(value) => {
                  setFilters({
                    ...filters,
                    blocked: value,
                  });
                }}
              />
            </Box>
          </Group>
          <AssignmentsTable tickets={tickets} />
        </Stack>
      )}
    </Stack>
  );
}
