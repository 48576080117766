import { Box, Avatar } from '@mantine/core';

import { SelectOption } from '../../types';
import getInitials from 'helpers/display/getInitials';

interface SelectTargetProps {
  selected: SelectOption;
  opened: boolean;
}

export default function SelectTarget({ selected }: SelectTargetProps) {
  return (
    <Box>
      <Avatar size="sm">
        {getInitials({ fullName: selected?.label as string })}
      </Avatar>
    </Box>
  );
}
