import { useMemo } from 'react';
import { Stack, Checkbox, Radio, Box, Textarea, Loader } from '@mantine/core';
import { useFormikContext } from 'formik';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import { DateInput } from '@mantine/dates';

import ClientsSelect from 'components/common/Select/variants/ClientsSelect';
import ProjectMultiSelect from 'components/common/MultiSelect/variants/ProjectMultiselect';

import { AddInvoiceRecordBody } from 'utils/billing';
import { BILLING_TYPE } from 'constants/billing';
import useClients from 'hooks/clients/useClients';
import { DATE_FORMATS } from 'constants/date';

function ClientProjectForm() {
  const { values, setFieldValue, status } =
    useFormikContext<Partial<AddInvoiceRecordBody>>();
  const { data, isLoading } = useClients();

  const clientAddress = useMemo(() => {
    const client = data?.clients?.find(
      (client) => client.id === values.client_id
    );

    if (!client) return '';

    const addresses = [
      client?.address_line1,
      client?.address_line2,
      `${client?.address_city}, ${client?.address_state}, ${client?.address_zip}`,
      client?.address_country,
    ];

    return addresses.filter((a) => a).join('\n');
  }, [values.client_id, data?.clients]);

  if (status.loading || isLoading) {
    return (
      <Box
        sx={(theme) => ({
          display: 'flex',
          padding: theme.spacing.xl,
          width: '100%',
        })}
      >
        <Loader sx={{ margin: 'auto' }} />
      </Box>
    );
  }

  return (
    <Stack spacing={8}>
      <ClientsSelect
        required
        label="Client"
        defaultValue={values.client_id}
        onChange={(option) => {
          setFieldValue('client_id', option.value);
          setFieldValue('projectIds', []);
        }}
      />
      {Boolean(values.client_id) && (
        <Textarea
          disabled
          autosize
          label="Client Address"
          value={clientAddress}
        />
      )}
      <ProjectMultiSelect
        label="Projects"
        value={values.projectIds}
        onChange={(values) => {
          setFieldValue('projectIds', values);
        }}
        filterByProject={(project) => project.client_id === values.client_id}
      />
      <DateInput
        required
        name="issue_date"
        label="Issue Date"
        value={parse(
          values.issue_date as string,
          DATE_FORMATS.DATE_KEY,
          new Date()
        )}
        onChange={(date) => {
          setFieldValue(
            'issue_date',
            format(date as Date, DATE_FORMATS.DATE_KEY)
          );
        }}
      />
      <Checkbox.Group
        withAsterisk
        defaultValue={values.billingTypes}
        label="Bill By:"
        onChange={(values) => {
          setFieldValue('billingTypes', values);
        }}
      >
        <Stack>
          <Checkbox value={BILLING_TYPE.TIME} label="Time" />
          <Checkbox value={BILLING_TYPE.EXPENSE} label="Expenses" />
        </Stack>
      </Checkbox.Group>
      <Checkbox.Group
        label="Include Non-Billable Time?"
        description="This will add line items with a cost of 0"
        value={
          values.includeNonBillable ? [values.includeNonBillable as string] : []
        }
        onChange={(values) => {
          setFieldValue('includeNonBillable', values[0]);
        }}
      >
        <Box sx={(theme) => ({ padding: theme.spacing.xs })}>
          <Checkbox value="yes" label="Yes, include non-billable time" />
        </Box>
      </Checkbox.Group>
      <Radio.Group
        withAsterisk
        name="groupBy"
        label="Group By:"
        value={values.groupBy}
        onChange={(value) => {
          setFieldValue('groupBy', value);
        }}
      >
        <Stack sx={(theme) => ({ padding: theme.spacing.xs })}>
          {/* <Radio value="by-billing-record" label="By Billing Record" /> */}
          <Radio value="by-task" label="By Task" />
          <Radio value="by-project" label="By Project" />
          {/* <Radio value="by-jobtype" label="By Job / Expense" /> */}
        </Stack>
      </Radio.Group>
    </Stack>
  );
}

export default function ClientProjectModalForm() {
  return <ClientProjectForm />;
}
