import {
  Table as MantineTable,
  Card,
  createStyles,
  Box,
  Loader,
  rem,
} from '@mantine/core';

import { BaseTableProps } from 'components/common/Table/types';

import TableCol from './TableCol';
import TableRow from './TableRow';

const useStyles = createStyles((theme) => ({
  col: {
    zIndex: 2,
    backgroundColor: theme.black,
    '& > div': {
      color: theme.white,
      textAlign: 'center',
    },
  },
}));

export default function BaseTable<R extends { id?: number | string }>({
  columns,
  rows,
  tableProps,
  ColComponent = TableCol,
  RowComponent = TableRow,
  getDataDisplay,
  onRowClick,
  colGroup,
  loading,
  height,
  placeholder = 'No results found.',
}: BaseTableProps<R>) {
  const { classes } = useStyles();

  if (!rows?.length) {
    return (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        {loading ? <Loader /> : placeholder}
      </Box>
    );
  }

  return (
    <Card
      withBorder
      p={0}
      sx={(theme) => ({
        overflow: 'visible',
        maxHeight: height,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: rem(9),
        },
        '&::-webkit-scrollbar-track': {
          background: theme.colors.gray[4],
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.colors.gray[5],
          borderRadius: theme.radius.md,
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: theme.colors.gray[6],
        },
      })}
    >
      <MantineTable miw={800} verticalSpacing="sm" {...tableProps}>
        {colGroup}
        <thead style={{ position: 'sticky', top: 0, zIndex: 100 }}>
          <tr>
            {columns?.map((col) => (
              <th colSpan={col.colSpan} key={col.id} className={classes.col}>
                <Box>
                  <ColComponent key={col.id} col={col} />
                </Box>
              </th>
            ))}
          </tr>
        </thead>
        <tbody style={{ maxHeight: height, width: '100%', overflow: 'auto' }}>
          {rows?.map((row) => (
            <RowComponent
              key={row.id}
              row={row}
              columns={columns}
              getDataDisplay={getDataDisplay}
              onClick={onRowClick}
            />
          ))}
        </tbody>
      </MantineTable>
    </Card>
  );
}
