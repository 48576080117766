import { MutableRefObject, useMemo, useRef } from 'react';
import { Box, ScrollArea, Stepper } from '@mantine/core';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Formik, FormikProps } from 'formik';
import format from 'date-fns/format';
import { notifications } from '@mantine/notifications';

import BaseModal from '../BaseModal';
import ModalFooter from './ModalFooter';
import AddInvoiceModalForm from './AddInvoiceModalForm';
import ClientProjectModalForm from './ClientProjectModalForm';
import VerifyDraftForm from './VerifyDraftForm';

import { MODALS } from 'constants/component';
import type { AddInvoiceRecordBody } from 'utils/billing';
import { DATE_FORMATS } from 'constants/date';
import { BILLING_TYPE } from 'constants/billing';

import useModalStore from 'hooks/store/useModalStore';
import useInstance from 'hooks/instance/useInstance';

import * as billingUtils from 'utils/billing';
import showApiErrNotif from 'helpers/api/showApiErrNotif';
import useApiStore from 'hooks/store/useApiStore';
import useInvoice from 'hooks/billing/useInvoice';
import useInstanceConfig from 'hooks/instance/useInstanceConfig';

interface AddInvoiceModalProps {
  invoiceId?: number;
}

function AddInvoiceModal({ invoiceId }: AddInvoiceModalProps) {
  const modal = useModal();
  const formikRef = useRef() as MutableRefObject<
    FormikProps<Partial<AddInvoiceRecordBody>>
  >;
  const { popModal } = useModalStore();
  const { invalidate } = useApiStore();
  const { data: instanceData, isLoading } = useInstance();
  const {
    data: invoiceData,
    isLoading: invoiceLoading,
    refetch,
  } = useInvoice({
    invoiceId,
    params: {
      embed: 'line_items',
    },
  });
  const { data } = useInstanceConfig();
  const invoice = invoiceData?.invoice;

  const initialValues: Partial<AddInvoiceRecordBody> = useMemo(
    () => ({
      id: invoice?.id,
      pub_id: invoice?.pub_id || instanceData?.instance?.next_invoice_id || 0,
      emails: invoice?.emails || '',
      client_id: invoice?.client_id,
      invoiceLineItems: invoice?.line_items || [],
      projectIds: invoice?.projects?.map((project) => `${project.id}`) || [],
      stepIndex: invoice ? 1 : 0,
      billingTypes: [BILLING_TYPE.TIME],
      groupBy: 'by-task',
      includeNonBillable: '',
      discount: invoice?.discount || 0,
      instance_info:
        invoice?.instance_info || data?.config?.invoice_instance_info || '',

      // default to one month from today
      due_date: invoice?.due_date || 'one-month',

      // defaults to today
      issue_date:
        invoice?.issue_date || format(new Date(), DATE_FORMATS.DATE_KEY),
    }),
    [
      data?.config?.invoice_instance_info,
      instanceData?.instance?.next_invoice_id,
      invoice,
    ]
  );

  const handleSubmit = async (values: Partial<AddInvoiceRecordBody>) => {
    try {
      if (invoice) {
        await billingUtils.updateInvoice(
          `${invoice.id}`,
          values as AddInvoiceRecordBody
        );
        notifications.show({
          message: 'Invoice draft successfully updated!',
          color: 'green',
        });
        refetch();
      } else {
        await billingUtils.createInvoice(values as AddInvoiceRecordBody);
        invalidate('instance');
        notifications.show({
          message: 'Invoice draft successfully created!',
          color: 'green',
        });
      }
      popModal(MODALS.ADD_INVOICE_MODAL);
      invalidate('list-invoices');
      formikRef.current.resetForm();
    } catch (err) {
      showApiErrNotif(
        'Unable to add invoice at this time. Try again later.',
        err
      );
    }
  };

  const handleClose = () => {
    popModal(MODALS.ADD_INVOICE_MODAL);
    formikRef.current.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={initialValues}
      initialStatus={{
        loading: false,
      }}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, status }) => (
        <BaseModal
          preventBackdropClose
          includesBackdrop
          id={MODALS.ADD_INVOICE_MODAL}
          isOpen={modal.visible}
          onClose={handleClose}
          title={invoiceId ? 'Edit Invoice' : 'Add Invoice'}
          height="80vh"
          size="60vw"
          FooterComponent={ModalFooter}
          loading={isLoading || invoiceLoading}
          scrollable={false}
        >
          <Box
            sx={(theme) => ({
              padding: theme.spacing.md,
            })}
          >
            <Stepper
              color="green"
              active={values.stepIndex || 0}
              onStepClick={(stepIndex) => {
                setFieldValue('stepIndex', stepIndex);
              }}
            >
              <Stepper.Step
                label="First step"
                description="Choose client and projects"
                loading={status.loading && values.stepIndex === 0}
              >
                <ScrollArea
                  offsetScrollbars
                  sx={(theme) => ({
                    padding: theme.spacing.sm,
                    height: 'calc(75vh - 66px)',
                  })}
                >
                  <ClientProjectModalForm />
                </ScrollArea>
              </Stepper.Step>
              <Stepper.Step
                label="Second step"
                description="Update line items"
                loading={status.loading && values.stepIndex === 1}
              >
                <ScrollArea
                  offsetScrollbars
                  sx={(theme) => ({
                    padding: theme.spacing.sm,
                    height: 'calc(75vh - 66px)',
                  })}
                >
                  <AddInvoiceModalForm />
                </ScrollArea>
              </Stepper.Step>
              <Stepper.Step label="Final step" description="Verify invoice">
                <ScrollArea
                  offsetScrollbars
                  sx={(theme) => ({
                    padding: theme.spacing.sm,
                    height: 'calc(75vh - 66px)',
                  })}
                >
                  <VerifyDraftForm />
                </ScrollArea>
              </Stepper.Step>
            </Stepper>
          </Box>
        </BaseModal>
      )}
    </Formik>
  );
}

export default NiceModal.create((props) => <AddInvoiceModal {...props} />);
