export const MODULES = {
  PROJECTS: 'projects',
  ASSIGNMENTS: 'assignments',
  TIMESHEET: 'timesheet',
  PEOPLE: 'people',
  CLIENTS: 'clients',
  REPORTS: 'reports',
  MANAGE: 'manage',
  ACTIVITY: 'activity',
  SETTINGS: 'settings',
  ADMIN: 'admin',
  INVOICES: 'invoices',
  BILLING_DASHBOARD: 'billing-dashboard',
};
