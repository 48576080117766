import { useMemo } from 'react';

import useUsers from 'hooks/users/useUsers';
import getUserFullName from 'helpers/display/getUserFullName';

import InstanceUserSelectUI from './UI';

import { InstanceUserSelectProps } from './types';

function InstanceUserSelectComponent({
  onChange,
  onBlur,
  defaultValue,
  label,
  variant = 'default',
  width,
  filter = () => true,
  readonly,
  params,
  ...props
}: InstanceUserSelectProps) {
  const { data } = useUsers({
    params: { active: '1', ...params },
  });

  const options = useMemo(() => {
    return data?.users
      ?.filter((user) => filter?.(`${user.id}`))
      ?.map((user) => {
        return {
          value: user.id as number,
          label: getUserFullName(user),
        };
      });
  }, [data?.users, filter]);

  return (
    <InstanceUserSelectUI
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      defaultValue={defaultValue}
      label={label}
      variant={variant}
      width={width}
      readonly={readonly}
      {...props}
    />
  );
}

export default function InstanceUserSelect(props: InstanceUserSelectProps) {
  return <InstanceUserSelectComponent {...props} />;
}
