import constructQueryPath from './constructQueryPath';

/**
 * constructs a QueryKey from an endpoint
 * @param path - api path to query from, example /users/:userId
 * @param params - map of params to inject into endpoint, must match :key, example :userId
 */
export default function constructQueryKey(
  path: string,
  params?: { [key: string]: string }
): string[] {
  return constructQueryPath(path, params).split(/\//gm);
}
