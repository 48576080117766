import { useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useLocation, Link } from 'react-router-dom';
import {
  createStyles,
  Navbar,
  Group,
  getStylesRef,
  rem,
  ActionIcon,
} from '@mantine/core';
import {
  IconLogout,
  IconArrowRight,
  IconMenu2,
  IconUserCog,
} from '@tabler/icons-react';

import config from 'config';
import useModules from 'hooks/modules/useModules';
import { SIDEBAR_WIDTH } from 'constants/styles';
import useAuth from 'hooks/auth/useAuth';
import useAuthStore from 'hooks/store/useAuthStore';
import Logo from 'components/common/Logo';

const useStyles = createStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: `calc(${theme.spacing.md} * 1.5)`,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
    height: rem(64),
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
    padding: `${theme.spacing.sm} ${theme.spacing.md}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,

      [`& .${getStylesRef('icon')}`]: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },
  },

  linkIcon: {
    ref: getStylesRef('icon'),
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({
        variant: 'light',
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor })
        .color,
      [`& .${getStylesRef('icon')}`]: {
        color: theme.fn.variant({ variant: 'light', color: theme.primaryColor })
          .color,
      },
    },
  },
}));

export default function ConsoleLayoutSidebar() {
  const location = useLocation();
  const { logOut } = useAuth();
  const { classes, cx } = useStyles();
  const { data } = useModules();
  const { isSuperUser } = useAuthStore();
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const isDev = process.env.REACT_APP_API_BASE_URL?.includes('dev');

  const props = useSpring({
    width: sidebarOpen ? SIDEBAR_WIDTH.OPEN : SIDEBAR_WIDTH.CLOSED,
  });

  return (
    <Navbar sx={{ zIndex: 100, width: 'auto' }} p="md" height="100%">
      <Navbar.Section grow>
        <animated.div style={{ ...props }}>
          <Group
            className={classes.header}
            position="apart"
            sx={{ display: 'flex', flexWrap: 'nowrap' }}
          >
            {sidebarOpen && (
              <Link to="/">
                <Logo sx={{ height: rem(64) }} />
              </Link>
            )}
            <ActionIcon onClick={() => setSidebarOpen(!sidebarOpen)}>
              {sidebarOpen ? <IconMenu2 /> : <IconArrowRight />}
            </ActionIcon>
          </Group>
          {data?.modules
            ?.sort((a, b) => a.priority - b.priority)
            ?.filter((m) => config.modules[m.key])
            .map((module) => {
              const configModule = config.modules[module.key];
              const href = configModule.href;
              const Icon = configModule.Icon;
              const isActive = location.pathname.includes(configModule.href);

              return (
                <Link
                  className={cx(classes.link, {
                    [classes.linkActive]: isActive,
                  })}
                  to={href}
                  key={module.key}
                >
                  <Icon className={classes.linkIcon} stroke={1.5} />
                  {sidebarOpen && (
                    <span>
                      {module.name} {isDev && ' (DEV)'}
                    </span>
                  )}
                </Link>
              );
            })}
        </animated.div>
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        {isSuperUser && (
          <a
            target="_blank"
            rel="noreferrer"
            className={classes.link}
            href={
              process.env.NODE_ENV !== 'production'
                ? 'http://localhost:8000/admin/'
                : '/admin/'
            }
          >
            <IconUserCog className={classes.linkIcon} stroke={1.5} />
            {sidebarOpen && <span>Admin</span>}
          </a>
        )}
        <div className={classes.link} onClick={logOut}>
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          {sidebarOpen && <span>Logout</span>}
        </div>
      </Navbar.Section>
    </Navbar>
  );
}
