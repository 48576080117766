import {
  ReactNode,
  createContext,
  useReducer,
  useCallback,
  useContext,
} from 'react';

const ACTIONS = {
  SHOW_POPOVER: 'SHOW_POPOVER',
};

interface PopOverState {
  isOpen: boolean;
  showPopOver?: (isOpen: boolean) => void;
}

const initialState: Partial<PopOverState> = {
  isOpen: false,
};

type PopOverContextState = typeof initialState;

export const PopOverContext = createContext<PopOverContextState>(initialState);

export const usePopOverContext = () => useContext(PopOverContext);

function PopOverReducer(
  state: PopOverContextState,
  action: {
    type: string;
    payload?: Partial<PopOverState & { key: keyof PopOverContextState }>;
  }
) {
  switch (action.type) {
    case ACTIONS.SHOW_POPOVER:
      return { ...state, isOpen: action.payload?.isOpen };
    default:
      return state;
  }
}

interface PopOverProviderProps {
  children: ReactNode;
}

export default function PopOverProvider({ children }: PopOverProviderProps) {
  const [state, dispatch] = useReducer(PopOverReducer, initialState);

  const showPopOver = useCallback(
    (isOpen: boolean) => {
      if (state.isOpen !== isOpen) {
        dispatch({
          type: ACTIONS.SHOW_POPOVER,
          payload: {
            isOpen,
          },
        });
      }
    },
    [state]
  );

  return (
    <PopOverContext.Provider
      value={{
        ...state,
        showPopOver,
      }}
    >
      {children}
    </PopOverContext.Provider>
  );
}
