import { useState } from 'react';

import BasePopOver from '../../BasePopOver';
import BasePopOverTarget from './PopOverTarget';
import PopOverDropdown from './PopOverDropdown';

import {
  LineItemDiscountPopOverDropdownProps,
  LineItemDiscountPopOverTargetProps,
} from '../types';
import { InvoiceLineItem } from 'types/api';

interface LineItemDiscountPopOverProps {
  onChange: (lineItem: Partial<InvoiceLineItem>) => void;
  onPopOverChange?: (show: boolean) => void;
  opened?: boolean;
  value?: number;
  lineItem?: Partial<InvoiceLineItem>;
  PopOverTarget?: () => JSX.Element;
}

export default function LineItemDiscountPopOverUI({
  onChange,
  value,
  lineItem,
  PopOverTarget = BasePopOverTarget,
}: LineItemDiscountPopOverProps) {
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <BasePopOver<
      LineItemDiscountPopOverDropdownProps,
      LineItemDiscountPopOverTargetProps
    >
      TargetComponent={PopOverTarget}
      DropdownComponent={PopOverDropdown}
      popoverProps={{
        opened: opened,
        onChange: setOpened,
        closeOnClickOutside: false,
      }}
      dropdownProps={{
        onSave: (lineItem: Partial<InvoiceLineItem>) => {
          onChange?.(lineItem);
          setOpened?.(false);
        },
        onReset: () => {
          setOpened?.(false);
        },
        defaultValue: value,
        lineItem: lineItem,
      }}
    />
  );
}
