import { notifications } from '@mantine/notifications';

import { ENDPOINTS } from 'constants/api';
import type { AvailabilityRecord } from 'types/api';
import type { AvailabilityRecordBody } from 'utils/user';

import useApiQuery from 'hooks/api/useApiQuery';
import useApiStore from 'hooks/store/useApiStore';

import showApiErrNotif from 'helpers/api/showApiErrNotif';
import * as userUtils from 'utils/user';

export interface AvailabilityRecordsHookParams {
  params?: any;
}

export default function useAvailabilityRecords(
  hookParams?: AvailabilityRecordsHookParams
) {
  const { params } = hookParams || {};
  const { invalidateEndpoint } = useApiStore();

  const queryResult = useApiQuery<{
    availability_records?: AvailabilityRecord[];
  }>({
    path: ENDPOINTS.AVAILABILITY_RECORDS,
    axiosConfig: {
      params,
    },
  });

  const addAvailabilityRecord = async (
    recordBody: Partial<AvailabilityRecordBody>
  ) => {
    try {
      await userUtils.addAvailabilityRecord({ body: recordBody });
      queryResult.refetch();
      invalidateEndpoint(ENDPOINTS.AVAILABILITY_RECORDS);

      notifications.show({
        message: 'Availability Record successfully added!',
        color: 'green',
      });
      return true;
    } catch (err) {
      showApiErrNotif('Unable to add availability record', err);
    }

    return false;
  };

  const updateAvailabilityRecord = async (
    recordId: number | string,
    recordBody: Partial<userUtils.AvailabilityRecordUpdateBody>
  ) => {
    try {
      await userUtils.updateAvailabilityRecord({
        recordId: recordId,
        body: recordBody,
      });
      queryResult.refetch();
      invalidateEndpoint(ENDPOINTS.AVAILABILITY_RECORDS);

      notifications.show({
        message: 'Availability Record successfully updated!',
        color: 'green',
      });

      return true;
    } catch (err) {
      showApiErrNotif('Unable to update availability record', err);
    }

    return false;
  };

  return {
    ...queryResult,
    addAvailabilityRecord,
    updateAvailabilityRecord,
  };
}
