import { NumberInputProps, TextInput, TextInputProps } from '@mantine/core';

import CurrencyInput from './variants/CurrencyInput';
import { DateInput, DateInputProps } from '@mantine/dates';
import { SelectProps } from '../Select/types';
import ProjectSelect from '../Select/variants/ProjectSelect';

type UserInputProps =
  | {
      variant: 'currency';
      inputProps?: NumberInputProps;
      onChange: (value: number) => void;
    }
  | {
      variant: 'min-expected-work';
      inputProps?: NumberInputProps;
      onChange: (value: number) => void;
    }
  | {
      variant: 'text';
      inputProps?: TextInputProps;
      onChange: (value: string) => void;
    }
  | {
      variant: 'date';
      inputProps?: DateInputProps;
      onChange: (date: Date) => void;
    }
  | {
      variant: 'project';
      inputProps?: SelectProps;
      onChange: (value: number) => void;
    };

export default function UserInput({
  variant,
  inputProps,
  onChange,
}: UserInputProps) {
  if (variant === 'currency') {
    return <CurrencyInput {...inputProps} onChange={onChange} />;
  }

  if (variant === 'date') {
    return <DateInput {...inputProps} onChange={onChange} />;
  }

  if (variant === 'text') {
    return (
      <TextInput {...inputProps} onChange={(e) => onChange(e.target.value)} />
    );
  }

  if (variant === 'project') {
    return (
      <ProjectSelect
        {...inputProps}
        onChange={(option) => onChange(option.value as number)}
      />
    );
  }

  return <></>;
}
