import { useMemo } from 'react';
import format from 'date-fns/format';

import { SelectOption, SelectProps } from '../../types';
import BaseSelect from '../BaseSelect';

import useGlobalConfig from 'hooks/instance/useGlobalConfig';

interface ExpenseTypeSelectProps extends SelectProps {
  defaultFirst?: boolean;
}

export default function DateDisplaySelect({
  onChange,
  defaultValue,
  ...rest
}: ExpenseTypeSelectProps) {
  const { config } = useGlobalConfig();

  const options: SelectOption[] = useMemo(() => {
    try {
      const displayOptions = JSON.parse(config?.display_meta || '{}').date
        .options;

      return (
        displayOptions?.map((option: string) => {
          return {
            value: option,
            label: format(new Date(), option),
          };
        }) || []
      );
    } catch (err) {
      return [];
    }
  }, [config]);

  return (
    <BaseSelect
      options={options}
      onChange={(selected) => {
        onChange?.(selected);
      }}
      defaultValue={defaultValue || undefined}
      {...rest}
    />
  );
}
