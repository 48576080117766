import { useCallback } from 'react';
import { ENDPOINTS } from 'constants/api';
import type { InstanceNotification } from 'types/api';

import useApiQuery from 'hooks/api/useApiQuery';
import {
  NotificationBody,
  updateNotification as updateNotificationCallback,
} from 'utils/user';

export interface UserNotificationsHookParams {
  userId: number;
}

export default function useUserNotifications({
  userId,
}: UserNotificationsHookParams) {
  const queryResult = useApiQuery<{
    notifications?: InstanceNotification[];
  }>({
    path: ENDPOINTS.NOTIFICATIONS,
    axiosConfig: {
      params: {
        user_id: userId,
        is_archived: 0,
      },
    },
  });

  const updateNotification = useCallback(
    async ({ notifId, body }: { notifId: number; body: NotificationBody }) => {
      await updateNotificationCallback({ notifId, body });
      queryResult.refetch();
    },
    [queryResult]
  );

  return {
    ...queryResult,
    updateNotification,
  };
}
