import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Tabs,
  createStyles,
  Box,
  Popover,
  Stack,
  Text,
  ScrollArea,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconHelp } from '@tabler/icons-react';

import ConsoleLayout from 'layouts/ConsoleLayout';
import { HEADER_HEIGHT, TABS_HEIGHT } from 'constants/component';

import BillingTab from './components/tab/Billing';
import GeneralTab from './components/tab/General';

type SettingsTab = 'general' | 'billing-rates';

const tabs: SettingsTab[] = ['general', 'billing-rates'];

const useStyles = createStyles((theme) => ({
  tabsList: {
    paddingLeft: theme.spacing.lg,
    backgroundColor: theme.white,
    width: '100%',
  },
  tab: {
    fontSize: theme.fontSizes.sm,
    height: TABS_HEIGHT,
    fontWeight: 'bold',
  },
}));

export default function SettingsPage() {
  const { classes } = useStyles();
  const [activeTab, setActiveTab] = useState<SettingsTab>('general');
  const [searchParams, setSearchParams] = useSearchParams();
  const [opened, { close, open }] = useDisclosure(false);

  const handleTabChange = (value: string) => {
    setActiveTab(value as SettingsTab);
    setSearchParams({
      tab: value,
    });
  };

  useEffect(() => {
    const tab = searchParams.get('tab') as SettingsTab;
    if (tabs.includes(tab)) {
      setActiveTab(tab);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <ConsoleLayout disablePadding disableScroll>
      <Tabs
        value={activeTab}
        onTabChange={handleTabChange}
        sx={{ position: 'relative', height: '100%' }}
      >
        <Tabs.List className={classes.tabsList}>
          {tabs.map((tab) => (
            <Tabs.Tab
              key={tab}
              value={tab}
              className={classes.tab}
              sx={(theme) => ({
                color:
                  activeTab === tab
                    ? `${theme.colors.blue[8]} !important`
                    : theme.colors.gray[6],
                textTransform: 'capitalize',
              })}
            >
              {tab.replace(/-/gm, ' ')}
            </Tabs.Tab>
          ))}
          {activeTab === 'billing-rates' && (
            <Box sx={{ position: 'absolute', right: 12, top: 12 }}>
              <Popover position="bottom" withArrow shadow="md" opened={opened}>
                <Popover.Target>
                  <Box
                    sx={{ margin: 'auto 0', cursor: 'pointer' }}
                    onMouseEnter={open}
                    onMouseLeave={close}
                  >
                    <IconHelp color="gray" />
                  </Box>
                </Popover.Target>
                <Popover.Dropdown style={{ pointerEvents: 'none' }}>
                  <Stack spacing={4} sx={{ textAlign: 'center' }}>
                    <Text weight="bold">
                      Billing Rate Priority List (Least to Greatest)
                    </Text>
                    <Text>Global Base Rate</Text>
                    <Text>Global Job Type Rate</Text>
                    <Text>Employee Base Rate</Text>
                    <Text>Employee Job Type Rate</Text>
                    <Text>Client Base Rate</Text>
                    <Text>Client Job Type Rate</Text>
                    <Text>Project Base Rate</Text>
                    <Text>Project Job Type Rate</Text>
                  </Stack>
                </Popover.Dropdown>
              </Popover>
            </Box>
          )}
        </Tabs.List>

        <Tabs.Panel value="general">
          <ScrollArea
            sx={{ height: `calc(100vh - ${HEADER_HEIGHT} - ${TABS_HEIGHT})` }}
          >
            <GeneralTab />
          </ScrollArea>
        </Tabs.Panel>

        <Tabs.Panel value="billing-rates">
          <ScrollArea
            sx={{ height: `calc(100vh - ${HEADER_HEIGHT} - ${TABS_HEIGHT})` }}
          >
            <BillingTab />
          </ScrollArea>
        </Tabs.Panel>
      </Tabs>
    </ConsoleLayout>
  );
}
