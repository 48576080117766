import { MutableRefObject, useMemo, useRef } from 'react';
import { Checkbox, Stack, TextInput } from '@mantine/core';
import { Formik, FormikProps, useFormikContext } from 'formik';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import BaseModal from '../BaseModal';
import ModalFooter from './ModalFooter';

import { EditUserBody } from 'utils/user';
import { MODALS } from 'constants/component';
import { InstanceUser } from 'types/api';

import useUsers from 'hooks/users/useUsers';
import useModalStore from 'hooks/store/useModalStore';
import getUserFullName from 'helpers/display/getUserFullName';
import useAuthStore from 'hooks/store/useAuthStore';
import useAuthUser from 'hooks/users/useAuthUser';
import useInstanceConfig from 'hooks/instance/useInstanceConfig';

import InstanceUserSelect from 'components/common/Select/variants/InstanceUserSelect';
import MinExpectedWorkInput from 'components/common/UserInput/variants/MinExpectedWorkInput';

function EditUserModalForm({ user }: { user?: InstanceUser }) {
  const { values, handleChange, setFieldValue } =
    useFormikContext<EditUserBody>();
  const { isAdmin } = useAuthStore();
  const { data: configData } = useInstanceConfig();
  const { user: authUser } = useAuthUser();

  const minExpectedPlaceholder = useMemo(() => {
    const minExpectedWork = configData?.config?.min_expected_work;

    if (user?.min_expected_work === 0) {
      return '0 hours';
    }

    if (typeof minExpectedWork === 'number') {
      const hourCount = minExpectedWork / 60;

      return `Company Default: ${hourCount} hour${hourCount === 1 ? '' : 's'}`;
    }

    return undefined;
  }, [configData?.config?.min_expected_work, user?.min_expected_work]);

  return (
    <Stack sx={() => ({ padding: 16, paddingBottom: 32 })} spacing={8}>
      <TextInput
        name="first_name"
        label="First Name"
        value={values.first_name}
        onChange={handleChange}
      />
      <TextInput
        name="last_name"
        label="Last Name"
        value={values.last_name}
        onChange={handleChange}
      />
      <TextInput
        name="email"
        label="Email"
        value={values.email}
        onChange={handleChange}
      />
      {isAdmin && user?.id !== authUser?.id && (
        <Checkbox
          name="is_admin"
          label="Is Admin"
          checked={Boolean(values.is_admin)}
          onChange={handleChange}
        />
      )}
      {isAdmin && (
        <InstanceUserSelect
          label="Manager"
          includeEmpty
          defaultValue={values.manager_id}
          onChange={(option) => {
            setFieldValue('manager_id', option.value);
          }}
          filter={(value) => {
            return value !== user?.id?.toString();
          }}
        />
      )}
      {isAdmin && (
        <MinExpectedWorkInput
          name="min_expected_work"
          label="Min. Daily Hours"
          placeholder={minExpectedPlaceholder}
          defaultValue={user?.min_expected_work ?? undefined}
          onValueChange={(value) => {
            setFieldValue('min_expected_work', value);
          }}
        />
      )}
    </Stack>
  );
}

function EditUserModal({ user }: { user?: InstanceUser }) {
  const modal = useModal();
  const { popModal } = useModalStore();
  const { updateUser } = useUsers();
  const formikRef = useRef() as MutableRefObject<
    FormikProps<Partial<EditUserBody>>
  >;

  const handleClose = () => {
    if (formikRef.current.isSubmitting) {
      popModal(MODALS.EDIT_USER_MODAL);
    }
  };

  const handleSubmit = async (values: Partial<EditUserBody>) => {
    if (
      await updateUser({
        userId: `${user?.id}`,
        body: { ...values, manager_id: values.manager_id || 0 },
      })
    ) {
      handleClose();
    }
  };

  const initialValues: Partial<EditUserBody> = useMemo(() => {
    return {
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      manager_id: user?.manager_id,
      is_admin: user?.is_admin,
      min_expected_work: user?.min_expected_work,
    };
  }, [user]);

  return (
    <Formik<Partial<EditUserBody>>
      enableReinitialize
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <BaseModal
        id={MODALS.EDIT_USER_MODAL}
        isOpen={modal.visible}
        onClose={handleClose}
        title={`Edit User (${getUserFullName(user)})`}
        height="60vh"
        size="40vw"
        FooterComponent={ModalFooter}
      >
        <EditUserModalForm user={user} />
      </BaseModal>
    </Formik>
  );
}

export default NiceModal.create((props) => <EditUserModal {...props} />);
