import { useState, useEffect } from 'react';
import { Stack, createStyles, Box, Text, rem } from '@mantine/core';
import { DatePickerInput, DateValue } from '@mantine/dates';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { notifications } from '@mantine/notifications';

import TicketStatusSelect from 'components/common/Select/variants/TicketStatusSelect';
import InstanceUserSelect from 'components/common/Select/variants/InstanceUserSelect';
import ContentSection from './ContentSection';
import { ProjectTicket } from 'types/api';
import { SelectOption } from 'components/common/Select/types';
import { TicketBody } from 'utils/ticket';
import { DATE_FORMATS } from 'constants/date';

import useDebounce from 'hooks/common/useDebounce';
import useProjectPageStore from 'hooks/store/useProjectPageStore';
import SubscribersGroup from './SubscribersGroup';
import { UpdateTicketSubscriptionCallback } from '../types';

interface DetailsSectionProps {
  ticket?: ProjectTicket;
  handleTicketUpdate: (ticketBody: Partial<TicketBody>) => Promise<boolean>;
  updateTicketSubscription?: UpdateTicketSubscriptionCallback;
}

const useStyles = createStyles((theme) => ({
  datePicker: {
    '& > label': {
      color: theme.white,
      fontWeight: 600,
      fontSize: theme.fontSizes.xs,
    },
  },
}));

export default function DetailsSection({
  ticket,
  handleTicketUpdate,
  updateTicketSubscription,
}: DetailsSectionProps) {
  const { classes } = useStyles();
  const { projectsMap, moveTicket, ruleErrors } = useProjectPageStore();
  const [dueDate, setDueDate] = useState<DateValue>();

  const handleStatusSelect = useDebounce(async (selected: SelectOption) => {
    if (selected.value !== undefined && selected.value !== ticket?.status) {
      const projectId = `${ticket?.project_id}`;
      const status = selected.value as string;
      const sourceGroup =
        projectsMap[projectId].ticketGroups[`dnd-list-${ticket?.status}`];
      const group = projectsMap[projectId].ticketGroups[`dnd-list-${status}`];
      const sourceIndex = sourceGroup.items.findIndex(
        (item) => item.id === ticket?.id
      );
      const destIndex = group.items.length;

      try {
        moveTicket(
          projectId,
          ticket?.status as string,
          sourceIndex,
          status,
          destIndex
        );

        await handleTicketUpdate({ status, position: group.items.length });
      } catch (err) {
        const error = err as Error;
        notifications.show({
          message: error.message,
          color: 'red',
        });
      }
    }
  });

  const handleUserSelect = useDebounce(async (selected: SelectOption) => {
    if (
      selected.value !== undefined &&
      selected.value !== ticket?.assignee?.id
    ) {
      await handleTicketUpdate({ assignee_id: selected.value as number });
    }
  });

  const handleDueDateChange = useDebounce(async (dateDue: DateValue) => {
    let dateStartString = null;

    if (dateDue) {
      dateStartString = format(dateDue, DATE_FORMATS.DATE_KEY);
    }

    await handleTicketUpdate({
      date_due: dateStartString,
    });

    setDueDate(dateDue);
  });

  // load due date state from ticket
  useEffect(() => {
    if (ticket?.date_due) {
      setDueDate((state) => {
        if (state === undefined) {
          return parse(
            ticket.date_due as string,
            DATE_FORMATS.DATE_KEY,
            new Date()
          );
        }

        return state;
      });
    }
  }, [ticket]);

  return (
    <ContentSection title="Details">
      <Stack>
        <TicketStatusSelect
          projectId={ticket?.project?.id}
          defaultValue={ticket?.status}
          onChange={handleStatusSelect}
          label="Status"
        />
        <InstanceUserSelect
          onChange={handleUserSelect}
          defaultValue={ticket?.assignee?.id}
          label="Assigned To"
          highlight={ruleErrors.includes('assignee')}
        />
        <DatePickerInput
          label="Date Due"
          className={classes.datePicker}
          value={dueDate}
          onChange={(date) => handleDueDateChange(date)}
          styles={{
            input: {
              padding: '0px 12px',
              borderRadius: '8px',
              lineHeight: '100%',
            },
          }}
        />
        <Box>
          <Text
            sx={() => ({
              fontSize: '0.75rem',
              lineHeight: '175%',
              fontWeight: 600,
            })}
          >
            People
          </Text>
          <SubscribersGroup
            ticket={ticket}
            subscribers={ticket?.subscribed_users}
            updateTicketSubscription={updateTicketSubscription}
          />
        </Box>
      </Stack>
    </ContentSection>
  );
}
