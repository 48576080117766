import { useState } from 'react';
import { Box, Group, Text, TextInput, rem } from '@mantine/core';

import Section from '../../section/Section';
import InstanceUserSelect from 'components/common/Select/variants/InstanceUserSelect';
import ClientsSelect from 'components/common/Select/variants/ClientsSelect';
import ProjectSelect from 'components/common/Select/variants/ProjectSelect';
import JobTypeSelect from 'components/common/Select/variants/JobTypeSelect';

import useBillingRatePreview from 'hooks/billing/useBillingRatePreview';

import useTimeInput from 'hooks/common/useTimeInput';
import getCurrencyDisplay from 'helpers/display/getCurrencyDisplay';
import { DOLLAR, HOUR } from 'constants/billing';

export default function BillingRatesPreview() {
  const [employee, setEmployee] = useState<string>();
  const [client, setClient] = useState<string>();
  const [project, setProject] = useState<string>();
  const [jobType, setJobType] = useState<string>();
  const {
    handleChange: handleTimeChange,
    handleBlur,
    inputValue,
    value,
  } = useTimeInput();

  const { data } = useBillingRatePreview({
    params: {
      employee_id: employee,
      client_id: client,
      project_id: project,
      job_type: jobType,
    },
    storeKey: 'billing-rate-preview',
  });

  const amount = ((data?.rate || 0) * value) / (HOUR * DOLLAR);

  return (
    <Section title="Preview">
      <Group sx={{ width: rem(400) }}>
        <InstanceUserSelect
          label="Employee"
          defaultValue={employee}
          onChange={(option) => setEmployee(option.value as string)}
          includeEmpty
        />
        <ClientsSelect
          label="Client"
          defaultValue={client}
          onChange={(option) => setClient(option.value as string)}
          includeEmpty
        />
        <ProjectSelect
          label="Project"
          defaultValue={project}
          onChange={(option) => setProject(option.value as string)}
          includeEmpty
        />
        <JobTypeSelect
          label="Job Type"
          defaultValue={jobType}
          onChange={(option) => setJobType(option.value as string)}
          includeEmpty
        />
        <TextInput
          label="Time"
          sx={{ width: '100%' }}
          onChange={handleTimeChange}
          onBlur={handleBlur}
          value={inputValue}
        />
      </Group>
      <Box>
        <Text>Estimated Billing: {getCurrencyDisplay(amount)}</Text>
      </Box>
    </Section>
  );
}
