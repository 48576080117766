import axios from './axios';

export const saveData = (() => {
  const a = document.createElement('a');
  document.body.appendChild(a);

  // @ts-ignore
  a.style = 'display: none';

  return (data: string, fileName: string, type: string) => {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

export const downloadCSV = async (
  endpoint: string,
  filename: string = `${Date.now()}.csv`,
  params: any = {}
) => {
  const res = await axios.get(endpoint, {
    params,
    headers: { Accept: 'text/csv' },
  });
  saveData(res.data, filename, 'text/csv');
};

export const downloadPDF = async (
  endpoint: string,
  filename: string = `${Date.now()}.pdf`,
  params: any = {}
) => {
  const res = await axios.get(endpoint, {
    params,
    responseType: 'blob',
    headers: { Accept: 'application/pdf' },
  });
  saveData(res.data, filename, 'application/pdf');
};
