import { ENDPOINTS } from 'constants/api';
import constructQueryPath from 'helpers/api/constructQueryPath';

import useApiQueryInfinite from 'hooks/api/useApiQueryInfinite';

import type { Activity } from 'types/api';
import { ApiQueryKey } from 'types/api/query';

interface ProjectActivityInfiniteHookParams {
  params?: any;
  projectId: number | string;
  disabled?: boolean;
  storeKey?: ApiQueryKey;
}

export default function useProjectActivityInfinite(
  hookParams: ProjectActivityInfiniteHookParams
) {
  const { params, disabled, projectId, storeKey } = hookParams || {};

  const queryResult = useApiQueryInfinite<{
    activity_items?: Activity[];
  }>({
    path: constructQueryPath(ENDPOINTS.PROJECT_ACTIVITY, {
      projectId: projectId.toString(),
    }),
    axiosConfig: {
      params,
    },
    queryOptions: {
      enabled: !disabled,
      refetchOnWindowFocus: false,
    },
    storeKey,
  });

  return {
    ...queryResult,
  };
}
