import baseAxios, { AxiosError, type AxiosRequestConfig } from 'axios';

function axios() {
  const defaultOptions = {
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/${
      window.INSTANCE_ID || process.env.REACT_APP_INSTANCE_ID
    }`,
    withCredentials: true,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'x-csrftoken',
    headers: {
      'X-CSRFToken': window.CSRF_TOKEN,
    },
  };

  const instance = baseAxios.create(defaultOptions);

  const _get = async function <R>(url: string, config?: AxiosRequestConfig) {
    try {
      return await instance.get<R>(url, config);
    } catch (err) {
      const error = err as AxiosError;
      if (error.response?.status === 403) {
        await instance.get('/auth/csrf', {
          baseURL: process.env.REACT_APP_API_BASE_URL,
        });
        return instance.get(url, config);
      } else {
        throw err;
      }
    }
  };

  const _post = async function <D, R>(
    url: string,
    data: D,
    config?: AxiosRequestConfig
  ): Promise<R | undefined> {
    try {
      return await instance.post(url, data, config);
    } catch (err) {
      const error = err as AxiosError;
      if (error.response?.status === 403) {
        await instance.get('/auth/csrf', {
          baseURL: process.env.REACT_APP_API_BASE_URL,
        });
        return instance.post(url, data, config);
      } else {
        throw err;
      }
    }
  };

  const _patch = async function <D, R>(
    url: string,
    data: D,
    config?: AxiosRequestConfig
  ): Promise<R | undefined> {
    try {
      return await instance.patch(url, data, config);
    } catch (err) {
      const error = err as AxiosError;
      if (error.response?.status === 403) {
        await instance.get('/auth/csrf', {
          baseURL: process.env.REACT_APP_API_BASE_URL,
        });
        return instance.patch(url, data, config);
      } else {
        throw err;
      }
    }
  };

  const _put = async function <D, R>(
    url: string,
    data: D,
    config?: AxiosRequestConfig
  ): Promise<R | undefined> {
    try {
      return await instance.put(url, data, config);
    } catch (err) {
      const error = err as AxiosError;
      if (error.response?.status === 403) {
        await instance.get('/auth/csrf', {
          baseURL: process.env.REACT_APP_API_BASE_URL,
        });
        return instance.put(url, data, config);
      } else {
        throw err;
      }
    }
  };

  const _delete = async function (url: string, config?: AxiosRequestConfig) {
    try {
      return await instance.delete(url, config);
    } catch (err) {
      const error = err as AxiosError;
      if (error.response?.status === 403) {
        await instance.get('/auth/csrf', {
          baseURL: process.env.REACT_APP_API_BASE_URL,
        });
        return instance.delete(url, config);
      } else {
        throw err;
      }
    }
  };

  return {
    post: _post,
    get: _get,
    patch: _patch,
    put: _put,
    delete: _delete,
  };
}

export default axios();
