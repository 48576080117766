import { Stack, TextInput, Textarea } from '@mantine/core';
import { useFormikContext } from 'formik';

import InvoiceLineItems from 'pages/Invoices/components/InvoiceLineItems';
import InvoiceDueDateSelect from 'components/common/Select/variants/InvoiceDueDateSelect';

import type { AddInvoiceRecordBody } from 'utils/billing';

function AddInvModalForm() {
  const { values, setFieldValue, status } =
    useFormikContext<AddInvoiceRecordBody>();

  if (status.loading) {
    return <></>;
  }

  return (
    <Stack spacing={8}>
      <TextInput
        required
        readOnly
        disabled
        label="Invoice ID"
        value={values.pub_id}
      />
      <InvoiceDueDateSelect
        required
        label="Due Date"
        defaultValue={values.due_date}
        onChange={(option) => {
          setFieldValue('due_date', option.value);
        }}
      />
      <Textarea
        autosize
        required
        label="Your Company Info"
        maxRows={4}
        value={values.instance_info}
        onChange={(e) => {
          setFieldValue('instance_info', e.target.value);
        }}
      />
      <InvoiceLineItems />
    </Stack>
  );
}

export default function AddInvoiceModalForm() {
  return <AddInvModalForm />;
}
