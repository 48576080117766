import ConsoleLayout from 'layouts/ConsoleLayout';
import ReportsTable from './components/ReportsTable';

import useReports from 'hooks/reports/useReports';

export default function ReportsPage() {
  const { data, isLoading } = useReports();

  return (
    <ConsoleLayout loading={isLoading}>
      <ReportsTable reports={data?.reports || []} />
    </ConsoleLayout>
  );
}
