import { useEffect } from 'react';
import {
  QueryKey,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from 'react-query';
import { AxiosRequestConfig } from 'axios';

import constructQueryKey from 'helpers/api/constructQueryKey';
import fetcher from 'helpers/api/fetcher';
import urlSerialize from 'helpers/api/urlSerialize';

import useApiStore from 'hooks/store/useApiStore';

import { ApiQueryKey } from 'types/api/query';

interface PagingParams {
  total: number;
  page: number;
  page_count: number;
  next_page: number | null;
}

export interface ApiQueryInfiniteHookParams<R> {
  path: string;
  pathParams?: { [key: string]: string };
  axiosConfig?: AxiosRequestConfig;
  queryOptions?:
    | Omit<
        UseInfiniteQueryOptions<
          R & PagingParams,
          unknown,
          R & PagingParams,
          R & PagingParams,
          QueryKey
        >,
        'queryKey' | 'queryFn'
      >
    | undefined;
  storeKey?: ApiQueryKey;
}

export default function useApiQueryInfinite<R>({
  path,
  pathParams,
  axiosConfig,
  queryOptions,
  storeKey,
}: ApiQueryInfiniteHookParams<R>) {
  const { setQueryKey, isQueryKeySet } = useApiStore();
  const queryKey = constructQueryKey(path, pathParams);
  const apiPath = queryKey.join('/');
  const apiQueryKey = queryKey.concat([
    axiosConfig?.params ? urlSerialize(axiosConfig.params) : '',
  ]);

  useEffect(() => {
    if (storeKey && !isQueryKeySet(storeKey, apiQueryKey)) {
      setQueryKey(storeKey, apiQueryKey);
    }
  }, [apiQueryKey, isQueryKeySet, setQueryKey, storeKey]);

  return useInfiniteQuery<R & PagingParams>({
    queryKey: apiQueryKey,
    queryFn: async ({ pageParam }) =>
      fetcher(apiPath, {
        ...axiosConfig,
        params: { ...axiosConfig?.params, page: pageParam || 1 },
      }),
    ...queryOptions,
    getNextPageParam: (lastPage) => lastPage.next_page,
  });
}
