import { useHotkeys } from '@mantine/hooks';
import NiceModal from '@ebay/nice-modal-react';

import ThemeProvider from 'contexts/ThemeProvider';
import QueryProvider from 'contexts/QueryProvider';
import RouterProvider from 'contexts/RouterProvider';
import { MODALS } from 'constants/component';

import useModalStore from 'hooks/store/useModalStore';

export default function App() {
  const { pushModal } = useModalStore();

  // CORS does not like localhost (dev only)
  if (
    window.location.origin.includes('localhost') &&
    process.env.NODE_ENV !== 'production'
  ) {
    window.location.assign(
      window.location.origin.replace('localhost', '127.0.0.1')
    );
  }

  useHotkeys([['ctrl+`', () => pushModal(MODALS.DEBUG)]]);

  return (
    <>
      <ThemeProvider>
        <QueryProvider>
          <NiceModal.Provider>
            <RouterProvider />
          </NiceModal.Provider>
        </QueryProvider>
      </ThemeProvider>
    </>
  );
}
