import useApiQuery from 'hooks/api/useApiQuery';

import { ENDPOINTS } from 'constants/api';
import { BillingRatesHookParams } from './types';

export default function useBillingRatePreview(
  hookParams?: BillingRatesHookParams
) {
  const { params, storeKey } = hookParams || {};

  const queryResult = useApiQuery<{ rate?: number }>({
    path: ENDPOINTS.BILLING_RATE_PREVIEW,
    axiosConfig: {
      params,
    },
    storeKey,
  });

  return {
    ...queryResult,
  };
}
