import { useState } from 'react';

import BasePopOver from '../../BasePopOver';
import PopOverDropdown from '../components/PopOverDropdown';
import PopOverTarget from '../components/PopOverTarget';

import { AddTicketEstimateCallback } from 'components/common/Modal/variants/EditTicketModal/types';

interface EstimatePopOverProps {
  updateTicketEstimate?: AddTicketEstimateCallback;
  filter?: (value: string) => boolean;
}

export default function BaseEstimatePopOver({
  updateTicketEstimate,
  filter,
}: EstimatePopOverProps) {
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <BasePopOver
      TargetComponent={PopOverTarget}
      DropdownComponent={PopOverDropdown}
      popoverProps={{
        closeOnClickOutside: false,
        opened: opened,
      }}
      dropdownProps={{
        onClose: () => setOpened(false),
        onSubmit: updateTicketEstimate,
        additionalProps: {
          selectProps: {
            filter,
          },
        },
      }}
      targetProps={{
        onClick: () => setOpened(!opened),
      }}
    />
  );
}
