import BaseSelect from '../BaseSelect';
import SelectTarget from './SelectTarget';
import { InstanceUserSelectProps } from './types';

export default function InstanceUserSelectUI({
  onChange,
  defaultValue,
  label,
  variant = 'default',
  options,
  width,
  ...props
}: InstanceUserSelectProps) {
  let TargetComponent: InstanceUserSelectProps['TargetComponent'] = undefined;
  let dropdownWidth = width;
  if (variant === 'avatar') {
    TargetComponent = SelectTarget;

    if (!dropdownWidth) {
      dropdownWidth = '200px';
    }
  }

  return (
    <BaseSelect
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      label={label}
      TargetComponent={TargetComponent}
      width={dropdownWidth}
      {...props}
    />
  );
}
