import { Box, Checkbox, ScrollArea, Stack, TextInput } from '@mantine/core';
import { Formik, FormikProps, useFormikContext } from 'formik';
import { MutableRefObject, useMemo, useRef } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import BaseModal from '../BaseModal';
import ModalFooter from './ModalFooter';
import ClientsSelect from 'components/common/Select/variants/ClientsSelect';
import BillingTypeSelect from 'components/common/Select/variants/BillingTypeSelect';
import { SelectOption } from 'components/common/Select/types';
import ProjectStatusSelect from 'components/common/Select/variants/ProjectStatusSelect';
import ProjectTicketStatusesMultiSelect from 'components/common/MultiSelect/variants/ProjectTicketStatusesMultiSelect';

import useProjects from 'hooks/projects/useProjects';
import { ClientProject } from 'types/api';
import { MODALS } from 'constants/component';
import useModalStore from 'hooks/store/useModalStore';
import RuleList from 'components/common/RuleList';
import CurrencyInput from 'components/common/UserInput/variants/CurrencyInput';
import BudgetTypeSelect from 'components/common/Select/variants/BudgetTypeSelect';

type FormValues = Pick<
  ClientProject,
  | 'title'
  | 'key'
  | 'client_id'
  | 'billing_type'
  | 'status'
  | 'billable'
  | 'status_rules'
  | 'budget_amount'
  | 'budget_type'
> & {
  ticket_statuses: string[];
};

const _initialValues: Partial<FormValues> = {
  title: '',
  key: '',
  client_id: undefined,
  billing_type: undefined,
  status: 'active',
  billable: false,
  ticket_statuses: [],
  budget_amount: 0,
  budget_type: 'total',
  status_rules: {},
};

function EditProjectModalForm() {
  const { values, handleChange, setFieldValue, touched, handleBlur, errors } =
    useFormikContext<FormValues>();

  const handleClientChange = (newClient: SelectOption) => {
    setFieldValue('client_id', newClient.value);
  };

  const handleBillingTypeChange = (newBillingType: SelectOption) => {
    setFieldValue('billing_type', newBillingType.value);
  };

  const handleStatusChange = (newStatus: SelectOption) => {
    setFieldValue('status', newStatus.value);
  };

  const handleTicketStatusesChange = (newTicketStatuses: string[]) => {
    setFieldValue('ticket_statuses', newTicketStatuses);
  };

  const handleBudgetTypeChange = (budgetType: FormValues['budget_type']) => {
    setFieldValue('budget_type', budgetType);
  };

  return (
    <Stack sx={() => ({ padding: 16, paddingBottom: 32 })} spacing={8}>
      <TextInput
        name="title"
        label="Project Name"
        value={values.title}
        onChange={handleChange}
      />
      <TextInput
        name="key"
        label="Key"
        onChange={handleChange}
        value={values.key}
      />
      <Checkbox
        label="Billable"
        name="billable"
        color="green"
        defaultChecked={values.billable}
        onChange={(e) => {
          setFieldValue('billable', e.target.checked);
        }}
      />
      <ClientsSelect
        label="Client"
        onChange={handleClientChange}
        defaultValue={values.client_id}
      />
      <BillingTypeSelect
        label="Billing Type"
        onChange={handleBillingTypeChange}
        defaultValue={values.billing_type}
      />
      <ProjectStatusSelect
        label="Status"
        onChange={handleStatusChange}
        defaultValue={values.status}
      />
      <ProjectTicketStatusesMultiSelect
        label="Ticket Statuses"
        value={values.ticket_statuses}
        onChange={handleTicketStatusesChange}
      />
      <Box>
        <Box sx={(theme) => ({ display: 'flex', columnGap: theme.spacing.sm })}>
          <CurrencyInput
            name="budget_amount"
            label="Budget"
            min={0}
            value={(values.budget_amount || 0) / 100}
            onChange={(value) =>
              setFieldValue('budget_amount', (value || 0) * 100)
            }
            error={touched.budget_amount && errors.budget_amount}
            onBlur={handleBlur}
          />
          <BudgetTypeSelect
            label="Budget Type"
            defaultValue={values.budget_type}
            onChange={(option) =>
              handleBudgetTypeChange(option.value as FormValues['budget_type'])
            }
            error={touched.budget_type ? errors.budget_type : undefined}
            onBlur={handleBlur}
          />
        </Box>
      </Box>
      <RuleList
        label="Ticket Status Rules"
        rules={values.status_rules || {}}
        setRules={(rules) => setFieldValue('status_rules', rules)}
      />
    </Stack>
  );
}

interface EditProjectModalProps {
  project: Partial<ClientProject>;
}

function EditProjectModal({ project }: EditProjectModalProps) {
  const modal = useModal();
  const formikRef = useRef() as MutableRefObject<
    FormikProps<Partial<FormValues>>
  >;
  const { popModal } = useModalStore();
  const { updateProject, isLoading } = useProjects();

  const handleClose = (force = false) => {
    if (!formikRef.current.isSubmitting || force) {
      popModal(MODALS.EDIT_PROJECT_MODAL);
      formikRef.current.resetForm();
    }
  };

  const handleSubmit = async (values: Partial<FormValues>) => {
    if (!project?.id) return;

    const success = await updateProject({
      projectId: project?.id?.toString(),
      projectBody: {
        ...values,
        ticket_statuses: values.ticket_statuses,
      },
    });

    if (success) {
      handleClose(true);
    }
  };

  const initialValues = useMemo(() => {
    const ticketStatuses = project?.ticket_statuses || null;

    return {
      title: project?.title || _initialValues.title,
      key: project?.key || _initialValues.key,
      client_id: project?.client_id || _initialValues.client_id,
      billing_type: project?.billing_type || _initialValues.billing_type,
      status: project?.status || _initialValues.status,
      ticket_statuses: ticketStatuses || _initialValues?.ticket_statuses,
      billable: project?.billable,
      status_rules: project?.status_rules || _initialValues?.status_rules,
      budget_amount: project?.budget_amount || _initialValues?.budget_amount,
      budget_type: project?.budget_type || _initialValues?.budget_type,
    };
  }, [project]);

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <BaseModal
        id={MODALS.EDIT_PROJECT_MODAL}
        isOpen={modal.visible}
        onClose={handleClose}
        title="Edit Project"
        height="80vh"
        size="60vw"
        FooterComponent={ModalFooter}
        loading={isLoading}
      >
        <ScrollArea sx={{ height: '75vh' }}>
          <EditProjectModalForm />
        </ScrollArea>
      </BaseModal>
    </Formik>
  );
}

export default NiceModal.create((props: EditProjectModalProps) => (
  <EditProjectModal {...props} />
));
