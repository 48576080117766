import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import parse from 'date-fns/parse';

import * as instanceUtils from 'utils/instance';

import { ENDPOINTS } from 'constants/api';
import { DATE_FORMATS } from 'constants/date';
import type { AvailabilityRecord, Instance } from 'types/api';

import useApiQuery from 'hooks/api/useApiQuery';
import showApiErrNotif from 'helpers/api/showApiErrNotif';

export interface AvailabilityRecordsHookParams {}

export default function useInstance() {
  const queryResult = useApiQuery<{
    instance?: Instance;
  }>({
    path: ENDPOINTS.INSTANCE,
    storeKey: 'instance',
  });

  const getDifferenceInBusinessDays = (dateStart: Date, dateEnd: Date) => {
    const days = eachDayOfInterval({ start: dateStart, end: dateEnd });
    const businessDays = queryResult?.data?.instance?.business_days || [];

    return days.filter((day) => {
      return businessDays.includes(day.getDay().toString());
    }).length;
  };

  const sumAvailabilityRecords = (
    availabilityRecords: AvailabilityRecord[]
  ) => {
    return availabilityRecords.reduce((a, b) => {
      let dayCount = 1;

      const dateStart = parse(
        b['date_start'] as string,
        DATE_FORMATS.DATE_KEY,
        new Date()
      );

      const dateEnd =
        b['date_end'] &&
        parse(b['date_end'] as string, DATE_FORMATS.DATE_KEY, new Date());

      if (dateEnd instanceof Date) {
        dayCount = Math.abs(getDifferenceInBusinessDays(dateStart, dateEnd));
      }

      return a + dayCount;
    }, 0);
  };

  const updateInstance = async (data: { logo?: File }) => {
    try {
      const formData = new FormData();

      Object.entries(data).forEach(([k, v]) => {
        formData.append(k, v);
      });

      await instanceUtils.updateInstance(formData);

      queryResult.refetch();

      return true;
    } catch (err) {
      showApiErrNotif(
        'Unable to update instance settings at this time. Try again later',
        err
      );
    }

    return false;
  };

  return {
    ...queryResult,
    getDifferenceInBusinessDays,
    sumAvailabilityRecords,
    updateInstance,
  };
}
