import { useCallback, useMemo } from 'react';
import { rem } from '@mantine/core';

import { TableColumn } from 'components/common/Table/types';
import { BaseTableProps } from 'components/common/Table/types';
import { HEADER_HEIGHT, TABS_HEIGHT } from 'constants/component';

import BaseTable from 'components/common/Table/variants/BaseTable';

export interface ReportsTableProps<T> {
  rows: T[];
  loading?: boolean;
}

export default function ReportTable<
  T extends { id?: string | number | undefined }
>({ rows, loading }: ReportsTableProps<T>) {
  const columns = useMemo(() => {
    const baseRow = rows[0];

    if (!baseRow) return [];

    return Object.keys(baseRow).map((key) => {
      return {
        id: key,
        display: key,
      };
    });
  }, [rows]);

  const getDataDisplay: BaseTableProps<T>['getDataDisplay'] = useCallback(
    (key: string | keyof T, row: T) => {
      return `${row[key as keyof T]}`;
    },
    []
  );

  return (
    <BaseTable<T>
      columns={columns}
      rows={rows}
      getDataDisplay={getDataDisplay}
      height={`calc(100vh - ${HEADER_HEIGHT} - ${TABS_HEIGHT} - ${rem(24)})`}
      loading={loading}
    />
  );
}
