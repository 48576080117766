import { useEffect } from 'react';
import { TextInput, TextInputProps } from '@mantine/core';

import useTimeInput from 'hooks/common/useTimeInput';

interface MinExpectedWorkInputProps extends TextInputProps {
  onValueChange?: (value: number) => void;
}

export default function MinExpectedWorkInput({
  defaultValue,
  onValueChange,
  ...props
}: MinExpectedWorkInputProps) {
  const {
    value,
    inputValue,
    handleChange: handleTimeChange,
    handleBlur: handleTimeBlur,
  } = useTimeInput({
    defaultValue: parseInt(defaultValue as string) ?? undefined,
  });

  useEffect(() => {
    onValueChange?.(value);

    // eslint-disable-next-line
  }, [value]);

  return (
    <TextInput
      name="min_expected_work"
      label="Min. Daily Hours"
      {...props}
      value={inputValue}
      onChange={handleTimeChange}
      onBlur={handleTimeBlur}
    />
  );
}
