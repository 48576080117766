import { BaseJsonEditorProps } from '../types';
import DateDisplaySelect from 'components/common/Select/variants/DateDisplaySelect';
import BaseJsonEditor from './Base';

interface ConfigJsonEditorProps extends BaseJsonEditorProps {}

export default function ConfigJsonEditor({
  value,
  setValue,
}: ConfigJsonEditorProps) {
  const getValueDisplay = (val: any) => {
    if (typeof val !== 'object') return val;

    const { type } = val;

    if (type === 'date_format') {
      return (
        <DateDisplaySelect
          label="Example"
          defaultValue={val.value}
          onChange={(option) => {
            setValue(
              JSON.stringify({
                ...JSON.parse(value),
                [val.name]: {
                  ...val,
                  value: option.value,
                  name: undefined,
                },
              })
            );
          }}
        />
      );
    }
  };

  return (
    <BaseJsonEditor
      value={value}
      setValue={setValue}
      getValueDisplay={getValueDisplay}
    />
  );
}
